import { Box, Button, Drawer, Tooltip, Typography, Menu, MenuItem, IconButton } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import './RadBillTrans.css'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { AmountFormat, CheckAccessFunc, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions'
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import RadBillingFilter from './RadBillingFilter'
import { localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import MoreVertIcon from '@mui/icons-material/MoreVert';

let isUser = null;
export default class RadOverAllBills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      overAllBillsData: [],
      totalBill: 0,
      totalAmount: 0,
      page: 0,
      pageSize: 10,
      FilterDatas: {
        "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "DateIndex": 1,
        "PatientName": "",
        "AccNo": "",
        "MobNo": "",
        "DoctorName": [],
        "BillNumber": "",
        "PaidAmount": "",
        "InPatientSelected": true,
        "OutPatientSelected": true,
        "CardSelected": true,
        "CashSelected": true,
        "UPISelected": true,
        "BankTransferSelected": true,
        "InsuranceSelected": true,
        "ChequeSelected": true,
        "BillType": ['op', 'ip', 'radiology'],
        "PayStatus": [],
        "PayMethods": [],
        'BillStatus': ["regular", "credit", 'return'],
        "CompletedSelected": true,
        "CreditSelected": true,
        "ReturnSelected": true,
        "CancelledSelected": false,
        "startTime": null,
        "endTime": null,
        'billType': ['Credit', 'Bill', 'Return'],
        'credit': true,
        'completed': true,
        'return': true,
        "BillModuleType": ['op', 'ip', 'radiology'],
        "opCreditBills": true,
        "ipCreditBills": true,
        'radiologyCreditBills': true,
        'RadiologyBills': true,
        "RadiologySelected": true
      },
      billSummaryId: null,
      selectedPatientData: {}
    }
  }

  componentDidMount() {
    isUser = JSON.parse(localGetItem('loggedInUserInfo'))
    this.getOverAllBillsData()
  }

  getOverAllBillsData = () => {
    try {
      let states = this.state
      let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
      let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

      let doctorId = [];
      states.FilterDatas.DoctorName.forEach(element => doctorId.push(element.doctor_id))

      RestAPIService.getAll(Serviceurls.RAD_OVER_ALL_BILL_TRANS_GET +
        "?from_date=" + this.state.FilterDatas?.FromDate +
        "&to_date=" + this.state.FilterDatas?.ToDate +
        "&start_time=" + setStartTime +
        "&end_time=" + setEndTime +
        "&patient_name=" + this.state.FilterDatas?.PatientName +
        "&patient_acc_number=" + this.state.FilterDatas?.AccNo +
        "&mobile_number=" + this.state.FilterDatas?.MobNo +
        "&doctor_id=" + doctorId +
        "&bill_number=" + this.state.FilterDatas?.BillNumber +
        "&moduel_type=" + this.state.FilterDatas.BillType +
        "&bill_type=" + this.state.FilterDatas?.BillStatus
      )
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              overAllBillsData: response.data.data,
              totalAmount: response.data.total_amount,
              totalBill: response.data.total_bill
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getOverAllBillsPrint = () => {
    try {
      let states = this.state
      let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
      let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

      let doctorId = [];
      states.FilterDatas.DoctorName.forEach(element => doctorId.push(element.doctor_id))

      this.setState({ disableBtn: true })
      RestAPIService.getAll(Serviceurls.RAD_OVER_ALL_BILL_TRANS_GET +
        "?from_date=" + this.state.FilterDatas?.FromDate +
        "&to_date=" + this.state.FilterDatas?.ToDate +
        "&start_time=" + setStartTime +
        "&end_time=" + setEndTime +
        "&patient_name=" + this.state.FilterDatas?.PatientName +
        "&patient_acc_number=" + this.state.FilterDatas?.AccNo +
        "&mobile_number=" + this.state.FilterDatas?.MobNo +
        "&doctor_id=" + doctorId +
        "&bill_number=" + this.state.FilterDatas?.BillNumber +
        "&moduel_type=" + this.state.FilterDatas.BillType +
        "&bill_type=" + this.state.FilterDatas?.BillStatus + "&export_type=pdf"
      ).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ disableBtn: false })
        }).catch((error) => {
          this.setState({ disableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ disableBtn: false })
      this.errorMessage(error.message)
    }
  }

  getPatientData = (data, value) => {
    try {
      let { history } = this.props
      RestAPIService.getAll(Serviceurls.FO_PATIENT_UPDATE + '?patient_id=' + data?.patient_id)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              selectedPatientData: response.data.data
            }, () => {
              setCachevalue(JSON.stringify({...this.state.selectedPatientData, ...{invoice_date : data?.invoice_date ? data?.invoice_date : ""}}), 'RadPatientData')
              if (value === 'ModifyBill') {
                setCachevalue(true, "isForCancelBill")
                setCachevalue(JSON.stringify({ fromModifyBill: true, billSummaryId: data?.id, ForCancel: true, AmountNet: data?.net_amount, FromBills: false, isForEditBill: value === "ModifyBill" }), "RadCancelBillData")
                this.props.history?.push({ pathname: '/RadiologyHome/Patient/Billing', state: { fromModifyBill: true, billSummaryId: data?.id } })
              } else if (value === 'cancelBill') {
                setCachevalue(true, "isForCancelBill")
                setCachevalue(JSON.stringify({ fromCancelBill: true, billSummaryId: data?.id, ForCancel: true, AmountNet: data?.net_amount, FromBills: false, isForEditBill: value === "cancelBill" }), "RadCancelBillData")
                this.props.history?.push({ pathname: '/RadiologyHome/Patient/Billing', state: { fromCancelBill: true, billSummaryId: data?.id } })
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
    let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
    let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

    let TextArray = [
      { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
      { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
    ]
    if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
      TextArray.push(
        { label: "Start Time", value: timeOnlyConvert(setStartTime) },
        { label: "End Time", value: timeOnlyConvert(setEndTime) }
      )
    }
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  ClosePopUp(Getapicall = false, message = "") {
    this.setState({
      FilterOpen: false,
      BillPreviewPop: false,
      CreditBillPopUp: false
    }, () => {
      if (Getapicall && message !== "") {
        this.successMessage(message)
      }
    })
  }

  renderFilter = (data) => {
    this.setState({
      FilterDatas: data,
      FilterOpen: false,
    }, () => { this.getOverAllBillsData() })
  }

  clearFilter = (data) => {
    this.setState({
      FilterDatas: data
    })
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  MenuItem = (data) => {
    const { t } = this.props
    let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
    const multi_option = [
      { value: "ModifyBill", label: ("Modify Bill") },
      { value: "cancelBill", label: ("Cancel Bill") },
      // { value: "ModifyHistory", label: ("Modify History") },
    ]

    if (data?.payment_status !== 'not paid') {
      multi_option?.splice(0, 1)
    }
    return (
      <div>
        <Tooltip title={("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            key={data?.id}
            elevation={1}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option, index) => (
              <MenuItem key={index}
                onClick={() => {
                  this.setState({
                    billSummaryId: data?.id
                  }, () => {
                    if (option?.value === 'ModifyBill' || option?.value === 'cancelBill') {
                      this.getPatientData(data, option?.value)
                    } else {

                    }
                  })
                }}
              >
                {(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  GetBillPrint = (id) => {
    try {
      this.setState({ disableBtn: true })
      RestAPIService.getAllPrint(Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + id + "&export_type=pdf").
        then((response) => {
          this.setState({ disableBtn: false })
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.setState({ disableBtn: false })
          this.errorMessage(error.response?.data?.message, false);
        })
    } catch (error) {
      this.errorMessage(error.message, false)
    }
  }

  render() {
    this.state.overAllBillsData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'invoice_date', headerName: 'Date', flex: 0.09, align: 'center', headerAlign: 'center',
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_date_ovr'>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>)
      },
      {
        field: 'invoice_number', headerName: 'Bill Number', flex: 0.09,
        renderCell: (params) => {
          let BillNumber = "";
          if (params?.row?.invoice_number) { BillNumber = params?.row?.invoice_number }
          const billArr = BillNumber ? BillNumber?.split("/") : [];
          return (
            <Tooltip placement='top' title={BillNumber} arrow>
              <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
            </Tooltip>
          )
        }
      },
      {
        field: 'module_type', headerName: 'Module Type', flex: 0.09,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_bill_type_ovr'>{params?.row?.module_type ? params?.row?.module_type : '-'}</Box>)
      },
      {
        field: 'bill_type', headerName: 'Bill Type', flex: 0.09,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_bill_type_ovr'>
          {((params?.row?.bill_type) || (params?.row?.payment_status)) ?
            <Box >
              <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.bill_type ? this.capitalizeFirstLetter(params?.row?.bill_type) : '-'}</Typography>
              <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{params?.row?.payment_status ? this.capitalizeFirstLetter(params?.row?.payment_status) : '-'}</Typography>
            </Box> : '-'
          }
        </Box>)
      },
      {
        field: 'patient_name', headerName: 'Patient Details', flex: 0.09,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_pat_name_ovr'>
          <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.patient_name ?
            params?.row?.patient_name?.length > 10 ?
              <Tooltip placement='top' title={params?.row?.patient_name + " " + params?.row?.patient_age + '/' + params?.row?.patient_gender?.toUpperCase()}>
                <div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.patient_name?.slice(0, 10) + '...' + (params?.row?.patient_age ? params?.row?.patient_age : '-') + '/' + (params?.row?.patient_gender ? params?.row?.patient_gender?.toUpperCase() : '-')}</div>
              </Tooltip> :
              (params?.row?.patient_name + " " + (params?.row?.patient_age ? params?.row?.patient_age : '-') + '/' + (params?.row?.patient_gender ? params?.row?.patient_gender?.toUpperCase() : '-')) : '-'}</Typography>
          <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.patient_acc_number ? params?.row?.patient_acc_number : '-'}</Typography>
        </Box>)
      },
      {
        field: 'service_data', headerName: 'Service Name & Type', flex: 0.09,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_svr_name_ovr'>{params?.row?.service_data ?
          params?.row?.service_data?.length > 15 ?
            <Tooltip title={params?.row?.service_data} placement='top' arrow><Typography>{params?.row?.service_data?.slice(0, 15) + '...'}</Typography></Tooltip> :
            <Typography>{params?.row?.service_data}</Typography> : '-'}</Box>)
      },
      // {
      //   field: 'payment_mode_types', headerName: 'Payment Method', flex: 0.09,
      //   renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_pay_mtd_ovr'>{params?.row?.payment_mode_types ? params?.row?.payment_mode_types?.length > 15 ?
      //     <Tooltip placement='top' title={params?.row?.payment_mode_types} arrow>
      //       <div>{params?.row?.payment_mode_types.slice(0, 15) + "..."}</div></Tooltip> :
      //     params?.row?.payment_mode_types : "-"}</Box>)
      // },
      {
        field: 'net_amount', headerName: 'Bill Amount', flex: 0.09,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_bill_amt_ovr'>{AmountFormat(params?.row?.net_amount ? params?.row?.net_amount : 0)}</Box>)
      },
      {
        field: 'received_amount', headerName: 'Received', flex: 0.09,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_revd_ovr'>{AmountFormat(params?.row?.received_amount ? params?.row?.received_amount : 0)}</Box>)
      },
      {
        field: 'Action', headerName: 'Action', flex: 0.09,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_act_ovr' display={'flex'}>
          <Tooltip placement='top' title={'Print'}>
            <Button className='Common_Btn_Min_Width' emed_tid='rad_bill_trns_prt_ovr'
              disabled={this.state.disableBtn}
              onClick={() => this.GetBillPrint(params?.row?.id)}
            >
              <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
            </Button>
          </Tooltip>
          {this.MenuItem(params?.row)}
        </Box>)
      }
    ]
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='rad_bill_trns_container'>
          <Box component={'div'} className='rad_bill_trns_header_card'>
            <Box component={'div'} className='rad_bill_trns_cards'>
              {AmountsCard('Total Bill', this.state.totalBill, true, "white", false, null, 'rad_bill_trns_tlt_bill_ovr')}
              {AmountsCard('Total Amount', this.state.totalAmount, false, "white", false, null, 'rad_bill_trns_tlt_amt_ovr')}
            </Box>
            <Box component={'div'} className='rad_bill_trns_btns'>
              <Tooltip placement='top' title={'Filter'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_flt_ovr'
                  onClick={() => this.setState({ FilterOpen: true })} >
                  <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              <Tooltip placement='top' title={'Print'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_prt_ovr'
                disabled={this.state.disableBtn}
                onClick={() => this.getOverAllBillsPrint()}
                >
                  <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              {/* <Tooltip placement='top' title={'Export'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_exp_ovr'>
                  <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip> */}
            </Box>
          </Box>
          <Box component={'div'} className='rad_bill_trns_content_card'>
            <DataGrid
              sx={{ backgroundColor: Colors.white }}
              columns={columns}
              rows={this.state.overAllBillsData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <RadBillingFilter PageName={"OverallBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} callDocApi={true}/>
        </Drawer>
      </Box>
    )
  }
}
