import { Autocomplete, Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Demo_Layout_Default_JSON } from '../../../../Utility/Constants';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import Webcam from "react-webcam";
import { Close } from '@material-ui/icons';
import { clearCachevalue, getCachevalue, setCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { CommonImgPreviewPop, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Colors } from '../../../../Styles/Colors';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../../Components/Loader';
import moment from "moment";
import { City, Country, State } from 'country-state-city';


const videoConstraints = {
  width: 500,
  height: 420,
  facingMode: "user",
  borderRadius: 100
};

class CreateApppointment extends Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
    this.state = {
      layoutData: [],
      mandotory_count: '03',
      refDoctorData: [],
      refSourceData: [],
      CorporateList: [],
      referredBy: "",
      referralSource: "",
      mobile_no: "",
      first_name: "",
      age: "",
      dob: null,
      gender: "",
      title: "",
      middle_name: "",
      surname: "",
      address_1: "",
      address_2: "",
      city: "",
      cityName: City.getCitiesOfState("IN", "TN"),
      country_code: 'IN',
      state_code: 'TN',
      AllCountry: Country.getAllCountries(),
      AllStates: State.getStatesOfCountry("IN"),
      pin_code: "",
      employer_id: "",
      employee_id: "",
      email_id: "",
      marital_status: "",
      father_husband_name: "",
      father_husband_age: "",
      occupation: "",
      emergency_contact_person: "",
      emergency_contact_mobile: "",
      relationship: "",
      docDocument: null,
      aadharProof: null,
      panProof: null,
      passportProof: null,
      rationProof: null,
      dlProof: null,
      doctorList: [],
      filteredDocList: [],
      spcInfo: [],
      tagDetails: [],
      AppointmentTypeList: [],
      selectedDocId: null,
      selectedSpecId: null,
      selectedTagId: null,
      selectedAppointTypeId: null,
      appointmentDateTime: new Date(),
      purposeOfVisit: "",
      MandatoryLabels: [],
      CorporateList: [],
      patient_img: null,
      temp_patient_img: null,
      ProfilePicClicked: false,
      pancard_number: "",
      ration_card_number: "",
      dl_number: "",
      passport_number: "",
      aadhar_card_number: "",
      SelectedPatientID: "",
      SelectedDocSRC: null,
      SameMobileNumPatients: [],
      isForIPUpdate: false,
      receive_email: false,
      receive_whatsapp: false,
      receive_sms: false,
      refDocumentSelected: null,
      insurance_company_name: null,
      policy_number: "",
      guardian: "",
      DateOpen: false,
      isPatientCreated: false,
      CreatedPatientId: null,
      createdBy: '',
      modifiedBy: '',
      NurseList: [],
      selectedNurseId: null,
      mother_name: "",
      mother_age: "",
      POVList: [],
      InsuranceList: [],
      NewInsurance: false,
      RequestedPatientData: {},
      DoctorPatientDetails: {},
      dateLimit: 90,
      dateLimitType: 'Days',
      isFrontCamera: true,
      facingMode : 'user',
      devices : [],
      doctorPatientId: null,
      disRefferedBy: false,
      disRefferedSource: false
    }
    this.uploadImage = React.createRef();
  }

  componentDidMount() {
    this.getDemoLayout()
    this.getReferralDctorData()
    this.getRefSourceData()
    this.GetCorporateList()
    this._getTagDetails();
    this._getCorpList();
    this._getAppointTypeList()
    this.getInsuranceList()
    this.getQmsConfig()
    this.getConnectedDevices()

    let POVList = localGetItem("PurposeOfVisit") === null ? '[]' : localGetItem("PurposeOfVisit")
    let array = []
    JSON.parse(POVList)?.forEach(element => array.push(element.reason));
    if (array.length > 0) {
      this.setState({
        POVList: array
      })
    }
    if (this.props.history?.location?.state?.AppointDate) {
      this.setState({
        appointmentDateTime: this.getDateTime(this.props.history?.location?.state?.AppointDate)
      }, () => { console.log(this.state.appointmentDateTime) })
    }

    let patientDetails = this.props.fromOT ? getCachevalue("OTpatientData") : getCachevalue("DoctorPatientData");
    let patients = JSON.parse(patientDetails)
    let fromDoctorUpdate = this.props.history?.location?.state?.doctorUpdate
    let fromDemographic = getCachevalue('updatePatient')
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    this.setState({ isQmsConfig: userData?.is_qms_enabled ? userData?.is_qms_enabled : false})
    if (fromDoctorUpdate) {
      this.LoaderFunction(true)
      this.setState({
        SelectedPatientID: patients?.patient_id
      }, () => { this.getPatientDemographics() })
    } else if (fromDemographic) {
      this.LoaderFunction(true)
      this.setState({
        SelectedPatientID: patients?.patient_id,
        appointmentId: patients?.appointment_id
      }, () => this.getPatientDemographics())
    }
    if (patients?.patient_id) {
      this.LoaderFunction(true)
      this.setState({ SelectedPatientID: patients?.patient_id }, () => { this.getPatientDemographics() })
    }
    if ((getCachevalue("AppRequestPatientData") && getCachevalue("AppRequestPatientData") !== JSON.stringify(this.state.RequestedPatientData))) {
      let Data = JSON.parse(getCachevalue("AppRequestPatientData"))
      this.setState({
        RequestedPatientData: Data,
        first_name: Data?.first_name ? Data?.first_name : "",
        mobile_no: Data?.mobile_number ? Data?.mobile_number : "",
        selectedAppointTypeId: Data?.appoinment_type ? Data?.Data?.appoinment_type : null,
        purposeOfVisit: Data?.message ? Data?.message : "",
        appointmentDateTime: new Date(`${Data?.appointment_date} ${Data?.appointment_time}`),
      }, () => {
        let fromRequest = this.props?.history?.location?.state?.fromRequest
        if (Data?.age !== "") {
          this.setState({
            age: !fromRequest ? Data?.patient_age : Data?.age,
            dob: !fromRequest ? this.handleFindApproxDob(Data?.patient_age) : this.handleFindApproxDob(Data?.age)
          })
        }
        if (Data?.patient_id) {
          this.setState({
            SelectedPatientID: Data?.patient_id ? Data?.patient_id : ""
          }, () => {
            if (fromRequest) {
              this.LoaderFunction(true)
              this.getPatientDemographics()
            }
          })
        }
      })
    }
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    }, ()=>{
      this.LoaderFunction(false)
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    }, ()=>{
      this.LoaderFunction(false)
    })
  }

  getPatientDemographics = (data) => {
    try {
      let fromDemographic = getCachevalue('updatePatient')
      this.LoaderFunction(true)
      let patient_id = data ? data : this.state.SelectedPatientID
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + "?patient_id=" + patient_id)
        .then(response => {
          if (response.data.status === "success") {
            this.assignDemographicsData(response.data.data)
            this.setState({refer:response?.data?.data?.patient_id ? true : false})
            if (data && fromDemographic) {
              this.getDoctorDemographicsData()
            } else if (data) {
              let list = response?.data?.data;
              setCachevalue(JSON.stringify(list), 'DoctorPatientData')
              setCachevalue(JSON.stringify(list?.patient_id), 'DoctorPatientId')
            }
          }
        })
        .catch(error => {
          this.errorMessage(error.message)
        });
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDoctorDemographicsData = () => {
    try {

      RestAPIService.getAll(Serviceurls.FO_PATIENT_APPOINTMENT_DETAILS + '?appointment_id=' + this.state.appointmentId)
        .then((response) => {
          if (response.data.status === 'success') {
            let list = response?.data?.data;
            setCachevalue(JSON.stringify(list), 'DoctorPatientData')
            setCachevalue(JSON.stringify(list?.patient_id), 'DoctorPatientId')
          }
        }).catch(error => {
          this.errorMessage(error.message)
        });
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getInsuranceList = () => {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST + '?clinic_id=' + clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              InsuranceList: response.data.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  // postNewInsurance = () => {
  //   var states = this.state

  //   var data = {
  //     "insurance_name": states.insurance_company_name
  //   }

  //   RestAPIService.create(data, Serviceurls.FO_INSURANCE_LIST)
  //     .then((response) => {
  //       if (response.data.status === 'success') {
  //       }
  //     }).catch((e) => {
  //       if (e?.response?.data?.message) {
  //         this.errorMessage(e.response.data.message)
  //       } else {
  //         this.errorMessage(e.message)
  //       }
  //     })
  // }

  getQmsConfig = () => {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.QMS_CONFIG + '?clinic_id=' + clinicId)
        .then(response => {
          if (response?.data?.status === "success") {
            let data = response?.data?.data
            this.setState({
              dateLimit: data?.appointment_date_limit ? data?.appointment_date_limit : 90,
              dateLimitType: data?.duration_type ? data?.duration_type : 'Days',
            })
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message);
          } else {
            this.errorMessage(error?.message)
          }
        });
    } catch (e) {
      this.errorMessage(e.message);
    }
  }

  getDateTime = (date) => {
    var dateParts = date.split("-");
    var day = parseInt(dateParts[0]);
    var month = parseInt(dateParts[1]) - 1;
    var year = parseInt(dateParts[2]);

    var originalDate = new Date(year, month, day);
    var currentTime = new Date();
    originalDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
    return originalDate
  }

  assignDemographicsData(Datas) {
    try {
      this.setState({
        referredBy: Datas.reffered_by,
        referralSource: Datas.referral_source,
        mobile_no: Datas.mobile_number,
        first_name: Datas.first_name,
        age: this.handleAgeCalculation(Datas.dob !== null ? new Date(Datas?.dob) : new Date(Datas?.approx_dob)),
        dob: Datas.dob !== null ? new Date(Datas?.dob) : new Date(Datas?.approx_dob),
        gender: Datas.gender,
        title: Datas.title,
        middle_name: Datas.middle_name,
        surname: Datas.last_name,
        address_1: Datas.address_line_1,
        address_2: Datas.address_line_2,
        country_code: this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode,
        AllStates: State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode),
        state_code: State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode).find((item) => (item.name === Datas?.state_name))?.isoCode,
        city: Datas?.city_name,
        pin_code: Datas.pincode,
        employer_id: Datas.employer_id,
        employee_id: Datas.id_no,
        email_id: Datas.email,
        marital_status: Datas.marital_status,
        father_husband_name: Datas.father_husband_name,
        father_husband_age: Datas.father_husband_age ? Datas.father_husband_age : "",
        occupation: Datas.occupation,
        emergency_contact_person: Datas.caretaker_name,
        emergency_contact_mobile: Datas.caretaker_mobile,
        relationship: Datas.caretaker_relationship,
        docDocument: Datas.document_key === "" ? null : Datas.document_key,
        aadharProof: Datas.aadhar === "" ? null : Datas.aadhar,
        panProof: Datas.pan === "" ? null : Datas.pan,
        passportProof: Datas.passport === "" ? null : Datas.passport,
        rationProof: Datas.ration_card === "" ? null : Datas.ration_card,
        dlProof: Datas.dl === "" ? null : Datas.dl,
        pancard_number: Datas.pancard_number,
        ration_card_number: Datas.ration_card_number,
        dl_number: Datas.dl_number,
        passport_number: Datas.passport_number,
        aadhar_card_number: Datas.aadhar_card_number,
        patient_img: Datas?.photo_url === "" ? null : Datas.photo_url,
        temp_patient_img: Datas?.photo_url === "" ? null : Datas.photo_url,
        receive_email: Datas?.receive_email,
        receive_whatsapp: Datas?.receive_whatsapp,
        receive_sms: Datas?.receive_sms,
        insurance_company_name: Datas?.insurance_company_name,
        policy_number: Datas?.policy_number,
        guardian: Datas?.guardian,
        createdBy: Datas?.created_by,
        modifiedBy: Datas?.modified_by,
        mother_name: Datas?.mother_name == null ? "" : Datas?.mother_name,
        mother_age: Datas?.mother_age == null ? "" : Datas?.mother_age,
        purposeOfVisit: Datas?.purpose_of_visit,
        disRefferedBy: Datas.reffered_by == "" ? false : true,
        disRefferedSource: Datas.referral_source == "" ? false : true
      }, () => {
        this.setState({ cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code) })
        this.LoaderFunction(false)
      })
    }
    catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e?.message)
    }
  }

  _getTagDetails = () => {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPPATIENTTAG + '?clinic_id=' + clinicId)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              tagDetails: response.data.data,
            });
          }
        })
        .catch(error => {
          this.errorMessage(error.message)
        });
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  _getCorpList = () => {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE + '?clinic_id=' + clinicId)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              CorporateList: response.data.data,
            });
          }
        })
        .catch(error => {
          this.errorMessage(error.message)
        });
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  _getAppointTypeList = () => {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT + '?clinic_id=' + clinicId)
      .then(response => {
        if (response.data.status === "success") {
          this.setState({
            AppointmentTypeList: response.data.data.filter((item) => (item.is_active === true)),
          }, () => {
            if (Object.keys(this.state.RequestedPatientData).length > 0) {
              this.setState({
                selectedAppointTypeId: response.data.data.find((item) => (item.appoinment_type === "Telephonic Appointment"))
              })
            } else {
              this.setState({
                selectedAppointTypeId: response.data.data.find((item) => (item.appoinment_type === "Walk in Appointment"))
              })
            }
          });
        }
      })
      .catch(error => {
        this.errorMessage(error.message)
      });
  }

  getDemoLayout = () => {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.FODemographicsLayout + '?clinic_id=' + clinicId)
        .then((response) => {
          if (response.data.status === "success") {
            var Data = response.data.data
            var len = Data.length
            this.setState({
              layoutData: Data[len - 1] ? Data[len - 1].field_order : Demo_Layout_Default_JSON,
              mandotory_count: Data[len - 1] ? ("0" + Data[len - 1].mandatory_field_count) : '04',
            }, () => {
              let MandLabels = []
              for (let i = 0; i < +this.state.mandotory_count; i++) {
                MandLabels.push(this.state.layoutData[i].label)
              }
              this.setState({ MandatoryLabels: MandLabels }, () => { this.LoaderFunction(false) })
            })
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getReferralDctorData = () => {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.DOC_REFERRAL_DOCTOR + '?clinic_id=' + clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              refDoctorData: response.data.data
            })
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getRefSourceData = () => {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.DOC_REFERRAL_SOURCE + '?clinic_id=' + clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              refSourceData: response.data.data
            })
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  GetCorporateList() {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE + '?clinic_id=' + clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              CorporateList: response.data.data
            })
          }
          else {
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  CheckExistingPatients() {
    try {
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.mobile_no}&address_key=&father_husband_key=&clinic_id=${clinicId}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SameMobileNumPatients: response.data.data
            })
          }
          else {
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  alphaValidation = (num) => {
    return !/[^A-Z a-z]+/.test(num)
  }
  numberValidation(num) {
    return /^\d+$/.test(num);
  }

  handleAgeCalculation = date => {
    // let age = "";
    // let dob = DateTime.fromJSDate(date);
    // var year_age = DateTime.now().diff(dob, 'years');
    // age = Math.floor(year_age.years) || 0;
    // return (date < new Date() && date > new Date("1872-01-01")) ? age : "";
    let age = "";
    let dob = new Date(date);
    let m = moment(dob, "DD-MM-YYYY");
    var end_date = moment();

    var year_age = end_date.diff(m, 'year') || 0;
    // reset(year) year with difference year
    m = moment(m).add(year_age, 'years');

    var month_age = end_date.diff(m, 'month') || 0;
    // reset(month) month with difference month
    m = moment(m).add(month_age, 'months');

    var day_age = end_date.diff(m, 'days') || 0;

    if (year_age > 0) {
      age = year_age || 0;
    } else {
      age = year_age + "Y/" + month_age + "M/" + day_age + "D";
    }
    return (date < new Date() && date > new Date("1872-01-01")) ? age : "";
  }

  handleFindApproxDob = ageEntered => {

    var year = 0;
    var month = 0;
    var days = 0;
    var tempDate;
    year = +(ageEntered)
    if (!year) {
      tempDate = null;
    } else {
      tempDate = DateTime.now().minus({ years: year }).toBSON();
    }

    return tempDate;
  }
  getPincodeToCity = (pincode) => {
    fetch("https://api.postalpincode.in/pincode/" + pincode)
      .then((res) => res.json())
      .then(response => {
        if (response && response[0].Status == "Success") {
          var data = response[0].PostOffice
          if (data && data.length > 0) {
            this.setState({
              city: this.state?.city ? this.state?.city : data[0].District,
              state: this.state?.state ? this.state?.state : data[0].State,
              country: this.state?.country ? this.state?.country : data[0].Country
            })
          }
        } else if (response.data.status == "fail") {
        }
      })
      .catch(e => {
      });
  }
  renderInput = (data, type) => {
    var states = this.state
    const { t } = this.props
    return (
      <form>
        <Box sx={{ marginTop: '2.5vh', marginLeft: '1vw', width: (data.label == "Age" || data.label == "Title") ? '7vw' : '12vw' }}>
          {data.label == "Date of Birth" ?
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                inputFormat={"dd-MM-yyyy"}
                autoComplete="off"
                value={states[data.field_name]}
                minDate={new Date("1872-01-01")}
                maxDate={DateTime.now()}
                onChange={(newDate) => {
                  var age = this.handleAgeCalculation(newDate)
                  this.setState({
                    dob: newDate,
                    age: age
                  })
                }}
                renderInput={(params) => <TextField {...params} size='small' autoComplete="off"
                  variant="outlined" fullWidth />}
                emed_tid='age_date_picker'
              />
            </LocalizationProvider>
            :
            data.label == "Title" ?
              <FormControl size='small' fullWidth sx={{ textAlign: 'center', height: '1.3vw' }}>
                <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                <Select
                  labelId="title-select-label"
                  MenuProps={{ style: { maxHeight: 250 } }}
                  size='small'
                  sx={{ fontSize: '0.8vw', }}
                  value={states[data.field_name]}
                  label={t(data.label)}
                  inputProps={{emed_tid:data.field_name}}
                  onChange={(e) => {
                    states[data.field_name] = e.target.value

                    this.setState({
                      states
                    }, () => {
                      var states = this.state
                      if (states.title == "Mr") {
                        states["gender"] = "m";
                      } else if (states.title == "Ms") {
                        states["gender"] = "f";
                      } else if (states.title == "Mrs") {
                        states["gender"] = "f";
                        states["marital_status"] = "Married"
                      }
                      this.setState({ states })
                    })
                  }}
                >
                  <MenuItem value={""}>{"Title"}</MenuItem>
                  <MenuItem value={"Dr"}>{"Dr"}</MenuItem>
                  <MenuItem value={"Mr"}>{"Mr"}</MenuItem>
                  <MenuItem value={"Ms"}>{"Ms"}</MenuItem>
                  <MenuItem value={"Mrs"}>{"Mrs"}</MenuItem>
                  <MenuItem value={"Master"}>{"Master"}</MenuItem>
                  <MenuItem value={"Baby"}>{"Baby"}</MenuItem>
                  <MenuItem value={"Baby of"}>{"Baby of"}</MenuItem>
                </Select>
              </FormControl>
              : data.label == "Gender" ?
                <FormControl size='small' sx={{ width: '10vw' }} emed_tid={data?.field_name}>
                  <InputLabel id="gender-select-label">{"Gender *"}</InputLabel>
                  <Select
                    sx={{ width: '10vw' }}
                    labelId='gender-select-label'
                    size='small'
                    label={t(data.label)}
                    value={states[data.field_name]}
                    onChange={(e) => {
                      states[data.field_name] = e.target.value
                      this.setState({
                        states
                      })
                    }}
                  >
                    <MenuItem key={1} value={"m"}>{t("Male")}</MenuItem>
                    <MenuItem key={2} value={"f"}>{t("Female")}</MenuItem>
                    <MenuItem key={2} value={"t"}>{t("TransGender")}</MenuItem>
                  </Select>
                </FormControl>
                : data.label === "City" ?
                  <Autocomplete
                    sx={{ width: "12vw" }}
                    size="small"
                    options={states.cityName ? states.cityName : []}
                    getOptionLabel={(option) => typeof (option) === "string" ? option : option.name}
                    onChange={(e, value) => {
                      this.setState({
                        city: value?.name ? value?.name : '',
                        // pin_code: '',
                      })
                    }}
                    clearIcon={false}
                    value={states.city}
                    renderInput={(params) => (
                      <TextField
                        label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                        InputProps={{ ...params.InputProps }}
                        onChange={(e) => this.setState({
                          city: e.target.value,
                          // pin_code: ''
                        })}
                        {...params}
                      />)}
                    onKeyDown={(e) => e.key === "Enter" ? e.preventDefault() : null}
                  />
                  : data.label === "Insurance Company Name" ?
                    <Autocomplete
                      sx={{ width: "12vw" }}
                      size="small"
                      options={states.InsuranceList}
                      getOptionLabel={(option) => (typeof (option) === "string" ? option : option.insurance_name)}
                      onChange={(event, value) => {
                        states["insurance_company_name"] = value?.insurance_name ? value?.insurance_name : ""
                        states["NewInsurance"] = false
                        this.setState({
                          states
                        })
                      }}
                      clearIcon={false}
                      value={states.insurance_company_name}
                      renderInput={(params) => (
                        <TextField
                          label={t("Insurance Company Name")} InputProps={{ ...params.InputProps }}
                          // onChange={(event) => {
                          //   states["insurance_company_name"] = event.target.value
                          //   states["NewInsurance"] = true
                          //   this.setState({
                          //     states
                          //   })
                          // }}
                          {...params}
                        />
                      )}
                    />
                    : data.label == "Mobile no" ?
                      <TextField
                        disabled={Object.keys(this.state.RequestedPatientData).length > 0}
                        label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                        autoComplete="off"
                        size="small"
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{"+91"}</InputAdornment>
                        }}
                        value={states[data.field_name]}
                        onChange={(e) => {
                          var states = this.state
                          var isMobile = this.numberValidation(e.target.value)
                          if (data.label == "Mobile no") {
                            let mobileNum = e.target.value
                            if ((isMobile && mobileNum.length < 11) || e.target.value == "") {
                              states[data.field_name] = e.target.value
                              if (e.target.value.length === 10) { this.CheckExistingPatients() }
                            }
                          }
                          this.setState({ states })
                        }}
                        error={states.mobile_no !== "" && states.mobile_no.length !== 10}
                      />
                      : data.label == "Corporate Name" ?
                        <FormControl size='small' sx={{ width: '12vw' }}>
                          <InputLabel id="gender-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                          <Select
                            sx={{ width: '12vw' }}
                            MenuProps={{ style: { maxHeight: 250 } }}
                            labelId='gender-select-label'
                            size='small'
                            label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                            value={states[data.field_name]}
                            onChange={(e) => {
                              states[data.field_name] = e.target.value
                              if (e.target.value === "") { states.employee_id = "" }
                              this.setState({
                                states
                              })
                            }}
                          >
                            <MenuItem value={""}>{"None"}</MenuItem>
                            {
                              states.CorporateList.length > 0 ?
                                states.CorporateList.map((list, index) => (
                                  <MenuItem key={index} value={list.id}>{list.company_name}</MenuItem>
                                )) : null
                            }
                          </Select>
                        </FormControl>
                        : data.label == "Marital Status" ?
                          <FormControl size='small' sx={{ width: '12vw' }}>
                            <InputLabel id="gender-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                            <Select
                              sx={{ width: '12vw' }}
                              labelId='gender-select-label'
                              size='small'
                              label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                              value={states[data.field_name]}
                              onChange={(e) => {
                                states[data.field_name] = e.target.value
                                this.setState({
                                  states
                                })
                              }}
                            >
                              <MenuItem key={1} value={"Single"}>{t("Single")}</MenuItem>
                              <MenuItem key={2} value={"Married"}>{t("Married")}</MenuItem>
                              <MenuItem key={2} value={"Divorced"}>{t("Divorced")}</MenuItem>
                            </Select>
                          </FormControl>
                          : data.label == "Employee ID No" ?
                            <TextField
                              autoComplete="off"
                              inputProps={{ maxLength: 20 }}
                              label={(type === 'mandatory' || data.is_default || states.employer_id) ? (t(data.label) + "*") : t(data.label)}
                              size="small" value={states[data.field_name]}
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              onChange={(e) => {
                                states[data.field_name] = e.target.value.toUpperCase()
                                this.setState({ states })
                              }}
                              disabled={states.employer_id === null || states.employer_id === ""}
                            />
                            : data.label == "State" ?
                              // <RegionDropdown className='eMed_demo_dropdown_inputs'
                              //   country={this.state.country}
                              //   value={this.state.state}
                              //   onChange={(val) => {
                              //     states[data.field_name] = val
                              //     this.setState({ states })
                              //   }} />
                              <FormControl size='small' sx={{ width: '12vw' }}>
                                <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                                <Select
                                  sx={{ width: '12vw' }}
                                  MenuProps={{ style: { maxHeight: 250, maxWidth: '12vw' } }}
                                  labelId='gender-select-label'
                                  size='small'
                                  label={data.label}
                                  value={states.state_code}
                                  onChange={(e) => {
                                    this.setState({ state_code: e.target.value },
                                      () => {
                                        this.setState({ cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code), city: ''})
                                      })
                                  }}
                                >
                                  {
                                    states.AllStates.map((list) => (
                                      <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                              : data.label == "Country" ?
                                <FormControl size='small' sx={{ width: '12vw', maxWidth: '12vw' }}>
                                  <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                                  <Select
                                    sx={{ width: '12vw' }}
                                    MenuProps={{ style: { maxHeight: 250, maxWidth: '12vw' } }}
                                    labelId='gender-select-label'
                                    label={data.label}
                                    size='small'
                                    value={states.country_code}
                                    onChange={(e) => {
                                      this.setState({ country_code: e.target.value, state_code: "", city: '' }, () => {
                                        this.setState({ AllStates: State.getStatesOfCountry(this.state.country_code), cityName: '' })
                                      })
                                    }}
                                  >
                                    {
                                      states.AllCountry.map((list) => (
                                        <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                                // <CountryDropdown className='eMed_demo_dropdown_inputs'
                                //   value={this.state.country}
                                //   onChange={(val) => this.setState({ country: val })}
                                //   priorityOptions={["IN"]}
                                //   whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                                // />
                                :
                                <TextField
                                  disabled={(data.label === "First Name") ? Object.keys(this.state.RequestedPatientData).length > 0 : false}
                                  autoComplete="off"
                                  inputProps={{ maxLength: (data.label === "First Name" || data.label === "Middle Name" || data.label === "Surname" || data.label === "Referred By" || data.label === "Referral Source" || data.label === "Mother's Name" || data.label === "Father/Husband Name" || data.label === "Emergency Contact Person" || data.label === "Relationship") ? 32 : ( data.label === "Occupation" || data.label === "'Insurance Company Name'" || data.label === "Insurance No") ? 45 : ( data.label === "Email ID") ? 50 : 100 }}
                                  label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                                  variant="outlined"
                                  value={states[data.field_name]}
                                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                  onChange={(e) => {
                                    var isAlpha = this.alphaValidation(e.target.value)
                                    var isNum = this.numberValidation(e.target.value)
                                    var isAlphaNum = CommonValidation.alphanumValidation(e.target.value)
                                    let isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)

                                    if (data.label === "Age" || data.label === "Father/Husband Age" || data.label === "Mother's Age") {
                                      var age = e.target.value
                                      if ((isNum && +(age) <= 150 && +age !== 0) || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        states['dob'] = data.label === "Age" ? null : states.dob
                                        this.setState({
                                          states,
                                        }, () => {
                                          if (data.label === "Age") {
                                            var approxDob = this.handleFindApproxDob(this.state.age);
                                            this.setState({
                                              dob: approxDob
                                            })
                                          }
                                        })
                                      }
                                      else if (e.target.value.includes("/")) {
                                        this.setState({ age: "", dob: null })
                                      }
                                    }
                                    else if (data.label === "First Name" || data.label === "Middle Name" || data.label === "Surname" || data.label === "Emergency Contact Person" || data.label === "Occupation" || data.label === "Relationship" || data.label === "City" || data.label === "Guardian") {
                                      if (isAlpha || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Father/Husband Name" || data.label === "Mother's Name") {
                                      if (isSpecial || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Pin Code") {
                                      if ((isNum || e.target.value == "") && e.target.value.length <= 6) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        }, () => {
                                          if (this.state.pin_code && this.state.pin_code.length === 6) {
                                            this.getPincodeToCity(this.state.pin_code)
                                          }
                                        })
                                      }
                                    }
                                    else if (data.label === "Emergency Mobile No") {
                                      if ((isNum || e.target.value == "") && e.target.value.length <= 10) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Insurance No") {
                                      if ((isAlphaNum || e.target.value == "")) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else {
                                      states[data.field_name] = e.target.value
                                      this.setState({
                                        states
                                      })
                                    }
                                  }}
                                  size="small"
                                />
          }
        </Box>
      </form>
    )
  }
  getConnectedDevices = () => {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const videoDevices = devices?.filter(device => device.kind === 'videoinput');
        this.setState({
          devices: videoDevices,
        });
      })
      .catch(error => {
        console.error('Error enumerating devices:', error);
      });
  }
  videoConstraints = () => ({
    facingMode: this.state.isFrontCamera ? 'user' : 'environment',
  });

  toggleCameraButton = () => {
    this.setState({
      isFrontCamera: !this.state.isFrontCamera,
      temp_patient_img: null
    }, () => { this.videoConstraints() })
  }


  OpenWebCamPopUP = () => {
    try {
      return (
        <Box>
          <Modal open={true}>
            <Box className="eMed_WebCamPop_Main">
              <Box component={'div'} className="eMed_WebCam_Header">
                <Typography variant='h6'>{"Patient Profile "}</Typography>
                <Button onClick={() => { this.setState({ temp_patient_img: this.state.patient_img, ProfilePicClicked: false }) }} >
                  <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                </Button>
              </Box>
              <Box component={'div'}>
                {
                  this.state.temp_patient_img === null ?
                    <Webcam
                      className='eMed_WebCam_Div'
                      audio={false}
                      mirrored={true}
                      ref={this.webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={this.videoConstraints()}
                    /> :
                    <img className='eMed_WebCam_Img_Div' src={this.state.temp_patient_img} />
                }
              </Box>
              <Box component={'div'} className="eMed_WebCam_Btn_div" width={"70%"} mr={"3vw"} ml={"7vw"}>
                <Button disabled={this.state.temp_patient_img !== null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: this.webcamRef.current.getScreenshot() }) }}>Capture</Button>
                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: null }) }}>Retake</Button>
                <Button disabled={this.state.temp_patient_img === null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ patient_img: this.state.temp_patient_img, ProfilePicClicked: false }) }}>Set Profile</Button>
                {this.state.devices?.length > 1 && 
                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.toggleCameraButton() }} >
                  Switch to {this.state.isFrontCamera ? "Back" : "Front"} Camera
                </Button>}
              </Box>
            </Box>
          </Modal>
        </Box>
      );
    } catch (e) { }
  };


  renderCreateAppointmentLeft = () => {
    const { t, fromViewGraphics } = this.props

    var states = this.state
    var Mandatory_Array = []
    var Non_Mandatory_Array = []
    const SplitCount = +(states.mandotory_count)
    const Total_Len = (states.layoutData).length

    var Active_Array = (states.layoutData).filter((item) => {
      return item.is_active == true
    })

    Mandatory_Array = (Active_Array).slice(0, SplitCount)
    Non_Mandatory_Array = (Active_Array).slice(SplitCount, Total_Len)
    return (
      <Box component={"div"} className={fromViewGraphics ? "eMed_createapt_left eMed_ipcreateapt_left" : "eMed_createapt_left"}>
        <Box className="eMed_createapt_left1">
          <Paper className='eMed_createapt_left1_paper1'>
            <Typography className='eMed_refdoc_title'>{t("Referral Information")}
              {(this.state.referredBy && !states?.disRefferedBy) || (this.state.referralSource && !states?.disRefferedSource) ?
                <Typography component={'span'} ml={'0.5vw'} color={Colors.red} fontSize={'0.8vw'} fontWeight={600}>*Once entered, 'Referred By' and 'Referred Source' cannot be updated</Typography> :
                null
              }
            </Typography>
            <Box component={'div'} className="eMed_createapt_left1_paper1_box">
              {/* <FormControl size='small' sx={{ width: '15vw' }} emed_tid='doc_dem_ref_by'>
                <InputLabel id="referredby-select-label">{"Referred By"}</InputLabel>
                <Select
                  labelId="referredby-select-label"
                  MenuProps={{ style: { maxHeight: 250 } }}
                  size='small'
                  value={states.referredBy}
                  label={"Referred By"}
                  onChange={(e) => {
                    this.setState({
                      referredBy: e.target.value
                    })
                  }}
                >
                  <MenuItem value={""}>{"Select Doctor"}</MenuItem>
                  {states.refDoctorData.map((item, index) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
              <FormControl size='small' sx={{ width: '15vw' }} emed_tid='doc_demo_ref_src'>
                <InputLabel id="referralSrc-select-label">{"Referral Source"}</InputLabel>
                <Select
                  labelId="referralSrc-select-label"
                  MenuProps={{ style: { maxHeight: 250 } }}
                  size='small'
                  value={states.referralSource}
                  label={"Referral Source"}
                  onChange={(e) => {
                    this.setState({
                      referralSource: e.target.value
                    })
                  }}
                >
                  <MenuItem value={""}>{"Select Source"}</MenuItem>
                  {states.refSourceData.map((item, index) => (
                    <MenuItem value={item.source_name}>{item.source_name}</MenuItem>
                  ))}

                </Select>
              </FormControl> */}
              <Autocomplete
                freeSolo
                clearIcon
                size='small'
                sx={{ width: '15vw' }}
                options={states.refDoctorData}
                disabled={states?.disRefferedBy}
                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
                value={states.referredBy}
                onChange={(e, newValue) => {
                  this.setState({ referredBy: newValue?.name })
                }}
                onInputChange={(e, newInput) => {
                  this.setState({ referredBy: newInput })
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option?.id}>
                    {option?.name}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label='Referred By' />}
              />
              <Autocomplete
                freeSolo
                clearIcon
                size='small'
                sx={{ width: '15vw' }}
                options={states.refSourceData}
                disabled={states?.disRefferedSource}
                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.source_name}
                value={states.referralSource}
                onChange={(e, newValue) => {
                  this.setState({ referralSource: newValue?.source_name })
                }}
                onInputChange={(e, newInput) => {
                  this.setState({ referralSource: newInput })
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option?.id}>
                    {option?.source_name}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label='Referral Source' />}
              />
              <input
                type="file"
                accept="application/pdf, image/jpg, image/jpeg, image/png"
                onChange={(e) => this.UploadImage(e, "docDocument")}
                ref={this.uploadImage}
                style={{ display: "none" }}
                emed_tid='doc_demo_img'
              />
              {
                (this.state.docDocument === null || this.state.docDocument === "delete") ?
                  <Button emed_tid='img_upload_btn'
                    size='small'
                    variant="contained"
                    endIcon={
                      <FileUploadOutlinedIcon />
                    }
                    onClick={() => this.uploadImage.current.click()}
                  >
                    Upload Document
                  </Button>
                  :
                  <Box>
                    <div className='emedhub_doc_image_size'>
                      <Tooltip title={typeof (this.state.docDocument) === "string" ? null : this.state.docDocument?.name} arrow placement='top'>
                        <img
                          onClick={() => {
                            if (typeof (this.state.docDocument) === "string") {
                              if (this.state.docDocument.split('.').pop() === "jpg" || this.state.docDocument.split('.').pop() === "jpeg" || this.state.docDocument.split('.').pop() === "JPG" || this.state.docDocument.split('.').pop() === "png") {
                                this.setState({ SelectedDocSRC: this.state.docDocument })
                              }
                              else {
                                window.open(this.state.docDocument)
                              }
                            }
                          }}
                          src={typeof (this.state.docDocument) === "string" ?
                            (this.state.docDocument.split('.').pop() === "jpg" || this.state.docDocument.split('.').pop() === "jpeg" || this.state.docDocument.split('.').pop() === "JPG" || this.state.docDocument.split('.').pop() === "png" ?
                              this.state.docDocument : ImagePaths.DocumentPreview.default) : ((this.state.docDocument?.type === "image/jpeg" || this.state.docDocument?.type === "image/png") ? ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default)}
                          className='eMed_createapt_right2_field_img_inv' />
                      </Tooltip>
                      <div>
                        <IconButton emed_tid='del_btn_demo' onClick={() => {
                          this.state.docDocument = "delete"
                          this.setState({ states })
                        }}
                          size='small'
                          className='emedhub_icon_img_close'
                        ><Close fontSize='small' /></IconButton>
                      </div>
                    </div>
                    {/* <div className='emedhub_doc_image_size'>
                      <img onClick={() => { this.setState({ SelectedDocSRC: this.state.docDocument, refDocumentSelected: true }) }} src={typeof (this.state.docDocument) === "string" ? this.state.docDocument : this.state.docDocument?.type === "application/pdf" ? ImagePaths.Reports.default : this.state.docDocument?.data_url} className='eMed_createapt_right2_field_img_inv' />
                      <div>
                        <IconButton onClick={() => {
                          this.setState({ docDocument: "delete" })
                        }}
                          size='small'
                          className='emedhub_icon_img_close'
                        ><Close fontSize='small' /></IconButton>
                      </div>
                    </div> */}
                  </Box>
              }
            </Box>
          </Paper>
          <Paper className='eMed_createapt_left1_paper2'>
            <IconButton className='eMed_createapt_left1_paper2_icon' onClick={() => { this.setState({ ProfilePicClicked: true }) }}>
              <img className='eMed_createapt_left1_paper2_img' src={this.state.patient_img === null ? ImagePaths.UploadPatientDP.default : this.state.patient_img} alt="ProfileImage" />
            </IconButton>
          </Paper>
        </Box>
        <Paper className={fromViewGraphics ? 'eMed_createapt_left2 eMed_ipcreateapt_left2' : 'eMed_createapt_left2'}>
          <Box className='eMed_refdoc_title eMed_Demo_Title'>
            <Typography sx={{ fontWeight: 600 }}>{t("Patient Information")}</Typography>
            {this.state.appointmentId ?
              <Tooltip title={'Print OP Card'}>
                <img style={{ marginLeft: '0.6vw', width: '1.3vw', height: '1.3vw', cursor: 'pointer' }}
                  src={ImagePaths.PrintIcons.default} alt='' onClick={() => { this.printOPCard() }} />
              </Tooltip> : null}
            <Box sx={{ width: '18.5vw' }}>
              <Box className='eMed_OP_PatientType'>
                <Box className='eMed_Demo_Created_Con'>
                  <Typography id='eMed_Demo_Created_Text'>{t('CreatedBy')}</Typography>
                  <Typography id='eMed_Demo_Created_Text'>:</Typography>
                </Box>
                <Typography id='eMed_Demo_Created_Text'>{this.state.createdBy ? this.state.createdBy : '-'}</Typography>
              </Box>
              <Box className='eMed_OP_PatientType'>
                <Box className='eMed_Demo_Created_Con'>
                  <Typography id='eMed_Demo_Created_Text'>{t('ModifiedBy')}</Typography>
                  <Typography id='eMed_Demo_Created_Text'>:</Typography>
                </Box>
                <Typography id='eMed_Demo_Created_Text'>{this.state.modifiedBy ? this.state.modifiedBy : '-'}</Typography>
              </Box>
            </Box>
          </Box>


          <Box className="eMed_demolayout_left_TextInput">
            {Mandatory_Array.map((item, index) => (
              item?.label == "Referral Source" || item?.label == "Referred By" ? null :
              this.renderInput(item, 'mandatory')
            ))}
          </Box>
          <Divider />
          <Box className="eMed_demolayout_left_TextInput">
            {Non_Mandatory_Array.map((item, index) => (
              item?.label == "Referral Source" || item?.label == "Referred By" ? null :
              this.renderInput(item, 'non-mandatory')
            ))}
          </Box>
          <Box className="emed_createAppoint_Checkbox">
            {/* <FormControlLabel control={<Checkbox checked={this.state.receive_email} onChange={(e) => { this.setState({ receive_email: e.target.checked }) }} size='small' />} label="Receive Email" /> */}
            <FormControlLabel control={<Checkbox checked={this.state.receive_sms} onChange={(e) => { this.setState({ receive_sms: e.target.checked }) }} size='small' />} label="Receive SMS" />
            {/* <FormControlLabel control={<Checkbox disabled checked={this.state.receive_whatsapp} onChange={(e) => { this.setState({ receive_whatsapp: e.target.checked }) }} size='small' />} label="WhatsApp" /> */}
          </Box>
        </Paper>

      </Box>
    )
  }


  renderDropdown = (label, mt, Data = [], stateKey) => {
    var states = this.state
    return (
      <Box sx={{ width: '45%', mt: mt }}>
        <Autocomplete
          size='small'
          clearIcon={false}
          sx={{ width: '14vw' }}
          componentsProps={{ popper: { style: { width: label === 'Doctor' ? "23vw" : "14vw" } } }}
          disabled={this.props?.is_Nurse && label === "Specialization" ? true : false}
          options={Data}
          getOptionLabel={(item) => ((label === "Doctor" ? item.doctor_name : label === "Specialization" ? item.name : label === "Patient Tag" ? item.patient_tag_name : label === "Appointment Type" ? item.appoinment_type : label === "Nurse" ? item.nurse : ""))}
          value={states[stateKey]}
          renderOption={label === 'Doctor' ? (props, item) => (
            <Box>
              {props["data-option-index"] === 0 ?
                <Box className="eMed_App_Doctor_table_head">
                  <Typography width={"50%"} fontWeight={'600'} fontSize={"0.8vw"}>Doctor Name</Typography>
                  <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>Total App</Typography>
                  <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>New App</Typography>
                </Box> : null}
              <Box component="li" className='eMed_App_Doctor_table_data' {...props}>
                <div style={{ width: "50%" }}>{item?.doctor_name.length > 15 ? <Tooltip placement="top" title={item?.doctor_name} arrow><div>{item?.doctor_name.slice(0, 16) + "..."}</div></Tooltip> : item?.doctor_name ? item?.doctor_name : "-"}</div>
                <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.total_appointment}</Typography>
                <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.new_appointment}</Typography>
              </Box>
            </Box>) : null}
          onChange={(event, newValue) => {
            states[stateKey] = newValue
            if (newValue !== null && "doctor_id" in newValue) {
              let docDetail = { ...newValue }
              docDetail["name"] = docDetail["specialization_name"];
              delete docDetail.specialization_name;
              docDetail["id"] = docDetail["specialization_id"];
              delete docDetail.specialization_id;
              states["selectedSpecId"] = docDetail;
            }
            if (newValue !== null && "name" in newValue) {
              let filteredLst = states["doctorList"].length > 0 && states["doctorList"].filter(item => item["specialization_id"] === newValue["id"])
              states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
              states["selectedDocId"] = null;
            }
            if (newValue === null && label === "Specialization") {
              states["filteredDocList"] = states["doctorList"];
            }
            this.setState({ states })
          }}
          renderInput={(params) => (
            <TextField
              {...params} inputProps={{...params.inputProps,emed_tid:label}}
              label={label + `${(label === "Doctor" || label === "Specialization" || label === "Nurse") ? " *" : ""}`}
              placeholder={label}
            />
          )}
        />
      </Box>
    )
  }

  UploadImage = (e, key) => {
    let states = this.state
    states[key] = e.target.files[0]
    this.setState({ states })
    e.target.value = ''
  }


  renderIdentityProof = (Label, key, NumberKey) => {
    let states = this.state;
    return (
      <Box className="eMed_createapt_right2_box">
        <TextField
          label={Label}
          sx={{ width: '19vw' }}
          size="small"
          value={states[NumberKey]}
          onChange={(e) => {
            let value = e.target.value
            if (NumberKey === "aadhar_card_number") {
              let Valid = CommonValidation.numberOnly(value)
              if ((Valid || value === "") && value.length <= 12) {
                states[NumberKey] = value
              }
            }
            else if (NumberKey === "passport_number") {
              let Valid = CommonValidation.ALPHA_NUMARIC(value)
              if ((Valid || value === "") && value.length <= 8) {
                states[NumberKey] = value.toUpperCase()
              }
            }
            else {
              let Valid = CommonValidation.ALPHA_NUMARIC(value)
              if ((Valid || value === "") && value.length <= 15) {
                states[NumberKey] = value.toUpperCase()
              }
            }
            this.setState({ states })
          }}
        />
        <IconButton size='small' component="label" emed_tid={key}>
          <input onChange={(e) => this.UploadImage(e, key)} hidden accept="application/pdf, image/jpg, image/jpeg, image/png" type="file" />
          <img className='eMed_createapt_right2_field_img' src={ImagePaths.UploadBtnTheme.default} alt="" />
        </IconButton>
        {(states[key] !== null && states[key] !== "delete") ?
          <div className='emedhub_doc_image_size'>
            <Tooltip title={typeof (states[key]) === "string" ? null : states[key]?.name} arrow placement='top'>
              <img
                onClick={() => {
                  if (typeof (states[key]) === "string") {
                    if (states[key].split('.').pop() === "jpg" || states[key].split('.').pop() === "jpeg" || states[key].split('.').pop() === "JPG" || states[key].split('.').pop() === "png") {
                      this.setState({ SelectedDocSRC: states[key] })
                    }
                    else {
                      window.open(states[key])
                    }
                  }
                }}
                src={typeof (states[key]) === "string" ?
                  (states[key].split('.').pop() === "jpg" || states[key].split('.').pop() === "jpeg" || states[key].split('.').pop() === "JPG" || states[key].split('.').pop() === "png" ?
                    states[key] : ImagePaths.DocumentPreview.default) : ((states[key]?.type === "image/jpeg" || states[key]?.type === "image/png") ? ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default)}
                className='eMed_createapt_right2_field_img_inv' />
            </Tooltip>
            <div>
              <IconButton onClick={() => {
                states[key] = "delete"
                this.setState({ states })
              }}
                size='small'
                className='emedhub_icon_img_close'
              ><Close fontSize='small' /></IconButton>
            </div>
          </div> : null}
      </Box>
    )
  }

  convertTo12Hour(time) {
    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Converts "0" hour to "12"
    return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
  }

  getSlotIntervel = () => {
    var date = new Date(this.state.appointmentDateTime)
    var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let DOctorId = userData?.doctor_id ? userData?.doctor_id : null
    this.LoaderFunction(true)
    try {
      RestAPIService.getAll(Serviceurls.SCHEDULE_TIME_GET + "?date=" + appointment_date + "&doctor_id=" + DOctorId + "&clinic_id=" + clinicId)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              slotList: response.data.data,
              slotPopup: true
            }, () => { this.LoaderFunction(false) })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.LoaderFunction(false)
        })

    } catch (e) {
      this.errorMessage(e.message)
      this.LoaderFunction(false)
    }
  }

  renderDateTimeFilter = () => {
    try {
      let today = new Date()
      let maxDate = new Date()
      if (this.state.dateLimitType === "Days") {
        maxDate.setDate(today.getDate() + this.state.dateLimit)
      } else {
        maxDate.setMonth(today.getMonth() + this.state.dateLimit);
      }
      let formatMaxDate = maxDate.toISOString()?.slice(0, 10)

      return (
        <Box sx={{ mt: '1vw' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              open={this.state.DateOpen}
              onOpen={() => { this.setState({ DateOpen: true }) }}
              onClose={() => { this.setState({ DateOpen: false }) }}
              inputFormat='dd-MM-yyyy & hh:mm a'
              value={this.state.appointmentDateTime}
              minDate={new Date((new Date).getFullYear(), (new Date).getMonth() - 2, 1)}
              // maxDate={new Date((new Date).getFullYear(), (new Date).getMonth() + 3, 0)}
              maxDate={formatMaxDate}
              onChange={(newDate) => {
                if (newDate === null) {
                  this.setState({ appointmentDateTime: new Date() })
                }
                else {
                  this.setState({ appointmentDateTime: newDate })
                }
              }}
              renderInput={(params) => <TextField size='small' inputProps={{...params.inputProps,emed_tid:'apt_date_picker'}}
                onKeyDown={(e) => e.preventDefault()}
                onClick={() => { this.setState({ DateOpen: true }) }}
                {...params}
                fullWidth
                variant='outlined'
                label="Appointment Date & Time *"
                style={{ width: "14vw", marginRight: "0.5vw", marginLeft: "0.5vw" }}
              />}
            />
          </LocalizationProvider>
          <Button variant='outlined' disabled={!this.state.isQmsConfig} style={{ width: "14vw", height: "2.5vw" }} size="small" id="eMed_Config_btn" onClick={() => { this.getSlotIntervel() }}>{this.state.selectedSlot ? `Slot : ${this.convertTo12Hour(this.state.selectedSlot)}` : "Select Appointment Slots"}</Button>

        </Box>
      )
    } catch (e) {

    }
  }

  renderCreateAppointmentRight = () => {
    try {
      const { t } = this.props
      var states = this.state
      let patientCreate = this.props.history?.location?.state?.fromCreate
      return (
        <Stack component={'div'}>
          {
            (patientCreate) ?
              <Paper elevation={2} sx={{ height: '32vh' }} className="eMed_createapt_right1">
                <Typography className='eMed_refdoc_title'>{t("Appointment Details")}</Typography>
                <Box className="eMed_createapt_right1_box">
                  {/* {this.renderDropdown("Specialization", "0vw", states.spcInfo, "selectedSpecId")} */}
                  {/* {this.props.is_Nurse ? this.renderDropdown("Nurse", "0vw", states.NurseList, "selectedNurseId") :
                    this.renderDropdown("Doctor", "0vw", states["filteredDocList"].length > 0 ? states.filteredDocList : states.doctorList, "selectedDocId")} */}
                  {this.renderDateTimeFilter()}
                  {this.renderDropdown("Patient Tag", "1vw", states.tagDetails, "selectedTagId")}
                  {this.renderDropdown("Appointment Type", "1vw", states.AppointmentTypeList, "selectedAppointTypeId")}
                </Box>
                <Autocomplete
                  inputProps={{ maxLength: 250 }}
                  options={states.POVList}
                  getOptionLabel={(options) => options}
                  onChange={(e, value) => {
                    this.setState({
                      purposeOfVisit: value
                    })
                  }}
                  clearIcon={false}
                  size='small'
                  sx={{ m: '1vw', width: '29vw' }}
                  value={states.purposeOfVisit}
                  renderInput={(params) => <TextField sx={{ width: "28.5vw" }} onChange={(event) => {
                    this.setState({
                      purposeOfVisit: event.target.value
                    })
                  }}
                    {...params} inputProps={{...params.inputProps,emed_tid:'apt_pov'}}
                    placeholder="Purpose Of Visit *"
                  />}
                />

              </Paper>
              : null
          }
          <Paper elevation={2} sx={{ height: (patientCreate) ? "38vh" : '72vh' }} className="eMed_createapt_right2">
            <Typography className='eMed_refdoc_title'>{t("Identity Proof")}</Typography>
            {this.renderIdentityProof("Aadhaar", "aadharProof", "aadhar_card_number")}
            {this.renderIdentityProof("PAN", "panProof", "pancard_number")}
            {this.renderIdentityProof("Passport", "passportProof", "passport_number")}
            {this.renderIdentityProof("Ration", "rationProof", "ration_card_number")}
            {this.renderIdentityProof("DL", "dlProof", "dl_number")}
          </Paper>
        </Stack>
      )
    } catch (e) {
      this.errorMessage(e)
    }
  }

  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  sendDocuments(key) {
    let states = this.state;
    if (states[key] === "" || states[key] === null) {
      return "None"
    }
    else if (states[key] === "delete") {
      return "delete"
    }
    else {
      if (typeof (states[key]) === "string") {
        if (key === "patient_img") {
          if (states[key].includes("https://s3-ap-south-1.amazonaws.com")) {
            return "None"
          } else {
            return this.dataURItoBlob(states[key])
          }
        } else {
          return "None"
        }
      }
      else {
        return states[key]
      }
    }
  }

  GetAppointmentDetails(id) {
    try {
      const { history } = this.props;
      RestAPIService.getAll(Serviceurls.FO_GET_APPOINMENT_DETAILS + "?appointment_id=" + id).
        then((response) => {
          if (response.data.status === "success") {
            setCachevalue(JSON.stringify(response.data.data), "patientData")
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  postDoctorPatientData = (METHOD) => {
    try {
      let states = this.state
      let date = new Date(states.dob)

      let Dob = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
      const dataForm = new FormData();
      let clinicId = getCachevalue('SelectedDoctorClinic')

      dataForm.append("first_name", states.first_name === null ? "" : CommonValidation.removeSpace(states.first_name))
      dataForm.append("last_name", states.surname === null ? "" : CommonValidation.removeSpace(states.surname))
      dataForm.append("middle_name", states.middle_name === null ? "" : CommonValidation.removeSpace(states.middle_name))
      dataForm.append("father_husband_name", states.father_husband_name === null ? "" : CommonValidation.removeSpace(states.father_husband_name))
      dataForm.append("father_husband_age", states.father_husband_age === null ? "" : CommonValidation.removeSpace(states.father_husband_age))
      dataForm.append("gender", states.gender === null ? "" : states.gender)
      dataForm.append("title", states.title === null ? "" : states.title)
      dataForm.append("occupation", states.occupation === null ? "" : CommonValidation.removeSpace(states.occupation))
      dataForm.append("dob", Dob === null ? "" : Dob)
      dataForm.append("approx_dob", Dob === null ? "" : Dob)
      dataForm.append("marital_status", states.marital_status === null ? "" : CommonValidation.removeSpace(states.marital_status))
      dataForm.append("mobile_number", states.mobile_no === null ? "" : CommonValidation.removeSpace(states.mobile_no))
      dataForm.append("mobile_number_2", "")
      dataForm.append("email", states.email_id === null ? "" : CommonValidation.removeSpace(states.email_id))
      dataForm.append("address_line_1", states.address_1 === null ? "" : CommonValidation.removeSpace(states.address_1))
      dataForm.append("address_line_2", states.address_2 === null ? "" : CommonValidation.removeSpace(states.address_2))
      dataForm.append("pincode", states.pin_code === null ? "" : CommonValidation.removeSpace(states.pin_code))
      dataForm.append("city_name", states.city === null ? "" : CommonValidation.removeSpace(states.city))
      dataForm.append("state_name", states.state_code === null ? "" : CommonValidation.removeSpace(states.AllStates.find((item) => (item.isoCode === states.state_code))?.name))
      dataForm.append("country", states.country_code === null ? "" : CommonValidation.removeSpace(states.AllCountry.find((item) => (item.isoCode === states.country_code))?.name))
      dataForm.append("reffered_by", states.referredBy === null ? "" : CommonValidation.removeSpace(states.referredBy))
      dataForm.append("referral_source", states.referralSource === null ? "" : CommonValidation.removeSpace(states.referralSource))
      dataForm.append("id_no", states.employee_id === null ? "" : CommonValidation.removeSpace(states.employee_id))
      dataForm.append("employer_id", states.employer_id === null ? "" : CommonValidation.removeSpace(states.employer_id))
      dataForm.append("document", states.docDocument === null ? "" : this.sendDocuments("docDocument"))
      dataForm.append("aadhar", states.aadharProof === null ? "" : this.sendDocuments("aadharProof"))
      dataForm.append("pan", states.panProof === null ? "" : this.sendDocuments("panProof"))
      dataForm.append("passport", states.passportProof === null ? "" : this.sendDocuments("passportProof"))
      dataForm.append("ration_card", states.rationProof === null ? "" : this.sendDocuments("rationProof"))
      dataForm.append("dl", states.dlProof === null ? "" : this.sendDocuments("dlProof"))
      dataForm.append("photo", states.patient_img === null ? "" : this.sendDocuments("patient_img"))
      dataForm.append("face_data", states.patient_img === null ? "" : this.sendDocuments("patient_img"))
      dataForm.append("pancard_number", states.pancard_number === null ? "" : CommonValidation.removeSpace(states.pancard_number))
      dataForm.append("ration_card_number", states.ration_card_number === null ? "" : CommonValidation.removeSpace(states.ration_card_number))
      dataForm.append("dl_number", states.dl_number === null ? "" : CommonValidation.removeSpace(states.dl_number))
      dataForm.append("passport_number", states.passport_number === null ? "" : CommonValidation.removeSpace(states.passport_number))
      dataForm.append("aadhar_card_number", states.aadhar_card_number === null ? "" : CommonValidation.removeSpace(states.aadhar_card_number))
      dataForm.append("caretaker_name", states.emergency_contact_person === null ? "" : CommonValidation.removeSpace(states.emergency_contact_person))
      dataForm.append("caretaker_mobile", states.emergency_contact_mobile === null ? "" : CommonValidation.removeSpace(states.emergency_contact_mobile))
      dataForm.append("caretaker_relationship", states.relationship === null ? "" : CommonValidation.removeSpace(states.relationship))
      dataForm.append("receive_email", states.receive_email === null ? "" : states.receive_email)
      dataForm.append("receive_whatsapp", states.receive_whatsapp === null ? "" : CommonValidation.removeSpace(states.receive_whatsapp))
      dataForm.append("receive_sms", states.receive_sms === null ? "" : states.receive_sms)
      dataForm.append("insurance_company_name", states.insurance_company_name === null ? "" : CommonValidation.removeSpace(states.insurance_company_name))
      dataForm.append("policy_number", states.policy_number === null ? "" : CommonValidation.removeSpace(states.policy_number))
      dataForm.append("guardian", states.guardian === null ? "" : CommonValidation.removeSpace(states.guardian))
      dataForm.append("mother_name", states.mother_name === null ? "" : CommonValidation.removeSpace(states.mother_name))
      dataForm.append("mother_age", states.mother_age === null ? "" : CommonValidation.removeSpace(states.mother_age))
      dataForm.append('clinic_id', clinicId)
      if (METHOD === 'POST') {
        this.LoaderFunction(true)
        let patientId = this.props.history?.location?.state?.patientId
        if (patientId || this.state.doctorPatientId) {
          dataForm.append('patient_id', states.SelectedPatientID)
          this.setState({ DisableBtn: true })
          RestAPIService.updateWithOutId(dataForm, Serviceurls.DOC_PATIENT_CREATION)
            .then((response) => {
              if (response.data.status === 'success') {
                this.LoaderFunction(false)
                // this.getPatientDemographics(response?.data?.data?.patient_id)
                // this.successMessage(response?.data?.message)
                this.setState({
                  SelectedPatientID: patientId || this.state.doctorPatientId || states.SelectedPatientID,
                  DisableBtn: false
                }, () => this.postDoctorAppointmentCreate(patientId ? patientId : this.state.doctorPatientId))
              }
            }).catch((e) => {
              this.setState({ DisableBtn: false })
              if (e?.response?.data?.message) {
                this.LoaderFunction(false)
                this.errorMessage(e.response.data.message)
              } else {
                this.LoaderFunction(false)
                this.errorMessage(e.message)
              }
            })
        } else {
          this.setState({ DisableBtn: true })
          RestAPIService.create(dataForm, Serviceurls.DOC_PATIENT_CREATION)
            .then((response) => {
              if (response.data.status === 'success') {
                this.LoaderFunction(false)
                if ((states.insurance_company_name != "" && states.insurance_company_name != null) && states.NewInsurance) {
                  this.postNewInsurance()
                }
                let patient_id = response.data.data
                this.setState({
                  SelectedPatientID: patient_id?.patient_id,
                  DisableBtn: false
                }, () => {
                  this.postDoctorAppointmentCreate(patient_id?.patient_id)
                })
              }
            }).catch((error) => {
              this.setState({ DisableBtn: false })
              if (error?.response?.data?.message) {
                this.LoaderFunction(false)
                this.errorMessage(error.response?.data?.message)
              } else {
                this.LoaderFunction(false)
                this.errorMessage(error.message)
              }
            })
        }

      } else {
        dataForm.append('patient_id', states.SelectedPatientID)
        this.LoaderFunction(true)
        this.setState({ DisableBtn: true })
        RestAPIService.updateWithOutId(dataForm, Serviceurls.DOC_PATIENT_CREATION)
          .then((response) => {
            if (response.data.status === 'success') {
              this.LoaderFunction(false)
              this.getPatientDemographics(response?.data?.data?.patient_id)
              this.setState({ DisableBtn: false })
              this.successMessage(response?.data?.message)
            }
          }).catch((e) => {
            this.setState({ DisableBtn: false })
            if (e?.response?.data?.message) {
              this.LoaderFunction(false)
              this.errorMessage(e.response.data.message)
            } else {
              this.LoaderFunction(false)
              this.errorMessage(e.message)
            }
          })
      }
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  postDoctorAppointmentCreate = (id) => {
    try {
      var states = this.state
      let { history } = this.props;

      var AptDateTime = new Date(states.appointmentDateTime)

      var AptDate = DateTime.fromJSDate(AptDateTime).toFormat('yyyy-MM-dd')

      var Aptime = DateTime.fromJSDate(AptDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let clinicId = getCachevalue('SelectedDoctorClinic')
      let TransID = this.props.history?.location?.state?.transferID
      let FollowupID = this.props.history?.location?.state?.followupID

      var data = {
        patient_id: id,
        // doctor_id: states.selectedDocId?.doctor_id ? states.selectedDocId?.doctor_id : null,
        // speciality_id: states.selectedSpecId?.id ? states.selectedSpecId?.id : null,
        purpose_of_visit: states.purposeOfVisit,
        patient_tag_id: states.selectedTagId?.id,
        appointment_date: AptDate,
        appointment_start_time: Aptime,
        patient_appointment_type: states.selectedAppointTypeId === null ? null : states.selectedAppointTypeId?.id,
        nurse_id: states.selectedNurseId?.staff_id ? states.selectedNurseId?.staff_id : null,
        request_id: this.state.RequestedPatientData?.id ? this.state.RequestedPatientData?.id : null,
        clinic_id: +clinicId
      }
      if (TransID) {
        data["transfer_id"] = TransID
      }
      if (FollowupID) {
        data["followup_id"] = FollowupID
      }
      this.setState({ DisableBtn: true })
      RestAPIService.create(data, Serviceurls.DOC_PATIENT_APPOINTMENT)
        .then((response) => {
          if (response.data.status === 'success') {
            clearCachevalue('DoctorPatientData')
            clearCachevalue('AppRequestPatientData')
            this.LoaderFunction(false)
            this.setState({ doctorPatientId: null, DisableBtn: false })
            history.push({ pathname: "/DoctorHome", state: { NewAppointmentCreated: true, NewMessage: response.data?.message } })
          }
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  MandatoryCheck() {
    let states = this.state;
    let MandatoryCount = +(states.mandotory_count)
    let flag = false;
    for (let i = 0; i < MandatoryCount; i++) {
      if (CommonValidation.removeSpace(states[states.layoutData[i].field_name]) === "" || states[states.layoutData[i].field_name] === null) {
        flag = false
        this.errorMessage(`${states.layoutData[i].label} is Mandatory !`)
        break
      } else {
        flag = true
      }
    }
    return flag
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  Validation() {
    let states = this.state;
    let Email = (states.MandatoryLabels.includes("Email ID") || states.email_id !== "") ? CommonValidation.emailValidation(states.email_id) : true;
    Email = states.email_id == null ? true : true;
    let Corporate = (states.employer_id === "" || states.employer_id === null) ? true : (states.employee_id !== "" && states.employee_id !== null)
    let Insurance = (states.insurance_company_name === "" || states.insurance_company_name === null) ? true : (states.policy_number !== "" && states.policy_number !== null)
    if (this.MandatoryCheck()) {
      let fromDoctorUpdate = this.props.history?.location?.state?.doctorUpdate ? this.props.history?.location?.state?.doctorUpdate : getCachevalue('updatePatient')
      if (fromDoctorUpdate) {
        if (states.mobile_no.length === 10 && Email && Corporate && Insurance && states.dob != "Invalid Date" && (this.state.dob < new Date() && this.state.dob > new Date("1872-01-01")) && (CommonValidation.removeSpace(this.state.purposeOfVisit) !== "")) {
          this.postDoctorPatientData('PUT')
        } else {
          if (states.mobile_no.length !== 10) { this.errorMessage("Invalid Mobile Number") }
          else if (!Email) { this.errorMessage("Invalid Email ID") }
          else if (states.dob == "Invalid Date") { this.errorMessage("Invalid Date of Birth") }
          else if (!(this.state.dob < new Date() && this.state.dob > new Date("1872-01-01"))) { this.errorMessage("Invalid Date of Birth") }
          else if (!Corporate) { this.errorMessage("Employee ID No is empty") }
          else if (!Insurance) { this.errorMessage("Insurance Policy No is empty") }
          else if ((CommonValidation.removeSpace(this.state.purposeOfVisit) === "") || (CommonValidation.removeSpace(this.state.purposeOfVisit) === undefined)) { this.errorMessage("Enter Purpose of Visit") }
        }
      } else {
        if (states.mobile_no.length === 10 && Email && Corporate && Insurance && states.dob != "Invalid Date" && (this.state.dob < new Date() && this.state.dob > new Date("1872-01-01")) && ((CommonValidation.removeSpace(this.state.purposeOfVisit) !== "") && (CommonValidation.removeSpace(this.state.purposeOfVisit) !== undefined))) {
          this.postDoctorPatientData("POST")
        } else {
          if (states.mobile_no.length !== 10) { this.errorMessage("Invalid Mobile Number") }
          else if (states.dob == "Invalid Date") { this.errorMessage("Invalid Date of Birth") }
          else if (!Email) { this.errorMessage("Invalid Email ID") }
          else if (!(this.state.dob < new Date() && this.state.dob > new Date("1872-01-01"))) { this.errorMessage("Invalid Date of Birth") }
          else if (!Corporate) { this.errorMessage("Employee ID No is empty") }
          else if (!Insurance) { this.errorMessage("Insurance Policy No is empty") }
          else if ((CommonValidation.removeSpace(this.state.purposeOfVisit) === "") || (CommonValidation.removeSpace(this.state.purposeOfVisit) === undefined)) { this.errorMessage("Enter Purpose of Visit") }
          else if ((DateTime.fromJSDate(states.appointmentDateTime).toFormat('yyyy-MM-dd') === "Invalid DateTime" || !(new Date((new Date).getFullYear(), (new Date).getMonth() - 2, 1) < states.appointmentDateTime && new Date((new Date).getFullYear(), (new Date).getMonth() + 3, 0) > states.appointmentDateTime))) { this.errorMessage("Invalid Appointment Date & Time !") }
        }
      }
    }
  }

  printOPCard = () => {
    try {
      let patientDetails = getCachevalue('DoctorPatientData')
      let appointmentId = JSON.parse(patientDetails)
      let clinicId = getCachevalue('SelectedDoctorClinic')
      RestAPIService.getAll(Serviceurls.OP_CARD_PRINT + "?patient_id=" + appointmentId?.patient_id + "&appointment_id=" + appointmentId?.appointment_id + "&clinic_id=" + clinicId + "&header=false")
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClearData() {
    clearCachevalue("OP_CreateAppointment_PatientId");
    clearCachevalue("AppRequestPatientData");
    this.setState({
      referredBy: "",
      referralSource: "",
      mobile_no: "",
      first_name: "",
      age: "",
      dob: null,
      gender: "",
      title: "",
      middle_name: "",
      surname: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      country: "",
      pin_code: "",
      employer_id: null,
      employee_id: "",
      email_id: "",
      marital_status: "",
      father_husband_name: "",
      father_husband_age: "",
      occupation: "",
      emergency_contact_person: "",
      emergency_contact_mobile: "",
      relationship: "",
      docDocument: null,
      aadharProof: null,
      panProof: null,
      passportProof: null,
      rationProof: null,
      dlProof: null,
      selectedDocId: null,
      selectedSpecId: null,
      selectedTagId: null,
      appointmentDateTime: new Date(),
      purposeOfVisit: null,
      pancard_number: "",
      ration_card_number: "",
      dl_number: "",
      passport_number: "",
      aadhar_card_number: "",
      insurance_company_name: null,
      policy_number: "",
      guardian: "",
      patient_img: null,
      temp_patient_img: null,
      selectedAppointTypeId: this.state.AppointmentTypeList.find((item) => (item.appoinment_type === "Walk in Appointment")),
      filteredDocList: [],
      isPatientCreated: false,
      SelectedPatientID: "",
      mother_name: "",
      mother_age: "",
      RequestedPatientData: {},
    })
  }

  PreviewPopClose() { this.setState({ SelectedDocSRC: null }) }

  SameNumberPatientPop() {
    let { t } = this.props;
    return (
      <Modal open={true}>
        <Box className="eMed_MobileNum_Main">
          <Box component={'div'} className="eMed_MobileNum_Header">
            <Typography variant='h6'>{"Patient List"}</Typography>
            <IconButton onClick={() => { this.setState({ SameMobileNumPatients: [] }) }} marginRight={'4vw'}>
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
            </IconButton>
          </Box>
          <Box component={'div'} className="eMed_MobileNum_wrapper">
            {
              this.state.SameMobileNumPatients.length > 0 ?
                this.state.SameMobileNumPatients.map((list) => {
                  let PatientFullName = `${list.first_name} ${list.last_name}`
                  list["patient_name"] = PatientFullName
                  list["patient_mobile_number"] = list.mobile_number
                  list["patient_photo_url"] = list.photo_url
                  return (
                    <Box>
                      <Box className="eMed_Patient_Num_Search_pop">
                        <CommonPatientDetails data={list} />
                        {this.props.isFromIP ?          // For IP Appointment
                          <Tooltip title={t("CreateAppointment")} arrow>
                            <IconButton disabled={list.is_admitted} emed_tid='ip_create_btn' onClick={() => {
                              this.props.history.push({ pathname: "/IPHome/CreateAppointment", state: { SelectedPatientDetails: list, PatientClicked: true } })
                            }} position="end">
                              <PersonAddAlt1Icon sx={{ color: list.is_admitted ? Colors.disableLabelColor : Colors.themeDark }} />
                            </IconButton>
                          </Tooltip>
                          :                           // For OP Appointment
                          ((list.appointment_date === "" || list.appointment_date !== "") || list.appointment_status === "Cancelled") ? <Tooltip title={t("CreateAppointment")} arrow>
                            <IconButton emed_tid='op_crt_btn' onClick={() => {
                              setCachevalue(list.patient_id, "OP_CreateAppointment_PatientId")
                              this.setState({
                                SelectedPatientID: list.patient_id,
                                doctorPatientId: list?.patient_id,
                                SameMobileNumPatients: []
                              }, () => { this.getPatientDemographics() })
                            }} position="end">
                              <PersonAddAlt1Icon color='primary' />
                            </IconButton>
                          </Tooltip> : <Typography fontSize={'0.8vw'}>{list.appointment_date}</Typography>
                        }
                      </Box>
                      <Divider />
                    </Box>
                  )
                }) : null
            }
          </Box>
        </Box>
      </Modal>
    )
  }

  onClickChipHandler = (item) => {
    this.setState({ selectedSlot: item })
  }

  OpenAppointmentSlotPopUP = () => {
    return (
      <Box>
        <Modal open={true}>
          <Box className="eMed_WebCamPop_Main" sx={{ height: "40vh" }}>
            <Box component={'div'} className="eMed_WebCam_Header">
              <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                Select Slot
              </Typography>
              <Button onClick={() => { this.setState({ slotPopup: false }) }} >
                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
              </Button>
            </Box>
            <Box component={'div'} style={{ display: "flex", flexWrap: "wrap", width: "90%", alignItems: "center", margin: "1vw", marginLeft: "2.5vw", height: "25vh", overflow: "scroll" }}>
              {this.state.slotList?.length > 0 ? this.state.slotList.map((item, index) => (
                <Chip className='eMed_chip'
                  size="medium"
                  label={this.convertTo12Hour(item)}
                  variant={item === this.state.selectedSlot ? 'contained' : 'outlined'}
                  key={index}
                  onClick={() => { this.onClickChipHandler(item) }}
                  color={'primary'}
                  sx={{ width: "5vw", borderRadius: "0.6vw" }}
                  clickable />)) : <Typography sx={{ height: "5vw", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "red" }}>{"Doctor Schedule not available"}</Typography>}
            </Box>
            <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingLeft: "1vw", paddingRight: "3vw" }}>
              <Button variant='outlined' size='small' id="eMed_Config_btn" style={{ marginRight: "0.5vw" }} onClick={() => { this.setState({ selectedSlot: "" }) }}>Clear</Button>
              <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => {
                let AppointTime = new Date(this.state.appointmentDateTime)
                if (this.state.selectedSlot) {
                  AppointTime.setHours(this.state.selectedSlot?.split(":")[0]);
                  AppointTime.setMinutes(this.state.selectedSlot?.split(":")[1]);
                }
                this.setState({
                  appointmentDateTime: (AppointTime && this.state.selectedSlot) ? AppointTime : this.state.appointmentDateTime,
                  slotPopup: false
                })
              }}>Select</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t, fromViewGraphics } = this.props
    let update = this.props.fromOT ? true : this.props.history.location?.state?.doctorUpdate ? this.props.history.location?.state?.doctorUpdate : getCachevalue('updatePatient')
    return (
      <Box component={"div"} className={fromViewGraphics ? 'eMed_bill_ipContainer' : 'eMed_bill_container'}>
        <Grid container className={fromViewGraphics ? 'eMed_bill_ipContent' : 'eMed_bill_content'} >
          <Grid container className={fromViewGraphics ? 'eMed_bill_ipContent1' : 'eMed_bill_content1'} spacing={fromViewGraphics ? 1 : 2}>
            <Grid item xs={8}>
              {this.renderCreateAppointmentLeft()}
            </Grid>
            <Grid item xs={4}>
              {this.renderCreateAppointmentRight()}
            </Grid>
          </Grid>
          <Box className={fromViewGraphics ? 'eMed_bill_content2 eMed_bill_Ipcontent2' : 'eMed_bill_content2'}>
            {this.props.fromOT ? null : <Button variant='outlined' size="small" id="eMed_Config_btn" sx={{ mr: '1vw' }} onClick={() => { this.ClearData() }}>{t("Clear")}</Button>}
            {this.props.fromOT ? null : <Button disabled={this.state.DisableBtn} variant='contained' size="small" id="eMed_Config_btn" sx={{ ml: '1vw' }} onClick={() => { this.Validation() }}>{update ? 'Update' : "Create Appointment"}</Button>}
          </Box>
        </Grid>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.ProfilePicClicked ? this.OpenWebCamPopUP() : null}
        {this.state.slotPopup ? this.OpenAppointmentSlotPopUP() : null}
        {this.state.SelectedDocSRC !== null ?
          <CommonImgPreviewPop src={this.state.SelectedDocSRC} title={"Document Preview"} PopClose={this.PreviewPopClose.bind(this)} /> : null}
        {this.state.SameMobileNumPatients.length > 0 ? this.SameNumberPatientPop() : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

export default withTranslation()(CreateApppointment);