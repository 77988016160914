import { Box, Typography, TextField, Tooltip, Button, Stack } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { CommonDeleteButton, CommonEditButton, ConfirmPopup, DeletePopup } from '../../../Components/Common Components/CommonComponents'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'

export default class SpecimenConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            specimenName: '',
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            specimenList: [],
            page: 0,
            pageSize: 10,
            isLoading: false,
            selectedId: null,
            deletedPopup: false,
            deleteId: null,
            delName: '',
            alertPopup: false,
            altName: '',
        }
    }

    componentDidMount() {
        this.getSpecimenList()
    }
    getSpecimenList = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_SAMPLE_TYPE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            specimenList: response.data.data || [],
                        })
                    }
                }).catch((e) => {
                    this.LoaderFunction(false)
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }
    postSpecimen = () => {
        try {
            let data = {
                'specimen_name': this.state.specimenName
            }
            if (this.state.selectedId) {
                data['id'] = this.state.selectedId
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.LAB_SAMPLE_TYPE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.LoaderFunction(false)
                        this.getSpecimenList()
                        this.clearValue()
                    }
                })
                .catch(e => {
                    this.LoaderFunction(false)
                    if (e.response.data.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    deleteSpecimen = (ID) => {
        try {
            this.LoaderFunction(true)
            RestAPIService.delete(Serviceurls.LAB_SAMPLE_TYPE + '?id=' + ID)
                .then(response => {
                    if (response.data.status == "success") {
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        this.getSpecimenList()

                    }
                }).catch((e => {
                    this.LoaderFunction(false)
                    if (e.response.data.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                }))

        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoading: key
        })
    }
    handleEdit = (data) => {
        this.setState({
            specimenName: data?.specimen_name,
            selectedId: data.id
        })
    }

    handleDelete = (data) => {
        if (!this.state.selectedId && !data.is_mapped) {
            if (!this.state.selectedId) {
                this.setState({
                    deletedPopup: true,
                    deleteId: data?.id,
                    delName: data?.specimen_name
                })
            }
        } else {
            if (this.state.selectedId) {
                this.errorMessage("You have clicked the Edit button. So, please add or clear the details.")
            } else if (data.is_mapped) {
                this.setState({
                    alertPopup: true,
                    altName: data?.specimen_name
                })
            }
        }
    }
    renderLeftside = () => {
        this.state.specimenList?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: 'sno', headerName: 'S.No', flex: 0.1 },
            {
                field: 'specimen_name', headerName: 'Specimen', flex: 0.7,
                renderCell: (params) => {
                    return (
                        <Box component={'div'}>
                            {params.row.specimen_name ? params.row.specimen_name?.length > 15 ?
                                <Tooltip placement="top" title={params.row.specimen_name}><Typography fontSize={'0.9vw'}>{params.row.specimen_name?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{params.row.specimen_name}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: "action", headerName: "Action", flex: 0.20, align: "center", headerAlign: "center",
                renderCell: (params) => <div>
                    <CommonEditButton size={"1.5vw"} onClick={this.handleEdit.bind(this, params.row)} />
                    <CommonDeleteButton size={"1.5vw"} onClick={this.handleDelete.bind(this, params.row)} />
                </div>
            },
        ]
        return (
            <Box height={'74vh'}>
                <DataGrid
                    rows={this.state.specimenList}
                    columns={columns}
                    getRowId={(row) => row['sno']}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    components={{
                        // Toolbar: CommonGridHeader.CommonToolBar,
                        NoRowsOverlay: () => (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                {'No Records To Be Shown'}
                            </Box>
                        )
                    }}
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination
                    headerHeight={40}
                    disableSelectionOnClick
                    loading={this.state.isLoading}
                />
            </Box>
        )
    }

    clearValue = () => {
        this.setState({
            specimenName: '',
            selectedId: null
        })
    }

    renderRightside = () => {
        return (
            <Box>
                <Box className='eMed_TestConfig_RightHead'>
                    <Typography sx={{ fontWeight: 'bold', marginLeft: '1.5vw', fontSize: '1vw', color: '#616161' }}>{'Specimen'}</Typography>
                </Box>
                <Box className='eMed_TestConfig_RightContentBox'>
                    <Box className='eMed_TestConfig_RightMethod'>
                        {this.renderTextBox("Specimen Name", '29vw', this.state.specimenName, 'specimenName')}
                    </Box>
                </Box>
                <Box className='eMed_TestConfig_RightBtnCon'>
                    <Stack spacing={2} direction="row">
                        <Button id='eMed_Btn_Text' variant='outlined' onClick={() => { this.clearValue() }}>{"Clear"}</Button>
                        <Button id='eMed_Btn_Text' emed_tid='Doc_Demo_Edit_Save_Btn'
                            disabled={this.state.disableBtn ? true : !CheckAccessFunc("Laboratory", "Configuration", "Specimen Configuration", null, "Tab")?.editAccess}
                            variant='contained' onClick={() => { this.postSpecimen() }}>{"Add"}</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }
    renderTextBox = (label, width, value, name) => {
        return (
            <TextField
                size={'small'}
                sx={{ width: width }}
                label={label}
                name={name}
                value={value}
                inputProps={{ maxLength: 40 }}
                variant="outlined"
                onChange={(e) => { this.textInputcheck(e) }}
            />
        )
    }
    textInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        })
    };

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: "",
        })
    }
    removeDataList = () => {
        this.deleteSpecimen(this.state.deleteId)
        this.setState({
            deletedPopup: false
        })
    }
    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            alertPopup: false,
            delName: '',
            altName: '',
            deleteId: null,
        })
    }
    render() {
        return (
            <Box Component={'div'} className='eMed_TestConfig_MainContainer'>
                <Box component={'div'} className='eMed_TestConfig_Con'>
                    <Box component={'div'} className='eMed_TestConfig_leftSide'>
                        {this.renderLeftside()}
                    </Box>
                    <Box component={'div'} className='eMed_TestConfig_RightSide'>
                        {this.renderRightside()}
                    </Box>
                </Box>
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletedPopup ?
                    <DeletePopup
                        DeletTitle={`Are You Sure? Do you want to remove the Specimen "${this.state.delName}"`}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {this.state.alertPopup ?
                    <ConfirmPopup
                        cnfTitle={'Infromation'}
                        cnfContent={`The specimen "${this.state.altName}" is mapped to the test, so please unmap the specimen and then remove.`}
                        cnfAlertPopupClose={this.PopupClose.bind(this)}
                        hideBtn={true}

                    />
                    : null}
            </Box>
        )
    }
}
