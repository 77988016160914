import React, { Component } from "react";
import '../IpPatientDetails.css';
import { Box, Paper, TextField, Button, Stack, Tooltip, Grid, IconButton, Autocomplete, Typography, Menu, MenuItem } from "@mui/material";
import { DataGrid, GridCsvExportMenuItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { CommonPatientDetails, CommonPatientCard, CommonPopUp, ConfirmPopup, ConsultantPatientCard } from "../../../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../../../Components/CommonFunctions/CommonValidation";
import { ImagePaths } from "../../../../../Utility/ImagePaths";
import { Colors } from "../../../../../Styles/Colors";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../../Utility/API/Serviceurls";
import { getCachevalue, localGetItem } from "../../../../../Utility/Services/CacheProviderService";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CurrencySymbol, multi_option } from '../../../../../Utility/Constants';
import { t } from "i18next";
import { CheckAccessFunc } from "../../../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../../../Components/Loader';
import { DateTime } from "luxon";


const ITEM_HEIGHT = 30;
class Laboratory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labDetails: [],
      labNameList: [],
      testNameList: [],
      testGrpList: [],
      patientData: {},
      attenderData: {},
      lab_name: "",
      lab_id: null,
      test_name: "",
      test_id: null,
      test_amount: null,
      test_grp: "",
      test_grp_id: null,
      test_grp_amount: null,
      spl_test: "",
      isTransmit: false,
      transmitDetail: {},
      deletedPopup: false,
      selectedRow: null,
      isSelected: false,
      selectionModel: [],
      isCheckClicked: false,
      isTransmit_Pop: false,
      isCnfClicked: false,
      isAlreadyExist: false,
      isAddCnfClicked: false,
      anchorEl: null,
      isHistoryClicked: false,
      historyData: [],
      focusError: "",
      current_page: 0,
      rowsPerPage: 10,
      withOutAppointment: {},
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: "",
      clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
      showCode:false
    }
  }
  componentDidMount() {
    let patientDetails = getCachevalue("IppatientData")
    let patients = JSON.parse(patientDetails)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    let showCode = JSON.parse(localGetItem('lab_test_code'))
    if (details?.patient_id === patients?.patient_id) {
      this.setState({
        withOutAppointment: details
      }, () => {
        this._getIpLabDetails();
        this.getPatientData();
        this.getLabList();
      })
    }
    this.setState({
      patientData: patients,
      showCode:showCode
    }, () => {
      this._getIpLabDetails();
      this.getPatientData();
      this.getLabList();
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getPatientData = () => {
    this.setState({
      attenderData: {}
    })
    let admission_id =   this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
    let url = `${Serviceurls.IP_PATIENT_DETAILS}?admission_id=${admission_id}`;
    if (this.props.is_From_IpNotes && this.state.clinic_id) {
      url = `${url}&clinic_id=${this.state.clinic_id}`
    }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              attenderData: response.data.data[0]
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.responseSuccessErrorMessage(error.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(error.message, false)
          }
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  getLabList = () => {
    this.setState({ labNameList: [] });
    try {
      let url = Serviceurls.FO_IP_LABLIST;
      if (this.props.is_From_IpNotes && this.state.clinic_id) {
        url = `${url}?clinic_id=${this.state.clinic_id}`
      }
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              labNameList: response.data.data,
              lab_name: response.data.data[0].laboratory_name,
              lab_id: response.data.data[0].laboratory_id,
            }, () => {
              if(this.state.lab_id){
                this.getTestAndGrpList(this.state.lab_id)
              } 
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.responseSuccessErrorMessage(error.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(error.message, false)
          }
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  getTestAndGrpList = (id) => {
    this.setState({ testNameList: [], testGrpList: [] });
    if(id){
    try {
      RestAPIService.getAll(`${Serviceurls.FO_IP_LABTESTSET}?laboratory_id=${id ? id : null}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              testNameList: response.data.test_data,
              testGrpList: response.data.test_group_data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.responseSuccessErrorMessage(error.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(error.message, false)
          }
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  } else {

  }
  }
  _getIpLabDetails = () => {
    this.setState({
      labDetails: [],
      current_page: 0,
      rowsPerPage: 10
    })
    let admission_id =   this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
    try {
      RestAPIService.getAll(`${Serviceurls.IP_LAB_GET}?ip_admission_id=${admission_id}`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              labDetails: response.data.data,
            }, () => {
              this.state.labDetails.forEach((item, i) => item.index = i)
            });
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }else{
            this.responseSuccessErrorMessage(error.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  _getHistoryDetails = (id) => {
    if (id) {
      this.setState({
        historyData: [],
      })
      try {
        RestAPIService.getAll(`${Serviceurls.FO_IP_HISTORY}?condition=laboratory&row_id${id ? id : null}`)
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                historyData: response.data.data,
              });
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.responseSuccessErrorMessage(e.message, false);
      }
    }
  }

  handleFocus = () =>{
    const {t} = this.props;
    let {lab_name} = this.state;
    if(lab_name === ""){
      this.setState({focusError : "Select Lab"});
      this.responseSuccessErrorMessage(`${t("Select")} ${t("Laboratory")}"`, false);
    }
  }

  responseSuccessErrorMessage(message, type) {
    this.setState({
      showSuccessResponse: message ? type : false,
      showErrorResponse: message ? !type : false,
      responseMessage: message
    })
  }

  handleTransmit = () =>{
    let { isTransmit, transmitDetail, selectedRows, isTransmit_Pop } = this.state;
    let startDate = new Date()
    let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}`
    const clinic_id = JSON.parse(localGetItem('SelectedDoctorClinicId'));
    if (transmitDetail && isTransmit && (!transmitDetail["is_transmit"])) {    // single Transmit
      let temp = {
        "invoice_date" : date,
        "ip_admission_id": this.state.patientData.id ? this.state.patientData.id : null,
        "is_transmit": true,
        "clinic_id": clinic_id || undefined,
        "doctor_id": this.state.patientData.doctor_id ? this.state.patientData.doctor_id : null,
        "line_item": [{
          "laboratory_id": transmitDetail.laboratory_id,
          "transmit_id": transmitDetail.id,
          "lab_set_id": transmitDetail.set_id ? transmitDetail.set_id : null,
          "lab_test_id": transmitDetail.test_id ? transmitDetail.test_id : null,
          "test_name": transmitDetail.test_id ? transmitDetail.lab_test_name: transmitDetail.set_id ? transmitDetail.lab_set_name :  "",
          "amount": transmitDetail.lab_test_amount ? +(transmitDetail.lab_test_amount) : transmitDetail.lab_set_amount ? +(transmitDetail.lab_set_amount) : 0,
          "special_test": ""
        }]
      }
      if (Object.values(temp).every((item => item !== null))) {
        this.handleAdd(temp);
      }
    } else if (selectedRows?.length > 0 && isTransmit_Pop) { // multi transmit
      this.setState({isCnfClicked: true})
      let checkTemp = {
        "invoice_date" : date,
        "ip_admission_id": this.state.patientData.id ? this.state.patientData.id : null,
        "clinic_id": clinic_id || undefined,
        "is_transmit": true,
        "line_item": []
      };
      selectedRows?.forEach(item => {
        let list = {
          "laboratory_id": item.laboratory_id,
          "clinic_id": clinic_id || undefined,
          "transmit_id": item.id,
          "lab_set_id": item.set_id ? item.set_id : null,
          "lab_test_id": item.test_id ? item.test_id : null,
          "test_name": item.test_id ? item.lab_test_name : item.set_id ? item.lab_set_name : "",
          "amount": item.lab_test_amount ? +(item.lab_test_amount) : item.lab_set_amount ? +(item.lab_set_amount) : 0,
          "special_test": ""
        }
        checkTemp["line_item"] = [...checkTemp["line_item"], list]
      })
      if (Object.keys(checkTemp)?.length > 0 && checkTemp["line_item"] && checkTemp["line_item"]?.length > 0) {
        this.handleAdd(checkTemp);
      }
    }
  }

  handleClear = () => {
    let {labNameList} = this.state;
    this.setState({
      lab_name:labNameList.length > 0 ? labNameList[0].laboratory_name : "",
      lab_id: labNameList.length > 0 ? labNameList[0].laboratory_id : null,
      test_name: "",
      test_grp: "",
      test_id: null,
      test_grp_id: null,
      test_amount: null,
      test_grp_amount: null,
      spl_test: "",
      focusError: "",
      isTransmit: false,
      transmitDetail: {},
      isCnfClicked: false,
      isAlreadyExist: false,
      isAddCnfClicked: false,
    })
  }
  handleAdd = (data) => {
    const { t } = this.props
    let states = this.state;
    let { test_name, test_grp, lab_name, spl_test } = this.state
    let isAvl = true;
    let Message = "";
    if(!data){
    if (!lab_name && !test_name && !test_grp && !spl_test) { isAvl = false; Message = t("Fillmandatory") }
    else if (!lab_name) { isAvl = false; Message = t("SelectLab") } 
    else if(!test_name && !test_grp && !spl_test) {isAvl = false; Message = t("Enter Anyone Test")}
  }
    if (isAvl) {
      // api call
      let datas = {}
      let tempData = {
        "laboratory_id": states["lab_id"] ? states["lab_id"] : null,
        "ip_admission_id": states["patientData"]["id"] ? states["patientData"]["id"] : null,
        "is_transmit": false,
        "line_item":[],
        "confirm_add": this.state.isAddCnfClicked
        }
          tempData["line_item"].push({
            "lab_set_id": states["test_grp_id"] ? states["test_grp_id"] : null,
            "lab_test_id": states["test_id"] ? states["test_id"] : null,
            "test_name": states["test_id"] ? states["test_name"] : states["test_grp_id"] ? states["test_grp"] : "",
            "amount": states["test_amount"] ? +(states["test_amount"]) : states["test_grp_amount"] ? +(states["test_grp_amount"]) : 0,
            "special_test": spl_test ? spl_test : ""
          })
      if(data && (states["isTransmit"] || states["isTransmit_Pop"])){ // single and multi transmit data
        datas = JSON.parse(JSON.stringify(data));
      } else if (tempData["line_item"].every((item => item !== null || item !== "" || item !== 0))){ // Adding new test data 
        datas = JSON.parse(JSON.stringify(tempData));
      }

      try {
        this.LoaderFunction(true)
        RestAPIService.create(datas, Serviceurls.IP_LAB_GET)
          .then(response => {
            if (response.data.status === "success") {
              this.handleClear();
              this.transmitPopClose();
              this._getIpLabDetails();
              this.setState({isAdd: false})
              this.LoaderFunction(false)
              this.responseSuccessErrorMessage(response.data.message, true);
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.setState({ isCnfClicked: false, isAdd: false,});
              this.LoaderFunction(false)
              if(error.response.data.message === "Test already entered for this admission"){
                this.setState({isAlreadyExist: true, isAddCnfClicked: false});
              } else{
                this.responseSuccessErrorMessage(error.response.data.message, false);
              }
            }
          });
      } catch (e) {
        this.setState({isAdd: false})
        this.responseSuccessErrorMessage(e.message, false);
      }
    } else {
      this.setState({isAdd: false})
      this.responseSuccessErrorMessage(Message, false)
    }
  }

  historyPopUpClose = () => {
    this.setState({
      anchorEl1: null,
      isHistoryClicked: false,
      historyData: []
    })
  }

  _showHistory(historydata) {
    const { t } = this.props;
    const historyColumn = [
      {
        field: 'modified_by_from', headerName: t('ModifiedBy'), sortable: false, hideable: false, flex: 0.25, renderCell: ({row}) => {
          return (<div>{row?.modified_by_from?.length > 28 ? <Tooltip placement="top" title={row?.modified_by_from} arrow><div>{row?.modified_by_from.slice(0, 25) + "..."}</div></Tooltip> : row?.modified_by_from ? row?.modified_by_from : "-"}</div>)
        }
      },
      {
        field: 'modified_date', headerAlign : "center", headerName: t('ModifiedDateAndTime'), align: "center", sortable: false, hideable: false, flex: 0.25, renderCell: ({row}) => {
          return (<Typography>{row?.modified_date ? row?.modified_date : "-"}</Typography>)
        }
      },
      {
        field: 'set_status_from', headerName: `${t('Previous')} ${t("Test")} ${t('Status')}`, flex: 0.25, sortable: false, hideable: false, renderCell: ({row}) => {
          return (<div>{row?.set_status_from?.length > 30 ? <Tooltip placement="top" title={row?.set_status_from} arrow><div>{row?.set_status_from.slice(0, 26) + "..."}</div></Tooltip> : row?.set_status_from ? row?.set_status_from : "-"}</div>)
        }
      },
      {
        field: 'set_status_to', headerName: `${t('Changed')} ${t("Test")} ${t('Status')}`, flex: 0.25, sortable: false, hideable: false, renderCell: ({row}) => {
          return (<div>{row?.set_status_to?.length > 30 ? <Tooltip placement="top" title={row?.set_status_to} arrow><div>{row?.set_status_to.slice(0, 26) + "..."}</div></Tooltip> : row?.set_status_to ? row?.set_status_to : "-"}</div>)
        }
      }
      ];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata ? historydata : []}
          columns={historyColumn}
          getRowId={(row) => row.modified_date}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => {
              return (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "40vh" }}>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }
          }}
          headerHeight={40}
        />
      </Box>
    )
  }

  renderTextBox(label, statekey, value, width) {
    const { t } = this.props;
    let states = this.state;
    return (
      <div id="eMed_srvConfig_divs">
        {statekey === "lab_name" ?
          <Autocomplete
            disableClearable
            value={value ? value : ""}
            sx={{ width: width ? width : "10vw" }}
            size="small"
            options={states.labNameList.length != 0 ? states["labNameList"] : []}
            getOptionLabel={option => typeof (option) === "string" ? option : option.laboratory_name}
            onChange={(event, newLab) => {
              this.setState({
                lab_name: newLab.laboratory_name,
                lab_id: newLab.laboratory_id,
                focusError: ""
              },
                () => {
                  this.getTestAndGrpList(this.state.lab_id);
                })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(label)+ "*"}
                autoComplete="off"
                size="small"
                InputProps={{
                  ...params.InputProps,
                }}
                inputProps={{...params.inputProps,'emed_tid': label, }}
                error = { value === "" && states["focusError"].length > 0 }
              />
            )}
          />
          :
          statekey === "test_name" ?
            <Autocomplete
              disableClearable
              disabled = {states["spl_test"] !== "" || states["test_grp"] !== ""}
              value={value ? value : ""}
              sx={{ width: width ? width : "10vw" }}
              size="small"
              options={states.testNameList.length != 0 ? states["testNameList"] : []}
              getOptionLabel={option => typeof (option) === "string" ? option : (this.state.showCode ? `${option.test_code ? `${option.test_code} - ` : ''}${option.name} - [${option.grp_name}] - ${CurrencySymbol} ${option.amount}` : `${option.name} - [${option.grp_name}] - ${CurrencySymbol} ${option.amount}`)}
              onChange={(event, newTest) => { this.setState({ test_name: newTest.name, test_id : newTest.id , test_amount : newTest.amount}) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(label)}
                  autoComplete="off"
                  inputProps={{...params.inputProps,'emed_tid': label, }}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  onFocus = {this.handleFocus}
                />
              )}
            />
            :
            statekey === "test_grp" ?
              <Autocomplete
                disableClearable
                disabled = {states["spl_test"] !== "" || states["test_name"] !== ""}
                value={value ? value : ""}
                sx={{ width: width ? width : "10vw" }}
                size="small"
                options={states.testGrpList.length != 0 ? states["testGrpList"] : []}
                getOptionLabel={option => typeof (option) === "string" ? option : (this.state.showCode ? `${option.grp_test_code ? `${option.grp_test_code} - ` : ''}${option.name}` : option.name)}
                onChange={(event, newGrp) => { this.setState({ test_grp: newGrp.name, test_grp_id : newGrp.id, test_grp_amount : newGrp.amount}) }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(label)}
                    autoComplete="off"
                    size="small"
                    inputProps={{...params.inputProps,'emed_tid': label, }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    onFocus = {this.handleFocus}
                  />
                )}
              /> :
              <TextField
                sx={{ width: width ? width : "10vw" }}
                size="small"
                autoComplete="off"
                disabled = {states["test_name"] !== "" || states["test_grp"] !== ""}
                label={t(label)}
                name={statekey}
                inputProps={{ emed_tid: `cb_${label}` }}
                value={value ? value : ""}
                onChange={(e) => {
                  let value = (e.target.value).trimStart();
                  let isValid = CommonValidation.alphanumValidation(value);
                  if (value === "" || isValid) {
                    states["spl_test"] = value
                  }
                  this.setState({ states })
                }}
                onFocus = {this.handleFocus}
              />}
      </div>
    )
  }

  msgClose(){
    this.setState({
        showSuccessResponse: false,
        showErrorResponse: false,
        responseMessage: ''
    })
  }

  getResultPrint = (value) => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${value.result_summary_id}&laboratory_id=${value.laboratory_id}` )
      .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.responseSuccessErrorMessage(error.response.data.message, false);
        })
    } catch (error) {
      this.responseSuccessErrorMessage(t("PrintError"), false);
    }
  }

  CustomToolbar = () => {
    const { t } = this.props;
    return (
      <GridToolbarContainer className='eMed_refdoc_grid' >
        <Button emed_tid = {"Transmit_TestID"} variant='contained' size="small" id="eMed_recPayment_btn" disabled={this.state.withOutAppointment?.is_ip}
          sx={{ display: (this.state.isCheckClicked && !this.state.withOutAppointment?.is_ip) ? "block" : "none" }}
          onClick={() => this.setState({ isTransmit_Pop: true })}>
          {t("Transmit")}
        </Button>
        <Tooltip placement='top' title='Show/Hide Column' arrow><GridToolbarColumnsButton /></Tooltip>
        <GridToolbarFilterButton placement='top' />
        <Tooltip placement='top' title='Table Density' arrow><GridToolbarDensitySelector /></Tooltip>
        <Tooltip placement='top' title='Export' arrow><GridToolbarExportContainer>
          <GridCsvExportMenuItem />
        </GridToolbarExportContainer>
        </Tooltip>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    )
  }

  handleTableCheckbox = (id) => {
    let states = this.state;
    let selectedIDs = new Set(id);
    let selectedRows = states["labDetails"].filter((row) => selectedIDs.has(row.id));
    states["selectedRows"] = [...selectedRows]
    states["selectionModel"] = id;
    states["isCheckClicked"] = true;
    this.setState({
      states
    }, () => {
      if (states["selectionModel"].length === 0) {
        this.setState({ isCheckClicked: false })
      }
    })
  }

  transmitPopClose = () => {
    this.setState({
      selectedRows: [],
      selectionModel: [],
      isCheckClicked: false,
      isTransmit_Pop: false
    })
  }

  addTestAgainClose = () =>{
    this.setState({
      isAlreadyExist: false,
      isAddCnfClicked: false
    })
  }

  addTestAgain = () =>{
    this.setState({
      isAddCnfClicked: true
    },() => {
      this.handleAdd()
    })
  }

  render() {
    const { t } = this.props;
    let states = this.state;

    const columns = [
      {
        field: 'lab_test_name', headerName: `${t('TestName')} / ${t('TestGroup')}`, flex: 0.18, renderCell: ({ row }) => {
          let test_name = row["lab_test_name"]? row["lab_test_name"]: row["lab_set_name"] ? row["lab_set_name"] : row["special_test"] ? row["special_test"] : "";
          return (
            <div>{test_name?.length > 20 ? <Tooltip placement="top" title={test_name} arrow><div>{test_name.slice(0, 18) + "..."}</div></Tooltip> : test_name ? test_name : "-"}</div>
          )
        }
      },
      {
        field: 'tested_on', headerAlign:"center", headerName: t('TestedOn'), flex: 0.18, align:"center", renderCell: ({ row }) => {
          return (
            <div>{row?.tested_on?.length > 20 ? <Tooltip placement="top" title={row?.tested_on} arrow><div>{row?.tested_on.slice(0, 18) + "..."}</div></Tooltip> : row?.tested_on ? row?.tested_on : "-"}</div>
          )
        }
      },
      {
        field: 'trun_over_time', headerAlign:"center", headerName: t('TurnOverTime'), flex: 0.17, align:"center", renderCell: ({ row }) => {
          return (<div>{row?.trun_over_time?.length > 20 ? <Tooltip placement="top" title={row?.trun_over_time} arrow><div>{row?.trun_over_time.slice(0, 18) + "..."}</div></Tooltip> : row?.trun_over_time ? row?.trun_over_time : "-"}</div>
          )
        }
      },
      {
        field: 'status', headerName: t('Report Status'),headerAlign:"center", align:"center", flex: 0.17, renderCell: ({ row }) => {
          let inProgress = row["status"] ? ((row["status"] !== "Result Completed" && row["status"] !== "Result Delivered" &&  row["status"] !== "OutSourced") ? "Processing" : row["status"] === "Result Delivered" ? "Result Completed" : row["status"]) : (row["special_test"]) ? "Special Test":"Awaiting Transmit";
          return (<Stack sx={{ padding: "0.4vw", borderRadius: "0.2vw", backgroundColor: row["status"] === "Result Completed" || row["status"] === "Result Delivered" ? Colors.BillCompleteBg : row["status"] === "OutSourced" ? Colors.BillPendingBg : (row["status"] !== "Result Completed" && row["status"] !== "Result Delivered" && row["status"] !== "OutSourced" &&  row["status"] !== "") ? Colors.BillInProcessBg : null }}>
            <Typography sx={{ color: (row["status"] === "Result Completed" || row["status"] === "Result Delivered") ? Colors.BillCompleteTxt : row["status"] === "OutSourced" ? Colors.BillPendingTxt : (row["status"] !== "Result Completed"&& row["status"] !== "Result Delivered" && row["status"] !== "OutSourced" &&  row["status"] !== "") ? Colors.BillInProcessTxt : null }}>
              {inProgress ? inProgress : "-"}
            </Typography>
          </Stack>)
        }
      },
      {
        field: 'modified_by', headerName: t('UpdatedBy'), flex: 0.18, renderCell: ({ row }) => {
          return (<div>{row?.modified_by?.length > 25 ? <Tooltip placement="top" title={row?.modified_by} arrow><div>{row?.modified_by.slice(0, 20) + "..."}</div></Tooltip> : row?.modified_by ? row?.modified_by : "-"}</div>)
        }
      },
      {
        field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.12, sortable: false, hideable: false, align: "center", renderCell: ({ row }) => {
          return (
            <Stack direction="row" justifyContent = "center" alignItems="center" >
              <Tooltip title={t("Transmit")} placement='top' arrow>
                <IconButton emed_tid = {"TransmitOff_TestID"} size="small" sx={{display : this.state.withOutAppointment?.is_ip ? "none" : "block"}} disabled = {this.props.is_From_IpNotes ? false : CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Laboratory", "SubTab")?.editAccess ? ((states["isTransmit"] && row["status"] !== "" && row["is_transmit"]) || (row["special_test"])  || this.state.withOutAppointment?.is_ip || this.state.isCheckClicked) : true} onClick={()=>{ this.setState({isTransmit : true, transmitDetail : row},() => {this.handleTransmit() })}}>
                  <img className="emedhub_medications_img" src={(row["status"] === "" && row["special_test"] === null && !this.state.isCheckClicked && !row?.is_transmit) ? ImagePaths.TransmitOn.default : ImagePaths.TransmitOff.default} /></IconButton>
              </Tooltip>
              <Tooltip title={t("Report")} placement='top'arrow>
                <IconButton emed_tid = {"Report_TestID"} size="small" disabled = {this.props.is_From_IpNotes ? false : CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Laboratory", "SubTab")?.editAccess ? (!(row["status"] === "Result Completed" || row["status"] === "Result Delivered")|| (row["special_test"])  || this.state.withOutAppointment?.is_ip) : true}  onClick = {() => this.getResultPrint(row)}>
                  <img  className="emedhub_medication_img" src={((row["status"] === "Result Completed" || row["status"] === "Result Delivered") && row["special_test"] === null) ? ImagePaths.IPLabOn.default : ImagePaths.IPLabOff.default} />
                </IconButton>
              </Tooltip>
              {/* There are no edit or delete buttons, therefore the history is hidden. -- Renuka */}
              {/* <div>
                <Tooltip title={t("More")}  placement='top' arrow><IconButton size="small" disabled = {row["special_test"] }  onClick={(event) => { this.setState({ anchorEl: event.currentTarget, selectedRow: row.index, isSelected: true }) }}><MoreVertIcon /></IconButton></Tooltip>
                {(this.state.isSelected && this.state.selectedRow == row.index) ?
                  <Menu
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={(e) => {
                      this.setState({
                        anchorEl: null
                      })
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '16ch',
                        marginLeft: "-7vw",
                      },
                    }}
                  >
                    {multi_option.map((option) => (
                      <MenuItem key={option} onClick={() => { this.setState({ anchorEl: null, isHistoryClicked: true }, () => { this._getHistoryDetails(row.id) }) }}>{t(option.label)}</MenuItem>))}
                  </Menu>
                  : null}
              </div> */}
            </Stack>
          )
        }
      }
    ]

    let data = {
      lineOne: t("AttenderName"),
      lineTwo: `${states["attenderData"]["attender_name"] ? states["attenderData"]["attender_name"] : "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${states["attenderData"]["attender_mobile"] ? states["attenderData"]["attender_mobile"] : "-"}`
    }

    let Consultant = {
      image: ImagePaths.Doctor_Icon.default,
      width: "2.3vw",
      height: "2.3vw",
      lineOne: t("ConsultantName"),
      lineTwo: `${states["attenderData"]["doctor_name"] ? states["attenderData"]["doctor_name"] : "-"}`
    }
    return (
      <Box id="eMed_medication_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container className="eMed_medication_Box" >
          <Stack className="eMed_medicationCard_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} >
            <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
              <CommonPatientDetails data={this.state.patientData} showDetailed={true}/>
            </Stack>
            <CommonPatientCard details={data} showDetailed={true}/>
            <ConsultantPatientCard name={Consultant} width={"20dvw"}/>
          </Stack>
          <Grid container item spacing={2} className="eMed_medication_mainDiv">
            <Grid item xs={8}>
              <Paper className="eMed_medication_Div" elevation={2}>
                <DataGrid
                  checkboxSelection 
                  rows={states["labDetails"]}
                  columns={columns}
                  getRowId={(row) => row.id}
                  selectionModel={states["selectionModel"]}
                  onSelectionModelChange={(id) => this.handleTableCheckbox(id)}
                  disableSelectionOnClick = {true}
                  isRowSelectable={({row}) => row.is_transmit === false}
                  localeText={{
                    toolbarColumns: "",
                    toolbarDensity: "",
                    toolbarExport: "",
                    toolbarFilters: "",
                    toolbarExportPrint: ""
                  }}
                  components={{
                    Toolbar: this.CustomToolbar,
                    NoRowsOverlay: () => {
                      return (
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "40vh" }}>
                          {t("NoRecordsFound")}
                        </Stack>
                      )
                    }
                  }}
                  headerHeight={40}
                  rowCount={states["labDetails"].length}
                  page={states["current_page"]}
                  onPageChange={(newPage) => this.setState({ current_page: newPage })}
                  pageSize={states["rowsPerPage"]}
                  onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                  rowsPerPageOptions={[10, 20, 30]}
                  pagination
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className="eMed_medication_Div" elevation={2}>
                <div className="eMed_lab_subDiv">
                  <div className='eMed_medication_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                    <p className='eMed_recPayment_rightHead_txt'>{t("LaboratoryReports")}</p>
                  </div>
                  <Stack className="emedhub_medication_RightContentBox" direction="column" justifyContent="center" alignItems="flex-start" spacing={0.5}>
                    {this.renderTextBox("SelectLab", "lab_name", states["lab_name"], "28.3vw")}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
                      {this.renderTextBox("TestName", "test_name", states["test_name"], "13.4vw")}
                      {this.renderTextBox("TestGroup", "test_grp", states["test_grp"], "13.8vw")}
                    </Stack>
                    {/* {this.renderTextBox("SpecialTest", "spl_test", states["spl_test"], "13.4vw")} */}
                  </Stack>
                </div>
                <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                  <Button emed_tid = {"Clear_TestID02"} variant='outlined' disabled = {this.state.withOutAppointment?.is_ip} size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                  <Button emed_tid = {"Add_TestID02"} disabled={this.props.is_From_IpNotes ? false : (CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Laboratory", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true) || this.state.isAdd } variant='contained' size="small" id="eMed_recPayment_btn" onClick={() => { this.setState({isAdd: true,},()=>{this.handleAdd()}) }}>{t("Add")}</Button>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {
          this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
        }
        {
          this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
        }
        {
          states["isHistoryClicked"] ?
            <CommonPopUp width={"75vw"} backgroundColor={Colors.white} popUpClose={this.historyPopUpClose.bind(this)} title={t("History")} component={this._showHistory.bind(this, states["historyData"])} />
            : null
        }
        {states["isTransmit_Pop"] && states["selectionModel"].length > 0 ?
          <ConfirmPopup
            cnfTitle={t('Confirmation')}
            cnfContent={`${t('Please confirm')} ${t(`ForMultiTransmit`)}`}
            cnfAlertPopupClose={this.transmitPopClose.bind(this)}
            cnfData={this.handleTransmit.bind(this)}
            cnfDisable={this.state.isCnfClicked}
          />
          : null}
          {states["isAlreadyExist"] ?
          <ConfirmPopup
            cnfTitle={t('Confirmation')}
            cnfContent={`${t('The')} ${states["test_id"] ? states["test_name"] : states["test_grp_id"] ? states["test_grp"] : ""} ${t('addSameTest')} ?`}
            cnfAlertPopupClose={this.addTestAgainClose.bind(this)}
            cnfData={this.addTestAgain.bind(this)}
            cnfDisable={this.state.isAddCnfClicked}
          />
          : null}
          <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
export default withTranslation()(Laboratory);
