import { Box, Button, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import ShowComponents from './ShowComponent'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../../Styles/Colors'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'

export default class DocOverallNotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            appointmentDate: this.props.appointmentDate,
            reviewNotes: "",
            comparePopup: false
        }
    }

    apiCalls = () => {
        this.getNotesData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getNotesData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_OVERALL_REVIEW_NOTES + `?patient_id=${this.props.patientId}&appointment_id=${this.props.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            reviewNotes: response.data.data.comments ? response.data.data.comments : ""
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostOverallNotes = () => {
        try {
            if (this.state.reviewNotes) {
                let data = {
                    'patient_id': this.props.patientId,
                    'appointment_id': this.props.appointmentId,
                    'comments': this.state.reviewNotes,
                    "doctor_id": this.props.doctorID,
                }
                RestAPIService.create(data, Serviceurls.DOC_OVERALL_REVIEW_NOTES)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.getNotesData()
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Enter Notes")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} height={'3.5vw'}>
                        <Typography fontSize={"0.85vw"} fontWeight={600}>Notes</Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {/* <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0) { this.CopyPreviousData() } }}>
                                {this.state.PreviousData?.length > 0 ?
                                    <Tooltip placement='top' title='Copy Previous'>
                                        <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                    </Tooltip> :
                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                }
                            </div>
                            <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0) { this.setState({ comparePopup: true }) } }}>
                                {this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 ?
                                    <Tooltip placement='top' title='Compare'>
                                        <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                    </Tooltip> :
                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                }
                            </div> */}
                            <Button
                                disabled={this.state.isLocked || this.props.isLocked}
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                onClick={() => { this.PostOverallNotes() }}
                            >Save</Button>
                        </div>
                    </Box>
                    <div style={{ height: "9.5vw", width: "95%", paddingLeft: "1vw" }}>
                        <TextField
                            label={("Write Your Advice Here")}
                            style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                            multiline={true}
                            rows={4}
                            variant="outlined"
                            value={this.state.reviewNotes}
                            onChange={(event) => {
                                let value = event.target.value
                                if (value?.length <= 500) {
                                    this.setState({ reviewNotes: value })
                                } else {
                                    this.errorMessage('Allowed only 500 characters')
                                }
                            }}
                        />
                    </div>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents>
        )
    }
}