import { Box, Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, CommonPopUp, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../../src/Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../src/Utility/API/Serviceurls';
import { FloorCountDropdown } from '../../../Utility/Constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
const FloorCountList = [];
for (let i = 1; i <= FloorCountDropdown; i++) { FloorCountList.push(i) }
class BedConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      BedList: [],
      ref: props.location?.pathname,

      BedNo: "",
      RoomNo: "",
      Block: "",
      Floor: "",
      BlockList: [],
      FloorList: [],
      Ward: "",
      Speciality: "",
      WardList: [],
      SpecialityList: [],

      isEditCliked: false,
      isDeleteCliked: false,
      SelectedList: null,
      isHistoryClicked: false,
      SelectedHistory: [],
      bedConfigLength:0,
      avalCount:0
    }
  }

  componentDidMount() {
    this.GetDropDownDatas()
    this.GetWardList()
    this.GetBedList()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  GetWardList() {
    try {
      RestAPIService.getAll(Serviceurls.WARD_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              WardList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  GetDropDownDatas() {
    try {
      RestAPIService.getAll(Serviceurls.HOSPITAL_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              BlockList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION).
        then((response) => {
          let datas = response.data.data;
          if (response.data.status === "success") {
            this.setState({
              SpecialityList: datas.filter(item => item.is_active === true)
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  GetBedList() {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.BED_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              BedList: response.data.data,
              bedConfigLength:response?.data?.data?.length > 0 ? response?.data?.data?.length : 0,
              avalCount:response.data.count ? response?.data.count : 0
            },()=>{this.LoaderFunction(false)})
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      this.LoaderFunction(false)
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }


  PostBedsData = () => {
    try {
      var states = this.state
      let floor = ( states.Floor || states.Floor === 0 )? (states.Floor).toString() : ''
      var data = {
        "bed_number": CommonValidation.removeSpace(states.BedNo),
        "room_number": CommonValidation.removeSpace(states.RoomNo),
        "block_id": states.Block,
        "floor": floor,
        "speciality_id": states.Speciality,
        "ward_id": states.Ward
      }
      if (states.isEditCliked) { data["config_id"] = states.SelectedList.id }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.BED_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.GetBedList()
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  ClearData() {
    this.setState({
      BedNo: "",
      RoomNo: "",
      Block: "",
      Floor: "",
      Ward: "",
      Speciality: "",
      isEditCliked: false,
      isDeleteCliked: false,
      SelectedList: {},
    })
  }

  onEditHandler = (value) => {
    try {
      this.setState({
        isEditCliked: true,
        SelectedList: value.row,
        BedNo: value.row.bed_number,
        RoomNo: value.row.room_number,
        Block: value.row.block_no_id,
        Ward: value.row.ward_id,
        Speciality: value.row.speciality_id
      }, () => { this.renderFloorList(value.row.floor) })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (value) => {
    try {
      if (!this.state.isEditCliked) {
        this.setState({
          isDeleteCliked: true,
          SelectedList: value.row,
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  GetHistoryData = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.HISTORY_DATA + "?condition=bed" + "&history_id=" + id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SelectedHistory: response.data.data,
              isHistoryClicked: true,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }


  _getHistory(historydata) {
    historydata.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props;
    let { anchorEl2 } = this.state;
    let open = Boolean(anchorEl2);

    const historyColumn = [
      { field: "sno", sortable: false, width: 100, headerName: t("SNo") },
      {
        field: "user", width: 150, headerName: t("User"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.user ? params?.row?.user : "-"}</Box>)
      },
      {
        field: "modified_date", width: 200, headerName: t("ModifiedDate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? params?.row?.modified_date : "-"}</Box>)
      },
      {
        field: "bed_number_from", width: 150, headerName: t("PreviousBed"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.bed_number_from ? params?.row?.bed_number_from : "-"}</Box>)
      },
      {
        field: "bed_number_to", width: 150, headerName: t("ChangedBed"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.bed_number_to ? params?.row?.bed_number_to : "-"}</Box>)
      },
      {
        field: "room_number_from", width: 150, headerName: t("PreviousRoom"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.room_number_from ? params?.row?.room_number_from : "-"}</Box>)
      },
      {
        field: "room_number_to", width: 150, headerName: t("ChangedRoom"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.room_number_to ? params?.row?.room_number_to : "-"}</Box>)
      },
      {
        field: "floor_from", width: 150, headerName: t("PreviousFloor"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.floor_from ? params?.row?.floor_from : "-"}</Box>)
      },
      {
        field: "floor_to", width: 150, headerName: t("ChangedFloor"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.floor_to ? params?.row?.floor_to : "-"}</Box>)
      },
      {
        field: "ward_from", width: 150, headerName: t("PreviousWard"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.ward_from ? params?.row?.ward_from : "-"}</Box>)
      },
      {
        field: "ward_to", width: 150, headerName: t("ChangedWard"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.ward_to ? params?.row?.ward_to : "-"}</Box>)
      },
      {
        field: "speciality_from", width: 150, headerName: t("PreviousSpciality"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.speciality_from ? params?.row?.speciality_from : "-"}</Box>)
      },
      {
        field: "speciality_to", width: 150, headerName: t("ChangedSpeciality"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.speciality_to ? params?.row?.speciality_to : "-"}</Box>)
      },
    ];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
          disableSelectionOnClick
        />
      </Box>
    )
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton emed_tid ={"More_testID"}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            inputProps={{ emed_tid:  "MenuTestID001" }}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option} onClick={() => { this.setState({ anchorEl: null }, () => { this.GetHistoryData(data.row.id) }) }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  renderCorporateLeft = () => {
    try {
      this.state.BedList.forEach((element, index) => { element.sno = index + 1 })
      const { t } = this.props
      const columns = [
        { field: "sno", sortable: false, flex: 0.05, headerName: t("SNo") },
        {
          field: "bed_number", flex: 0.13, headerName: t("BedNo"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.bed_number ? params?.row?.bed_number?.length > 10 ?
              <Tooltip placement='top' title={params?.row?.bed_number} arrow>
                <div>{params?.row?.bed_number.slice(0, 10) + "..."}</div></Tooltip> :
              params?.row?.bed_number : "-"}</Box>)
        },
        {
          field: "room_number", flex: 0.13, headerName: t("RoomNo"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.room_number ? params?.row?.room_number?.length > 10 ?
              <Tooltip placement='top' title={params?.row?.room_number} arrow>
                <div>{params?.row?.room_number.slice(0, 10) + "..."}</div></Tooltip> :
              params?.row?.room_number : "-"}</Box>)
        },
        {
          field: "block", flex: 0.11, headerName: t("Block"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.block ? params?.row?.block?.length > 10 ?
              <Tooltip placement='top' title={params?.row?.block} arrow>
                <div>{params?.row?.block.slice(0, 10) + "..."}</div></Tooltip> :
              params?.row?.block : "-"}</Box>)
        },
        {
          field: "floor", flex: 0.07, headerName: t("Floor"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'}>{(params?.row?.floor || params?.row?.floor === 0) ? params?.row?.floor : "-"}</Box>)
        },
        {
          field: "ward__ward_name", flex: 0.11, headerName: t("Ward"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.ward__ward_name ? params?.row?.ward__ward_name?.length > 10 ?
              <Tooltip placement='top' title={params?.row?.ward__ward_name} arrow>
                <div>{params?.row?.ward__ward_name.slice(0, 10) + "..."}</div></Tooltip> :
              params?.row?.ward__ward_name : "-"}</Box>)
        },
        {
          field: "speciality__name", flex: 0.13, headerName: t("Speciality"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.speciality__name ? params?.row?.speciality__name?.length > 10 ?
              <Tooltip placement='top' title={params?.row?.speciality__name} arrow>
                <div>{params?.row?.speciality__name.slice(0, 10) + "..."}</div></Tooltip> :
              params?.row?.speciality__name : "-"}</Box>)
        },
        {
          field: "room_type", flex: 0.11, headerName: t("RoomType"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.room_type ? params?.row?.room_type?.length > 10 ?
              <Tooltip placement='top' title={params?.row?.room_type} arrow>
                <div>{params?.row?.room_type.slice(0, 10) + "..."}</div></Tooltip> :
              params?.row?.room_type : "-"}</Box>)
        },
        {
          field: "action", flex: 0.15, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false, hideable: false,
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Room Configuration", "Bed Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
            <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "Room Configuration", "Bed Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
            {this.MenuItem(params)}
          </Box>
        }
      ]
      return (
        <Stack component={"div"}>
          <Paper className='eMed_refdoc_left' sx={{ height: "76vh" }}>
            <DataGrid
              rows={this.state.BedList}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Paper>
        </Stack>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox(label, Key, maxLength) {
    let states = this.state;
    return (
      <TextField
        className='eMed_small_textFields2'
        inputProps={{ maxLength: maxLength , emed_tid : label}}
        size='small'
        label={label}
        value={states[Key]}
        autoComplete="off"
        onChange={(e) => {
          states[Key] = e.target.value.trimStart();
          this.setState({ states })
        }}
      />
    )
  }

  renderFloorList(Floor = "") {
    try {
      let states = this.state;
      const FloorCountList = [];
      let Block = [];
      Block = states.BlockList.filter(list => list['id'] === states.Block);
      for (let i = 0; i <= Block[0]?.floor_number; i++) {
        let Object = { id: i, value: i, floor_number: i }
        FloorCountList.push(Object)
      }
      this.setState({
        FloorList: FloorCountList
      }, () => { this.setState({ Floor: Floor }) })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderDropdown(label, Key, List, LabelKey, ValueKey, disable = false) {
    let states = this.state;
    try {
      return (
        <FormControl size='small' className='eMed_small_textFields2'>
          <InputLabel>{label}</InputLabel>
          <Select
            disabled={disable}
            value={states[Key]}
            inputProps={{ emed_tid: `cb_${label}` }}
            label={label}
            onChange={(e) => {
              states[Key] = e.target.value
              this.setState({ states }, () => {
                if (Key === "Block") {
                  this.renderFloorList()
                }
              })
            }}
            MenuProps={{
              style: { maxHeight: 300, maxWidth: 150 }
            }}
          >
            {
              List?.length > 0 ?
                List.map((list, index) => (
                  <MenuItem key={index} value={list[ValueKey]}>{list[LabelKey]}</MenuItem>
                )) : null
            }
          </Select>
        </FormControl>
      )
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  validation() {
    try {
      var states = this.state;
      let SameBedName = [];
      SameBedName = states.BedList.filter(list =>
        list['bed_number'] === states.BedNo &&
        list['room_number'] === states.RoomNo &&
        list['block_no_id'] === states.Block &&
        list['floor'] == states.Floor &&
        // list['speciality_id'] === states.Speciality && 
        list['ward_id'] === states.Ward);
      var { t } = this.props;
      if (
        CommonValidation.removeSpace(states.BedNo) !== "" &&
        CommonValidation.removeSpace(states.RoomNo) !== "" &&
        states.Block !== "" &&
        states.Floor !== "" &&
        states.Ward !== "" &&
        states.Speciality !== "" &&
        (SameBedName.length === 0 || (SameBedName.length == 1 && SameBedName[0].id === states.SelectedList?.id))
      ) {
        this.PostBedsData()
      }
      else {
        if (CommonValidation.removeSpace(states.BedNo) === "") {
          this.errorMessage(t("EnterBedNumber"))
        }
        else if (CommonValidation.removeSpace(states.RoomNo) === "") {
          this.errorMessage(t("EnterRoomNo"))
        }
        else if (states.Block === "") {
          this.errorMessage(t("SelectBlock"))
        }
        else if (states.Floor === "") {
          this.errorMessage(t("SelectFloor"))
        }
        else if (states.Ward === "") {
          this.errorMessage(t("SelectWard"))
        }
        else if (states.Speciality === "") {
          this.errorMessage(t("SelectSpeciality"))
        }
        else if (SameBedName.length > 0) {
          this.errorMessage(t("BedNoExists"))
        }

      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderCorporateRight = () => {
    try {
      const { t } = this.props
      return (
        <Stack component={"div"}>
          <Paper className='eMed_refdoc_right' sx={{ height: "76vh" }} elevation={2}>
            <Box component={'div'} className="eMed_Right_Fields_Div">
              <Typography className='eMed_refdoc_title'>{t("BedConfig")}
                <Typography component={'span'} ml={'10vw'} fontWeight={600}>{`Bed Limit : ${this.state.bedConfigLength} / ${this.state.avalCount}`}</Typography>
              </Typography>
              <Box component={"div"} className="eMed_Row_Div">
                <Box>{this.renderTextBox(`${t("BedNo")} *`, "BedNo", 15)}</Box>
                <Box>{this.renderTextBox(`${t("RoomNo")} *`, "RoomNo", 15)}</Box>
              </Box>
              <Box component={"div"} className="eMed_Row_Div">
                <Box>{this.renderDropdown(`${t("SelectBlock")} *`, "Block", this.state.BlockList, "block_name", "id")}</Box>
                <Box>{this.renderDropdown(`${t("SelectFloor")} *`, "Floor", this.state.FloorList, "floor_number", "id", this.state.Block === "")}</Box>
              </Box>
              <Box component={"div"} className="eMed_Row_Div">
                <Box>{this.renderDropdown(`${t("SelectWard")} *`, "Ward", this.state.WardList, "ward_name", "id")}</Box>
                <Box>{this.renderDropdown(`${t("SelectSpeciality")} *`, "Speciality", this.state.SpecialityList, "name", "id")}</Box>
              </Box>
              {this.state.avalCount === this.state.bedConfigLength ? 
              <Box component={"div"} className="eMed_Row_Div">
                <FormHelperText sx={{color:Colors.red,fontWeight:600}}>Bed limit exceeded.Please contact the administrator.</FormHelperText>
              </Box> : null}
            </Box>
            <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
              <Button emed_tid ={"ClearData_TestID002"} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
              <Button emed_tid ={"AddRoom_TestID002"} disabled={this.state.avalCount === this.state.bedConfigLength || !CheckAccessFunc("front_office", "Settings", "Room Configuration", "Bed Configuration", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.validation() }}>{t("AddRoom")}</Button>
            </Stack>
          </Paper>
        </Stack>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  popupClose() {
    this.setState({
      isDeleteCliked: false
    })
  }

  DeleteBedsData() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.BED_CONFIG + "?config_id=" + states.SelectedList.id).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.ClearData()
            this.GetBedList()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  historyPopUpClose() {
    this.setState({
      isHistoryClicked: false,
      SelectedHistory: []
    })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    let { t } = this.props
    try {
      return (
        <Box component={"div"} className='eMed_common_container'>
          <Grid container className='eMed_refdoc_content' >
            <Grid item xs={8}>
              {this.renderCorporateLeft()}
            </Grid>
            <Grid item xs={4}>
              {this.renderCorporateRight()}
            </Grid>
          </Grid>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} Bed Number ${this.state.SelectedList ? this.state.SelectedList?.bed_number : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeleteBedsData.bind(this)}
            DeleteNotify={t("effectEntireSetting")}
          /> : null}
          {(this.state.isHistoryClicked) ?
            <CommonPopUp width={"75vw"}
              backgroundColor={Colors.white}
              popUpClose={this.historyPopUpClose.bind(this)}
              title={t("History")}
              component={this._getHistory.bind(this, this.state.SelectedHistory)} />
            : null
          }
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
}

export default withTranslation()(BedConfig)