import { Box, Typography, Button, Stack, TextField, IconButton, Modal, Paper, InputAdornment } from "@mui/material";
import React, { Component } from "react";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { Trans, withTranslation } from 'react-i18next';
import './purchase.css';

const GSTIN_NO = /^[a-z A-Z 0-9]+$/;
const ADDRESS = /^[a-z A-Z 0-9,/]+$/;
const Numeric = /^\d+$/;
const mail_id = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const alphabet = /^[a-z A-Z]*$/;
const mobile = /^[0-9-+()]*$/;
const RG_DL_NO = /([a-zA-Z0-9]{2,3})+(\/)+([a-zA-Z0-9]{2,4})+(\/)+([a-zA-Z0-9]{2,3})?$/;
const spl_DL_NO = /^([a-z A-Z 0-9/]*)$/

 class VendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_id: this.props.edit_id ? this.props.edit_id : null,
            view_id: this.props.view_id ? this.props.view_id : null,
            isEditClicked: this.props.isEditClicked ? this.props.isEditClicked : false,
            data: {
                "name": "",
                "gstin_number": "",
                "dl_no": "",
                "dl_no_2": "",
                "address_line_1": "",
                "address_line_2": "",
                "pincode": "",
                "city_name": "",
                "country": "India",
                "state": "Tamil Nadu",
                "mobile_number": "",
                "mobile_code":"+91",
                "phone_number": "",
                "email": ""
            },
            helperText: {
                "vendorNameText" : "",
                "mobile_numberText": "",
                "gstin_numberText": "",
                "dl_noText": "",
                "dl_no_2Text":"",
                "address_line_1Text": "",
                "pincodeText": "",
                "cityText": "",
                "state_Text":"",
                "email_Text":""
            },
            open: true,
            isClicked: false,
            disableBtn: false,
            isSave: false,
        }
    }
    componentDidMount() {
        if (this.state.edit_id || this.state.view_id) {
            this._doGetVendorDetail()
        }
    }
    //get api of vendor detail
    _doGetVendorDetail = () =>{
        let {edit_id, view_id, data} = this.state
        let id = edit_id ? edit_id : view_id ? view_id : null;
        this.clearScreen();
        RestAPIService.getAll(Serviceurls.LAB_VENDOR_DETAIL + "?vendor_id=" + id)
        .then(response => {
            if (response.data.status == "success") {
                data = response.data.data[0];
                data["mobile_code"] = "+91";
                this.setState({
                    data
                })
            }
          })
          .catch(error =>{
            if (error.response.data.status == "fail") {
                this.props.SuccessErrorBox(error.responses.data.message, false);
            }
          })
    }

    handleChange = (event) => {
        const {t} = this.props
        let { data, helperText } = this.state
        const name = event.target.name;
        const value = event.target.value.trimStart();
        
        if(name === "name"){
            data["name"] = value;
            if(!value){
                helperText["vendorNameText"] = t("InvalidVendorName");
            }else{
                helperText["vendorNameText"] = "";
            }
        } else if (name === "gstin_number") {
            if (value === "" || GSTIN_NO.test(value) && value.length < 16) {
                data["gstin_number"] = value.toUpperCase();
                helperText["gstin_numberText"] = "";
            }
        } else if (name === "dl_no" || name === "dl_no_2") {
            if (value.length <= 12) {
                data[name] = value.toUpperCase();
                if(GSTIN_NO.test(value)){
                    if(name === "dl_no"){
                        helperText["dl_noText"] = "";
                    }else if(name === "dl_no_2"){
                        helperText["dl_no_2Text"] = ""; 
                    }
                }else{
                if(name === "dl_no"){
                helperText["dl_noText"] = t("InvalidLicenseFormat");
                }else if(name === "dl_no_2"){
                    helperText["dl_no_2Text"] = t("InvalidLicenseFormat"); 
                }
            }
        }
        } else if(name === "city_name") {
            if (value === "" || alphabet.test(value)){
                data["city_name"] = value;
                if(name === "city_name") {
                    helperText["cityText"] = "";
                } else {
                    if (name === "city_name" && value === "") {
                        helperText["cityText"] = t("InvalidCity");
                    }
                }
            }
        } else if (name === "address_line_1" || name === "address_line_2") {
            if (value === "" || ADDRESS.test(value)) {
                data[name] = value;
                if (name === "address_line_1") {
                    helperText["address_line_1Text"] = "";
                } else if (name === "address_line_1" && value === "") {
                    helperText["address_line_1Text"] = t("InvalidAddressLine1");
                }
            }
        } else if(name === "pincode"){
            if ((value === "" || Numeric.test(value)) && value.length < 7) {
                data["pincode"] = value;
                helperText["pincodeText"] = "";
            }else if(value === ""){
                helperText["pincodeText"] = t("InvalidPincode");
            }
        } else if ((name === "mobile_number" || name === "phone_number")) {
            if ((value === "" || Numeric.test(value)) && value.length < 11) {
                data[name] = value;
                if(name === "mobile_number") {
                    helperText["mobile_numberText"] = "";
                } else {
                    if (name === "mobile_number" && value === "") {
                        helperText["mobile_numberText"] = t("InvalidMobileNo");
                    }
                }
            }
        }else if (name === "email"){
            data["email"] = value;
            if (mail_id.test(value)) {
                helperText["email_Text"] = "";
            } else {
                helperText["email_Text"] = t("InvalidEmail");
            }
        } else {
            data[name] = value;
        }
        this.setState({
            data,
            helperText
        });
    };

    selectRegionCountry = (statekey, val) => {
        const { t } = this.props
        let { data, helperText } = this.state
        if (statekey === "state") {
            data["state"] = val
            if (val) {
                helperText["state_Text"] = "";
            } else {
                helperText["state_Text"] = t("InvalidState");
            }
        } else {
            data["country"] = val
        }
        this.setState({
            data,
            helperText
        })
    }
    renderTextBox(label, type = "text", stateKey, value = '', helperText){
        const { t } = this.props;
        let {view_id} = this.state;
        return (
            <div>
                {
                    stateKey === "state" ?
                        <RegionDropdown
                            defaultOptionLabel = "State *"
                            className = "emedhub_vendor_dropDown"
                            style = {{borderColor: helperText.length > 0 ? "#ff0000" : ""}}
                            country={"India"}
                            value={value ? value : ""}
                            inputProps={{ emed_tid: "stateTestID" }}
                            onChange={(val) => this.selectRegionCountry("state", val)}
                            disabled= {view_id ? true : false}
                        />
                        : stateKey === "country" ?
                            <CountryDropdown
                                className = "emedhub_vendor_dropDown"
                                value={value ? value : ""}
                                onChange={(val) => this.selectRegionCountry("country", val)}
                                whitelist={["IN"]}
                                emed_tid = {"countryTestID"}
                                disabled= {view_id ? true : false}
                            />
                            : <TextField
                                className="emedhub_vendor_textBox"
                                required = {(stateKey == "name"  || stateKey == "gstin_number" || stateKey == "dl_no" || stateKey == "address_line_1" || stateKey == "city_name" || stateKey == "pincode" || stateKey == "state" || stateKey == "mobile_number" || stateKey == "email")? true :false }
                                autoComplete = "off"
                                size = "small"
                                type={type}
                                label={t(label)}
                                name={stateKey}
                                value={value ? value : ""}
                                onChange={this.handleChange}
                                InputProps={{
                                    readOnly: stateKey === "mobile_code" || view_id ? true : false,
                                    startAdornment: stateKey === "mobile_number" ? <InputAdornment position="end">{'+91'}</InputAdornment> : false
                                  }}
                                inputProps={{ emed_tid: `${stateKey}` }}
                                error={ helperText && helperText.length > 0 ? true : false }
                                helperText={helperText ? helperText : ''}
                            />
                }
                { stateKey === "state" &&  helperText.length > 0 ? <p id= "emedhub_vendorDetails_error">{helperText}</p> : null}
            </div>
        )
    }

    clearScreen() {
        this.setState({
            data: {
                "name": "",
                "gstin_number": "",
                "dl_no": "",
                "dl_no_2": "",
                "address_line_1": "",
                "address_line_2": "",
                "pincode": "",
                "city_name": "",
                "country": "India",
                "state": "Tamil Nadu",
                "mobile_number": "",
                "mobile_code":"+91",
                "phone_number": "",
                "email": ""
            },
            isClicked: false
        })
    }

    // POST & UPDATE API
    addVendor = () => {
        let { data, edit_id } = this.state
        let datas = {}
        if(edit_id){
            datas = {
                "vendor_id" : edit_id,
                "vendor_name": data.name,
                "gstin_number": data.gstin_number,
                "dl_no": data.dl_no,
                "dl_no_2": data.dl_no_2,
                "pincode": data.pincode,
                "address_line_1": data.address_line_1,
                "address_line_2": data.address_line_2,
                "country":data.country,
                "city": data.city_name,
                "state": data.state,
                "mobile_number": data.mobile_number,
                "phone_number": data.phone_number,
                "email": data.email
            }
        }else{
            datas = {
                "vendor_name": data.name,
                "gstin_number": data.gstin_number,
                "dl_no": data.dl_no,
                "dl_no_2": data.dl_no_2,
                "pincode": data.pincode,
                "address_line_1": data.address_line_1,
                "address_line_2": data.address_line_2,
                "country":data.country,
                "city": data.city_name,
                "state": data.state,
                "mobile_number": data.mobile_number,
                "phone_number": data.phone_number,
                "email": data.email
            }
        }
        if ((data.name && data.state && data.email && data.mobile_number ) && (data.gstin_number && data.dl_no && data.address_line_1 && data.pincode && data.city_name)) {

            if(edit_id){
            this.setState({disableBtn: true})
            RestAPIService.create(datas,Serviceurls.LAB_VENDOR)
            .then(response => {
                if (response.data.status == "success") {
                    this.clearScreen();
                    this.props.SuccessErrorBox(response.data.message, true);
                    this.handleClose();
                    this.setState({disableBtn: false, isSave: false,})
                }
              })
              .catch(error =>{
                if (error.response.data.status == "fail") {
                    this.props.SuccessErrorBox(error.response.data.message, false);
                    this.setState({disableBtn: false, isSave: false,})
                }
              })
            }else{
            this.setState({disableBtn: true, isSave: false,})
            RestAPIService.create(datas,Serviceurls.LAB_VENDOR)
            .then(response => {
                if (response.data.status == "success") {
                    this.clearScreen();
                    this.props.SuccessErrorBox(response.data.message, true);
                    this.handleClose()
                    this.setState({disableBtn: false, isSave: false,})
                }
              })
              .catch(error =>{
                if (error.response.data.status == "fail") {
                    this.props.SuccessErrorBox(error.response.data.message, false);
                    this.setState({disableBtn: false, isSave: false,})
                }
              })
        }
    }}

    validate() {
        const {t} = this.props
        let isValid = true;
        let { data, helperText } = this.state
        if (data.name) {
            helperText["vendorNameText"] = "";
        } else {
            helperText["vendorNameText"] = t("InvalidVendorName");
            isValid = false;
        }

        if(data.gstin_number.length != 15 ){
            helperText["gstin_numberText"] = t("GSTIN should contain 15 digits");
            isValid = false;
        }
        else {
            helperText["gstin_numberText"] = "";
        }

        if(data.dl_no.length != 12){
            helperText["dl_noText"] = t("Licence No should contain 12 digits");
            isValid = false;
        }
         else {
            helperText["dl_noText"] = "";
        }
        
        if(data.dl_no_2){
        if(data.dl_no_2.length != 12){
            helperText["dl_no_2Text"] = t("Licence No should contain 12 digits");
            isValid = false;
        }else {
            helperText["dl_no_2Text"] = "";
        }}
        
        if (data.address_line_1) {
            helperText["address_line_1Text"] = "";
        } else {
            helperText["address_line_1Text"] = t("InvalidAddressLine1");
            isValid = false;
        }
        if (data.city_name) {
            helperText["cityText"] = "";
        }else {
            helperText["cityText"] = t("InvalidCity");
            isValid = false;
        }if (data.pincode) {
            helperText["pincodeText"] = "";
        } else {
            helperText["pincodeText"] = t("InvalidPincode");
            isValid = false;
        }
        if (data.mobile_number && data.mobile_number.length === 10) {
            helperText["mobile_numberText"] = "";
        } else if (data.mobile_number.length !== 10){
            helperText["mobile_numberText"] = t("MobileNoShould");
            isValid = false;
        } else {
            helperText["mobile_numberText"] = t("InvalidMobileNo");
            isValid = false;
        }
        if (data.email && mail_id.test(data.email)) {
            helperText["email_Text"] = "";
        } else if(!(mail_id.test(data.email))){
            helperText["email_Text"] = t("InvalidEmail");
            isValid = false;
        } else {
            helperText["email_Text"] = t("RequiredEmail");
            isValid = false;
        }
        if (data.state) {
            helperText["state_Text"] = "";
        } else {
            helperText["state_Text"] = t("InvalidState");
            isValid = false;
        }
        this.setState({ 
            helperText 
        },()=>{
            if(isValid){
            this.addVendor()
            }else{
                this.setState({
                    isSave: false,
                })
            }
        })
    }

    handleClose = () => {
        this.props.popUpClose()
    }

    render() {
        let { data, helperText , open, edit_id, view_id} = this.state;
        const { t } = this.props;
        return (
                <Modal open={open}>
                <Paper elevation={3} id="emedhub_vendorDetails_mainBox">
                    <div id="emedhub_vendorDetails_header">
                        <Typography>{edit_id ? t("EditVendor"): view_id ? t("ViewVendor"): t("AddNewVendor")}</Typography>
                        {
                        view_id ? null:
                        <IconButton emed_tid = {edit_id ? t("EditVendor"): view_id ? t("ViewVendor"): t("AddNewVendor")}  size="small" onClick={this.handleClose}><img className="emedhub_popupIcon_img" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                        }
                    </div>
                <div>
                    <div className="emedhub_vendor_subDiv">
                        {this.renderTextBox("VendorName", "", "name", data.name, helperText.vendorNameText)}
                        {this.renderTextBox("GSTIN", "", "gstin_number", data.gstin_number, helperText.gstin_numberText)}
                        {this.renderTextBox("LicenceNo01", "", "dl_no", data.dl_no,helperText.dl_noText)}
                        {this.renderTextBox("LicenceNo02", "", "dl_no_2", data.dl_no_2, helperText.dl_no_2Text)}
                    </div>
                    <div className="emedhub_vendor_subDiv">
                        {this.renderTextBox("AddressLine1", "", "address_line_1", data.address_line_1, helperText.address_line_1Text)}
                        {this.renderTextBox("AddressLine2", "", "address_line_2", data.address_line_2, "")}
                        {this.renderTextBox("City", "", "city_name", data.city_name, helperText.cityText)}
                        {this.renderTextBox("State", "", "state", data.state, helperText.state_Text)}
                    </div>
                    <div className="emedhub_vendor_subDiv eMed_pur_ctry_txt">
                        {this.renderTextBox("Country", "", "country", data.country,"")}
                        {this.renderTextBox("Pincode","tel", "pincode", data.pincode, helperText.pincodeText)}
                    </div>
                    <div className="emedhub_vendor_subDiv eMed_pur_txt">
                        <Stack direction ="row" spacing ={1}>
                        {/* {this.renderTextBox("", "", "mobile_code", data.mobile_code)} */}
                        {this.renderTextBox("MobileNumber", "tel", "mobile_number", data.mobile_number, helperText.mobile_numberText)}
                        </Stack>
                        {this.renderTextBox("OfficeNumber", "tel", "phone_number", data.phone_number, "")}
                        {this.renderTextBox("Emailid", "email", "email", data.email, helperText.email_Text)}
                    </div>
                    <div id="emedhub_vendorDetails_footer" >
                        <div>
                            <div className="emedhub_vendorDetails_footer_subdiv">
                                <div className="emedhub_vendorDetails_bulletin"></div>
                                <p className="emedhub_vendorDetails_footer_Text1">{ edit_id ? t('Clear'): view_id ? t("Close"): t("AddVendor")}</p>
                                <p className="emedhub_vendorDetails_footer_Text2">{edit_id ? t("EntireNotReflect "): view_id ? t("BacktoVendor"):t("CreateVendor")}</p>
                            </div>
                            {
                            view_id ? 
                            null :
                            <div className="emedhub_vendorDetails_footer_subdiv">
                                <div className="emedhub_vendorDetails_bulletin"></div>
                                <p className="emedhub_vendorDetails_footer_Text1">{edit_id ? t('SaveChanges') : t("Clear")}</p>
                                <p className="emedhub_vendorDetails_footer_Text2">{edit_id ? t("Modifiedsaved") : t("ClearScreen")}</p>
                            </div>
                            }
                        </div>
                        {
                        edit_id ? 
                        <div>
                        <Button emed_tid = "isClicked_Test_ID" size = "small" variant={this.state.isClicked ? "contained" : "outlined"} sx={{ marginRight: "0.8vw", textTransform:"capitalize" }} onClick={() => { this.setState({ isClicked: !this.state.isClicked },() => { this.clearScreen() }) }} >{t('Clear')}</Button>
                        <Button emed_tid = "SaveChanges_Test_ID" size = "small" disabled={this.state.isSave} variant="contained" sx={{textTransform:"capitalize"}} onClick={() => {this.setState({isSave: true},()=>{this.validate()})}} >{t('SaveChanges')}</Button>
                        </div>
                        :
                        view_id ?
                        <div>
                        <Button emed_tid = "Close_Test_ID001" size = "small" variant="contained" sx={{textTransform:"capitalize"}} onClick={() => {this.handleClose()}} >{t('Close')}</Button>
                        </div>
                        :
                        <div>
                        <Button emed_tid = "Clear_Test_ID001"  size = "small" variant={this.state.isClicked ? "contained" : "outlined"} sx={{ marginRight: "0.8vw", textTransform:"capitalize" }} onClick={() => { this.setState({ isClicked: !this.state.isClicked },() => { this.clearScreen() }) }} >{t('Clear')}</Button>
                        <Button size = "small" variant="contained" emed_tid='Vendor_List_NewVendor_Btn' disabled={this.state.disableBtn} sx={{textTransform:"capitalize"}} onClick={() => {this.validate()}} >{t('AddVendor')}</Button>
                        </div>
                        }
                    </div>
                    </div>
                    </Paper>
                    </Modal>
        )
    }
}

export default withTranslation()(VendorDetails);