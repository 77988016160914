import React, { Component } from 'react';
import { Autocomplete, Box, Button, Checkbox, Drawer, FormControlLabel, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { splitDate, AmountFormat, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import Loader from '../../../../Components/Loader';
import { CurrencySymbol } from '../../../../Utility/Constants';

const deptList = [
    { label: "IP", value: "ip" },
    { label: "OP", value: "op" },
]
if(localGetItem("Show_Radiology_Bills_In_FO") === "true"){
    deptList.push( { label: "Radiology", value: "radiology" })
}

let LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))

if (localGetItem("multiLaboratory") === "true") {
    LoggedUserData?.laboratory_data?.map((item) => {
        deptList.push({ label: item?.laboratory_name, value: item?.laboratory_name })
    })
} else {
    deptList.push({ label: "Lab", value: "lab" })
}
if (localGetItem("multiPharmacy") === "true") {
    LoggedUserData?.pharmacy_data?.map((item) => {
        deptList.push({ label: item?.pharmacy_name, value: item?.pharmacy_name })
    })
}

const concessionList = ["Doctor", "Hospital"];
class ConcessionReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            concessionData: [],
            department: [],
            doctorNameList: localGetItem("DoctorsList") === null ? [] : JSON.parse(localGetItem("DoctorsList")),
            doctorName: null,
            billNumber: "",
            total_bill_amount: 0,
            total_concession: 0,
            concession_type: "",
            total_doctor_concession: 0,
            total_hospital_concession: 0,
            opSelected : true,
            ipSelected : true,
            labSelected : true,
            radiologySelected :(localGetItem("Show_Radiology_Bills_In_FO") === "true") ? true : false ,
            pharmacySelected : (localGetItem("Show_Pharmacy_Bills_In_FO") === "true") ? false : false,
            printLoading: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        let LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
        this.setState({
            PharmacyData: JSON.parse(JSON.stringify((LoggedUserData?.pharmacy_data))),
            LabData: JSON.parse(JSON.stringify((LoggedUserData?.laboratory_data)))
        }, () => {
            this.getConcessionData()
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getConcessionData = () => {
        let departments =  this.state.department?.length > 0 ? this.state.department.map(item => item.value) : "all";
        let doctor_name = this.state.doctorName ? this.state.doctorName.doctor_name : "";
        let doctor_id = this.state.doctorName ? this.state.doctorName.doctor_id :""
        let concession_type = this.state.concession_type ? this.state.concession_type : "";
        let selectLab = this.state.LabData?.filter((item) => {
            return this.state.department?.some((list) => item?.laboratory_name === list?.value);
        })
        let selectPharmacy = this.state.PharmacyData?.filter((item) => {
            return this.state.department?.some((list) => item?.pharmacy_name === list?.value);
        })
        let labId = selectLab?.length > 0 ? selectLab?.map((item)=> item?.laboratory_id) : ''
        let pharmacyId = selectPharmacy?.length > 0 ? selectPharmacy?.map((item)=> item?.pharmacy_id) : ''
        if(departments === "all"){
            pharmacyId = this.state.PharmacyData?.map((item)=> item?.pharmacy_id)
            labId = this.state.LabData?.map((item)=> item?.laboratory_id)        
        }
        try {
            let moduleType = []
            if (this.state.opSelected) {
                moduleType.push("op")
            }
            if (this.state.ipSelected) {
                moduleType.push("ip")
            }
            if (this.state.labSelected) {
                moduleType.push("lab")
            }
            if (this.state.pharmacySelected) {
                moduleType.push("pharmacy")
            }
            if (this.state.radiologySelected) {
                moduleType.push("radiology")
            }
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.FO_REPORTS_CONCESSION}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&department=${departments}&doctor_id=${doctor_id}&doctor_name=${doctor_name}&bill_no=${this.state.billNumber}&concession_type=${concession_type}&laboratory_id=${labId}&pharmacy_id=${pharmacyId}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        let list = response.data?.data?.length > 0 ? response.data?.data : [];
                        list.forEach((item, index) => {
                            item["concession_amount"] = item.doctor_concession ? item.doctor_concession : item.hosptial_concession;
                            item["sno"] = index + 1
                        })
                        this.setState({
                            concessionData: list,
                            total_bill_amount: response?.data?.total_bill_amount,
                            total_concession: response?.data?.total_concession_amount,
                            total_doctor_concession: response?.data?.total_doctor_concession,
                            total_hospital_concession: response?.data?.total_hospital_concession,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    printReports = () => {
        try {
            let moduleType = []
            if (this.state.opSelected) {
                moduleType.push("op")
            }
            if (this.state.ipSelected) {
                moduleType.push("ip")
            }
            if (this.state.labSelected) {
                moduleType.push("lab")
            }
            if (this.state.pharmacySelected) {
                moduleType.push("pharmacy")
            }
            if (this.state.radiologySelected) {
                moduleType.push("radiology")
            }
            let departments = this.state.department?.length > 0 ? this.state.department.map(item => item.value) : "all";
            let doctor_name = this.state.doctorName ? this.state.doctorName.doctor_name : "";
            let doctor_id = this.state.doctorName ? this.state.doctorName.doctor_id : "";
            let concession_type = this.state.concession_type ? this.state.concession_type : "";
            let selectLab = this.state.LabData?.filter((item) => {
                return this.state.department?.some((list) => item?.laboratory_name === list?.value);
            })
            let selectPharmacy = this.state.PharmacyData?.filter((item) => {
                return this.state.department?.some((list) => item?.pharmacy_name === list?.value);
            })
            let labId = selectLab?.length > 0 ? selectLab?.map((item)=> item?.laboratory_id) : ''
            let pharmacyId = selectPharmacy?.length > 0 ? selectPharmacy?.map((item)=> item?.pharmacy_id) : ''
            if(departments === "all"){
                pharmacyId = this.state.PharmacyData?.map((item)=> item?.pharmacy_id)
                labId = this.state.LabData?.map((item)=> item?.laboratory_id)        
            }
            this.setState({disableBtn: true, printLoading: true})
            RestAPIService.getAll(`${Serviceurls.FO_REPORTS_CONCESSION}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&department=${departments}&doctor_id=${doctor_id}&doctor_name=${doctor_name}&bill_no=${this.state.billNumber}&concession_type=${concession_type}&laboratory_id=${labId}&pharmacy_id=${pharmacyId}&export_type=pdf`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                        this.setState({disableBtn: false, printLoading: false})
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({disableBtn: false, printLoading: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false, printLoading: false})
        }
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button  emed_tid = {"LabCloseButton_TestID01"}  onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            emed_tid = {"FilterDate_TestID01"} 
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    {/* <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            disableClearable
                            options={deptList}
                            getOptionLabel={(option) => (typeof(option.label) === "string" ? option.label : option)}
                            value={this.state.department}
                            onChange={(event, newValue) => {
                                this.setState({ department: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_department' }}
                                    label={t("Select Department")}
                                    autoComplete='off'
                                    emed_tid = {"FDepartment_TestID01"} 
                                />
                            )}
                        />
                    </Box> */}
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            disableClearable
                            options={concessionList}
                            getOptionLabel={(option) => (option)}
                            value={this.state.concession_type}
                            onChange={(event, newValue) => {
                                this.setState({ concession_type: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'concession_type' }}
                                    label={t('Select Concession Type')}
                                    autoComplete='off'
                                    emed_tid = {"Concession_TestID01"} 
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            disableClearable
                            options={this.state.doctorNameList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_docter' }}
                                    label={t('selectDoctor')}
                                    autoComplete='off'
                                    emed_tid = {"selectDoctorTestID01"} 
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                  {option?.doctor_name}
                                </li>
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            sx={{width: "20vw"}}
                            inputProps={{ maxLength: 20 , emed_tid: 'billnumber'}}
                            size='small'
                            label={t("BillNumber")}
                            value={this.state.billNumber}
                            autoComplete='off'
                            emed_tid = {"BillNumberTestID01"} 
                            onChange={(e) => {
                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                if (number || e.target.value === "") {
                                    this.setState({ billNumber: e.target.value })
                                }
                            }}
                        />
                    </Box>
                    <Box component={"div"}>
                        <Box className="eMed_Filter_Header">
                            <Typography marginLeft={"1vw"} >Select Department</Typography>
                        </Box>
                        <Box className="eMed_CheckBox_Group">   
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.opSelected} onChange={(e) => { this.setState({ opSelected: e.target.checked }) }} size='small' />} label={t("Out Patient")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.ipSelected} onChange={(e) => { this.setState({ ipSelected: e.target.checked }) }} size='small' />} label={t("In Patient")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.labSelected} onChange={(e) => { this.setState({ labSelected: e.target.checked }) }} size='small' />} label={t("Lab")} />
                            <FormControlLabel disabled={localGetItem("Show_Radiology_Bills_In_FO") !== "true"} className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.radiologySelected} onChange={(e) => { this.setState({ radiologySelected: e.target.checked }) }} size='small' />} label={t("Radiology")} />
                            <FormControlLabel disabled={localGetItem("Show_Pharmacy_Bills_In_FO") !== "true"} className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.pharmacySelected} onChange={(e) => { this.setState({ pharmacySelected: e.target.checked }) }} size='small' />} label={t("Pharmacy")} />
                        </Box>
                    </Box>

                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"  emed_tid = {"getConcessionDataTestID01"} 
                        onClick={() => {
                            this.setState({
                                billNumber: "",
                                department: [],
                                doctorName: null,
                                concession_type: "",
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                opSelected : true,
                                ipSelected : true,
                                labSelected : true,
                                radiologySelected : (localGetItem("Show_Radiology_Bills_In_FO") === "true") ? true : false,
                                pharmacySelected :  (localGetItem("Show_Pharmacy_Bills_In_FO") === "true") ? false : false,
                            }, () => this.getConcessionData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid = {"Search_TestID01"} 
                        onClick={() => {
                            this.getConcessionData()
                            this.setState({
                                FilterOpen: false
                            })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    render() {
        const { t } = this.props
        let {concessionData} = this.state;

        const columns = [
            {
                field: "patient_name", headerName: t("Patient Details"), flex: 0.15, density: 'compact',
                renderCell: ({ row }) => (<CommonPatientDetails data={row} disablePhoto={true}/>)
            },
            {
                field: "doctor_name", headerName: t("Doctor Name"), flex: 0.125, density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.doctor_name?.length > 20 ?
                    <Tooltip placement='top' title={row?.doctor_name} arrow><div>{row?.doctor_name.slice(0, 18) + "..."}</div></Tooltip>
                    : row?.doctor_name ? row?.doctor_name : "-"}</Box>)
            },
            {
                field: "department", headerName: t("Department"), flex: 0.105, density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.department?.length > 15 ?
                    <Tooltip placement='top' title={row?.department} arrow><div>{row?.department.slice(0, 13) + "..."}</div></Tooltip>
                    : row?.department ? row?.department : "-"}</Box>)
            },
            {
                field: "concession_type", headerName: t("Concession Type"), flex: 0.10, density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.concession_type?.length > 15 ?
                    <Tooltip placement='top' title={row?.concession_type} arrow><div>{row?.concession_type.slice(0, 13) + "..."}</div></Tooltip>
                    : row?.concession_type ? row?.concession_type : "-"}</Box>)
            },
            {
                field: "invoice_number", headerName: t("Bill No"), flex: 0.09, align: "center", headerAlign: "center", density: 'compact',
                renderCell: ({ row }) => {
                    let BillNumber = row?.common_invoice_number ? row?.common_invoice_number : row?.invoice_number ? row?.invoice_number : ""
                    const billArr = BillNumber ? BillNumber?.split("/") : [];
                    return (
                        <Tooltip placement='top' title={BillNumber} arrow>
                            <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: "invoice_date", headerName: t("Bill Date"), flex: 0.10, align: "center", headerAlign: "center", density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.invoice_date ? splitDate(row?.invoice_date) : "-"}</Box>)
            },
            {
                field: "common_total_amount", headerName: t("Bill Amount"), flex: 0.10, type: "number", density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.common_total_amount ? AmountFormat(row?.common_total_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "concession_amount", headerName: t("Concession"), flex: 0.12, type: "number", density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.concession_amount ? AmountFormat(row?.concession_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "remarks", headerName: t("Remarks"), flex: 0.11, density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.remarks?.length > 13 ?
                    <Tooltip placement='top' title={row?.remarks} arrow><div>{row?.remarks.slice(0, 13) + "..."}</div></Tooltip>
                    : row?.remarks ? row?.remarks : "-"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                    {/* {AmountsCard(t("Total Bill Amount"), this.state.total_bill_amount)} */}
                    {AmountsCard(t("Total Doctor concession"), this.state.total_doctor_concession)}
                    {AmountsCard(t("Total Hospital concession"), this.state.total_hospital_concession)}
                    {AmountsCard(t("Total Concession"), this.state.total_concession)}

                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' emed_tid = {"eMed_action_img_TestID"} onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Tooltip title={t("Print")} placement='top' arrow>
                            <Button className='eMed_usrconf_btn' emed_tid = {"eMed_usrconf_btn_TestID01"}  disabled={this.state.disableBtn} onClick={() => { this.printReports() }}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={concessionData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader || this.state.printLoading} forPrint={this.state.printLoading ? true : false} label={this.state.printLoading ? "Building PDF..." : null} />
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(ConcessionReport)