import React, { Component } from "react";
import { Button, TextField, Typography, Modal, FormControl, InputLabel, Select, MenuItem, FormHelperText, Autocomplete } from "@mui/material";
import "./inventory.css"
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'

const Expiry_period = [
    { label: "", value: 0 },
    { label: "1 month", value: 1 },
    { label: "2 months", value: 2 },
    { label: "3 months", value: 3 },
    { label: "6 months", value: 6 },
]

const Material_type = [
    { label: "Material Type 1", value: '1' },
    { label: "Material Type 2", value: '2' },
    { label: "Material Type 3", value: '3' },
    { label: "Material Type 4", value: '4' },
]

const Unit_list = [
    { label: "Unit 1", value: '1' },
    { label: "Unit 2", value: '2' },
    { label: "Unit 3", value: '3' },
    { label: "Unit 4", value: '4' },
]

const Product_type = [
    { label: "Product Type 1", value: '1' },
    { label: "Product Type 2", value: '2' },
    { label: "Product Type 3", value: '3' },
    { label: "Product Type 4", value: '4' },
]

const gstPercentage = [
    { label: "0%", value: "0" },
    { label: "0.25%", value: "0.25" },
    { label: "3%", value: "3" },
    { label: "5%", value: "5" },
    { label: "12%", value: "12" },
    { label: "18%", value: "18" },
    { label: "28%", value: "28" },
]

const Numeric = /^\d+$/;

class NewProductRegistry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: {
                "material_type": "",
                "brand_name": "",
                "product_name": "",
                "product_type": "",
                "unit": "",
                "vendor_Name": "",
                'vendor_id': null,
                "manufacture_Name": "",
                "phyBox_No": "",
                "rec_threshold": "",
                "hsn_code": "",
                "gst": "",
                "expiry_period": "",
                "upc": "",
                "recommended_By": "",
                "descrip": ""
            },
            helperText: {
                "material_typeTxt": "",
                "brand_nameTxt": "",
                "product_nameTxt": "",
                "product_typeTxt": "",
                "unitTxt": ""
            },
            vendor_list: [],
            open: true,
            isClicked: false,
            materialTypeList:[],
            productTypeList:[],
            unitList:[],
            isSuccessMsg:false,
            isSuccessMsgText:"",
            disableBtn: false,
        }
    }

    componentDidMount() {
        this._getVendorList()
        this.getProductTypeList()
        this.getMaterialType()
        this.getUnitList()
    }
    
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          isSuccessMsg: false,
          isSuccessMsgText: "",
        })
    }

    _getVendorList = () => {
        this.setState({
            vendor_list: [] 
        })
        RestAPIService.getAll(Serviceurls.LIST_VENDOR)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        vendor_list: response.data.data,
                    });
                }
            })
            .catch(error => {
                if (error.response.data.status == "fail") {
                    this.props.SuccessErrorBox(error.response.data.message, false);
                }
            });
    }

    addProduct = () => {
        let { productList } = this.state
        let status = "Approved"
        let data = {
            "material_type": +productList.material_type,
            "brand_name": CommonValidation.removeSpace(productList.brand_name),
            "product_name": CommonValidation.removeSpace(productList.product_name) ,
            "product_type": +productList.product_type,
            "unit": +productList.unit,
            "vendor_id": productList.vendor_id,
            "manufacturer_name": CommonValidation.removeSpace(productList.manufacture_Name),
            "physical_box_no": productList.phyBox_No,
            "reorder_threshold": productList.rec_threshold ? productList.rec_threshold : 0,
            "hsn_code": productList.hsn_code,
            "gst_percentage": productList.gst ? productList.gst : 0,
            "expiry_alert_period": productList.expiry_period ? productList.expiry_period : 0,
            "bar_code": productList.upc,
            "recommended_by": productList.recommended_By,
            "description": productList.descrip,
            "status": status
        }
        if (productList.material_type && productList.brand_name && productList.product_name && productList.product_type && productList.unit) {
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.LAB_PRODUCT_DETAILS)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({open:false,disableBtn:false})
                        this.props.SuccessErrorBox(response.data.message,"success")
                        this.handleClose()
                        this.clearContent();
                        this.props._getStockList();
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.props.SuccessErrorBox(error.response.data.message,"error")
                    }
                    this.setState({disableBtn: false})
                })
        }
    }
    validate = () => {
        const { t } = this.props
        let { productList, helperText } = this.state
        if (productList.material_type) {
            helperText["material_typeTxt"] = "";
        } else {
            helperText["material_typeTxt"] = t("requiredMaterial");
        }
        if (productList.brand_name) {
            helperText["brand_nameTxt"] = "";
        } else {
            helperText["brand_nameTxt"] = t("requiredBrandname");
        }
        if (productList.product_name) {
            helperText["product_nameTxt"] = "";
        } else {
            helperText["product_nameTxt"] = t("requiredProduct");
        }
        if (productList.product_type) {
            helperText["product_typeTxt"] = "";
        } else {
            helperText["product_typeTxt"] = t("requiredProductType");
        }
        if (productList.unit) {
            helperText["unitTxt"] = "";
        } else {
            helperText["unitTxt"] = t("requiredUnit");
        }
        this.setState({
            helperText
        }, () => {
            this.addProduct()
        })
    }

    updateState = (event) => {
        const { t } = this.props;
        let { productList, helperText } = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if (name == "material_type") {
            productList[name] = value;
            if (!value) {
                helperText["material_typeTxt"] = t("requiredMaterial");
            } else {
                helperText["material_typeTxt"] = "";
            }
        } else if (name == "brand_name") {
            productList[name] = value;
            if (!value) {
                helperText["brand_nameTxt"] = t("requiredBrandname");
            } else {
                helperText["brand_nameTxt"] = "";
            }
        } else if (name == "product_name") {
            productList[name] = value;
            if (!value) {
                helperText["product_nameTxt"] = t("requiredProduct");
            } else {
                helperText["product_nameTxt"] = "";
            }
        } else if (name == "product_type") {
            productList[name] = value;
            if (!value) {
                helperText["product_typeTxt"] = t("requiredProductType");
            } else {
                helperText["product_typeTxt"] = "";
            }
        } else if (name == "unit") {
            productList[name] = value;
            if (!value) {
                helperText["unitTxt"] = t("requiredUnit");
            } else {
                helperText["unitTxt"] = "";
            }
        }
        else if (name == "gst" || name == "rec_threshold") {
            if (value == "" || Numeric.test(value)) {
                productList[name] = value;
            }
        }
        else {
            productList[name] = value;
        }
        this.setState({
            productList,
            helperText
        })
    }

    renderTextBox = (label, statekey, value = '', helperText = '') => {
        const { t } = this.props;
        const defaultdata = [
            { name: 'No options Found' }
        ]
        let { productList, vendor_list } = this.state;
        return (
            <div>
                {statekey === "material_type" ?
                    <FormControl sx={{ width: "18.85vw" }} size="small" required error={helperText.length > 0 ? true : false}>
                        <InputLabel>{t(label)}</InputLabel>
                        <Select
                            emed_tid ={"usr_materialType"}
                            value={productList.material_type}
                            name={statekey}
                            label={t(label)}
                            onChange={this.updateState}
                        >
                            {
                                this.state.materialTypeList?.length > 0 ? this.state.materialTypeList.map((list, index) => (
                                    <MenuItem key={index} value={list.id} >{list.material_type}</MenuItem>
                                )) : "No data"
                            }
                        </Select>
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>
                    :
                    statekey === "product_type" ?
                        <FormControl sx={{ width: "18.85vw" }} size="small" required error={helperText.length > 0 ? true : false}>
                            <InputLabel>{t(label)}</InputLabel>
                            <Select
                                emed_tid ={"usr_producttype"}
                                value={productList.product_type}
                                name={statekey}
                                label={t(label)}
                                onChange={this.updateState}
                            >
                                {
                                    this.state.productTypeList?.length > 0 ? this.state.productTypeList.map((list, index) => (
                                        <MenuItem key={index} value={list.id} >{list.product_type}</MenuItem>
                                    )) : "No data"
                                }
                            </Select>
                            <FormHelperText>{helperText}</FormHelperText>
                        </FormControl>
                        :
                        statekey === "unit" ?
                            <FormControl sx={{ width: "18.85vw" }} size="small" required error={helperText.length > 0 ? true : false}>
                                <InputLabel>{t(label)}</InputLabel>
                                <Select
                                    value={productList.unit}
                                    emed_tid ={"usr_ProductListUnit"}
                                    name={statekey}
                                    label={t(label)}
                                    onChange={this.updateState}
                                    MenuProps={{
                                        style: { maxHeight: 350 },
                                        }}
                                >
                                    {
                                        this.state.unitList?.length > 0 ? this.state.unitList.map((list, index) => (
                                            <MenuItem key={index} value={list.id} >{list.unit}</MenuItem>
                                        )) : "No data"
                                    }
                                </Select>
                                <FormHelperText>{helperText}</FormHelperText>
                            </FormControl>
                            : statekey === "expiry_period" ?
                                <FormControl sx={{ width: "18.85vw" }} size="small">
                                    <InputLabel>{t(label)}</InputLabel>
                                    <Select
                                        value={productList.expiry_period}
                                        emed_tid ={"exiry_period"}
                                        name={statekey}
                                        label={t(label)}
                                        onChange={this.updateState}
                                    >
                                        {
                                            Expiry_period.map((list, index) => (
                                                <MenuItem key={index} value={list.value} >{list.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                : statekey === "vendor_Name" ?
                                    <Autocomplete
                                        disableClearable
                                        value={value ? value : ""}
                                        sx={{ width: "18.85vw" }}
                                        options={vendor_list.length != 0 ? vendor_list : defaultdata}
                                        getOptionLabel={option => typeof (option) === "string" ? option : option.name}
                                        onChange={(event, newVendor) => {
                                            productList["vendor_Name"] = (newVendor.name);
                                            productList["vendor_id"] = (newVendor.vendor_id);
                                            this.setState({
                                                productList
                                            })
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t(label)}
                                                size="small"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                                inputProps={{...params.inputProps, 'emed_tid': 'vendor_list_testID' }}
                                            />
                                        )}
                                    />
                                    : statekey === "gst" ?
                                        <Autocomplete
                                            disableClearable
                                            value={value ? value : ""}
                                            options={gstPercentage.length > 0 ? gstPercentage : []}
                                            getOptionLabel={(options) => typeof(options) === "string" ? options : options.label}
                                            sx={{ width: "18.85vw" }}
                                            onChange={(e, newPercent) => {
                                                productList["gst"] = newPercent["value"] ? newPercent["value"] : newPercent
                                                this.setState({
                                                    productList
                                                })
                                            }}
                                            onInputChange={(event, newPercent) => {
                                                productList["gst"] = newPercent;
                                                this.setState({
                                                    productList
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} label={t(label)} size="small" InputProps={{ ...params.InputProps, type: 'search' }}   inputProps={{...params.inputProps, 'emed_tid': 'vendor_list_testID2' }} />}
                                        /> :
                                    <TextField
                                        required={statekey === "brand_name" || statekey === "product_name"}
                                        label={t(label)}
                                        sx={{ width: statekey === "descrip" ? "39vw" : "18.85vw" }}
                                        size="small"
                                        autoComplete="off"
                                        name={statekey}
                                        value={value}
                                        inputProps={{ maxLength: statekey === "descrip" ? 250 : null ,emed_tid: `${statekey}`}}
                                        error={helperText.length > 0 ? true : false}
                                        helperText={helperText}
                                        onChange={this.updateState}
                                    />
                }
            </div>
        )
    }
    handleClose = () => {
        this.props.popUpClose()
    }
    clearContent = () => {
        this.setState({
            productList: {
                "material_type": "",
                "brand_name": "",
                "product_name": "",
                "product_type": "",
                "unit": "",
                "vendor_Name": "",
                'vendor_id': null,
                "manufacture_Name": "",
                "phyBox_No": "",
                "rec_threshold": "",
                "hsn_code": "",
                "gst": "",
                "expiry_period": "",
                "upc": "",
                "recommended_By": "",
                "descrip": ""
            }
        })
    }

    getMaterialType = () => {
        try{
            RestAPIService.getAll(Serviceurls.LAB_MATERIAL_TYPE)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.setState({
                        materialTypeList:response.data.data
                    })
                }
            }).catch((error)=>{
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    getProductTypeList = () => {
        try{
            RestAPIService.getAll(Serviceurls.LAB_PRODUCT_TYPE)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.setState({
                        productTypeList:response.data.data
                    })
                }
            }).catch((error)=>{
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    getUnitList = () => {
        try{
            RestAPIService.getAll(Serviceurls.LAB_PRODUCT_UNIT)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.setState({
                        unitList:response.data.data
                    })
                }
            }).catch((error)=>{
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }
    render() {
        const { t } = this.props;
        let { productList, open, helperText } = this.state;
        return (
            <Modal open={open}>
                <div id="emedhub_registry_mainBox">
                    <div className="add_product_top_header">
                        <Typography sx={{flex:"0.5",alignSelf:"center",paddingLeft:"1vw"}}><b>{t("AddNewProduct")}</b></Typography>
                        <div className="add_product_top_header_left">
                        <img className="stock_edit_img_close"  emed_tid='Stock_Edit_img_close_testId' onClick={this.handleClose} src={ImagePaths.LabCloseButton.default} alt={"close"} />
                        </div>
                    </div>
                    <div>
                        <div className="emedhub_registry_subDiv">
                            {this.renderTextBox("MaterialType", "material_type", productList.material_type, helperText.material_typeTxt)}
                            {this.renderTextBox("BrandName", "brand_name", productList.brand_name, helperText.brand_nameTxt)}
                            {this.renderTextBox("ProductName", "product_name", productList.product_name, helperText.product_nameTxt)}
                            {this.renderTextBox("ProductType", "product_type", productList.product_type, helperText.product_typeTxt)}
                        </div>
                        <div className="emedhub_registry_subDiv">
                            {this.renderTextBox("Units", "unit", productList.unit, helperText.unitTxt)}
                            {this.renderTextBox("VendorName", "vendor_Name", productList.vendor_Name, "")}
                            {this.renderTextBox("ManufactureName", "manufacture_Name", productList.manufacture_Name, "")}
                            {this.renderTextBox("PhyBoxNo", "phyBox_No", productList.phyBox_No, "")}
                        </div>
                        <div className="emedhub_registry_subDiv">
                            {this.renderTextBox("ReorderThreshold", "rec_threshold", productList.rec_threshold, "")}
                            {this.renderTextBox("HSNCode", "hsn_code", productList.hsn_code, "")}
                            {this.renderTextBox("GST%", "gst", productList.gst, "")}
                            {this.renderTextBox("ExpiryPeriod", "expiry_period", productList.expiry_period, "")}
                        </div>
                        <div className="emedhub_registry_subDiv">
                            {this.renderTextBox("UPC", "upc", productList.upc, "")}
                            {this.renderTextBox("RecommendedBy", "recommended_By", productList.recommended_By, "")}
                            {this.renderTextBox("Description", "descrip", productList.descrip, "")}
                        </div>
                    </div>
                    <div className="add_product_card">
                        <div className="add_product_flex_one">
                            {/* <div className="emedhub_registry_footer_subdiv">
                                <div className="emedhub_registry_bulletin"></div>
                                <p className="emedhub_registry_footer_Text1">{t("Save")}</p>
                                <p className="emedhub_registry_footer_Text2">{t("IntimationOfProductApproval")}</p>
                            </div>
                            <div className="emedhub_registry_footer_subdiv">
                                <div className="emedhub_registry_bulletin"></div>
                                <p className="emedhub_registry_footer_Text1">{t("Cancel")}</p>
                                <p className="emedhub_registry_footer_Text2">{t("EntireNotReflect")}</p>
                            </div> */}
                        </div> 
                        <div className="add_product_flex_two">
                            <Button size="small" sx={{ marginRight: "1vw" }} variant={this.state.isClicked ? "contained" : "outlined"} emed_tid='StockList_NewProduct_Btn1' onClick={() => { this.setState({ isClicked: !this.state.isClicked }, () => { this.clearContent() }) }}>{t('Clear')}</Button>
                            <Button size="small" variant="contained" emed_tid='StockList_NewProduct_Btn2' disabled={this.state.disableBtn} onClick={() => { this.validate() }}>{t('AddProduct')}</Button>
                        </div>
                        </div>
                    {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : this.state.isSuccessMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.isSuccessMsgText}
                            msgPop={this.msgClose.bind(this)}
                        /> : null
                }
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(NewProductRegistry);