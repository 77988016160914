import React, { Component } from 'react';
import './Config.css';
import {
  Button,
  Stack,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Autocomplete,
  IconButton,
  Tooltip,
  InputAdornment,
  Box,
  Divider,
  Typography,
  Modal,
  FormHelperText
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import SearchIcon from '@mui/icons-material/Search';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { Code_Length, CurrencySymbol } from '../../../Utility/Constants';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
class PackageConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      SearchText: "",
      testGroupDataList: [],
      testConfigData: [],
      PackageName: "",
      PackageAmount: "",
      PackageDescrp: "",
      SelectedTestName: null,
      SelectedTestGrp: null,
      PackageSplitList: [],
      PackageDataList: [],
      SelectedPackageData: {},
      disableBtn: false,
      packageCode:'',
      hasCode:false
    }
  }

  componentDidMount() {
    this.getPackageConfigData()
    this.getTestData()
    this.getTestGroupData()
  }

  getTestGroupData = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              testGroupDataList: response.data.data
            })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          })
        })

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  getPackageConfigData = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              PackageDataList: response.data.data
            }, ()=>{ this.LoaderFunction(false) })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          }, ()=>{ this.LoaderFunction(false) })
        })

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }


  getTestData = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + "?list=all")
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              testConfigData: response.data.data
            })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          })
        })

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: "",
    })
  }

  postPackageConfigData = () => {
    try {
      var states = this.state
      let TestIds = []
      let GrorpIds = []
      this.state.PackageSplitList.forEach((item) => {
        if (item?.lab_test_details) {
          GrorpIds.push(item.id)
        } else {
          TestIds.push(item?.id)
        }
      })
      var data = {
        "package_name": CommonValidation.removeSpace(states.PackageName),
        "package_amt": states.PackageAmount ? +states.PackageAmount : 0,
        "desc": CommonValidation.removeSpace(states.PackageDescrp),
        "lab_test": TestIds,
        "lab_group": GrorpIds,
        'package_test_code':states.packageCode ? states.packageCode : ''
      }
      if (Object.keys(this.state.SelectedPackageData).length > 0) {
        data['package_id'] = this.state.SelectedPackageData?.package_id
      }
      this.setState({disableBtn: true})
      RestAPIService.create(data, Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION)
        .then(response => {
          if (response.data.status == "success") {
            this.setState({
              successMsg: true,
              successMsgText: response.data.message
            }, () => {
              this.clearValue()
              this.getPackageConfigData()
              this.setState({disableBtn: false})
            })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.response.data.message,
            disableBtn: false,
          })
        })
    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message,
        disableBtn: false,
      })
    }
  }

  DelectPackage = () => {
    try {
      RestAPIService.delete(Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION + "?package_id=" + this.state.SelectedPackageData?.package_id)
        .then(response => {
          if (response.data.status == "success") {
            this.setState({
              successMsg: true,
              successMsgText: response.data.message
            }, () => {
              this.getPackageConfigData()
            })
          }
        }).catch((e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.response.data.message
          })
        }))

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  GetIndividualPackageData = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION + "?package_id=" + this.state.SelectedPackageData?.package_id)
        .then(response => {
          if (response.data.status === "success") {
            let GrpList = response.data.data?.group_details ? response.data.data?.group_details : []
            let TestList = response.data.data?.test_details ? response.data.data?.test_details : []
            this.setState({
              PackageName: response.data.data?.package_name,
              PackageAmount: response.data.data?.package_amt,
              PackageDescrp: response.data.data?.desc,
              packageCode:response.data.data?.package_test_code,
              PackageSplitList: [...GrpList, ...TestList]
            }, ()=>{ this.LoaderFunction(false) })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          }, ()=>{ this.LoaderFunction(false) })
        })

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  textInputcheck = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(name === 'packageCode'){
      if(value?.length <= Code_Length || value === ''){
        this.setState({
          packageCode:value
        })
      }
    }else{
      if (value.length <= 150) {
        this.setState({
          [name]: value
        })
      };
    }
  };

  numInputcheck = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value) && value.length <= 10) {
      this.setState({
        [name]: value,
      },() => {
        if(name === 'PackageAmount'){
          this.setState({isChange:true})
        }
      });
    }
  };

  renderTextBox = (label, width, value, name, multiline = false) => {
    return (
      <div>
        <TextField
          // disabled={name === "testUnit" ? true : name === 'packageCode' ? this.state.hasCode : false}
          disabled={name === "testUnit" ? true : false}
          size={'small'}
          sx={{ width: width }}
          label={label}
          rows={3}
          name={name}
          multiline={multiline}
          value={value}
          variant="outlined"
          onChange={name === 'PackageAmount' ? this.numInputcheck : this.textInputcheck}
          inputProps={{emed_tid : `${name}`}}
          InputProps={{
            endAdornment: name === "PackageAmount" ? (
              <InputAdornment position="start">
                {/* <CurrencyRupeeIcon /> */}
                {CurrencySymbol}
              </InputAdornment>
            ) : null,
          }}
        />
      </div>
    )
  }

  renderLeftTable = () => {
    var states = this.state
    const { t } = this.props
    let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code

    const filtereddata = showCode ?
    this.state.PackageDataList.filter((data) => {
      return (
        (data?.package_test_code === null ? null : data?.package_test_code?.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.package_name === null ? null : data.package_name.toLowerCase().includes(this.state.SearchText.toLowerCase()))
      );
    }) :
     this.state.PackageDataList.filter((data) => {
      return (
        (data.package_name === null ? null : data.package_name.toLowerCase().includes(this.state.SearchText.toLowerCase()))
      );
    });
    return (
      <div>
        <Paper elevation={0}>
          <Box className="eMed_Search_Box_">
            <TextField
              variant='standard'
              placeholder='Search'
              inputProps={{ emed_tid: "SearchIconTestID" }}
              value={this.state.SearchText}
              onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"   emed_tid = "SearchIconTestID001">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '15vw' }} />
          </Box>
          <TableContainer className='eMed_ConfigTable_Paper'>
            <Table stickyHeader size='small'>
              <TableHead sx={{ height: '7.3vh' }}>
                <TableRow>
                  <TableCell id='eMed_config_TableRow1' align='center' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("Package Name")}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('No of Tests')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('No of Group')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='right' sx={{ color: Colors.HeadText }}>{t(`Package Amount (${CurrencySymbol})`)}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                .map((data, index) => {
                  var sno = (states.page * states.rowsPerPage) + (index + 1)
                  return (
                    <TableBody>
                      <TableRow sx={{ bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white' }}>
                        <TableCell align='center'><div>{sno}</div></TableCell>
                        <TableCell align='left'><div>{data.package_name ? `${showCode ? data?.package_test_code ? `${data?.package_test_code} / ` : '' : ''}${data.package_name}` : '-'}</div></TableCell>
                        <TableCell align='center'><div>{data.no_of_tests ? data.no_of_tests : 0}</div></TableCell>
                        <TableCell align='center'><div>{data.no_of_groups ? data.no_of_groups : 0}</div></TableCell>
                        <TableCell align='right'><div>{data.package_amt ? data.package_amt : 0}</div></TableCell>
                        <TableCell align='center'>
                          <div className='eMed_TestConfig_Con'>
                            <IconButton size='small' emed_tid = {"GetIndividualPackageDataTestId"}  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess}>
                              <img src={CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess ? ImagePaths.Edit.default : ImagePaths.DisabledEdit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                onClick={() => {
                                  this.setState({
                                    SelectedPackageData: data,
                                    hasCode:data?.package_test_code ? true : false,
                                    hasShare:data?.is_share ? data?.is_share : false
                                  }, () => { this.GetIndividualPackageData() })

                                }}
                              />
                            </IconButton>
                            <IconButton  emed_tid = {"deletedPopup_TestId"}  size='small' disabled={!CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess}>
                              <img src={CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                onClick={() => {
                                  this.setState({
                                    SelectedPackageData: data
                                  }, () => { this.setState({ deletedPopup: true }) })
                                }}
                              />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                })
              }
            </Table>
            {filtereddata.length === 0 ?
              <div className='eMed_TestConfig_NoRecords'>
                {t('NoRecordsFound')}
              </div> : null}
          </TableContainer>
        </Paper>
        <div className='eMed_TestConfig_LeftPageination'>
          <TablePagination
            component='div'
            id="eMed_tbl_pagination"
            rowsPerPageOptions={[10, 20, 30]}
            count={filtereddata.length}
            rowsPerPage={states.rowsPerPage}
            page={states.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    )
  }
  renderRightTable = () => {
    var { PackageSplitList } = this.state
    const { t } = this.props
    return (
      <div>
        <Paper elevation={0}>
          <TableContainer className='eMed_ConfigRightTable_Paper' sx={{ width: '29vw' }}>
            <Table stickyHeader size='small'>
              <TableHead sx={{ flex: 1, }}>
                <TableRow>
                  <TableCell id='eMed_config_TableRow1' align='left'>{t('SNo')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left'>{t('Test Name or Group Name')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left'>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              {PackageSplitList && PackageSplitList.length > 0 ? PackageSplitList.map((item, index) => {
                let TestorGrpName = item?.name ? item?.name : (item?.test_name ? item?.test_name : "")
                return (
                  <TableBody>
                    <TableRow sx={{ height: '2.5vw' }}>
                      <TableCell align='left'><div>{index + 1}</div></TableCell>
                      <TableCell align='left' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '2.5vw' }}>
                        {TestorGrpName.length > 20 ?
                          <Tooltip placement="top" title={TestorGrpName} arrow>
                            <div >{TestorGrpName ? TestorGrpName.slice(0, 20) + '...' : '-'}</div>
                          </Tooltip> : <div> {TestorGrpName ? TestorGrpName : '-'}</div>}
                        {item?.lab_test_details ?
                          <Tooltip title={<div style={{ display: 'flex', flexDirection: 'column', maxHeight: '15vw', overflowY: 'scroll' }}>
                            {item?.lab_test_details.map((testitem, testindex) => (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography fontSize={"0.9vw"} sx={{ textDecoration: 'underline' }}>{testindex === 0 ? "Test Names" : ""}</Typography>
                                <Typography fontSize={"0.9vw"}>{`${testitem?.test_name} ${testindex === item?.lab_test_details?.length - 1 ? "" : ","}`}</Typography>
                              </div>
                            ))}</div>} placement='top' arrow>
                            <Typography fontSize={'0.9vw'} color={Colors.themeDark} paddingLeft={"0.5vw"}>View Tests</Typography>
                          </Tooltip> : null}
                      </TableCell>
                      <TableCell align='left'>
                        <Tooltip title='Delete' placement='top' arrow>
                          <img className='eMed_TestConfig_DeleteIcon eMed_cursor' emed_tid = {"TestConfig_DeleteIcon_TestID"} role={"button"} src={ImagePaths.Delete.default} alt="Delete"
                            onClick={() => {
                              PackageSplitList.splice(index, 1)
                              this.setState({
                                PackageSplitList
                              })
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }) : null}
            </Table>
            {PackageSplitList.length === 0 ?
              <div className='eMed_TestConfig_NoRecords'>
                {t("NoRecordsFound")}
              </div> : null}
          </TableContainer>
        </Paper>
      </div>
    )
  }

  clearValue = () => {
    this.setState({
      SearchText: "",
      PackageName: "",
      PackageAmount: "",
      PackageDescrp: "",
      SelectedTestName: null,
      SelectedTestGrp: null,
      PackageSplitList: [],
      SelectedPackageData: {},
      hasCode:false,
      packageCode:'',
      isChange:false
    })
  }

  onSave = () => {
    var states = this.state
    const { t } = this.props

    if (CommonValidation.removeSpace(states?.PackageName) !== "" && states?.PackageAmount !== "" && states.PackageSplitList.length > 0) {
      this.postPackageConfigData()
    } else {
      if (CommonValidation.removeSpace(states?.PackageName) === "") {
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: t('Enter Package Name')
        })
      }
      else if (states?.PackageAmount === "") {
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: t('Enter Package Amount')
        })
      }
      else if (states.PackageSplitList.length === 0) {
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: t('Select Test Name or Group')
        })
      }
    }

  }


  PopupClose = () => {
    this.setState({
      deletedPopup: false,
      SelectedPackageData: {}
    })
  }
  removeDataList = () => {
    this.DelectPackage()
    this.setState({
      deletedPopup: false
    })
  }


  AddTestinPackage() {
    try {
      const { t } = this.props;
      if (this.state.SelectedTestName !== null) {
        let isDuplicate = false
        for (let i = 0; i < this.state.PackageSplitList.length; i++) {
          if (this.state.PackageSplitList[i]?.id === this.state.SelectedTestName?.id) {
            isDuplicate = true
            this.setState({
              isErrorMsg: true,
              isErrorMsgText: t('Test Already Added')
            })
            break
          } else if (this.state.PackageSplitList[i]?.lab_test_details) {
            for (let j = 0; j < this.state.PackageSplitList[i]?.lab_test_details?.length; j++) {
              if (this.state.PackageSplitList[i]?.lab_test_details[j]?.id === this.state.SelectedTestName?.id) {
                isDuplicate = true
                this.setState({
                  isErrorMsg: true,
                  isErrorMsgText: `Test Already Includes in Group - ${this.state.PackageSplitList[i]?.name}`
                })
              }
            }
          }
        }
        if (!isDuplicate) {
          this.state.PackageSplitList.push(this.state.SelectedTestName)
          this.setState({ PackageSplitList: this.state.PackageSplitList, SelectedTestName: null })
        }

      } else if (this.state.SelectedTestGrp !== null) {
        let isDuplicate = false
        for (let i = 0; i < this.state.PackageSplitList.length; i++) {
          if (this.state.PackageSplitList[i]?.id === this.state.SelectedTestGrp?.id && this.state.PackageSplitList[i]?.lab_test_details) {
            isDuplicate = true
            this.setState({
              isErrorMsg: true,
              isErrorMsgText: t('Group Already Added')
            })
            break
          } else {
            for (let j = 0; j < this.state.SelectedTestGrp?.lab_test_details?.length; j++) {
              if (this.state.SelectedTestGrp?.lab_test_details[j]?.id === this.state.PackageSplitList[i]?.id) {
                isDuplicate = true
                this.setState({
                  isErrorMsg: true,
                  isErrorMsgText: t('Group contains a already added Test')
                })
              }
            }
          }
        }
        if (!isDuplicate) {
          this.state.PackageSplitList.push(this.state.SelectedTestGrp)
          this.setState({ PackageSplitList: this.state.PackageSplitList, SelectedTestGrp: null })
        }
      } else if (this.state.SelectedTestName === null && this.state.SelectedTestGrp === null) {
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: t('Select Test Name or Test Group')
        })
      }
    }
    catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e?.message
      })
    }
  }

  LoaderFunction = (key = false) => {
    this.setState({
      isLoader: key
    })
  }

  render() {
    const { t } = this.props
    return (
      <div className='eMed_TestConfig_MainContainer'>
        <div className='eMed_TestConfig_Con'>
          <div className='eMed_TestConfig_leftSide'>
            {this.renderLeftTable()}
          </div>
          <div className='eMed_TestConfig_RightSide'>
            <div className='eMed_TestConfig_RightHead'>
              <p className='eMed_Testconfig_RightHead_text'>{t('Package Configuration')}</p>
            </div>
            <div className='eMed_TestConfig_RightContentBox'>
              <div className='eMed_TestConfig_RightMethod'>
                {this.renderTextBox(t("Package Name *"), '13vw', this.state.PackageName, 'PackageName')}
                {JSON.parse(localGetItem('lab_test_code')) || JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code ?
                  this.renderTextBox(t("Package Code"), '13vw', this.state.packageCode, 'packageCode') :
                  this.renderTextBox(t("Package Amount *"), '13vw', this.state.PackageAmount, 'PackageAmount')}
              </div>
              {JSON.parse(localGetItem('lab_test_code')) || JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code ?
                <div className='eMed_TestConfig_RightMethod'>
                  {this.renderTextBox(t("Package Amount *"), '13vw', this.state.PackageAmount, 'PackageAmount')}
                </div> : null}
              <FormHelperText sx={{color:'red',fontWeight:600}}>{this.state.hasShare ?
              this.state.isChange ? 'Existing Doctor Share configuration for this Package will be deleted.You need to reconfigure it' : '' : null}</FormHelperText>
              <div className='eMed_TestConfig_RightMethod'>
                {this.renderTextBox(t("Package Description"), '29vw', this.state.PackageDescrp, 'PackageDescrp', true)}
              </div>
              <Divider textAlign='left' sx={{ marginLeft: '0.5vw', marginTop: '0.5vw' }}>
                <Typography component={'p'} className='eMed_Service_title'>Add Test to Package</Typography></Divider>

              <div className='eMed_TestConfig_RightMethod'>
                <Autocomplete
                  options={this.state.testConfigData}
                  getOptionLabel={(options) => options.test_name}
                  onChange={(e, value) => {
                    this.setState({
                      SelectedTestGrp: null,
                      SelectedTestName: value
                    })
                  }}
                  value={this.state.SelectedTestName}
                  id="combo-box-demo"
                  sx={{ width: "10vw" }}
                  renderInput={(params) => <TextField {...params} label={t("Test Name")} size='small' inputProps={{...params.inputProps, 'emed_tid': 'Test_Name_Test_Id01' }} />}
                />
                <Autocomplete
                  options={this.state.testGroupDataList}
                  getOptionLabel={(options) => options.name}
                  onChange={(e, value) => {
                    this.setState({
                      SelectedTestGrp: value,
                      SelectedTestName: null
                    })
                  }}
                  value={this.state.SelectedTestGrp}
                  id="combo-box-demo"
                  sx={{ width: "10vw" }}
                  renderInput={(params) => <TextField {...params} label={t("Test Group")} size='small' inputProps={{...params.inputProps, 'emed_tid': 'Test_Name_Test_Id02' }} />}
                />
                <Button id='eMed_Btn_Text' emed_tid = "eMed_Btn_Text_test_Id01"  variant='contained' sx={{ width: '5vw' }}
                  onClick={() => { this.AddTestinPackage() }}
                >{t('Add')}</Button>
              </div>
              <div className='eMed_TestConfig_Right_SideTable'>
                {this.renderRightTable()}
              </div>
            </div>
            <div className='eMed_TestConfig_RightBtnCon'>
              <Stack spacing={2} direction="row">
                <Button id='eMed_Btn_Text' emed_tid = "eMed_Btn_Text_test_Id02" variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                <Button id='eMed_Btn_Text' emed_tid='packageCongfig_Save_Btn03' disabled={this.state.disableBtn ? true : !CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess}  variant='contained' onClick={() => { this.onSave() }}>{t("Save")}</Button>
              </Stack>
            </div>
          </div>
        </div>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletedPopup ?
          <DeletePopup DeletTitle={` Are you sure to delete Package - ${this.state.SelectedPackageData?.package_name}`}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.removeDataList.bind(this)}
            DeleteNotify={'It will affect on entire configuration'}
            color={'red'}
          />
          : null}
           <Loader loaderOpen={this.state.isLoader} />
      </div>
    );
  }
}

export default withTranslation()(PackageConfig);