import React, { Component } from 'react';
import './Config.css';
import {
    Button,
    Stack,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Tooltip,
    InputAdornment,
    Box,
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import SearchIcon from '@mui/icons-material/Search';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';
class DoctorDemographics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            refDoctorList: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            doctorName: '',
            mobileNo: '',
            regNo: '',
            specilization: '',
            qualification: '',
            clinic: '',
            mobileNoCode: '+91',
            selectedEditID: '',
            deletedPopup: false,
            deletedID: '',
            searchKey: '',
            selectedIndex: '',
            SearchText:"",
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getRefDoctorList()
    }

    // componentWillReceiveProps(props){
    //     if(props.search_key != this.state.searchKey){
    //         this.setState({
    //             searchKey: props.search_key
    //         },() => {
    //             this.getRefDoctorList()
    //         })
    //     }
    // }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: "",
        })
      }

      LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }
    getRefDoctorList = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_REFERRED_DOCTOR + "?search_key=" + this.state.searchKey)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            refDoctorList: response.data.data ? response.data.data : []
                        },()=>{this.LoaderFunction(false)})
                    }
                },()=>{this.LoaderFunction(false)}).catch(e => {
                    this.LoaderFunction(false)
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                })

        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    postRefDoctorList = () => {
        try {
            var states = this.state
            var data = {
                "name": states.doctorName ? states.doctorName.replace(/\s+/g, ' ').trim() : '',
                "mobile_no": states.mobileNo ? states.mobileNo : '',
                "reg_no": states.regNo ? states.regNo : '',
                "qualification": states.qualification ? states.qualification.replace(/\s+/g, ' ').trim() : '',
                "specialization": states.specilization ? states.specilization.replace(/\s+/g, ' ').trim() : '',
                "hospital_name": states.clinic ? states.clinic.replace(/\s+/g, ' ').trim() : ''
            }
            if (this.state.selectedEditID) {
                data['id'] = this.state.selectedEditID
            }
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.LAB_REFERRED_DOCTOR)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getRefDoctorList()
                            this.setState({
                                doctorName: '',
                                mobileNo: '',
                                regNo: '',
                                specilization: '',
                                qualification: '',
                                clinic: '',
                                selectedEditID:'',
                                disableBtn: false,
                            })
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message,
                        disableBtn: false,
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                disableBtn: false,
            })
        }
    }
    editRegDoctorList = (item, index) => {
        this.setState({
            selectedEditID: item.id,
            doctorName: item.name,
            regNo: item.reg_no,
            mobileNo: item.mobile_number,
            specilization: item.specialization,
            qualification: item.qualification,
            clinic: item.hospital_name,
            selectedIndex: index
        })
    }
    deleteRefDoctorList = (ID) => {
        try {
            RestAPIService.delete(Serviceurls.LAB_REFERRED_DOCTOR + '?id=' + ID)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getRefDoctorList()
                        })
                    }
                }).catch((e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                }))

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    textInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(value.length <= 150){
        this.setState({
            [name]: value
        })};
    };

    numInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const re = /^[0-9+]+$/;
        if (value === "" || re.test(value)) {
            this.setState({
                [name]: value,
            });
        }
    };
     letterInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const re = /^[A-Za-z\s]+$/;
        if (value === "" || re.test(value) && value.length <= 50) {
            this.setState({
                [name]: value,
            });
        }
     }

    renderTextBox = (label, width, value, name) => {
        return (
            <div>
                <TextField
                    size={'small'}
                    sx={{ width: width }}
                    label={label + "*"}
                    name={name}
                    value={value}
                    inputProps={{ maxLength: name === "mobileNo" ? 10 : name === "regNo" ? 7 : '', emed_tid : `${name}` } }
                    InputProps={{startAdornment:name === "mobileNo" ? <InputAdornment position="end">{'+91'}</InputAdornment> : false}}
                    variant="outlined"
                    onChange={name === 'mobileNo' || name === "regNo" ? this.numInputcheck : name === "doctorName" || name === "specilization" ? this.letterInputcheck :  this.textInputcheck}
                />
            </div>
        )
    }

    renderLeftTable = () => {
        var states = this.state
        const { t } = this.props
        //  filter table data based on search Text
        const filtereddata = this.state.refDoctorList.filter((data) => {
            return (
                (data.name === null ? null : data.name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.specialization === null ? null : data.specialization.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.qualification === null ? null : data.qualification.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.hospital_name === null ? null : data.hospital_name.toLowerCase().includes(this.state.SearchText.toLowerCase()))
            );
        });
        return (
            <div>
                <Paper elevation={0}>
                <Box className="eMed_Search_Box_">
                        <TextField
                            variant='standard'
                            placeholder='Search'
                            value={this.state.SearchText}
                            onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
                            inputProps={{ emed_tid: "SearchText_Test_ID" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" emed_tid ="SearchIcon_Test_ID" >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '15vw' }} />
                    </Box>
                    <TableContainer className='eMed_ConfigTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ height: '7.3vh' }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("DoctorName")}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Specialization')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Qualification')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Hospital/ClinicName')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                                .map((data, index) => {
                                    var sno = (states.page * states.rowsPerPage) + (index + 1)
                                    return (
                                        <TableBody>
                                            <TableRow sx={{bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white'}}>
                                                <TableCell align='left'>{sno}</TableCell>
                                                <TableCell align='left'>
                                                    {data.name.length > 15 ?
                                                        <Tooltip placement="top" title={data.name} arrow>
                                                            <div>{data.name ? data.name.slice(0, 15) + '...': '-'}</div>
                                                        </Tooltip> : <div>{data.name ? data.name : '-'}</div>}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {data.specialization.length > 15 ?
                                                        <Tooltip placement="top" title={data.specialization} arrow>
                                                            <div>{data.specialization ? data.specialization.slice(0, 15) + '...': '-'}</div>
                                                        </Tooltip> : <div>{data.specialization ? data.specialization: '-'}</div>}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {data.qualification.length > 15 ?
                                                        <Tooltip placement="top" title={data.qualification} arrow>
                                                            <div>{data.qualification ? data.qualification.slice(0, 15) + '...' : '-'}</div>
                                                        </Tooltip> : <div>{data.qualification ? data.qualification: '-'}</div>}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {data.hospital_name.length > 15 ?
                                                        <Tooltip placement="top" title={data.hospital_name} arrow>
                                                            <div>{data.hospital_name ? data.hospital_name.slice(0, 15) + '...': '-'}</div>
                                                        </Tooltip> : <div>{data.hospital_name ? data.hospital_name : '-'}</div>}</TableCell>
                                                <TableCell align='center'>
                                                    <div className='eMed_TestConfig_Con'>
                                                        <IconButton size='small'  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Referred Doctors",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Referred Doctors",  null, "Tab")?.editAccess ? ImagePaths.Edit.default : ImagePaths.DisabledEdit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                                onClick={() => { this.editRegDoctorList(data, index) }}
                                                            />
                                                        </IconButton>
                                                        <IconButton size='small' emed_tid ="DeleteIcon_Test_ID"  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Referred Doctors",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Referred Doctors",  null, "Tab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        deletedPopup: true,
                                                                        deletedID: data.id
                                                                    })
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                        </Table>
                        {filtereddata.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Paper>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata.length}
                        rowsPerPage={states.rowsPerPage}
                        page={states.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        )
    }

    clearValue = () => {
        this.setState({
            doctorName: '',
            mobileNo: '',
            regNo: "",
            specilization: '',
            qualification: '',
            clinic: '',
            selectedEditID: '',
            selectedIndex: '',
        })
    }

    validCheck = () => {
        const {t} = this.props
        var saveErrorMsg = ''
        var states = this.state
        if (!states.doctorName ||
            !states.mobileNo ||
            !states.regNo ||
            !states.specilization ||
            !states.qualification ||
            !states.clinic) {
            saveErrorMsg = t('Fillmandatory')
        }
        return saveErrorMsg
    }

    onSave = () => {
        var inValid = this.validCheck()
        if (inValid) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: inValid
            })
        } else {
            this.postRefDoctorList()
        }
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false
        })
    }
    removeDataList = () => {
        this.deleteRefDoctorList(this.state.deletedID)
        this.setState({
            deletedPopup: false
        })
    }
    render() {
        const { t } = this.props
        return (
            <div className='eMed_TestConfig_MainContainer'>
                <div className='eMed_TestConfig_Con'>
                    <div className='eMed_TestConfig_leftSide'>
                        {this.renderLeftTable()}
                    </div>
                    <div className='eMed_TestConfig_RightSide'>
                        <div className='eMed_TestConfig_RightHead'>
                            <p className='eMed_Testconfig_RightHead_text'>{t('DoctorDemogrphics')}</p>
                        </div>
                        <div className='eMed_TestConfig_RightContentBox'>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("DoctorName"), '29vw', this.state.doctorName, 'doctorName')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("MobileNo"), '14vw', this.state.mobileNo, 'mobileNo')}
                                {this.renderTextBox(t("RegistrationNo"), '14vw', this.state.regNo, 'regNo')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Specialization"), '18vw', this.state.specilization, 'specilization')}
                                {this.renderTextBox(t("Qualification"), '10vw', this.state.qualification, 'qualification')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Hospital/ClinicName"), '29vw', this.state.clinic, 'clinic')}
                            </div>
                        </div>
                        <div className='eMed_TestConfig_RightBtnCon'>
                            <Stack spacing={2} direction="row">
                                <Button id='eMed_Btn_Text'  emed_tid='Doc_Demo_Clear_Test_ID' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                                <Button id='eMed_Btn_Text' emed_tid='Doc_Demo_Test_ID_Save_Btn' disabled={this.state.disableBtn ? true : !CheckAccessFunc("Laboratory", "Configuration", "Referred Doctors",  null, "Tab")?.editAccess}  variant='contained' onClick={() => { this.onSave() }}>{t("Save")}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("ConfirmremoveDoctor")}
                        DeleteNotify={t("effectentireBilling")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
              <Loader loaderOpen={this.state.isLoader} />
            </div>
        );
    }
}

export default withTranslation()(DoctorDemographics);