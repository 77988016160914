import React, { Component } from "react";
import { TextField, Button, Stack, Typography, Autocomplete, FormControl, InputLabel, Select, 
MenuItem, Paper,List, ListItem,ListItemButton, ListItemText, Modal, OutlinedInput, Box, Tooltip} from "@mui/material";
import "./purchase.css";
import { Colors } from '../../../Styles/Colors';
import { Trans, withTranslation } from 'react-i18next';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CurrencySymbol } from "../../../Utility/Constants";

const Expiry_period = [
    { label: "", value: '0' },
    { label: "1 month", value: '1' },
    { label: "2 months", value: '2' },
    { label: "3 months", value: '3' },
    { label: "6 months", value: '6' },
]
const gstPercentage = [
    { label: "0%", value: "0" },
    { label: "0.25%", value: "0.25" },
    { label: "3%", value: "3" },
    { label: "5%", value: "5" },
    { label: "12%", value: "12" },
    { label: "18%", value: "18" },
    { label: "28%", value: "28" },
]

const Numeric = /^\d+$/;
const Decimal = /^[\d]+(\.\d{0,2})?$/ ;

const DEFAULT_SET = {
    "brand_name": "",
    "product_name": "",
    "product_type": "",
    "material_type": "",
    "unit": "",
    "batch_no": "",
    "expiry_date": null,
    "hsn_code": "",
    "bar_code": "",
    "pack_of_drugs": "",
    "sales_unit": "",
    "buying_price": "",
    "free_quantity": "",
    "discount_percentage": "",
    "discount_amount": "",
    "sales_discount": "",
    "sales_discount_amount": "",
    "gst_percentage": 0,
    "mrp_per_pack": "",

    "total_buying_price": 0,
    "mrp_per_quantity": 0,
    "stock_in_quantity": 0,

    "expiry_alert_period": "",
    "reorder_threshold": "",
    "physical_box_no": "",
    "description": "",
    "manufacturer_name": "",
    "recommended_by": "",
    // excess
    "total_gst_amount": "0",
    "product_ms_id": null,

    "productDetails": {},
    "isProductSelected": false,
    "previousPriceDetails" : null,
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class DirectPoRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: JSON.parse(JSON.stringify(DEFAULT_SET)),
            brand_suggestionList: [],
            EPRValue: 0,
            is_sales_amount_changed: false,
            isDifferentBatchExist: false,
            existBatchList: [],
            selectedBatchNo: "",
            open: true,
            // suggestion 
            productSuggestionList: [],
            purchase_status: this.props.purchase_status ? this.props.purchase_status : "",
            open1: false,
            defaultDisabled : this.props.screenType === "directPo" ? false : true,
            disableBtn: false,
        }
    }
    componentWillReceiveProps(props) {
        if (props.selectedEntry != this.state.data && Object.keys(props.selectedEntry).length > 0) {
            let { data } = this.state;
            let mergedItem = JSON.parse(JSON.stringify(props.selectedEntry))
            let dataFromLeft = { ...data, ...mergedItem }
            this.setState({
                data: dataFromLeft
            })
        }
    }

    _getBrandSuggestionList = (brand_name) => {
        this.setState({
            brand_suggestionList: []
        })
        RestAPIService.getAll(Serviceurls.LAB_PO_BRAND_SUGGESTION + "?brand_name=" + brand_name)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        brand_suggestionList: response.data.data,
                    });
                }
            })
            .catch(error => {
                if (error?.response?.data?.status == "fail") {
                    this.setState({
                        brand_suggestionList: [],
                    });
                    this.props.SuccessErrorBox(error.response.data.message, false);
                }
            });
    }

    _getProductDetails = () => {
        RestAPIService.getAll(Serviceurls.LAB_PO_PRODUCTDETAILS + "?brand_name=" + this.state.data["brand_name"])
            .then(response => {
                if (response.data.status == "success") {
                    let { data } = this.state;
                    let isProductSelected = true;
                    let selectedIndex = -1;
                    let list = response.data.data
                    let productNameList = [];
                    let tempList = [];

                    list.forEach((item,i) => {
                        let value = item.display_name;
                        let productLen = productNameList.length;
                        let productUniqList = productNameList.length > 0 ? productNameList[productLen - 1].display_name : []

                        if (value && !productUniqList.includes(value)) {
                            productNameList.push(item)
                            selectedIndex = i
                        }

                        if (productNameList.length > 1) {
                            isProductSelected = true
                        }

                        if (value) {
                            tempList.push(item)
                        }
                    })

                    if (tempList.length == 1 && productNameList.length == 1 && selectedIndex > -1) {
                        data["product_ms_id"] = list[selectedIndex]['product_ms_id'];
                        data['product_name'] = list[selectedIndex]['product_name'];
                        data['product_type'] = list[selectedIndex]['product_type'];
                        data['material_type'] = list[selectedIndex]['material_type'];
                        data['unit'] = list[selectedIndex]['unit'];
                        data['manufacturer_name'] = list[selectedIndex]['manufacturer_name'];
                        data['physical_box_no'] = list[selectedIndex]['physical_box_no'];
                        data['expiry_alert_period'] = list[selectedIndex]['expiry_alert_period'] ? list[selectedIndex]['expiry_alert_period'] : '0';
                        data['reorder_threshold'] = list[selectedIndex]['reorder_threshold'];
                        data['hsn_code'] = list[selectedIndex]['hsn_code'];
                        data['bar_code'] = list[selectedIndex]['bar_code'];
                        data['gst_percentage'] = list[selectedIndex]['gst_percentage'];
                        data['manufacturer_name'] = list[selectedIndex]['manufacturer_name'];
                        data['recommended_by'] = list[selectedIndex]['recommended_by'];
                        data['description'] = list[selectedIndex]['description'];

                    } else if (productNameList.length == 1 && selectedIndex > -1) {
                        data["product_ms_id"] = list[0]['product_ms_id'];
                        data["product_name"] = list[0]['product_name'];
                        data['product_type'] = list[0]['product_type'];
                        data['material_type'] = list[0]['material_type'];
                        data['unit'] = list[0]['unit'];

                    } else {
                        data["product_ms_id"] = "";
                        data['product_name'] = "";
                        data['product_type'] = "";
                        data['material_type'] = "";
                        data['unit'] = "";
                        data['manufacturer_name'] = "";
                        data['physical_box_no'] = "";
                        data['expiry_alert_period'] = 0;
                        data['reorder_threshold'] = "";
                        data['hsn_code'] = "";
                        data['bar_code'] = "";
                        data['gst_percentage'] = 0;
                        data['manufacturer_name'] = "";
                        data['recommended_by'] = "";
                        data['description'] = "";
                    }
                    this.setState({
                        data,
                        productSuggestionList: productNameList,
                        isProductSelected: isProductSelected
                    })
                }
            })
            .catch(error => {
                if (error.response.data.status == "fail") {
                    this.props.SuccessErrorBox(error.response.data.message, false);
                }
            });
    }

    _getPreviousProductDetails = () =>{
        let {data} = this.state
        try{
            RestAPIService.getAll(`${Serviceurls.LAB_PREVIOUS_PRICE}?brand_name=${data.brand_name}&product_name=${data.product_name}&product_type=${data.product_type}&material_type=${data.material_type}&unit=${data.unit}`)
                .then(response => {
                    if (response.data.status == "success") {
                        let previous_price = response.data
                        data["previousPriceDetails"] = previous_price.data.buying_price ? previous_price.data.buying_price : null;
                        this.setState({
                            data
                        });
                }else{
                        this.props.SuccessErrorBox(response.data.message, false);
                    }
                })
                .catch(error => {
                    this.props.SuccessErrorBox(error.response.data.message, false);
                });
        } catch (e) {
            this.props.SuccessErrorBox(e.message, false);
        }
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    checkBatchNumber = () => {
        let { data } = this.state
        let cgst_percentage = parseFloat((data.gst_percentage / 2).toFixed(2))
        let datas = {
            "product_ms_id": data.product_ms_id ? data.product_ms_id : 0,
            "expiry_date": data.expiry_date,
            "mrp_per_quantity": data.mrp_per_quantity ? parseFloat(data.mrp_per_quantity) : 0,
            "cgst_percentage": cgst_percentage ? cgst_percentage : 0,
            "sgst_percentage": cgst_percentage ? cgst_percentage : 0
        }
        this.setState({disableBtn: true})
        RestAPIService.create(datas, Serviceurls.LAB_CHECK_BATCH_NO)
            .then(response => {
                if (response.data.status == "success") {
                    let { data } = this.state
                    let Data = this.removeDuplicate(response.data.data)
                    if (response.data.data && (response.data.data).length === 1) {
                        if ((Data)[0] == data.batch_no) {
                            this._AddItem();
                        } else {
                            this.setState({
                                isDifferentBatchExist: true,
                                existBatchList: Data
                            })
                        }
                    } else if (response.data.data.length > 1) {
                        this.setState({
                            isDifferentBatchExist: true,
                            existBatchList: Data
                        })
                    } else {
                        this._AddItem();
                    }
                    this.setState({disableBtn: false})
                } else {
                    this._AddItem();
                    this.setState({disableBtn: false})
                }
            })
            .catch(error => {
                this._AddItem();
                this.props.SuccessErrorBox(error.response.data.message, false);
                this.setState({disableBtn: false})
            })
    }

    updateState = (event) => {
        const { t } = this.props;
        let { data, is_sales_amount_changed } = this.state;
        let name = event.target.name;
        let value = event.target.value.trimStart();

            if(name === "manufacturer_name" || name === "recommended_by"){
                if(value == "" || value.length <= 50){
                data[name] = value
                this.setState({
                    data
                })
            }
            return
        }
            else if(name === "description"){
                if(value == "" || value.length <= 100){
                data[name] = value
                this.setState({
                    data
                })
            }
            return
        }


        if(name === "brand_name"){
            data["product_ms_id"] = "";
            data['product_name'] = "";
            data['product_type'] = "";
            data['material_type'] = "";
            data['unit'] = "";
            data['manufacturer_name'] = "";
            data['physical_box_no'] = "";
            data['expiry_alert_period'] = 0;
            data['reorder_threshold'] = "";
            data['hsn_code'] = "";
            data['bar_code'] = "";
            data['gst_percentage'] = 0;
            data['manufacturer_name'] = "";
            data['recommended_by'] = "";
            data['description'] = "";
            this.setState({
                data
            })
        }
        if (name === "sales_discount") {
            // set flag
            is_sales_amount_changed = false
        }

        if (name === "sales_discount_amount") {
            // set flag
            is_sales_amount_changed = true
        }

        // number validation
        if(name === "sales_unit" || name === "free_quantity" || name === "reorder_threshold" || name === "pack_of_drugs"){
            if (!(Numeric.test(value) || value == "")) {
                // no change
                return
            }
        }
        // decimal validation
        if (name == "buying_price" || name == "discount_amount" || name == "discount_percentage" || name === "sales_discount"
            || name === "sales_discount_amount" || name === "gst_percentage" || name === "mrp_per_pack") {

            if (!(Decimal.test(value) || value == "")) {
                // no change
                return
            }
        }
        if((value > 0 || value === "") || (name == "batch_no")){
            data[name] = value
            this.setState({
                data
        },() => {
                this._calculateTransaction();
            if(data.brand_name && data.product_name && data.product_type && data.material_type && data.unit && name === "batch_no"){
                    this._getPreviousProductDetails();
                }
            })
        }
    }

    _calculateTransaction(){
        let { data, is_sales_amount_changed } = this.state;
        let pack_of_drugs = parseInt(data.pack_of_drugs) || 0;
        let sales_unit = parseInt(data.sales_unit) || 0;
        let buying_price = parseFloat(data.buying_price) || 0;
        let free_quantity = parseInt(data.free_quantity) || 0;
        let discount_percentage = parseFloat(data.discount_percentage) || 0;
        let discount_amount = parseFloat(data.discount_amount) || 0;
        let seller_disc_percent = parseFloat(data.sales_discount) || 0;
        let seller_disc_amount = parseFloat(data.sales_discount_amount) || 0;
        let mrp_per_pack = parseFloat(data.mrp_per_pack) || 0;
        let gst_percentage = parseFloat(data.gst_percentage) || 0;

        // calc quantity
        let stock_in_quantity = pack_of_drugs * sales_unit;
        data.free_quantity = free_quantity;

        let total_buying_price = parseFloat((sales_unit * buying_price).toFixed(2));
        let mrp_per_quantity = pack_of_drugs ? (mrp_per_pack / pack_of_drugs).toFixed(2) : 0;

        // normal discount
        discount_amount = total_buying_price > 0 && discount_percentage > 0 ? ((total_buying_price * discount_percentage) / 100).toFixed(2) : 0;

        // total buying price after discount deduction
        let total_buying_price_after_discount = total_buying_price - parseFloat(discount_amount);

        if (is_sales_amount_changed) {
            // set discount percent
            data.sales_discount = total_buying_price_after_discount && seller_disc_amount > 0 ? ((100 * seller_disc_amount) / total_buying_price_after_discount).toFixed(2) : 0;

        } else {

            // set discount amount
            seller_disc_amount = buying_price && seller_disc_percent > 0 ? ((total_buying_price_after_discount * seller_disc_percent) / 100).toFixed(2) : 0;
        }

        // set data
        data.sales_discount_amount = seller_disc_amount;
        data.discount_percentage = discount_percentage;
        data.discount_amount = discount_amount;
        data.stock_in_quantity = stock_in_quantity;
        data.mrp_per_quantity = mrp_per_quantity;

        //total buying price calculation if gst not mentioned
        data.total_buying_price = (parseFloat(total_buying_price.toFixed(2)) - parseFloat(discount_amount) - parseFloat(seller_disc_amount)).toFixed(2);

        //if gst percentage added
        if (gst_percentage === 0) {
            data.total_gst_amount = 0;
        } else {
            let taxable_amount = parseFloat(total_buying_price) - parseFloat(discount_amount) - parseFloat(seller_disc_amount);
            let gst_amount = ((taxable_amount * gst_percentage) / 100).toFixed(2)
            data.total_gst_amount = gst_amount;

            // add GST to final buying price
            data.total_buying_price = (parseFloat(data.total_buying_price) + parseFloat(gst_amount)).toFixed(2)

        }
        // EPRValue Calculation start
        var PurchaseRate = parseInt(buying_price)
        let GST = 1 + (parseInt(gst_percentage) / 100)
        let PurcahseQty = parseInt(sales_unit)
        let PurcahseQtyandfreeQty = PurcahseQty + (parseInt(free_quantity) ? parseInt(free_quantity) : 0)
        let Discount = (discount_percentage ? parseInt(discount_percentage) : 0) + (seller_disc_percent ? parseInt(seller_disc_percent) : 0)
        let TotalDis = 1 - (Discount / 100)
        let EPRTotalValue = (PurchaseRate * GST / mrp_per_pack) * (PurcahseQty / PurcahseQtyandfreeQty) * (TotalDis)

        this.setState({
            data,
            EPRValue: (EPRTotalValue && EPRTotalValue != Infinity) ? ((EPRTotalValue * 100).toFixed(2)) : 0
        })
    }
    handleProductSuggestion = (item) => {
        let { data } = this.state
        if (item) {
            data["product_ms_id"] = item['product_ms_id'];
            data['product_name'] = item['product_name'];
            data['product_type'] = item['product_type'];
            data['material_type'] = item['material_type'];
            data['unit'] = item['unit'];
            data['manufacturer_name'] = item['manufacturer_name'];
            data['physical_box_no'] = item['physical_box_no'];
            data['expiry_alert_period'] = item['expiry_alert_period'] ? item['expiry_alert_period'] : '0';
            data['reorder_threshold'] = item['reorder_threshold'];
            data['hsn_code'] = item['hsn_code'];
            data['bar_code'] = item['bar_code'];
            data['gst_percentage'] = item['gst_percentage'];
            data['manufacturer_name'] = item['manufacturer_name'];
            data['recommended_by'] = item['recommended_by'];
            data['description'] = item['description'];

            this.setState({
                data
            })
        }
    }
    clearValues = () => {
        this.setState({
            data: JSON.parse(JSON.stringify(DEFAULT_SET)),
            EPRValue: 0,
            isDifferentBatchExist: false,
            existBatchList: [],
            selectedBatchNo: "",
        })
    }

     _AddItem = () =>{
        let { data , EPRValue } = this.state;
        data['epr_value'] = EPRValue
        this.props.sendItem(data , false)
        this.setState({
            data: JSON.parse(JSON.stringify(DEFAULT_SET)),
            EPRValue: 0,
            isDifferentBatchExist: false,
            existBatchList: [],
            selectedBatchNo: "",
        })
    }
    onAdd = () => {
        let {data,EPRValue} =this.state
        data["epr_value"] = EPRValue
        let isValid = this._validateData(data);
        if(isValid){
            this.checkBatchNumber();
        }
    }
    _validateData = (data) => {
        const {t} = this.props
        let isValid = true;
        let responseMessage = "";
        // let newProduct = this.state.productSuggestionList.length > 0 ? this.state.productSuggestionList.find(item => item.product_name !== data.product_name) : ""
        if (!data.product_ms_id) { isValid = false; responseMessage = "EnterBrandname"; }
        // els  e if (newProduct) {isValid = false; responseMessage = "New product cannot be purchased";}
        else if(!data.batch_no) { isValid = false; responseMessage = "EnterBatchnumber"; }
        else if(!data.expiry_date) { isValid = false; responseMessage = "Enterexpirydate"; }
        else if(!data.pack_of_drugs) { isValid = false; responseMessage = "EnterQtyPerUnit"; }
        else if(!data.sales_unit) { isValid = false; responseMessage = "EnterNoOfUnit"; }
        else if(!data.buying_price) { isValid = false; responseMessage = "Enterbuyingprice"}
        else if(!data.mrp_per_pack) { isValid = false; responseMessage = "EnterMRP"; }
        else if(data.reorder_threshold > (data.stock_in_quantity || data.quantity)){ 
            isValid = false; responseMessage = "AlertReorderThreshold";
        }
        if (!isValid) {
            this.props.SuccessErrorBox(t(responseMessage), false)
        }

        if(data.brand_name || data.product_name){
            var Data = this.state.data
            Data["brand_name"] = (data.brand_name).trim()
            Data["product_name"] = (data.product_name).trim()
            this.setState({
                Data
            })
        }
        return isValid;
    }

    onKeyDown = (e) => {
        e.preventDefault();
    };

    renderTextRight(width, label, statekey, value = '', helperText = '') {
        const { t } = this.props
        let { brand_suggestionList, data, productSuggestionList ,isProductSelected, open1} = this.state
        return (
            <div id="eMed_pro_date_picker">
                {statekey === "expiry_date" ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            // open={open1}
                            // onOpen={() => {
                            //     this.setState({
                            //         open1: true
                            //     })
                            // }}
                            minDate={new Date().setMonth(new Date().getMonth()+1)}
                            // onClose={() => {
                            //         this.setState({
                            //             open1: false
                            //         })
                            // }}
                            views={['month', 'year']}
                            inputFormat="MM-yyyy"
                            label={t(label) + "*"}
                            name={statekey}
                            value={value ? value : null}
                            autoComplete = "off"
                            onChange={
                                (value) => {
                                    data["expiry_date"] = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
                                    this.setState({
                                        data
                                    })
                                }
                            }
                        
                            renderInput={(params) => <TextField  inputProps = {{...params.inputProps, emed_tid : "editStockList"}}  onKeyDown={(e) => this.onKeyDown(e)} size="small" sx={{ width: width ? width : "14.5vw" }} {...params}  onClick = {()=>{this.setState({open1 : true})}}/>}
                        />
                    </LocalizationProvider>
                    : statekey === "brand_name" ?
                        <Autocomplete
                            disabled = {this.state.defaultDisabled ? this.state.defaultDisabled : false}
                            size="small"
                            name={statekey}
                            value={value ? value : ""}
                            sx={{ width: width ? width : "14.5vw" }}
                            options={brand_suggestionList.length !== 0 ? brand_suggestionList : []}
                            getOptionLabel={option => option}
                            onChange={(event, newBrand) => {
                                data["brand_name"] = newBrand;
                                 this.setState({ data }, () => {
                                    if (!data.isEdit) {
                                        data["product_ms_id"] = "";
                                        data['product_name'] = "";
                                        data['product_type'] = "";
                                        data['material_type'] = "";
                                        data['unit'] = "";
                                        data['manufacturer_name'] = "";
                                        data['physical_box_no'] = "";
                                        data['expiry_alert_period'] = 0;
                                        data['reorder_threshold'] = "";
                                        data['hsn_code'] = "";
                                        data['bar_code'] = "";
                                        data['gst_percentage'] = 0;
                                        data['manufacturer_name'] = "";
                                        data['recommended_by'] = "";
                                        data['description'] = "";
                                    } else {
                                        data['isEdit'] = false
                                    }
                                    this._getProductDetails()
                                })
                            }}
                            renderInput={(params) => <TextField
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (value?.length >= 3) {
                                        this._getBrandSuggestionList(value)
                                    }
                                }}
                                {...params}
                                label={t(label) + "*"}
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                                />
                            }
                        />
                        : statekey === "expiry_alert_period" ?
                                <FormControl sx={{ width: width ? width : "14.5vw" }} size="small">
                                    <InputLabel>{t(label)}</InputLabel>
                                    <Select
                                        inputProps={{ emed_tid: "expiry_alert_period_TestId" }}
                                        value={statekey === "expiry_alert_period" ? data.expiry_alert_period : ""}
                                        name={statekey}
                                        label={t(label)}
                                        onChange={this.updateState}
                                    >
                                        {
                                            Expiry_period.map((list, index) => (
                                                <MenuItem key={index} value={list.value} >{list.label}</MenuItem>
                                            ))       
                                        }
                                    </Select>
                                </FormControl>
                            : statekey === "gst_percentage" ?
                                <Autocomplete
                                    disableClearable
                                    value={value ? value.toString() : ""}
                                    options={gstPercentage.length > 0 ? gstPercentage : []}
                                    sx={{width: width ? width : "14.5vw" }}
                                    onChange={(e, newPercent) => {
                                        data["gst_percentage"] = newPercent["value"] ? newPercent["value"] : newPercent
                                        if(newPercent["value"])
                                            this.setState({
                                                data
                                        },()=>{
                                                this._calculateTransaction();
                                            })
                                    }}
                                    renderInput={(params) => <TextField {...params} label={t(label)} size="small"  InputProps={{ ...params.InputProps,type: 'search' }}   inputProps={{...params.inputProps, 'emed_tid': 'editStockList' }}/>}
                                />
                                :
                                statekey === "buying_price" && data.previousPriceDetails ?
                        <Tooltip title= { data.previousPriceDetails ? t("Previousbuyingprice")+(`- ${CurrencySymbol}`) + data.previousPriceDetails : null} placement="top" arrow>
                                        <TextField
                            label={t(label)+ "*"}
                            sx={{ width: width ? width : "14.5vw" }}
                            size="small"
                            autoComplete="off"
                            name={statekey}
                            inputProps={{emed_tid: "helperText_test_id"}}
                            value={value}
                            error={helperText.length > 0 ? true : false}
                            helperText={helperText}
                            onChange={this.updateState}
                        />
                         </Tooltip>
                        :
                        <TextField
                            label={t(label)}
                            required= {statekey === "product_type" || statekey === "product_name" || statekey === "material_type" || statekey === "unit" || statekey === "pack_of_drugs" || statekey === "sales_unit" || statekey === "buying_price" || statekey === "mrp_per_pack" ?  true : false}
                                        sx={{ width: width ? width : "14.5vw" }}
                            disabled = {statekey === "product_name" && this.state.defaultDisabled ? this.state.defaultDisabled : false}
                            InputProps={{
                                readOnly: statekey === "product_type" || statekey === "material_type" || statekey === "unit" ? true : false
                              }}
                            inputProps={{ emed_tid: `${statekey}` }}
                            size="small"
                            autoComplete="off"
                            name={statekey}
                            value={value}
                            error={helperText.length > 0 ? true : false}
                            helperText={helperText}
                            onChange={this.updateState}
                        />
                }

                {statekey === "product_name" && isProductSelected && productSuggestionList.length > 1 ? <Paper elevation={2}>
                    <List
                        sx={{ width:"100%", bgcolor: 'background.paper' }}
                        aria-label="contacts"
                    >
                        {productSuggestionList && productSuggestionList.length > 0 ? productSuggestionList.map((item,index) => {
                            return (<ListItem disablePadding key={index} >
                                <ListItemButton  emed_tid = "handleProductSuggestion_TestId" onClick={()=>{this.setState({isProductSelected : false},()=>{this.handleProductSuggestion(item)})}}>
                                    <ListItemText primary={item.display_name} />
                                </ListItemButton>
                            </ListItem>)
                        }) : null}
                    </List>
                </Paper> : null}

            </div>
        )
    }

    tileBlock(width, label, data) {
        const { t } = this.props
        return (
            <Stack direction="column" sx={{ width: width ? width : "7.20vw", backgroundColor: "#E4F2F0", borderRadius: "0.3vw", padding: "0.3vw", height: "4vw", marginTop: "0.6vw" }}>
                <Typography emed_tid = {`${label}_TestID`} sx={{ fontSize: '0.78vw', color: "#888888" }}>{t(label)}</Typography>
                <Typography sx={{ fontSize: '0.9vw', marginTop: '0.2vw', fontWeight: 500 }}>{data}</Typography>
            </Stack>
        )
    }

    handleBatchNoChange = (e) => {
        let value = e.target.value;
        this.setState({
            selectedBatchNo: value,
        })
    }

    render() {
        const { t } = this.props
        let { data, isDifferentBatchExist, existBatchList, open, selectedBatchNo } = this.state
        let gst_percentage  = data.cgst_percentage ?  (data.cgst_percentage * 2) : 0;
        let gst_amount = data.cgst_amount ? (data.cgst_amount * 2) : 0;
        return (
            <div id="emedhub_directpo_rightDiv">
             <Paper  elevation={2} >
                <div id="emedhub_directpo_rightSubDiv" style={{height: '71vh'}}>

                        <div className='emedhub_directpo_RightHead'>
                            <p className='emedhub_directpo_RightHead_text'>{t("ProductDetails")}</p>
                        </div>

                    <div id='emedhub_directpo_RightContentBox1'  className="emedhub_directpo_RightContentBox">
                        <Stack elevation ={3}>
                                {this.renderTextRight("30vw", "BrandName", "brand_name", data.brand_name, "")}
                                {this.renderTextRight("30vw", "ProductName", "product_name", data.product_name, "")}
                            </Stack>
                            <div className="emedhub_directpo_RightContentSubDiv">
                                {this.renderTextRight("14.5vw", "ProductType", "product_type", data.product_type, "")}
                                {this.renderTextRight("14.5vw", "MaterialType", "material_type", data.material_type, "")}
                            </div>
                            {this.renderTextRight("14.5vw", "Unit", "unit", data.unit, "")}
                            <div className="emedhub_directpo_RightContentSubDiv">
                                {this.renderTextRight("14.5vw", "BatchNumber", "batch_no", data.batch_no, "")}
                                {this.renderTextRight("14.5vw", "ExpiryDate", "expiry_date", data.expiry_date, "")}
                            </div>
                            <div className="emedhub_directpo_RightContentSubDiv">
                                {this.renderTextRight("14.5vw", "UPC", "bar_code", data.bar_code, "")}
                                {this.renderTextRight("14.5vw", "HSNCode", "hsn_code", data.hsn_code, "")}
                            </div>
                        </div>

                        <div>
                            <div className='emedhub_directpo_RightHead'>
                                <p className='emedhub_directpo_RightHead_text'>{t("PriceDetails")}</p>
                            </div>
                            <div id='emedhub_directpo_RightContentBox2' className="emedhub_directpo_RightContentBox">
                                <div className="emedhub_directpo_RightContentSubDiv">
                                    {this.renderTextRight("14.5vw", "QtyPerUnit", "pack_of_drugs", data.pack_of_drugs, "")}
                                    {this.renderTextRight("14.5vw", "NoofUnit", "sales_unit", data.sales_unit, "")}
                                </div>
                                <div className="emedhub_directpo_RightContentSubDiv">
                                    {this.renderTextRight("14.5vw", "BuyingpriceperUnit", "buying_price", data.buying_price, "")}
                                    {this.renderTextRight("14.5vw", "FreeQuantityUnit", "free_quantity", data.free_quantity, "")}
                                </div>
                                <div className="emedhub_directpo_RightContentSubDiv">
                                    {this.renderTextRight("14.5vw", "Discount%", "discount_percentage", data.discount_percentage, "")}
                                    {this.renderTextRight("14.5vw", "DiscountAmount", "discount_amount", data.discount_amount, "")}
                                </div>
                                <div className="emedhub_directpo_RightContentSubDiv">
                                    {this.renderTextRight("14.5vw", "S.Discount%", "sales_discount", data.sales_discount, "")}
                                    {this.renderTextRight("14.5vw", "S.DiscountAmount", "sales_discount_amount", data.sales_discount_amount, "")}
                                </div>
                                <div className="emedhub_directpo_RightContentSubDiv">
                                    {this.renderTextRight("14.5vw", "GST%", "gst_percentage", data.gst_percentage ? data.gst_percentage : gst_percentage, "")}
                                    {this.renderTextRight("14.5vw", "MRPUnit", "mrp_per_pack", data.mrp_per_pack, "")}
                                </div>
                                <div className="emedhub_directpo_RightContent_TileBlock">
                                    {this.tileBlock("7.10vw", "GST%", data.gst_percentage ? data.gst_percentage : gst_percentage)}
                                    {this.tileBlock("7.10vw", "GSTAmount", data.total_gst_amount ? data.total_gst_amount : gst_amount)}
                                    {this.tileBlock("14.5vw", "TotalBuyingPrice", data.total_buying_price)}
                                </div>
                                <div className="emedhub_directpo_RightContent_TileBlock">
                                    {this.tileBlock("14.9vw", "Quantity", data.stock_in_quantity || data.quantity)}
                                    {this.tileBlock("14.5vw", "MRPQuantity", data.mrp_per_quantity)}
                                </div>
                                <div className='eMed_pur_epr'>{this.tileBlock("14.9vw", "EPR", this.state.EPRValue)}</div>
                                <div className="emedhub_directpo_RightContentSubDiv">
                                    {this.renderTextRight("14.5vw", "ExpiryPeriod", 'expiry_alert_period', data.expiry_alert_period, "")}
                                    {this.renderTextRight("14.5vw", "ReorderThreshold", "reorder_threshold", data.reorder_threshold, "")}
                                </div>
                                <div className="emedhub_directpo_RightContentSubDiv">
                                    {this.renderTextRight("14.5vw", "PhyBox/RoomNo", "physical_box_no", data.physical_box_no, "")}
                                    {this.renderTextRight("14.5vw", "Description", "description", data.description, "")}
                                </div>
                                <div className="emedhub_directpo_RightContentSubDiv">
                                    {this.renderTextRight("14.5vw", "ManufactureName", "manufacturer_name", data.manufacturer_name, "")}
                                    {this.renderTextRight("14.5vw", "RecommendedBy", "recommended_by", data.recommended_by, "")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Stack spacing={2} direction="row" id='emedhub_directpo_RightBtnCon' backgroundColor="#f5f8f7" marginBottom="2vh" alignItems="center" justifyContent="center" padding="1vh">
                        <Button variant='outlined' size="small" sx={{ textTransform: "capitalize" }} emed_tid = "clearValues_test_ID"  onClick={() => { this.clearValues() }}>{t("Clear")}</Button>
                        <Button variant='contained' size="small" emed_tid='DirectPo_Add_Btn' disabled={this.state.disableBtn ? true : this.state.purchase_status == "Completely Delivered" ? true : false} sx={{ textTransform: "capitalize", backgroundColor: this.state.purchase_status == "Completely Delivered" ? "#ccccb3" : null }} onClick={() => { this.onAdd() }}>{t("Add")}</Button>
                    </Stack>
                </Paper>
                {
                    isDifferentBatchExist && existBatchList.length > 0 ?
                        <Modal open={open}>
                            <Box id="emedhub_directpoRight_mainBox">
                                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                    <p className='eMed_bat_font'>{t("ConfirmBatchNo")}</p>
                                    <FormControl sx={{ m: 2, width: 300 }}>
                                        <InputLabel >{t("BatchNumber")}</InputLabel>
                                        <Select
                                            inputProps={{ emed_tid: "usr_BatchNumbertestId" }}
                                            value={selectedBatchNo ? selectedBatchNo : ""}
                                            onChange={(e) => this.handleBatchNoChange(e)}
                                            input={<OutlinedInput label={t("BatchNumber")} />}
                                            renderValue={(selected) => selected}
                                            MenuProps={MenuProps}
                                        >
                                            {existBatchList && existBatchList.length > 0 && existBatchList.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                        <Button emed_tid = "selectedBatchNo_test_Id" disabled={selectedBatchNo ? false : true} size="small" variant="contained" sx={{ backgroundColor: selectedBatchNo ? null : "#ccccb3" }}
                                            onClick={() => {
                                                let { data, EPRValue, selectedBatchNo } = this.state;
                                                data['epr_value'] = EPRValue
                                                data['batch_no'] = selectedBatchNo
                                                this.props.sendItem(data , false)
                                                this.setState({
                                                    data: JSON.parse(JSON.stringify(DEFAULT_SET)),
                                                    EPRValue: 0,
                                                    isDifferentBatchExist: false,
                                                    existBatchList: [],
                                                    selectedBatchNo: "",
                                                })
                                            }}>{t("Update")}</Button>
                                        <Button size="small" emed_tid = "Cancel_test_Id" variant="outlined" onClick={() => {
                                            this.setState({
                                                open: false
                                            }, () => {
                                                this._AddItem();
                                            })
                                        }}>{t("Cancel")}</Button>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Modal>
                        : null
                }
            </div>
        )
    }
}
export default withTranslation()(DirectPoRight);