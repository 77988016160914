import React, { Component } from 'react';
import './IPPatientsEst.css';
import { IconButton, Tooltip, Box, MenuItem, Stack, Button, Menu, Drawer, Autocomplete, Typography, TextField } from '@mui/material';
import { ImagePaths } from '../../../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AmountsCard } from '../../../../../Components/CommonCards/CommonCards'
import CommonGridHeader, { CommonPatientDetails, CommonEditButton , DeletePopup} from "../../../../../Components/Common Components/CommonComponents";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DataGrid } from '@mui/x-data-grid';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import { AmountFormat } from "../../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from "luxon";
import { CurrencySymbol } from '../../../../../Utility/Constants';

class Estimation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estDetails: [],
            DoctorNameList: [],
            doctorName: [],
            Estimate_date: null,
            DateTimerOpen: false,
            isEdit: false,
            TotalAmount: 0,
            TotalEstimation: 0,
            anchorEl: null,
            selectedRow: null,
            FilterOpen: false,
            isSelected: false,
            selectedOption: "",
            EditDetails: {},
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getEstimateDetail();
        this.getDoctorsList();
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }
    
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getDoctorsList() {
        try {
          RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
          .then((response) => {
              if (response.data.status === "success") {
                this.setState({
                  DoctorNameList: response.data.data?.filter((item) => (item.specialization_active === true))
                })
              }
              else {
                this.errorMessage(response.data?.message)
              }
            }).catch((error) => {
              this.errorMessage(error?.message)
            })
        }
        catch (e) {
          this.errorMessage(e?.message)
        }
      }

    getEstimateDetail = () => {
        let states = this.state;
        let startDate = states.Estimate_date ? new Date(states.Estimate_date) : "";
        let estimateDate = startDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : "";
        let doctorId = [];
        states.doctorName.forEach(element => doctorId.push(element.doctor_id));
        
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(`${Serviceurls.IP_ESTIMATE_LIST_GET}?date=${estimateDate}&doctor_id=${doctorId}`)
                .then(response => {
                    let data = response?.data?.data;
                    if (response.data.status === "success") {
                        this.setState({
                            TotalAmount: data?.total_amt,
                            TotalEstimation: data?.tot_est_count,
                            estDetails: data?.data_set
                        }, () => {
                            this.LoaderFunction(false);
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response?.data?.message, false);
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message, false);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message, false);
        }
    }

    MenuItem = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "Delete", label: t("Delete") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        emed_tid = {"More_TestID03"}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option}  emed_tid = {option.label} onClick={() => { this.setState({ anchorEl: null , selectedOption: option.value}) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    getSelectedItem = (item) => {
        if(item){
        try {
            RestAPIService.getAll(`${Serviceurls.IP_ESTIMATE_BILL_GET}?id=${item.id}`)
                .then(response => {
                    let data = response?.data?.data;
                    if (response.data.status === "success") {
                        let temp = {
                            patient_name: item.patient_name,
                            patient_age: item.patient_age,
                            patient_gender: item.patient_gender,
                            doctor_name: item.doctor_name,
                            qualification: item?.qualification,
                            ...data
                        }
                        this.setState({
                            EditDetails: temp,
                            isEdit: true
                        }, () => {
                            this.props.history.push({
                                pathname: "/IPHome/EstimationSummary",
                                state: { EstimateData: temp, isEdit: this.state.isEdit }
                              })
                        })
                    }
                })
                .catch(error => {
                    console.log(error.response.data.message)
                });
        } catch (e) {
            console.log(e.message);
        }
    }
    }


    // Drawer start
    
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button emed_tid = {"LabCloseButton_TestID"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_Ip_Est_date_filter'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={states.Estimate_date ? states.Estimate_date : null}
                                inputFormat='DD-MM-YYYY'
                                label={t('SelectDate')}
                                onChange={
                                    (newDate) => {
                                        this.setState({
                                            Estimate_date: newDate,
                                        });
                                    }}
                                renderInput={(params) => <TextField size='small' autoComplete='off' {...params}  inputProps={{ ...params.inputProps,'emed_tid': 'SelectDate01_TestID_OP'}} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={states.DoctorNameList?.length > 0 ? states.DoctorNameList?.map((item) => item) : "No Data"}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={states.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectDoctor')}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'selectDoctor_TestID_OP'}}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                  {option?.doctor_name}
                                </li>
                            )}
                        />
                    </Box>  
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid = {"getEstimateDetail_TestID"}
                        onClick={() => {
                            this.setState({ 
                                Estimate_date: null,
                                doctorName: [], 
                            },
                                () => this.getEstimateDetail())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid = {"Search_TestID05"}
                        onClick={() => {
                            this.getEstimateDetail()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    // Drawer end

    PopupClose = () => {
        this.setState({
            selectedOption: "",
            selectedRow: null
        })
    }

    removeDataList = () => {
        try {
            RestAPIService.delete(`${Serviceurls.IP_ESTIMATE_BILL_GET}?id=${this.state.selectedRow ? this.state.selectedRow : 0}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.PopupClose();
                        this.successMessage(response.data?.message);
                        this.getEstimateDetail();
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data?.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message);
        }
    }

    printEstimateBill = () => {
        let states = this.state;
        let startDate = states.Estimate_date ? new Date(states.Estimate_date) : "";
        let estimateDate = startDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : "";
        let doctorId = [];
        states.doctorName.forEach(element => doctorId.push(element.doctor_id));

        let url = ""
        if(this.state.selectedRow) {
           url = `${Serviceurls.IP_ESTIMATE_BILL_GET}?id=${states.selectedRow ? states.selectedRow : 0}&export_type=pdf`
        }else{
           url = `${Serviceurls.IP_ESTIMATE_LIST_GET}?date=${estimateDate}&doctor_id=${doctorId}&export_type=pdf`
        }
        try{
            this.setState({disableBtn: true})
            RestAPIService.getAllPrint(url).
              then((response) => {
                const file = new Blob(
                  [response.data],
                  { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.setState({ selectedRow: "" })
                this.setState({disableBtn: false})
              }).catch((error) => {
                if(error.response?.data?.message){
                this.errorMessage(error.response?.data?.message);
                }else{
                  this.errorMessage(error.message);
                }
                this.setState({ selectedRow: "" })
                this.setState({disableBtn: false})
              })
          }catch(error){
            this.setState({disableBtn: false})
            this.errorMessage(error.message)
          }
    }

    getEstimateExcel = () => {
        const {t} = this.props
        let states = this.state;
        let startDate = states.Estimate_date ? new Date(states.Estimate_date) : "";
        let estimateDate = startDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : "";
        let doctorId = [];
        states.doctorName.forEach(element => doctorId.push(element.doctor_id));
        try {
          RestAPIService.excelGet(`${Serviceurls.IP_ESTIMATE_LIST_GET}?date=${estimateDate}&doctor_id=${doctorId}&export_type=excel`).
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 
        
              let filename = response.headers["content-disposition"].split("filename=")[1]
        
              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              }
            }).catch((error) => {
                this.errorMessage(error.response.data?.message)
            })
        } catch (error) {
            this.errorMessage(t("PrintError"))
        }
      }

    renderCard = () => {
        const { t } = this.props;
        return (
            <Box id="eMed_Ip_Est_card">
                <Box className="eMed_Ip_Est_AmtCrd">
                    {AmountsCard(t("TotalAmount"), this.state.TotalAmount)}
                    {AmountsCard(t("TotalEstimation"), this.state.TotalEstimation, true)}
                </Box>
                <Box className="eMed_Ip_Est_fltDiv">
                    <Button emed_tid = {"CreateEstimation_TestID05"} variant='contained' size="small" id="eMed_recPayment_btn" sx={{ marginRight: "0.5vw" }}
                    onClick={() => {
                        this.props.history.push({ pathname: "/IPHome/EstimationSummary" })
                      }}
                    >
                        {t("CreateEstimation")}
                    </Button>
                    <IconButton emed_tid = {"Filter_TestID05"}  className='eMed_usrconf_btn' size="small" onClick={() => { this.setState({ FilterOpen: true }) }}>
                        <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                    </IconButton>
                    <IconButton  className='eMed_usrconf_btn' size="small" emed_tid="estimation_print_btn" disabled={this.state.disableBtn} onClick = {() => {this.printEstimateBill()}}>
                        <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                    </IconButton>
                    <IconButton className='eMed_usrconf_btn' emed_tid = {"getEstimateExcel_TestID"} size="small" onClick = {() => {this.getEstimateExcel()}}>
                        <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                    </IconButton>
                </Box>
            </Box>
        )
    }

    renderTable = () => {
        const { t } = this.props;
        let states = this.state;
        const columns = [
            { field: 'patient_name', headerName: t('Patient Details'), flex: 0.19, renderCell: ({ row }) => (<CommonPatientDetails data={row} />), density: 'compact' },
            { field: 'est_date', headerName: t('Date & Time'), flex: 0.14, density: 'compact', headerAlign: "center", align: "center"},
            {
                field: 'doctor_name', headerName: t('DoctorName'), flex: 0.15, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={row?.doctor_name} arrow><div>{row?.doctor_name.slice(0, 18) + "..."}</div></Tooltip> : row.doctor_name ? row.doctor_name : "-"}</div>)
            },
            {
                field: 'surgery', headerName: t('Complaints'), flex: 0.15, density: 'compact',
                renderCell: ({ row }) => (
                        <div className='eMed_OP_PatientType'> {row.surgery && row.surgery?.length > 15 ?
                            <Tooltip title={row.surgery ? row.surgery : "-"} placement={'top'} arrow>
                                <Typography>{(row.surgery ? row.surgery.slice(0, 13) + '...' : '-')}</Typography>
                            </Tooltip>
                            : <Typography>{(row.surgery ? row.surgery : '-')}</Typography>}
                        </div>
                )
            },
            {
                field: 'est_period', headerName: t('EstimationPeriod'), flex: 0.12, density: 'compact',
            },
            {
                field: 'total_amt', headerName: `${t('EstimateAmt')} (${CurrencySymbol})`, flex: 0.14, density: 'compact', type: "number", renderCell : ({row}) => {
                    let amount = AmountFormat(row.total_amt);
                    return (<Typography>{amount ? amount.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
                }
            },
            {
                field: 'action', headerName: t('Action'), flex: 0.11, headerAlign: 'center', align: "center", hideable: false, sortable: false, density: 'compact',
                renderCell: ({ row }) => (
                    <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                        <CommonEditButton size="1.5vw" onClick={this.getSelectedItem.bind(this, row)} />
                        <Tooltip title={t("Print")} placement='top' arrow>
                        <IconButton size = "small" emed_tid = {"printEstimateBill_TestID01"}   
                        onClick={() => {
                            this.setState({
                                selectedRow: row.id
                            }, () => {
                                this.printEstimateBill()
                            })
                        }}
                    >
                        <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_IP_Est_printimg' />
                    </IconButton>
                </Tooltip>
                        {this.MenuItem(row)}
                    </Stack>
                )
            }
        ];
        return (
            <div>
                <Box className='eMed_IPAptTbl_Con'>
                    <DataGrid
                        rows={states?.estDetails}
                        columns={columns}
                        getRowId={(row) => row.id}
                        pageSize={this.state.pageSize}
                        page={this.state.page}
                        rowsPerPageOptions={[10, 20, 30]}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        density={'compact'}
                        rowHeight={100}
                        pagination
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
            </div>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <Box id='eMed_IP_Appointment'>
                {this.renderCard()}
                {this.renderTable()}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.selectedOption === "Delete" ?
                    <DeletePopup DeletTitle={`${t("deleteMsg")}?`}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(Estimation);