import { Autocomplete, Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography, Dialog, IconButton } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import {CommonGridToolBarWithFilterText} from '../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';

let RoleData = null;
let userAccess = null;
let subUserAccess = null;
class PharmaWantedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 10,
      rowCount: 0,
      WantedListData: [],
      VendorList: [],
      SelectedWantedList: [],
      searchkey: "",
      FilterOpen: false,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      prevHistory: false  ,
      prevVendorData: {},
      isInformMsg: false,
      isInformMsgText: ''
    }
  }

  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    let pharmacy_type = RoleData?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy";
    userAccess = CheckAccessFunc(pharmacy_type, "Purchase", 'Wanted List', null, "Tab");
    subUserAccess = CheckAccessFunc(pharmacy_type, "Purchase", 'Wanted List', 'Order List', "SubTab");
    this.GetWantedList()
    this.VendorList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  infMessage = (message) => {
    this.setState({
      isInformMsg: true,
      isInformMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      isInformMsg: false,
      isInformMsgText: '',
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  GetWantedList() {
    try {
      this.LoaderFunction(true);
      let searchKey = this.state.searchkey ? (this.state.searchkey?.replaceAll('&','%26')).replaceAll(','," "): ""
      RestAPIService.getAll(Serviceurls.PURCHASE_WANTED_LIST + "?search_key=" + (searchKey) + "&page=" + (this.state.page + 1) + "&page_size=" + this.state.pageSize).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              WantedListData: response.data?.data,
              rowCount: response.data?.total_count
            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  VendorList() {
    try {
      RestAPIService.getAll(Serviceurls.PURCHASE_VENDOR_LIST + `?from=purchase_order`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              VendorList: response.data?.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  handleTableCheckbox = (id) => {
    let states = this.state;
    let selectedIDs = new Set(id);
    let selectedRows = states["WantedListData"].filter((row) => selectedIDs.has(row.drug_id));
    let FinalData = []
    selectedRows.forEach((item) => {
      FinalData.push({
        "vendor_id": item.vendor_id ? item.vendor_id : null,
        "order_quantity": item.qty ? +item.qty : null,
        "stock_id": null,
        "drug_id": item.drug_id,
      })
    })
    this.setState({ SelectedWantedList: FinalData })
  }

  AddtoWantedListCart = (item) => {
    let { SelectedWantedList } = this.state
    try {
      let tempdata
      let datas = {
        wanted_list: []
      }
      const { t } = this.props

      if (item) { // individual select
        tempdata = {
          "vendor_id": item.vendor_id,
          "order_quantity": +item.qty,
          "stock_id": null,
          "drug_id": item.drug_id,
        };
        datas['wanted_list'].push(tempdata);

      } else if (SelectedWantedList.length > 0) { // Bulk select

        datas["wanted_list"] = [...SelectedWantedList];
      }

      if (datas?.wanted_list?.length > 0) {
        this.setState({disableBtn: true})
        RestAPIService.create(datas, Serviceurls.PURCHASE_WANTED_LIST_CART)
          .then(response => {
            if (response.data.status == "success") {

              this.GetWantedList();
              this.successMessage(response.data.message)
              this.setState({disableBtn: false})
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
            this.setState({disableBtn: false})
          })
      } else {
        if (!item.order_quantity) {
          this.errorMessage(t("KindlyaddQuantity"));
        } else if (!item.vendor_id) {
          this.errorMessage(t("KindlyaddVendor"));
        }
        this.setState({disableBtn: false})
      }
    }
    catch (e) {
      this.setState({disableBtn: false})
      this.errorMessage(e.message)
    }
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid='want_close' onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("BrandName")}
              sx={{ width: "20vw" }}
              size="small"
              inputProps={{emed_tid: 'BrandName'}}
              autoComplete="off"
              value={states.searchkey ? states.searchkey : ""}
              onChange={(e) => {
                this.setState({
                  searchkey: e.target.value.trimStart()
                })
              }}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button emed_tid='fil_clear' variant='outlined' size="small"
            onClick={() => {
              this.setState({
                searchkey: "",
              }, () => this.GetWantedList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button emed_tid='fil_search' variant='contained' size="small"
            onClick={() => {
              this.GetWantedList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  getWantedListExcel = () => {
    const { t } = this.props;
    try {
      this.setState({disableBtn: true})
      RestAPIService.excelGet(`${Serviceurls.PURCHASE_WANTED_LIST}?page=${this.state.page + 1}&page_size=${this.state.pageSize}&export=${"excel"}`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.errorMessage(error.response.data?.message)
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(t("PrintError"))
      this.setState({disableBtn: false})
    }
  }

  onFilterChange = (filterModel) => {
    if(filterModel?.quickFilterValues[0]?.length >= 2 || filterModel?.quickFilterValues?.length === 0 ){
      this.setState({
        searchkey: filterModel?.quickFilterValues[0] ? filterModel?.quickFilterValues.join() : ""
      }, () =>  {
        this.GetWantedList();
      })
    }
  }

  gridToolBar = () => {
    const {t} = this.props;
    return (
      <Box>
        <CommonGridToolBarWithFilterText  searchHolder = {t("searchBrandName")}/>
      </Box>
    )
  }

  renderTable = () => {
    this.state.WantedListData.forEach((element, index) => { 
      let page = this.state.page 
      let pageSize = this.state.pageSize
      let serial = (page * pageSize) + (index + 1) 
      element.serial = serial
     })
    const { t } = this.props
    const columns = [
      {
        field: "serial", flex: 0.04, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.serial ? params?.row?.serial : "-"}</Box>)
      },
      {
        field: "drug_name", flex: 0.15, headerName: t("Brand Name"),
        renderCell: (params) => (params?.row?.drug_name?.length > 23 ?
          <Tooltip placement="top" title={params?.row?.drug_name} arrow><div>{params?.row?.drug_name.slice(0, 22) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.drug_name ? params?.row?.drug_name : "-"}</Box>)
      },
      {
        field: "generic_name", flex: 0.15, headerName: t("Generic Name"),
        renderCell: (params) => (params?.row?.generic_name?.length > 23 ?
          <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 22) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.generic_name ? params?.row?.generic_name : "-"}</Box>)
      },
      {
        field: "dosage_type", flex: 0.15, headerName: t("Dosage Type / Strength"), headerAlign: "center", align: "center",
        renderCell: (params) => {
          let dosage = `${params?.row?.dosage_type ? params?.row?.dosage_type : "-"} / ${params?.row?.drug_strength ? params?.row?.drug_strength : "-"}`
          return (dosage?.length > 23 ?
            <Tooltip placement="top" title={dosage} arrow><div>{dosage.slice(0, 22) + "..."}</div></Tooltip>
            : <Box component={'div'}>{dosage ? dosage : "-"}</Box>)
        }
      },
      {
        field: "available_quantity", flex: 0.1, headerName: t("Avail.Qty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.available_quantity ? params?.row?.available_quantity : "-"}</Box>)
      },
      {
        field: "mrp_per_quantity", flex: 0.1, headerName: t("Latest MRP/Qty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.mrp_per_quantity ? params?.row?.mrp_per_quantity : "-"}</Box>)
      },
      {
        field: "vendor_name", flex: 0.17, headerName: t("Vendor Name"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} emed_tid = {`want_vnd`}>
          <Autocomplete
            size='small'
            clearIcon={false}
            sx={{ width: "12vw" }}
            disabled = {!userAccess?.editAccess}
            componentsProps={{ popper: { style: { width: '14vw' } } }}
            options={this.state.VendorList}
            getOptionLabel={(option) => option?.name}
            value={this.state.VendorList.find((item) => (item.vendor_id === params.row.vendor_id)) ? this.state.VendorList.find((item) => (item.vendor_id === params.row.vendor_id)) : null}
            onChange={(event, newValue) => {
              this.state.WantedListData[params.row.serial - 1]["vendor_id"] = newValue?.vendor_id
              this.setState({
                WantedListData: this.state.WantedListData
              })
            }}
            renderInput={(params) => (<TextField {...params} variant='standard' />)}
          />
        </Box>)
      },
      {
        field: "qty", flex: 0.1, headerName: t("Order Qty"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} emed_tid = {`want_ordqty`}>
          <TextField
            inputProps={{
              style: {
                height: "2vw",
                padding: '0 14px',
              },
            }}
            disabled = {!userAccess?.editAccess}
            autoComplete='off'
            value={params.row?.qty ? params.row?.qty : ""}
            onChange={(e) => {
              let number = CommonValidation.numberOnly(e.target.value);
              if (number || e.target.value === "") {
                this.state.WantedListData[params.row.serial - 1]["qty"] = +e.target.value
                this.setState({
                  WantedListData: this.state.WantedListData
                })
              }

            }}
            className='eMed_DataGrid_TextField' />
        </Box>)
      }
    ]

    if (userAccess?.editAccess) {
      columns.push(
        {
          field: "action", flex: 0.07, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Tooltip title={t("Move to Order")} placement="top" arrow>
              <Button emed_tid='want_moveord' disabled = {this.state.disableBtn || this.state.isLoader} className='eMed_usrconf_btn' onClick={() => { this.setState({ disableBtn : true },()=>{this.AddtoWantedListCart(params?.row)}) }}>
                <img src={ImagePaths.PharmaPurchase.default} alt="cart" className='eMed_action_img' />
              </Button></Tooltip> 
              <Tooltip title={t("Previous History")} placement="top" arrow>
              <Button emed_tid='want_moveord' className='eMed_usrconf_btn' 
              onClick={() => {
                if(params.row.from_dc){
                  this.infMessage("Previous vendor details are unavailable for DC orders.")
                }else if(params.row.is_stock_transferred){
                  this.infMessage(  'Previous vendor details are unavailable for transferred orders.')                  
                }else{
                  this.setState({prevHistory: true, prevVendorData: params.row?.previous_purchase_details})}
                }
                }>
                <img src={ImagePaths.PreHistory.default} alt="cart" className='eMed_action_img' />
              </Button></Tooltip>
          </Box>
        }
      );
    }
    
    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container'>
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              checkboxSelection = {userAccess?.editAccess}
              onSelectionModelChange={(id) => this.handleTableCheckbox(id)}
              rows={this.state.WantedListData}
              columns={columns}
              getRowId={(row) => row['drug_id']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              paginationMode='server'
              onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.GetWantedList()) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.GetWantedList())}
              rowCount={this.state.rowCount}
              filterMode="server"
              onFilterModelChange={this.onFilterChange}
            />
          </Paper>
        </Stack>
      </Box>
    )
  }

  PreviousHistory = () => {
    let { prevVendorData } = this.state
    return (
      <Box>
        <Box component={'div'} display={'flex'} flexDirection={'row'} width={'28vw'} justifyContent={'space-between'}>
          <Typography fontSize={'1vw'} fontWeight={'bold'}>Previous Purchase Details</Typography>
          <IconButton size='small' onClick={() => { this.setState({ prevHistory: false }) }}>
            <img src={ImagePaths.Close.default} alt="close" style={{ width: '1vw', height: '1vw' }} />
          </IconButton>
        </Box>
        <hr />
        <Box marginTop={".5vw"}>
          <Typography fontSize={'0.8vw'}>Vendor Details</Typography>
          <Box marginTop={'.5vw'}>
            <Typography fontSize={'1vw'} fontWeight={'bold'}>{prevVendorData.vendor_name ? prevVendorData.vendor_name : '-'}</Typography>
            <Typography fontSize={'.8vw'} >{prevVendorData.gst ? prevVendorData.gst : '-'}</Typography>
          </Box>
        </Box>
        <hr />
        <Box marginTop={".5vw"}>
          <Typography fontSize={'.8vw'} fontWeight={'600'}>Purchase Details</Typography>
          <Box display={'flex'} flexDirection={'row'}>
            <Box marginTop={'.5vw'} width={'10vw'}>
              <Typography fontSize={'.8vw'}>Purchase Date</Typography>
              <Typography fontSize={'1vw'} fontWeight={'bold'}>{prevVendorData.purchase_date ? prevVendorData.purchase_date : '-'}</Typography>
            </Box>
            <Box marginTop={'.5vw'} width={'8vw'}>
              <Typography fontSize={'.8vw'}>Quantity</Typography>
              <Typography fontSize={'1vw'} fontWeight={'bold'}>{prevVendorData.qty ? prevVendorData.qty : '0'}</Typography>
            </Box>
            <Box marginTop={'.5vw'} width={'10vw'}>
              <Typography fontSize={'.8vw'}>Amount</Typography>
              <Typography fontSize={'1vw'} fontWeight={'bold'}>{prevVendorData.amount ? prevVendorData.amount : '0'}</Typography>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'row'} marginTop={'.5vw'}>
            <Box marginTop={'.5vw'} width={'10vw'}>
              <Typography fontSize={'.8vw'}>Free qty</Typography>
              <Typography fontSize={'1vw'} fontWeight={'bold'}>{prevVendorData.free_qty ? prevVendorData.free_qty : '0'}</Typography>
            </Box>
            <Box marginTop={'.5vw'} width={'8vw'}>
              <Typography fontSize={'.8vw'}>Discount</Typography>
              <Typography fontSize={'1vw'} fontWeight={'bold'}>{prevVendorData.discount_percentage ? `${prevVendorData.discount_percentage}%` : '0'}</Typography>
            </Box>
            <Box marginTop={'.5vw'} width={'10vw'}></Box>
          </Box>
        </Box>
      </Box>
    )
  }
  
  render() {

    return (
      <div>
        <div className='eMed_Purchase_Table_top' style={{ justifyContent: 'space-between' }}>
          <div>
            <Button emed_tid='want_movelst' disabled = {this.state.isLoader} className='eMed_Filter_Btns' variant='contained' size='small' sx={{ display: this.state.SelectedWantedList.length > 0 ? "block" : "none" }} 
            onClick={() => { this.AddtoWantedListCart() }}
            >{`Move to Order (${this.state.SelectedWantedList?.length})`}</Button>
          </div>
          <div className='eMed_Purchase_Table_top' style={{ justifyContent: 'space-between' }}>
            {subUserAccess?.viewAccess ? 
            <><Button  emed_tid='want_ordlst'className='eMed_Filter_Btns' variant='contained' size='small'
              onClick={() => { this.props.history.push({ pathname: '/PharmacyPurchase/WantedList/OrderList' }) }}
            >{`Order List`}</Button>
            <Divider orientation='vertical' />
            </>: null}
            {/* <Tooltip title="Filter" placement='top' arrow>
              <Button emed_tid='want_filter' className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
              </Button>
            </Tooltip> */}
            <Tooltip title="Export" placement='top' arrow>
              <Button emed_tid='want_export' className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.getWantedListExcel()}>
                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
              </Button></Tooltip>
          </div>
        </div>
        {this.renderTable()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          {this.state.isInformMsg ?
          <ToastMsg
            severity={'warning'}
            msg={this.state.isInformMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.prevHistory ?
          <Dialog
            className="emed_DialogboxOrder"
            open={this.state.prevHistory}
            onClose={() => { this.setState({ prevHistory: false }) }}
            maxWidth={"md"}
          >
            <div className='emed_Dialog_Appoin' style={{ width: '30vw', height: "45vh" }}>
              {this.PreviousHistory()}
            </div>
          </Dialog> : null}
      </div>
    )
  }
}
export default withTranslation()(PharmaWantedList)