import { Box, Button, Checkbox, FormControlLabel, Modal, Tab, Tabs, TextField, Typography, Tooltip } from "@mui/material";
import React, { Component } from "react";
import { Colors } from "../../../../Styles/Colors";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";
import ShowComponents from "./ShowComponent";
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class DocIntraocularPressure extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            templateData: [],
            value: "Two",
            LeftEye: {
                NCT: "",
                Applanation: "",
                CCT: "",
                CIOP: "",
                Correction: "",
                comments: "",
            },
            RightEye: {
                NCT: "",
                Applanation: "",
                CCT: "",
                CIOP: "",
                Correction: "",
                comments: "",
            },
            postLoad: false,
            templateDataLeft: [],
            templateDataRight: [],
            Leftchecked: false,
            Rightchecked: false,
            IOPData: [],
            deleteId: null,
            deletedPopup: false,
        }
    }

    apiCalls = () => {
        this.getIntraocularPressure()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getIntraocularPressure = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_INTRAOCULARPRESSURE + `?appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let IOP = response.data.data.ophthalmology_iop ? response.data.data.ophthalmology_iop : [];

                        this.setState({
                            LeftEye: {
                                NCT: "",
                                Applanation: "",
                                CCT: "",
                                CIOP: "",
                                Correction: "",
                                comments: "",
                            },
                            RightEye: {
                                NCT: "",
                                Applanation: "",
                                CCT: "",
                                CIOP: "",
                                Correction: "",
                                comments: "",
                            },
                            value: "Two",
                            Leftchecked: false,
                            Rightchecked: false
                        }, () => {
                            var left = []; var Right = [];
                            if (IOP[0] && IOP[1]) {
                                if (IOP[0]?.eye_type == "LE") {
                                    left.push(IOP[0])
                                } else {
                                    Right.push(IOP[0])
                                }
                                if (IOP[1]?.eye_type == "RE") {
                                    Right.push(IOP[1])
                                } else {
                                    left.push(IOP[1])
                                }
                            } else if (IOP[0]) {
                                if (IOP[0]?.eye_type == "LE") {
                                    left.push(IOP[0])
                                } else {
                                    Right.push(IOP[0])
                                }
                            }
                            this.setState({
                                templateDataLeft: left,
                                templateDataRight: Right,
                                disableLeft: false,
                                disableRight: false,
                                IOPData: IOP
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (data) => {
        if (data.eye_type === "RE") {
            if (this.state.templateDataLeft?.length > 0 && !this.state.templateDataLeft[0]?.id) {
                let item = this.state.templateDataLeft[0]
                this.setState({
                    LeftEye: {
                        NCT: item?.NCT,
                        Applanation: item?.APPLANATION_TONOMETRY,
                        CCT: item?.CCT,
                        CIOP: item?.CIOP,
                        Correction: item?.Correction,
                        comments: item?.comment
                    },
                })
            }
            this.setState({
                value: "Two",
                openPopUp: true,
                RightEye: {
                    NCT: data?.NCT,
                    Applanation: data?.APPLANATION_TONOMETRY,
                    CCT: data?.CCT,
                    CIOP: data?.CIOP,
                    Correction: data?.Correction,
                    comments: data?.comment,
                    id: data?.id
                },
                disableLeft: true
            })
        } else {
            if (this.state.templateDataRight?.length > 0 && !this.state.templateDataRight[0]) {
                let item = this.state.templateDataRight[0]
                this.setState({
                    RightEye: {
                        NCT: item?.NCT,
                        Applanation: item?.APPLANATION_TONOMETRY,
                        CCT: item?.CCT,
                        CIOP: item?.CIOP,
                        Correction: item?.Correction,
                        comments: item?.comment
                    }
                })
            }
            this.setState({
                value: "One",
                openPopUp: true,
                LeftEye: {
                    NCT: data?.NCT,
                    Applanation: data?.APPLANATION_TONOMETRY,
                    CCT: data?.CCT,
                    CIOP: data?.CIOP,
                    Correction: data?.Correction,
                    comments: data?.comment,
                    id: data?.id
                },
                disableRight: true
            })
        }
    }

    onDeleteHandler = () => {
        try{
            let id = this.state.deleteId ? this.state.deleteId : null
            RestAPIService.delete(Serviceurls.DOC_INTRAOCULARPRESSURE + `?id=${id}`)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.successMessage(response?.data?.message)
                    this.getIntraocularPressure()
                    this.setState({ deletedPopup: false, deleteId: null })
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
        }catch(e){
            this.errorMessage(e?.message)
        }
    }

    renderIntraocularTable = (Heading, value, width) => {
        return (
            <div style={{ display: "flex", flexDirection: "column", width: width, alignItems: "flex-start" }}>
                <div style={{ marginBottom: "0.5vw" }}>{Heading}</div>
                <div style={{ fontSize: "0.9vw", fontWeight: "600" }}>{value ? value : "-"}</div>
            </div>
        )
    }

    renderIntraocularPressureTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto' }} component={'div'}>
                    {this.state?.templateDataRight?.length > 0 ? this.state.templateDataRight.map((item) => (
                        <div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div>{"Right Eye (RE)"}</div>
                                    <div onClick={() => {
                                        this.setState({ isEdit: true }, () => {
                                            if (!this.props.isLocked) {
                                                this.onEditHandler(item)
                                            }
                                        })
                                    }} style={{ marginLeft: "0.65vw", marginTop: "0.3vw", cursor: "pointer" }}>
                                        {this.props.isLocked ?
                                            <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                            :
                                            <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                        }
                                    </div>
                                </div>
                                {item?.id ? <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => { this.setState({ deletedPopup: true, deleteId: item?.id }) }}
                                    emed_tid='intraOcularPressure_DeleteBtn_testId'
                                >
                                    {this.props.isLocked ?
                                        <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                        :
                                        <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                    }
                                </Button> : null}
                                <Button
                                    disabled={this.state.isLocked || this.props.isLocked || this.state?.IOPData.length > 1}
                                    variant='contained'
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                    onClick={() => {
                                        this.setState({ openPopUp: true })
                                    }}
                                >Add</Button>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                                {this.renderIntraocularTable("NCT", item.NCT, "11vw")}
                                {this.renderIntraocularTable("Applanation Tonometry", item.APPLANATION_TONOMETRY, "16vw")}
                                {this.renderIntraocularTable("CCT (pachymetry)", item.CCT, "13vw")}
                                {this.renderIntraocularTable("CIOP", item.CIOP, "10vw")}
                                {this.renderIntraocularTable("Comments", item.comment, "7vw")}
                            </div>
                        </div>)) :
                        null}
                    {this.state?.templateDataLeft?.length > 0 ? this.state.templateDataLeft.map((item) => (
                        <div style={{ marginTop: "0.5vw" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div>{"Left Eye (LE)"}</div>
                                <div onClick={() => {
                                    this.setState({ isEdit: true }, () => {
                                        if (!this.props.isLocked) {
                                            this.onEditHandler(item)
                                        }
                                    })
                                }} style={{ marginLeft: "0.65vw", marginTop: "0.3vw", cursor: "pointer" }}>
                                    {this.props.isLocked ?
                                        <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                        :
                                        <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                    }
                                </div>
                                {item?.id ? <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => { this.setState({ deletedPopup: true, deleteId: item?.id }) }}
                                    emed_tid='intraOcularPressure_secondDeleteBtn_testId'
                                >
                                    {this.props.isLocked ?
                                        <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                        :
                                        <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                    }
                                </Button> : null}
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                                {this.renderIntraocularTable("NCT", item.NCT, "11vw")}
                                {this.renderIntraocularTable("Applanation Tonometry", item.APPLANATION_TONOMETRY, "16vw")}
                                {this.renderIntraocularTable("CCT (Pachymetry)", item.CCT, "13vw")}
                                {this.renderIntraocularTable("CIOP", item.CIOP, "10vw")}
                                {this.renderIntraocularTable("Comments", item.comment, "7vw")}
                            </div>
                        </div>)) :
                        null}
                </Box >
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        var { LeftEye, RightEye } = this.state
        if ((LeftEye.NCT || LeftEye.Applanation || LeftEye.CCT) ||
            (RightEye.NCT || RightEye.Applanation || RightEye.CCT)) {
            return null
        } else {
            return "Please Enter Any Value"
        }
    }

    PostIntraocularPressure = () => {
        var { LeftEye, RightEye, appointmentId } = this.state
        var data = []
        try {
            if ((LeftEye.NCT || LeftEye.Applanation || LeftEye.CCT) && (RightEye.NCT || RightEye.Applanation || RightEye.CCT)) {
                data = [{
                    "appointment_id": appointmentId,
                    "eye_type": "LE",
                    "NCT": LeftEye?.NCT ? LeftEye?.NCT.toString() : null,
                    "APPLANATION_TONOMETRY": LeftEye?.Applanation ? LeftEye?.Applanation.toString() : null,
                    "CCT": LeftEye?.CCT ? LeftEye?.CCT.toString() : null,
                    "CORRECTION_FACTOR": LeftEye?.Correction ? Math.round(LeftEye?.Correction).toString() : null,
                    "CIOP": !isNaN(LeftEye?.CIOP) && LeftEye?.CIOP ? LeftEye?.CIOP.toString() : null,
                    "comment": LeftEye?.comments ? LeftEye?.comments : ""
                }, {
                    "appointment_id": appointmentId,
                    "eye_type": "RE",
                    "NCT": RightEye?.NCT ? RightEye?.NCT.toString() : null,
                    "APPLANATION_TONOMETRY": RightEye?.Applanation ? RightEye?.Applanation.toString() : null,
                    "CCT": RightEye?.CCT ? RightEye?.CCT.toString() : null,
                    "CORRECTION_FACTOR": RightEye?.Correction ? Math.round(RightEye?.Correction).toString() : null,
                    "CIOP": !isNaN(RightEye?.CIOP) && RightEye?.CIOP ? RightEye?.CIOP.toString() : null,
                    "comment": RightEye?.comments ? RightEye?.comments : ""
                }];
                if (LeftEye.id) { data[0]['id'] = LeftEye.id }
                if (RightEye.id) { data[1]['id'] = RightEye.id }
            }
            else if (LeftEye.NCT || LeftEye.Applanation || LeftEye.CCT) {
                data = [{
                    "appointment_id": appointmentId,
                    "eye_type": "LE",
                    "NCT": LeftEye?.NCT ? LeftEye?.NCT.toString() : null,
                    "APPLANATION_TONOMETRY": LeftEye?.Applanation ? LeftEye?.Applanation.toString() : null,
                    "CCT": LeftEye?.CCT ? LeftEye?.CCT.toString() : null,
                    "CORRECTION_FACTOR": LeftEye?.Correction ? Math.round(LeftEye?.Correction).toString() : null,
                    "CIOP": !isNaN(LeftEye?.CIOP) && LeftEye?.CIOP ? LeftEye?.CIOP.toString() : null,
                    "comment": LeftEye?.comments ? LeftEye?.comments : ""
                }]
                if (LeftEye.id) { data[0]['id'] = LeftEye.id }
            }
            else if (RightEye.NCT || RightEye.Applanation || RightEye.CCT) {
                data = [{
                    "appointment_id": appointmentId,
                    "eye_type": "RE",
                    "NCT": RightEye?.NCT ? RightEye?.NCT.toString() : null,
                    "APPLANATION_TONOMETRY": RightEye?.Applanation ? RightEye?.Applanation.toString() : null,
                    "CCT": RightEye?.CCT ? RightEye?.CCT.toString() : null,
                    "CORRECTION_FACTOR": RightEye?.Correction ? Math.round(RightEye?.Correction).toString() : null,
                    "CIOP": !isNaN(RightEye?.CIOP) && RightEye?.CIOP ? RightEye?.CIOP.toString() : null,
                    "comment": RightEye?.comments ? RightEye?.comments : ""
                }]
                if (RightEye.id) { data[0]['id'] = RightEye.id }
            }
            let validate = this.checkValidation()
            if (validate == null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_INTRAOCULARPRESSURE)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.handleClear()
                            this.setState({ postLoad: false, openPopUp: false }, () => {
                                this.getIntraocularPressure()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else { this.errorMessage(validate) }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }


    renderRightSideValues = (Heading, value) => {
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <div>{Heading}</div>
                <div className="eMed_BoxShadow">
                    {value?.length > 13 ? <Tooltip title={value} placement='top' arrow><div>{value?.slice(0, 10) + "..."}</div></Tooltip>: value}
                </div>
            </div>
        )
    }

    handleClear = () => {
        if (this.state.isEdit) {
            if (this.state.templateDataLeft?.length > 0 && this.state.value === "One") {
                let item = this.state.templateDataLeft[0]
                this.setState({
                    LeftEye: {
                        NCT: "",
                        Applanation: "",
                        CCT: "",
                        CIOP: "",
                        Correction: "",
                        comments: "",
                        id: item?.id
                    },
                })
            }
            else if (this.state.templateDataRight?.length > 0) {
                let item = this.state.templateDataRight[0]
                this.setState({
                    RightEye: {
                        NCT: "",
                        Applanation: "",
                        CCT: "",
                        CIOP: "",
                        Correction: "",
                        comments: "",
                        id: item?.id
                    }
                })
            }

        } else {
            this.setState({
                LeftEye: {
                    NCT: "",
                    Applanation: "",
                    CCT: "",
                    CIOP: "",
                    Correction: "",
                    comments: ""
                },
                RightEye: {
                    NCT: "",
                    Applanation: "",
                    CCT: "",
                    CIOP: "",
                    Correction: "",
                    comments: ""
                },
                postLoad: false,
                disableLeft: false,
                disableRight: false,
                Leftchecked: false,
                Rightchecked: false,
            })
        }
    }

    CopyLefttoRight = (value) => {
        let { LeftEye } = this.state
        let states = this.state
        if (value) {
            if (this.state.templateDataRight?.length > 0) {
                states.RightEye["id"] = this.state.templateDataRight[0]?.id
            }
            states.RightEye["NCT"] = LeftEye.NCT
            states.RightEye["Applanation"] = LeftEye.Applanation
            states.RightEye["CCT"] = LeftEye.CCT
            states.RightEye["CIOP"] = LeftEye.CIOP
            states.RightEye["Correction"] = LeftEye.Correction
            states.RightEye["comments"] = LeftEye.comments
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                value: this.state.isEdit ? "One" : "Two",
                states
            })
        } else {
            states.RightEye["NCT"] = ""
            states.RightEye["Applanation"] = ""
            states.RightEye["CCT"] = ""
            states.RightEye["CIOP"] = ""
            states.RightEye["comments"] = ""
            states.RightEye["Correction"] = ""
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                states
            })
        }
    }

    CopyRighttoLeft = (value) => {
        let { RightEye } = this.state
        let states = this.state
        if (value) {
            if (this.state.templateDataLeft?.length > 0) {
                states.LeftEye["id"] = this.state.templateDataLeft[0]?.id
            }
            states.LeftEye["NCT"] = RightEye.NCT
            states.LeftEye["Applanation"] = RightEye.Applanation
            states.LeftEye["CCT"] = RightEye.CCT
            states.LeftEye["CIOP"] = RightEye.CIOP
            states.LeftEye["Correction"] = RightEye.Correction
            states.LeftEye["comments"] = RightEye.comments
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                value: this.state.isEdit ? "Two" : "One",
                states
            })
        } else {
            states.LeftEye["NCT"] = ""
            states.LeftEye["Applanation"] = ""
            states.LeftEye["CCT"] = ""
            states.LeftEye["CIOP"] = ""
            states.LeftEye["Correction"] = ""
            states.LeftEye["comments"] =""
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                states
            })
        }
    }

    CIOPCalculation() {
        let { LeftEye, RightEye } = this.state
        let convertNumber = this.state.value === "One" ? parseFloat(LeftEye.CCT) || 0 : parseFloat(RightEye.CCT) || 0;
        let selectedApplanation = this.state.value === "One" ? parseFloat(LeftEye.Applanation) || 0 : parseFloat(RightEye.Applanation) || 0;
        if (this.state.value === "One") {
            if (convertNumber == 0 || selectedApplanation == 0) {
                LeftEye["CIOP"] = ""
                this.setState({
                    LeftEye
                })
            } else if (convertNumber == 545) {
                LeftEye["CCT"] = convertNumber
                LeftEye["CIOP"] = 0
                LeftEye["Correction"] = 545
                this.setState({
                    LeftEye
                })
            } else if (convertNumber > 0) {

                var getCorrection = ((convertNumber - 545) / 50 * 2.5);
                var getCIOP = parseInt(selectedApplanation - getCorrection)
                LeftEye["CCT"] = convertNumber
                LeftEye["CIOP"] = getCIOP
                LeftEye["Correction"] = getCorrection
                this.setState({
                    LeftEye
                })
            }
        } else {
            if (convertNumber == 0 || selectedApplanation == 0) {
                RightEye["CIOP"] = ""
                this.setState({
                    RightEye
                })
            } else if (convertNumber == 545) {
                RightEye["CCT"] = convertNumber
                RightEye["CIOP"] = 0
                RightEye["Correction"] = 545
                this.setState({
                    RightEye
                })
            } else if (convertNumber > 0) {

                var getCorrection = ((convertNumber - 545) / 50 * 2.5);
                var getCIOP = parseInt(selectedApplanation - getCorrection)
                RightEye["CCT"] = convertNumber
                RightEye["CIOP"] = getCIOP
                RightEye["Correction"] = getCorrection
                this.setState({
                    RightEye
                })
            }
        }
    }

    IntraocularPressurePopup = () => {
        let { LeftEye, RightEye } = this.state
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "43vw", height: "73vh", maxHeight: "100%", padding: "1vh" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Intraocular Pressure</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openPopUp: false }, () => { this.getIntraocularPressure() })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <div style={{ width: "100%" }}>
                                <Tabs
                                    value={this.state.value}
                                    onChange={(e, newValue) =>
                                        this.setState({
                                            value: newValue
                                        })}
                                >
                                    <Tab disabled={this.state.disableRight} value="Two" label={"Right Eye"} className='eMed_tab_header' />
                                    <Tab disabled={this.state.disableLeft} value="One" label={"Left Eye"} className='eMed_tab_header' />
                                </Tabs>
                                <Box component={"div"} style={{ padding: "0.75vw" }}>
                                    <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", paddingLeft: "0.5vw" }}>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "0.5vw" }}>
                                            <TextField
                                                label='Non-Contact tonometry'
                                                sx={{ width: '19.5vw', marginTop: "0.5vw" }}
                                                value={this.state.value === "One" ? LeftEye.NCT : RightEye.NCT}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    let isValid = CommonValidation.numberOnly(e.target.value)
                                                    if ((value?.length < 4 && isValid) || value == "") {
                                                        if (this.state.value === "One") {
                                                            LeftEye["NCT"] = e.target.value
                                                            this.setState({ LeftEye })
                                                        } else {
                                                            RightEye["NCT"] = e.target.value
                                                            this.setState({ RightEye })
                                                        }
                                                    }
                                                    if (this.state.value === "One" && this.state.Leftchecked) {
                                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                                    } else if (this.state.value === "Two" && this.state.Rightchecked) {
                                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                                    }
                                                }}
                                            />
                                            <TextField
                                                label='Applanation tonometry'
                                                sx={{ width: '19.5vw', marginLeft: "0.65vw", marginTop: "0.5vw" }}
                                                value={this.state.value === "One" ? LeftEye.Applanation : RightEye.Applanation}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    let isValid = CommonValidation.numberOnly(e.target.value)
                                                    if ((value?.length < 4 && isValid) || value == "") {
                                                        if (this.state.value === "One") {
                                                            LeftEye["Applanation"] = e.target.value
                                                            this.setState({ LeftEye }, () => { this.CIOPCalculation() })
                                                        } else {
                                                            RightEye["Applanation"] = e.target.value
                                                            this.setState({ RightEye }, () => { this.CIOPCalculation() })
                                                        }
                                                    }
                                                    if (this.state.value === "One" && this.state.Leftchecked) {
                                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                                    } else if (this.state.value === "Two" && this.state.Rightchecked) {
                                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw" }}>
                                            <TextField
                                                label='Central Corneal Thickness(Pachymetry)'
                                                sx={{ width: '19.5vw' }}
                                                value={this.state.value === "One" ? LeftEye.CCT : RightEye.CCT}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    let isValid = CommonValidation.numberOnly(e.target.value)
                                                    if ((value?.length < 4 && isValid) || value == "") {
                                                        if (this.state.value === "One") {
                                                            LeftEye["CCT"] = e.target.value
                                                            this.setState({ LeftEye }, () => { this.CIOPCalculation() })
                                                        } else {
                                                            RightEye["CCT"] = e.target.value
                                                            this.setState({ RightEye }, () => { this.CIOPCalculation() })
                                                        }
                                                    }
                                                    if (this.state.value === "One" && this.state.Leftchecked) {
                                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                                    } else if (this.state.value === "Two" && this.state.Rightchecked) {
                                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                                    }
                                                }}
                                            />
                                            <TextField
                                                label='Corrected Intraocular Pressure'
                                                disabled={true}
                                                sx={{ width: '19.5vw', marginLeft: "0.65vw" }}
                                                value={this.state.value === "One" ? LeftEye.CIOP ? LeftEye.CIOP : "" : RightEye.CIOP ? RightEye.CIOP : ""}
                                            />
                                        </div>
                                        <div style={{ marginTop: "1vw" }}>
                                            <div>
                                                <TextField
                                                    label={("Enter Comments")}
                                                    style={{ marginTop: "0.5vw", width: "99%", marginBottom: "0.75vw" }}
                                                    multiline={true}
                                                    rows={3}
                                                    variant="outlined"
                                                    value={this.state.value === "One" ? LeftEye.comments : RightEye.comments}
                                                    onChange={(event) => {
                                                        let value = event.target.value
                                                        if (value?.length <= 250) {
                                                            if (this.state.value === "One") {
                                                                LeftEye["comments"] = value
                                                                this.setState({ LeftEye })
                                                            } else {
                                                                RightEye["comments"] = value
                                                                this.setState({ RightEye })
                                                            }
                                                        } else {
                                                            this.errorMessage('Allowed only 250 characters')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Box>
                                    {this.state.value === "One" ?
                                        <Box sx={{ marginTop: "1vw" }}>
                                            <FormControlLabel checked={this.state.Leftchecked} control={<Checkbox checked={this.state.Leftchecked} onClick={(e) => { this.CopyLefttoRight(!this.state.Leftchecked) }} />} label="Copy to Right Eye (RE)" />
                                        </Box> :
                                        <Box sx={{ marginTop: "1vw" }}>
                                            <FormControlLabel checked={this.state.Rightchecked} control={<Checkbox checked={this.state.Rightchecked} onClick={(e) => { this.CopyRighttoLeft(!this.state.Rightchecked) }} />} label="Copy to Left Eye (LE)" />
                                        </Box>}
                                </Box>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "0.75vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.handleClear() }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.PostIntraocularPressure() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    closeTableDelete = () => {
        this.setState({ 
            deletedPopup: false,
            deleteId: null
        })
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: Colors.Background, height: "3.5vw" }}
                        >
                            <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} height={'3.5vw'}>
                                <Typography fontWeight={600}>Intraocular Pressure</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'}>
                                    {this.renderIntraocularPressureTable()}
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {this.IntraocularPressurePopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletedPopup ?
                        <DeletePopup DeletTitle={`Are you sure you want to delete ?`}
                            deleteAlertPopupClose={this.closeTableDelete.bind(this)}
                            removeData={this.onDeleteHandler.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents>
        )
    }

}