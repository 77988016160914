import React, { Component } from 'react';
import { Typography, Button, TextField, OutlinedInput, InputAdornment, FormControl, MenuItem, Autocomplete, Box } from '@mui/material';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import './home.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';

class Transmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchkey: "",
            transmitBillList: [],
            isSelectDatePicker: false,
            filterDate: new Date(),
            patientId: '',
        }
    }

    componentDidMount() {
        this.getTransmitBill()
    }



    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    getTransmitBill() {
        try {
            let date = DateTime.fromJSDate(this.state.filterDate).toFormat('yyyy-MM-dd')
            let searchkey = this.state.searchkey ? this.state.searchkey : ""
            let lab_id = this.state.lab_id ? this.state.lab_id : ""
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_TRANSMIT_GET + "?search_key=" + searchkey + "&transmit_date=" + date + "&lab_id=" + lab_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            transmitBillList: response?.data?.data ? response?.data?.data : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    }
                    else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSpecialTransmitPrint = (patient_id, transmitId) => {
        RestAPIService.getAll(Serviceurls.LAB_PATIENT_TRANSMIT_GET + `?export_type=pdf&patient_id=${patient_id}&transmitted_id=${transmitId}`).
            then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.reponse.data.message
                }, () => {
                    setTimeout(() => {
                        this.setState({ isErrorMsg: false })
                    }, 2000);
                })
            })
    }
    render() {
        const { t } = this.props
        const { history } = this.props.properties
        return (
            <div>
                <div style={{ display: "flex", margin: "0.5vw" }}>
                    <label style={{ flex: "0.5" }}><b>{t("Transmit")}</b></label>
                    <div style={{ flex: "0.5", display: "flex", justifyContent: "end" }}>
                        <img
                            src={ImagePaths.LabCloseButton.default}
                            style={{ cursor: "pointer", height: "1vw", width: "1vw" }}
                            onClick={() => { this.props.onClickTranmittedBills(false) }}
                            alt=""
                        />
                    </div>
                </div>

                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "0.5vw" }}>
                    <OutlinedInput
                        className="emed_ordersearch"
                        placeholder="Search Patient..."
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                this.getTransmitBill()
                            }
                        }}
                        onChange={(e) => {
                            this.setState({
                                searchkey: e.target.value
                            })
                        }}
                        value={this.state.searchkey}
                        size="small"
                        sx={{ width: "10vw", marginRight: "0.5vw" }}
                        endAdornment={
                            <InputAdornment position="end" >
                                <i style={{ cursor: "pointer" }} onClick={() => this.getTransmitBill()} class="fas fa-search"></i>
                            </InputAdornment>
                        }
                    />

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            className="Lab_SavedBill_date"
                            id="Lab_SavedBill_date"
                            label={("")}
                            value={this.state.filterDate}
                            inputFormat={"dd-MM-yyyy"}
                            autoComplete="off"
                            onChange={(newValue) => {
                                this.setState({
                                    filterDate: newValue
                                }, () => {
                                    let date = DateTime.fromJSDate(this.state.filterDate).toFormat('yyyy-MM-dd')
                                    this.getTransmitBill()
                                })
                            }}
                            renderInput={(params) => <TextField size="small" sx={{ width: "10vw" }} {...params}
                                error={this.state.expDateerror} helperText={this.state.expDatehelpertext} />}
                        />
                    </LocalizationProvider>
                </div>
                {this.state.transmitBillList && this.state.transmitBillList.length > 0 ?
                    <div className={'Lab_Saved_Bill_Main'}>
                        {this.state.transmitBillList.map((item, index) => {
                            return (
                                <div className={"Lab_Home_Saved_Bill"}>
                                    <div className='SavedBillFirst' >
                                        <Typography className='savedBillText'>{item.patient_name}</Typography>
                                        <div className='Lab_saved_bill_sub_container'>
                                            <Typography sx={{ fontSize: "0.8vw" }} className='savedTextLight'>{`${item?.patient_mobile_number} | ${item?.patient_account_number}`}</Typography>
                                        </div>
                                    </div>
                                    <div className='SavedBillSecond'>
                                        <Typography className='savedBillText'>{t("Date")}</Typography>
                                        <Typography sx={{ fontSize: "0.8vw" }} className='savedTextLight'>{item?.transmit_date}</Typography>
                                    </div>
                                    <button size="small" className='Lab_Saved_Bill_Button' onClick={() => {

                                        let lineItems = item.line_items
                                        let { transmitBillList } = this.state
                                        let specialTestTransmitIds = null; let lineItemsList = [];
                                        lineItems.forEach((element, index) => {
                                            if (element.special_test_name) {
                                                specialTestTransmitIds = element.transmit_summary_ids;
                                            } else {
                                                lineItemsList.push(element)
                                            }
                                        });
                                        if (specialTestTransmitIds) {
                                            this.getSpecialTransmitPrint(item?.patient_id, specialTestTransmitIds);
                                        }
                                        if (lineItemsList?.length > 0) {
                                            item.line_items = lineItemsList
                                            this.props.onClickTranmittedBills(false)
                                            history.push({ pathname: "/MainHome", state: { savedTransmitList: item } })
                                            setTimeout(() => {
                                                history.push({ pathname: "/MainHome", state: { savedTransmitList: {} } })
                                            }, 2000);
                                        }
                                        // if(item.is_special_test){
                                        //     this.getSpecialTransmitPrint(item.patient_id) 
                                        //     this.props.onClickTranmittedBills(false)
                                        // }else{
                                        //     this.props.onClickTranmittedBills(false)
                                        //     history.push({ pathname: "/MainHome", state: { savedTransmitList: item } })
                                        // }

                                    }}><p className='Lab_saved_Bill_Text'>{t('Proceed')}</p></button>
                                </div>
                            )
                        })}


                    </div> :
                    <div><Typography sx={{ textAlign: "center", position: "relative", top: "10vh" }}>{t("No records found")}</Typography></div>}
            </div>
        );
    }
}
export default withTranslation()(Transmit)