import React, { Component } from 'react';
import './reports.css';
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { Autocomplete, Box, Button, Drawer, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { DateTime } from 'luxon';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { t } from "i18next";
import { Trans, withTranslation } from 'react-i18next';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';



class OutSourceReport extends Component {
  state = {
    billData: [],
    page: 0,
    pageSize: 10,
    searchkey: '',
    disableBtn: false,
    fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    dateIndex: 1,
    testName: [],
    labName: null,
    testConfigData: [],
    testOutSourceLabGet: [],
  }

  componentDidMount() {
    this.getOutSourceReport()
    this.getOutSourceLabData()
    this.getTestData()
  
  }

  // componentWillReceiveProps(props) {
  //   if (props.search_key != this.state.searchkey) {
  //     this.setState({
  //       searchkey: props.search_key
  //     }, () => {
  //       this.getOutSourceReport()
  //       this.getOutSourceLabData()
  //       this.getTestData()
  //     })
  //   }
  // }

  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      isSuccessMsg: false,
      isSuccessMsgText: "",
    })
}

  CustomDates = (FromDate, ToDate) => {
    this.setState({
      QueryFromDate: FromDate,
      QueryToDate: ToDate,
      datePickerPop: false
    }, () => this.getOutSourceReport())
  }
  PopClose = () => {
    this.setState({
      FilterOpen: false
    }, () => this.getOutSourceReport())
  }

  handleDate = () => {
    var today = DateTime.now().toFormat('yyyy-MM-dd')
    var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
    var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
    var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
    var startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
    var endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
    const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd');
    const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd');


    if (this.state.dateFilter === 0) {
      this.setState({
        QueryFromDate: "",
        QueryToDate: "",
      }, () => this.getOutSourceReport())
    }
    else if (this.state.dateFilter === 1) {
      this.setState({
        QueryFromDate: today,
        QueryToDate: today,
      }, () => this.getOutSourceReport()
      )

    }
    else if (this.state.dateFilter === 2) {
      this.setState({
        QueryFromDate: yesterday,
        QueryToDate: yesterday,
      }, () => this.getOutSourceReport())
    }
    else if (this.state.dateFilter === 3) {
      this.setState({
        QueryFromDate: startOfPreviousWeek,
        QueryToDate: endOfPreviousWeek,
      }, () => this.getOutSourceReport())
    }
    else if (this.state.dateFilter === 4) {
      this.setState({
        QueryFromDate: startOfPreviousMonth,
        QueryToDate: endOfPreviousMonth,
      }, () => this.getOutSourceReport())
    }
    else if (this.state.dateFilter === 5) {
      this.setState({ datePickerPop: true })
    }
  }


  DateHelperText = () => {
    if (this.state.dateFilter === 0) {
      return ""
    }
    else if (this.state.dateFilter === 1) {
      return DateTime.now().toFormat('dd-MM-yyyy')
    }
    else if (this.state.dateFilter === 2) {
      return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
    }
    else if (this.state.dateFilter === 3) {
      // return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
      const startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week');
      const endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week');
      return `${startOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')} to ${endOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')}`

    }
    else if (this.state.dateFilter === 4) {
      // return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
      const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month');
      const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month');
      return `${startOfPreviousMonth.toFormat('dd-MM-yyyy')} to ${endOfPreviousMonth.toFormat('dd-MM-yyyy')}`

    }
    else if (this.state.dateFilter === 5) {
      return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
    }
  }

  FilterDate = (from, to, index) => {
    this.setState({
        fromDate: from,
        toDate: to,
        dateIndex: index
    })
  }

  getTestData = () => {
    try {
        RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + `?only_test_name=${true}`)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({
                        testConfigData: response.data.data
                    })
                }
            }).catch(e => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: e.message
                })
            })

    } catch (e) {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
        })
    }
  }
  LoaderFunction = (key) => {
    this.setState({
        isLoader: key
    })
}

  getOutSourceReport = () => {
    try {
      this.LoaderFunction(true)
      let lab_name = this.state.labName ? this.state.labName?.outsource_laboratory_name : ''
      let testName = []; this.state.testName.forEach(element => testName.push(element.test_name))
      RestAPIService.getAll(Serviceurls.REPORTS_OUTSOURCE_GET + "?from_date=" + this.state.fromDate + "&to_date=" + this.state.toDate + "&search_key=" + this.state.searchkey + '&lab_name=' + lab_name + '&test_name=' + testName).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              billData: response.data.data,
              totalBillCount: response.data.totel_bill_count,
              pendingCount: response.data.pending_count,
              completeCount: response.data.comlete_count,
              deliveredCount: response.data.delivered_count
            },()=>{this.LoaderFunction(false)})
          }
        }).catch((error) =>{ 
          this.LoaderFunction(false)
          alert(error.response.data.message)})
    } catch (error) {
      this.LoaderFunction(false)
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error.message
      })
    }
  }

  getOutSourceReportPrint = () => {
    try {
      this.setState({disableBtn: true})
      let lab_name = this.state.labName ? this.state.labName?.outsource_laboratory_name : ''
      let testName = []; this.state.testName.forEach(element => testName.push(element.test_name))
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_OUTSOURCE_PRINT + "?export_type=pdf" +  "&from_date=" + this.state.fromDate + "&to_date=" + this.state.toDate + "&search_key=" + this.state.searchkey + '&lab_name=' + lab_name + '&test_name=' + testName).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.reponse.data.message,
            disableBtn: false,
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError"),
        disableBtn: false,
      })
    }
  }

  getOutsourceReportexcel = () => {
    try {
      this.setState({disableBtn: true})
      let lab_name = this.state.labName ? this.state.labName?.outsource_laboratory_name : ''
      let testName = []; this.state.testName.forEach(element => testName.push(element.test_name))
      RestAPIService.excelGet(Serviceurls.LAB_PATIENT_OUTSOURCE_PRINT + "?export_type=excel" +  "&from_date=" + this.state.fromDate + "&to_date=" + this.state.toDate + "&search_key=" + this.state.searchkey + '&lab_name=' + lab_name + '&test_name=' + testName).
        then((response) => {
          if(response.data){
          var pom = document.createElement('a');
          var csvContent = response.data; //here we load our csv data 
    
          let filename = response.headers["content-disposition"].split("filename=")[1]
    
          var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
          var url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute('download', filename ? filename : 'registry.xlsx');
          pom.click();
          this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error.response.data.message,
            disableBtn: false,
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: t("PrintError"),
        disableBtn: false,
      })
    }
  }

  getOutSourceLabData = () => {
    try {
        RestAPIService.getAll(Serviceurls.LAB_OUTSOURCE_CONFIG)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({
                        testOutSourceLabGet: response.data.data ? response.data.data : []
                    })
                }
            }).catch(e => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: e.message
                })
            })

    } catch (e) {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
        })
    }
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
        <Box component={"div"} className='eMed_rts_filter_container'>
            <Box component={"div"} className='eMed_filter_Top'>
                <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                <Button emed_tid = "LabCloseButton__test_id01" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                    <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                </Button>
            </Box>
            <Box className='eMed_rts_middle'>
                <Box className='eMed_date_filter'>
                    <CommonDatePicker
                        FromDate={this.state.fromDate}
                        ToDate={this.state.toDate}
                        DateIndex={this.state.dateIndex}
                        SelectedDates={this.FilterDate?.bind(this)}
                        HideAllMenu={true} />
                </Box>
                <Box className='eMed_rts_dropdown'>
                  <Autocomplete
                    options={this.state.testOutSourceLabGet}
                    getOptionLabel={(options) => typeof (options) === "string" ? options : options.outsource_laboratory_name}
                    onChange={(e, value) => {
                      this.setState({
                        labName: value,
                      })
                    }}
                    value={this.state.labName ? this.state.labName : null}
                    id="combo-box-demo"
                    // sx={{ width: "29vw" }}
                    renderInput={(params) => <TextField {...params} label={t("OutsourceLaboratoryName")} size='small' inputProps={{ ...params.inputProps, type: "search", emed_tid :"OutsourceLaboratoryName_test_id" }} />}
                  />
                </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.testConfigData}
                            getOptionLabel={(option) => (option?.test_name)}
                            value={this.state.testName}
                            onChange={(event, newValue) => {
                                this.setState({ testName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Test')}
                                    inputProps={{ ...params.inputProps, type: "search", emed_tid : "Test_test_id" }}

                                />
                            )}
                        />
                    </Box>
            </Box>
            <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                <Button variant='outlined' size="small" emed_tid = "getOutSourceReport_test_id"                     
                    onClick={() => {
                        this.setState({
                            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                            dateIndex: 1,
                            testName:[],
                            labName:null,
                            page:0,
                            FilterOpen: false,
                        }, () => this.getOutSourceReport())
                    }}
                    id="eMed_Config_btn">{t("Clear")}</Button>
                <Button variant='contained' size="small"  emed_tid = "getOutSourceReport01_test_id" 
                    onClick={() => {
                        this.getOutSourceReport()
                        this.setState({ FilterOpen: false, page:0 })
                    }}
                    id="eMed_Config_btn">{t("Search")}</Button>
            </Stack>
        </Box>
    )
}

  render() {
    this.state.billData.forEach((element,index) => element.sno = index + 1 )
    const columns = [
      {
        field: 'sno', headerName: t("SNo"), sortable: false, flex: 0.05,
      },
      { field: 'tests_names', headerName: t('TestName'), sortable: false, flex: 0.141,
      renderCell:(params)=>(<div>{params.row.tests_names?.length > 15 ? <Tooltip placement='top' title={params.row.tests_names} arrow><div>{params.row.tests_names?.slice(0,15) + "..."}</div></Tooltip> : params.row.tests_names ? params.row.tests_names : "-"}</div>) },
      { field: 'bill_number', headerName: t("BillNo"), sortable: false, flex: 0.111 , headerAlign: "center", align: "center",
        renderCell: (params) => {
          let BillNumber = params?.row?.bill_number ? (params?.row?.bill_number) : (params?.row?.temp_bill_number ? params?.row?.temp_bill_number : "");
          const billArr = BillNumber ? BillNumber?.split("/") : [];
          return (
            <Tooltip placement='top' title={BillNumber} arrow>
              <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
            </Tooltip>)}
      },
      { field: 'outsource_lab_name', headerName: t('Outsource Lab Name'), sortable: false, flex: 0.141,
      renderCell:(params)=>(<div>{params.row.outsource_lab_name?.length > 15 ? <Tooltip placement='top' title={params.row.outsource_lab_name} arrow><div>{params.row.outsource_lab_name?.slice(0,15) + "..."}</div></Tooltip> : params.row.outsource_lab_name ? params.row.outsource_lab_name : "-"}</div>) },
      {
        field: 'patient_name', headerName: t("PatientName"), sortable: false, flex: 0.111 ,
      renderCell:(parmas => (<div>{(parmas?.row?.patient_name + parmas?.row?.surname)?.length > 20 ? <Tooltip title={parmas.row.patient_name + parmas?.row?.surname} arrow><div>{(parmas?.row?.patient_name + parmas?.row?.surname).slice(0,18) + "..."}</div></Tooltip> : parmas?.row?.patient_name ? parmas?.row?.patient_name + " "+ parmas?.row?.surname : "-"}</div>)) },
      { field: 'patient_account_number', headerName: t("UHID"), sortable: false, flex: 0.111 , headerAlign: "center", align: "center",
      renderCell:(params) => (<div>{params?.row?.patient_account_number ? params?.row?.patient_account_number : "-"}</div>)},
      { field: 'mobile_number', headerName: t('MobileNumber'), sortable: false, flex: 0.111 ,
      renderCell:(params)=>(<div>{params.row.mobile_number.length > 20 ? <Tooltip placement='top' title={params.row.mobile_number} arrow><div>{params.row.mobile_number.slice(0,20) + "..."}</div></Tooltip> : params.row.mobile_number ? params.row.mobile_number : "-"}</div>)},
      { field: 'bill_date' , headerName:t("BillDate"), sortable: false,flex:0.1,headerAlign:'center',align:"center" ,
      renderCell:(params)=>(<div>{params?.row?.bill_date ? params?.row?.bill_date : "-"}</div>)},
      {
        field: 'report_status', headerName: t('rtsStatus'), sortable: false, flex: 0.111, headerAlign: "center", align: "center",
        renderCell: (params) => (<div className={params.row.report_status === "Completed" ? 'paid' :
        params.row.report_status === "Delivered" ? 'paid' : 'pending'}>{params.row.report_status ? params.row.report_status : "-"}</div>)
      },
    ]
    return (
      <div className='reports_container'>
        <div className='reports_header_card'>
          <div className='reports_doctor_left'>
            <div className=''>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("TotalBill"),this.state?.totalBillCount)}
              </div>
            </div>
            <div className=''>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("pendingReports"),this.state?.pendingCount,true)}
              </div>
            </div>
            <div className=''>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("CompletedReports"),this.state?.completeCount,true)}
              </div>
            </div>
            <div className=''>
              <div className='reports_bill_box_one'>
                {AmountsCard(t("DeliveredReports"),this.state?.deliveredCount,true)}
              </div>
            </div>
          </div>
          <div style={{display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "flex-end", marginRight: '1.6vw'}}>
            <div>
            <Tooltip title="Filter" placement='top' arrow>
                <img src={ImagePaths.Filter.default} alt="filter" emed_tid='OutSource_Report_filter_btn_TestId01' className='eMed_action_img' onClick={() => { this.setState({ FilterOpen: true }) }}  style={{marginRight:'1vw',marginBottom:'0.5vw'}}  />
            </Tooltip>
            <img className='eMed_rts_upload' style={{marginRight:'1vw'}} emed_tid='OutSource_Report_uploadt_btn02' src={ImagePaths.LabPrintIcon.default} onClick={()=>{
               if(!this.state.disableBtn){
                this.getOutSourceReportPrint()
               }}} alt='print' />
            <img className='eMed_rts_upload' emed_tid='OutSource_Report_Print_btn' src={ImagePaths.LabUploadButton.default} onClick={()=>{
               if(!this.state.disableBtn){
                this.getOutsourceReportexcel()
               }}} alt='upload' />
            </div>
          </div>
        </div>

        <div className='reports_table_card'>
          <DataGrid
            rows={this.state.billData}
            columns={columns}
            page={this.state.page}
            hideFooterSelectedRowCount
            getRowId={(row) => row.sno}
            loading={this.state.isLoader}
            pageSize={this.state.pageSize}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => {
              this.setState({ pageSize: newPageSize })
            }}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            headerHeight={40}
          />
        </div>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.PopClose }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : this.state.isSuccessMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.isSuccessMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
      </div>

    )
  }

}

export default withTranslation()(OutSourceReport)