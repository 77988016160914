import React, { Component } from "react";
import {
    Box, Button, Stack, Paper, Typography, Modal,
    IconButton, TextField, Divider, RadioGroup,
    FormControlLabel, Radio, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,Popover,Tooltip
} from "@mui/material";
import './purchase.css';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid';
import { Trans, withTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { CurrencySymbol } from "../../../Utility/Constants";
const Numeric = /^\d+$/;

class VendorMonthly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor_id: this.props.vendor_id ? this.props.vendor_id : null,
            vendor_detail: {
                result: [],
                total_bill_amount: 0,
                total_paid_amount: 0,
                total_balance_amount: 0,
                total_po: 0,
                vendor_name: "",
                vendor_gstin_number: null,
                vendor_city: "",
                vendor_state: "",
                vendor_pincode: null
            },
            paymentDetail: {
                payable_amount: 0,
                payment_date: null,
                payment_made_by: "",
                comment: "",
                selectedPaymentType: null,
            },
            amountDetail: {},
            historyOfPayment: [],
            transaction_id: null,
            card_number: null,
            isReceivedClicked: false,
            isViewClicked: false,
            pageSize: 10,
            current_page: 0,
            anchorEl:null,
            disableBtn: false,
        }
    }
    componentDidMount() {
        this._getVendorPaymentMonth();
    }

    SuccessErrorBox = (message, type) => {
        this.props.responseSuccessErrorMessage(message, type)
    }

    _getVendorPaymentMonth = () => {
        let { vendor_id, vendor_detail } = this.state;

        this.setState({
            vendor_detail: {
                result: [],
                total_bill_amount: 0,
                total_paid_amount: 0,
                total_balance_amount: 0,
                total_po: 0,
                vendor_name: "",
                vendor_gstin_number: null,
                vendor_city: "",
                vendor_state: "",
                vendor_pincode: null
            }
        })

        if (vendor_id) {
            RestAPIService.getAll(`${Serviceurls.LAB_VENDOR_PAYMENT_MONTH}?vendor_id=${vendor_id}&bill_date_year=${""}`)
                .then(response => {
                    if (response.data.status == "success") {

                        vendor_detail["result"] = response.data.data ? response.data.data : [];
                        vendor_detail["total_bill_amount"] = response.data.total_bill_amount ? response.data.total_bill_amount : 0;
                        vendor_detail["total_paid_amount"] = response.data.total_paid_amount ? response.data.total_paid_amount : 0;
                        vendor_detail["total_balance_amount"] = response.data.total_balance_amount ? response.data.total_balance_amount : 0;
                        vendor_detail["total_po"] = response.data.total_po ? response.data.total_po : 0;
                        vendor_detail["vendor_name"] = response.data.vendor_name ? response.data.vendor_name : "-";
                        vendor_detail["vendor_gstin_number"] = response.data.vendor_gstin_number ? response.data.vendor_gstin_number : 0;
                        vendor_detail["vendor_city"] = response.data.vendor_city ? response.data.vendor_city : "-";
                        vendor_detail["vendor_state"] = response.data.vendor_state ? response.data.vendor_state : "-";
                        vendor_detail["vendor_pincode"] = response.data.vendor_pincode ? response.data.vendor_pincode : "-";

                        this.setState({
                            vendor_detail
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.SuccessErrorBox(error.response.data.message, false);
                    }
                });
        }
    }

    _proceedtoPay = () => {
        try{
        let { paymentDetail, amountDetail, transaction_id, card_number } = this.state
        let datas = {
            id: amountDetail["id"],
            payment_type: paymentDetail["selectedPaymentType"],
            payment_amount: parseFloat(paymentDetail["payable_amount"]),
            payment_date: DateTime.fromJSDate(paymentDetail.payment_date).toFormat("yyyy-MM-dd"),          //yyyy-mm-dd
            payment_made_by: paymentDetail["payment_made_by"],
            transaction_id: transaction_id ? (transaction_id).toString() : "",
            cheque_number: "",
            card_number: card_number ? (card_number).toString() : "",
            comments: paymentDetail["comment"] ? paymentDetail["comment"] : ""
        }
        this.setState({disableBtn: true})
        if (datas.id && datas.payment_type && datas.payment_amount) {
            RestAPIService.create(datas, Serviceurls.LAB_VENDOR_PAYMENT_MONTH)
                .then(response => {
                    if (response.data.status == "success") {
                        this.SuccessErrorBox(response.data.message, true);
                        this.handleClose();
                        this._getVendorPaymentMonth();
                        this.setState({disableBtn: false})
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.SuccessErrorBox(error.response.data.message, false);
                        this.setState({disableBtn: false})
                    }
                })
        } else {
            this.SuccessErrorBox("Kindly Enter Payment Mode and Payable Amount", false);
            this.setState({disableBtn: false})
        }
    }catch(error){
        this.SuccessErrorBox(error.message, false);
        this.setState({disableBtn: false})
    }
    }

    handleCellClick = (param, event) => { event.stopPropagation() }

    handleChange = (event) => {
        let { paymentDetail } = this.state
        let name = event.target.name;
        let value = event.target.value; 
        if(name === "payable_amount"){
            if(value == "" || CommonValidation.NumberWithDot(value)){
                paymentDetail["payable_amount"] = value;
            }
        }else{
            paymentDetail[name] = value;
        }
        this.setState({
            paymentDetail
        })
    }

    onKeyDown = (e) => {
        e.preventDefault();
    };

    renderTextBox(label, stateKey, value = '') {
        let { paymentDetail } = this.state;
        const { t } = this.props
        return (
            <div>
                {stateKey === "payment_date" ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={t(label)+ "*"}
                            name={"payment_date"}
                            autoComplete="off"
                            // size="small"
                            value={value}
                            onChange={
                                (value) => {
                                    paymentDetail["payment_date"] = value
                                    this.setState({
                                        paymentDetail
                                    })
                                }
                            }
                            renderInput={(params) => <TextField sx={{ width: "18.5vw" }} {...params} inputProps={{ ...params.inputProps,'emed_tid': 'payment_date_TestID_OP'}} onKeyDown={(e) => this.onKeyDown(e)} />}
                        />
                    </LocalizationProvider>
                    :
                    <TextField
                        className="emedhub_vendor_textBox"
                        required= {stateKey ===  "payable_amount" ? true : false}
                        // size="small"
                        sx={{ width: "18.5vw" }}
                        autoComplete="off"
                        multiline={stateKey === "comment" ? true : false}
                        rows={stateKey === "comment" ? 3 : null}
                        label={t(label)}
                        inputProps={{ emed_tid: `cb_${label}` }}
                        name={stateKey}
                        value={value ? value : ""}
                        onChange={this.handleChange}
                    />}
            </div>
        )
    }
    handleView = (event, cellvalues) => {
        let { vendor_id, historyOfPayment } = this.state
        let id = cellvalues.row.id;
        this.setState({
            historyOfPayment: []
        })
        if (id && vendor_id) {
            RestAPIService.getAll(`${Serviceurls.LAB_VENDOR_HISTORY}?vendor_id=${vendor_id}&id=${id}`)
                .then(response => {
                    if (response.data.status == "success") {
                        historyOfPayment = response.data.data ? response.data.data : [];
                        this.setState({
                            historyOfPayment
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.SuccessErrorBox(error.response.data.message, false);
                    }
                });
        }
    }

    renderPayment = (stateKey, label, value) => {
        let states = this.state;
        return (
            <Stack direction="row" spacing={3} justifyContent="space-evenly" alignItems="center" >
                <p>{label}</p>
                <TextField
                    className="emedhub_vendor_textBox"
                    size="small"
                    sx={{ width: "14.5vw" }}
                    autoComplete="off"
                    name={stateKey}
                    inputProps={{ emed_tid:` cb_${label}` }}
                    value={value ? value : ""}
                    onChange={(e) => {
                        states[e.target.name] = e.target.value;
                        this.setState({
                            states
                        })
                    }}
                />
            </Stack>
        )
    }
    handleClose = () => {
        this.setState({
            isReceivedClicked: false,
            transaction_id: null,
            card_number: null,
            amountDetail: {},
            paymentDetail: {
                payable_amount: 0,
                payment_date: null,
                payment_made_by: "",
                comment: "",
                selectedPaymentType: null
            },
        })
    }
    historyClose = () => {
        this.setState({
            isViewClicked: false,
            historyOfPayment: []
        }, () => {
            this._getVendorPaymentMonth();
        })
    }
    handlePopoverOpen = (event) => {
        this.setState({anchorEl : event.currentTarget});
      };
    
    handlePopoverClose = () => {
        this.setState({anchorEl :null});
      };
    
      getVendorPaymentMonthlyexcel = () => {
        try {
          this.setState({disableBtn: true})
          RestAPIService.excelGet(Serviceurls.LAB_VENDOR_PAYMENT_MONTH + "?export_type=excel" + "&vendor_id=" + this.state.vendor_id).
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 
        
              let filename = response.headers["content-disposition"].split("filename=")[1]
        
              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              this.setState({disableBtn: false})
              }
            }).catch((error) => {
                this.SuccessErrorBox(error.response.data.message, false);
                this.setState({disableBtn: false})
            })
        } catch (error) {
            this.SuccessErrorBox(error.message, false);
            this.setState({disableBtn: false})
        }
      }

    render() {
        const { t } = this.props
        let { vendor_detail, isReceivedClicked, isViewClicked, paymentDetail, pageSize, current_page, amountDetail ,historyOfPayment, anchorEl} = this.state;
        let balanceAmount = ((amountDetail.total_balance_amount) - (paymentDetail.payable_amount));
        const open = Boolean(anchorEl);
        const columns = [
            { field: 's.no', headerClassName: 'grid--header', headerName: 'S.No', flex: 0.04, sortable: false, hideable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: "month", headerClassName: 'grid--header', headerAlign : "center" , headerName: 'Month', flex: 0.142, sortable: false, hideable: false, align: "center" ,
            renderCell:(params)=>(<div>{params?.row?.month ? params?.row?.month : "-"}</div>)},
            { field: 'total_po', headerClassName: 'grid--header', headerName: 'Total Invoice', flex: 0.142, sortable: false, type:"number" ,
            renderCell:(params)=>(<div>{params?.row?.total_po ? params?.row?.total_po : "-"}</div>)},
            { field: 'total_bill_amount', headerClassName: 'grid--header', headerName: `Bill Amount (${CurrencySymbol})`, flex: 0.142, sortable: false, type:"number" ,
            renderCell:(params)=>(<div>{params?.row?.total_bill_amount ? AmountFormat(params?.row?.total_bill_amount).replace(`${CurrencySymbol}`,"") : "-"}</div>)},
            { field: 'total_paid_amount', headerClassName: 'grid--header', headerName: `Paid Amount (${CurrencySymbol})`, flex: 0.142, sortable: false, type:"number",
            renderCell:(params)=>(<div>{params?.row?.total_paid_amount ? params?.row?.total_paid_amount : "-"}</div>)},
            { field: 'total_balance_amount', headerClassName: 'grid--header', headerName: `OutStanding Amount (${CurrencySymbol})`, flex: 0.142, sortable: false, type:"number", renderCell: (cellvalues)=>{
                let total_balance_amount = AmountFormat(cellvalues.row["total_balance_amount"])
                return(
                        <Typography sx={{ color: cellvalues.row["total_balance_amount"] == 0 ? "#1E972A" : "#B82121" }}>
                            {total_balance_amount ? total_balance_amount.replace(`${CurrencySymbol}`,"") : "-"}
                        </Typography>
                );
            }},
            {
                field: 'payment_status', headerClassName: 'grid--header', headerAlign: 'center',headerName: 'Status', flex: 0.125, sortable: false, hideable: false, align:"center", renderCell: (cellvalues) => {
                    return ( cellvalues.row["payment_status"] ?
                        <Tooltip placement="top" title={cellvalues.row["payment_status"] == "Payment completed" ? "Completed" : "Pending"} arrow><div>
                            <img className="emedhub_popupIcon_img" src={cellvalues.row["payment_status"] == "Payment completed" ? ImagePaths.checkCircle.default : ImagePaths.pendingAction.default  } alt={"status"} />
                        </div></Tooltip> : "-"
                    )
                }
            },
            {
                field: 'actions', headerClassName: 'grid--header', headerName: 'Action', type: 'actions', flex: 0.125, sortable: false, hideable: false,
                renderCell: (cellvalues) => {
                    return (
                        <Stack direction="row" justifyContent="space-around" alignItems="center">
                            <Button  emed_tid ={"Payback_TestID"} variant="contained" size="small" sx={{ backgroundColor: cellvalues.row["payment_status"] == "Payment completed" ? "#ccccb3" : null , textTransform:"capitalize", maxHeight:"2.9vw"}} disabled={cellvalues.row["payment_status"] == "Payment completed" ? true : false} onClick={() => { this.setState({ isReceivedClicked: true, amountDetail: cellvalues.row }) }}>{cellvalues.row["payment_status"] == "Payment completed" ? t("Paid") : t("Payback")}</Button>
                            <Tooltip title ={"View"} placement="top" arrow><IconButton  emed_tid ={"view_TestID"} size="small" sx={{ borderRadius:"0.3vw"}} onClick={(event) => { this.setState({ isViewClicked: true }, () => { this.handleView(event, cellvalues) }) }} ><img  className="emedhub_directPo_img" src={ImagePaths.LabViewButton.default} alt={"view"} /></IconButton></Tooltip>
                        </Stack>
                    );
                }
            }
        ]
        return (
            <Box className="emedhub_vendorMonthly_mainBox" sx={{ '& .grid--header': { backgroundColor: '#FAF9F9' } }}>
                <Paper className="emedhub_vendorMonthly_paper" elevation={2}>
                    <Stack direction="row" sx={{ padding: "0.625vw" }} justifyContent="space-evenly" spacing={2} alignItems="start">
                    <IconButton emed_tid={"vendorPayment_TestID"} onClick={() => { this.props.goBack("vendorPayment") }} sx={{marginRight:"0.5vw"}}><img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"}/></IconButton>
                        <Stack spacing={2} direction="column">
                            <div>
                                <Typography sx={{ fontSize: "0.75vw", color: "#66686A" }}>{t("VendorName")}</Typography>
                                <Typography sx={{ fontWeight: 700 }}>{vendor_detail.vendor_name}</Typography>
                                <Typography sx={{ fontSize: "0.75vw", color: "#66686A" }}>{`${vendor_detail.vendor_city} , ${vendor_detail.vendor_state} ,`}</Typography>
                                <Typography sx={{ fontSize: "0.75vw", color: "#66686A" }}>{vendor_detail.vendor_pincode}</Typography>
                            </div>
                            <div>
                                <Typography sx={{ fontSize: "0.75vw", color: "#66686A" }}>{t("GSTNumber")}</Typography>
                                <Typography sx={{ fontWeight: 700 }}>{vendor_detail.vendor_gstin_number}</Typography>
                            </div>
                            <div>
                                <Typography sx={{ fontSize: "0.75vw", color: "#66686A" }}>{t("BillAmount")}</Typography>
                                <Typography sx={{ fontWeight: 700 }}>{`${CurrencySymbol}  ${vendor_detail.total_bill_amount}`}</Typography>
                            </div>
                        </Stack>
                        <Stack spacing={2} direction="column" justifyContent="flex-end">
                            <div>
                                <Typography sx={{ fontSize: "0.75vw", color: "#66686A" }}>{t("OutstandingAmount")}</Typography>
                                <Typography sx={{ fontWeight: 700 }}>{`${CurrencySymbol}  ${vendor_detail.total_balance_amount}`}</Typography>
                            </div>
                            <div>
                                <Typography sx={{ fontSize: "0.75vw", color: "#66686A" }}>{t("TotalPO")}</Typography>
                                <Typography sx={{ fontWeight: 700 }}>{vendor_detail.total_po}</Typography>
                            </div>
                        </Stack>
                    </Stack>
                </Paper>
                <Stack sx={{ margin: "1vw" }} spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                    <IconButton size="small" emed_tid='Vendor_Payment_Print_Btn' disabled={this.state.disableBtn}><img className="emedhub_directPo_img"  src={ImagePaths.LabPrintIcon.default} alt='print' /></IconButton>
                    <IconButton size="small" emed_tid='Vendor_Payment_Export_Btn' disabled={this.state.disableBtn}><img className="emedhub_directPo_img"  src={ImagePaths.LabUploadButton.default} onClick={()=>this.getVendorPaymentMonthlyexcel()} alt='upload' /></IconButton>
                </Stack>
                <div id="emedhub_ventorMonthly_Table">
                    <DataGrid
                        rows={vendor_detail["result"]}
                        columns={columns}
                        disableColumnFilter
                        getRowId={(row) => row.id}
                        hideFooterSelectedRowCount
                        components={{
                            NoRowsOverlay: ()=>{
                                return(
                                    <Stack direction="row" justifyContent = "center" alignItems = "center" sx={{height:"30vh"}}>
                                    {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                          }}
                        rowCount={vendor_detail["result"].length}
                        onCellClick={this.handleCellClick}
                        onRowClick={this.handleCellClick}
                        page={current_page}
                        onPageChange={(newPage) => this.setState({ current_page: newPage })}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                    />
                </div>
                {isReceivedClicked && amountDetail ?
                    <Modal open={true}>
                        <Paper elevation={3} id="emedhub_vendorMonthly_popupBox">
                            <div id="emedhub_vendorDetails_header" className="eMed_pur_back_color">
                               <Stack direction = "row" spacing={2}>
                                <Typography sx={{ fontWeight: "bold" }}>{t("Payment")}</Typography>
                                <IconButton emed_tid = {"Alert_TestID"} size="small" aria-owns={open ? 'mouse-over-popover' : undefined } aria-haspopup="true" onMouseEnter={(event)=>this.handlePopoverOpen(event)} onMouseLeave={()=>this.handlePopoverClose()} ><img  className="emedhub_popupIcon_img" src={ImagePaths.vector.default} alt={"Alert"}/></IconButton>
                                    <Popover  emed_tid = {"mouse-over-popover_TestID"} id="mouse-over-popover" sx={{ pointerEvents: 'none',padding:"0.10vw" }} open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} onClose={()=>{this.handlePopoverClose()}} disableRestoreFocus >
                                        <Stack direction="column" spacing={1} sx={{ backgroundColor: "#FFE9E9", borderRadius: "0.3vw" }}>
                                            <Stack direction="row" spacing={3}>
                                                <img className="emedhub_popupIcon_img" src={ImagePaths.vector.default} alt={"Alert"} />
                                                <Typography sx={{fontWeight:"bold"}}>{`${CurrencySymbol} ${vendor_detail["total_balance_amount"]}/-`}</Typography>
                                            </Stack>
                                            <Typography sx={{ color: "#B82121"}}>{t("OutstandingAmount")}</Typography>
                                        </Stack>
                                    </Popover>
                                </Stack>
                                <IconButton size="small" emed_tid ={"handleClose_TestID01"} onClick={() => { this.handleClose() }}><img className="emedhub_popupIcon_img" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                            </div>
                            <div>
                                <div className="emedhub_vendor_subDiv eMed_pur_txt_field">
                                    {this.renderTextBox("PayableAmount", "payable_amount", paymentDetail.payable_amount)}
                                    {this.renderTextBox("PaymentDate", "payment_date", paymentDetail.payment_date)}
                                </div>
                                <div className="emedhub_vendor_subDiv eMed_pur_txt_field">
                                    {this.renderTextBox("PaymentMadeBy", "payment_made_by", paymentDetail.payment_made_by)}
                                    {this.renderTextBox("Comment", "comment", paymentDetail.comment)}
                                </div>
                                <Divider />
                                <div style={{ padding: "0.5vw" }}>
                                    <Typography className='eMed_pur_txt_one'>{t("PaymentMethod")}</Typography>
                                    <Typography className='eMed_pur_txt_two'>{t("SelectPaymentMode")}</Typography>
                                    <Stack direction="row" spacing={3} alignItems = "center">
                                        <RadioGroup
                                            row
                                            value={paymentDetail.selectedPaymentType}
                                            onClick={(e) => {
                                                paymentDetail["selectedPaymentType"] = e.target.value
                                                this.setState({
                                                    paymentDetail
                                                },()=>{
                                                    this.setState({card_number:"",transaction_id:""})
                                                })
                                            }
                                            }
                                        >
                                            <FormControlLabel value={1} control={<Radio emed_tid ={"Cash_TestID"} size="small" />} label={t("Cash")} />
                                            <FormControlLabel value={2} control={<Radio emed_tid ={"Card_TestID"} size="small" />} label={t("Card")} />
                                            <FormControlLabel value={4} control={<Radio emed_tid ={"UPI_TestID"}size="small" />} label={t("UPI")} />
                                            <FormControlLabel value={5} control={<Radio emed_tid ={"BankTransfer_TestID"} size="small" />} label={t("BankTransfer")} />
                                        </RadioGroup>
                                        <Stack direction="row" justifyContent="flex-start" alignItems = "center">
                                            {
                                                paymentDetail["selectedPaymentType"] == 1 ? null :
                                                    paymentDetail["selectedPaymentType"] == 2 ? this.renderPayment("card_number", "Card Number", this.state.card_number) :
                                                        paymentDetail["selectedPaymentType"] == 4 ? this.renderPayment("transaction_id", "Transaction ID", this.state.transaction_id) :
                                                            paymentDetail["selectedPaymentType"] == 5 ? this.renderPayment("transaction_id", "Transaction ID", this.state.transaction_id) : null
                                            }
                                        </Stack>
                                    </Stack>
                                    <Divider />
                                    <Stack direction="column" spacing={2} alignItems="flex-start">
                                        <Typography className="eMed_pur_bill_txt">{t("Billing")}</Typography>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography sx={{ color: "#66686A" }}>{t("TotalBillingAmount")}</Typography>
                                            <Typography sx={{ color: "#66686A" }}>{`${CurrencySymbol} ${amountDetail.total_bill_amount}`}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography sx={{ color: "#66686A" }}>{t("TotalPaidAmount")}</Typography>
                                            <Typography sx={{ color: "#66686A" }}>{`${CurrencySymbol} ${amountDetail.total_paid_amount}`}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography sx={{ color: "#66686A" }}>{t("OutstandingAmount")}</Typography>
                                            <Typography sx={{ color: "#66686A" }}>{`${CurrencySymbol} ${amountDetail.total_balance_amount}`}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography sx={{ fontWeight: "bold" }}>{t("PayableAmount")}</Typography>
                                            <Typography sx={{ fontWeight: "bold" }}>{`${CurrencySymbol} ${paymentDetail.payable_amount ? paymentDetail.payable_amount : 0}`}</Typography>
                                        </Stack>
                                        {/* <Divider/> */}
                                        <Stack direction="row" spacing={3} justifyContent="space-evenly">
                                            <Typography sx={{ color: "#66686A" }}>{t("BalanceAmount")}</Typography>
                                            <Typography sx={{ color: "#66686A" }}>{`${CurrencySymbol} ${balanceAmount ? balanceAmount : 0}`}</Typography>
                                        </Stack>
                                    </Stack>
                                </div>
                                <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ marginBottom: "0.5vw" }}>
                                    <Button emed_tid ={"Cancel_testID"} size="small" variant="outlined" sx= {{textTransform:"capitalize"}} onClick={() => { this.handleClose() }} >{t('Cancel')}</Button>
                                    <Button  size="small" variant="contained" emed_tid='Vendor_Monthly_Pay_Btn' disabled={this.state.disableBtn} sx= {{textTransform:"capitalize"}} onClick={() => { this._proceedtoPay() }}>{t('Proceed')}</Button>
                                </Stack>
                            </div>
                        </Paper>
                    </Modal>
                    : null}
                {
                    isViewClicked && historyOfPayment.length > 0 ?
                        <Modal open={true}>
                            <Paper elevation={3} id="emedhub_vendorMonthly_popupBox" sx={{ padding: "1.5vw" }}>
                                <div id="emedhub_vendorDetails_header" className='eMed_pur_pay_text'>
                                    <Typography sx={{ fontWeight: "bold" }}>{t("PaymentReceived")}</Typography>
                                </div>
                                <div style={{ padding: "0.5vw" }}>
                                    <Typography sx={{ fontSize: "0.75vw", color: "#66686A" }}>{t("VendorName")}</Typography>
                                    <Typography sx={{ fontWeight: "bold" }}>{vendor_detail.vendor_name ? vendor_detail.vendor_name : "-"}</Typography>
                                </div>
                                <div>
                                    <Paper elevation={1} sx={{ borderRadius: "0.3vw" }}>
                                        <TableContainer id="emedhub_ventorMonthly_Table" sx={{ borderRadius: "0.3vw" }}>
                                            <Table stickyHeader size='small'>
                                                <TableHead className="emedhub_directpoTable_head" sx={{ height: "5vh" }}>
                                                    <TableRow>
                                                        <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{t("SNo")}</TableCell>
                                                        <TableCell sx={{ flex: 0.25 ,textAlign:"center"}} id="emedhub_directpoTable_headTxt">{t("PaymentDoneon")}</TableCell>
                                                        <TableCell sx={{ flex: 0.22 ,textAlign:"right"}} id="emedhub_directpoTable_headTxt">{t("BilledAmount")+(` (${CurrencySymbol})`)}</TableCell>
                                                        <TableCell sx={{ flex: 0.22 ,textAlign:"center"}} id="emedhub_directpoTable_headTxt"> {t("status")}</TableCell>
                                                        <TableCell sx={{ flex: 0.21 ,textAlign:"right"}} id="emedhub_directpoTable_headTxt">{t("PaidAmount") + ` (${CurrencySymbol})`}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="emedhub_directpoTable_head">

                                                    {historyOfPayment.length > 0 && historyOfPayment.map((data, index) => {
                                                        let sno = (index + 1)
                                                        let payment_date = new Date(data.payment_date)
                                                        let total_amount = data.total_amount ? AmountFormat(data["total_amount"]) : 0;
                                                        let paid_amount = data.paid_amount ? AmountFormat(data["paid_amount"]) : 0;
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell sx={{ flex: 0.1 }}>{sno}</TableCell>
                                                                <TableCell sx={{ flex: 0.25 ,textAlign:"center"}}>{payment_date ? DateTime.fromJSDate(payment_date).toFormat('dd-MM-yyyy') : null}</TableCell>
                                                                <TableCell sx={{ flex: 0.22 ,textAlign:"right"}}>{total_amount ? total_amount.replace(`${CurrencySymbol}`,"") : 0 }</TableCell>
                                                                <TableCell sx={{ flex: 0.22 ,textAlign:"center"}}>{
                                                                    <div>
                                                                        <Tooltip placement="top" title={data["payment_status"] == "Payment completed" ? "Completed" : "Pending"} arrow><img className="emedhub_popupIcon_img" src={data["payment_status"] == "Payment completed" ? ImagePaths.checkCircle.default : ImagePaths.pendingAction.default } alt={"status"} /></Tooltip>
                                                                    </div>
                                                                }</TableCell>
                                                                <TableCell sx={{ flex: 0.21,textAlign:"right" }}>{paid_amount ? paid_amount.replace(`${CurrencySymbol}`,"") : 0 }</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>

                                    <div id="emedhub_vendorDetails_footer" className="eMed_pur_footer">
                                        <div className="emedhub_vendorDetails_footer_subdiv">
                                            <div className="emedhub_vendorDetails_bulletin"></div>
                                            <p className="emedhub_vendorDetails_footer_Text1">{t('Close')}</p>
                                            <p className="emedhub_vendorDetails_footer_Text2">{t("BacktoVendorPayment")}</p>
                                        </div>
                                        <Button emed_tid ={"Close_history testID"}size="small" variant="contained" sx={{textTransform:"capitalize"}} onClick={() => { this.historyClose() }}>{t('Close')}</Button>
                                    </div>
                                </div>
                            </Paper>
                        </Modal>
                        : null
                }
            </Box>
        )
    }
}
export default withTranslation()(VendorMonthly);