import React, { Component } from 'react';
import './Config.css';
import {
    Button,
    Stack,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Tooltip,
    Box,
    InputAdornment
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import SearchIcon from '@mui/icons-material/Search';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';

class OutsourceConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            outSourceConfigData: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            outsourceLabName: '',
            GSTNno: '',
            regNo: '',
            address1: '',
            address2: '',
            city: '',
            pincode: '',
            selectState: '',
            selectedEditID: '',
            deletedPopup: false,
            deletedID: '',
            searchKey: '',
            selectedIndex: '',
            SearchText:"",
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getOutSourceConfigData()
    }

    // componentWillReceiveProps(props) {
    //     if (props.search_key != this.state.searchKey) {
    //         this.setState({
    //             searchKey: props.search_key
    //         }, () => {
    //             this.getOutSourceConfigData()
    //         })
    //     }
    // }

    msgClose(){
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
        })
      }
      LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }
    getOutSourceConfigData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_OUTSOURCE_CONFIG + "?search_key=" + this.state.searchKey)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            outSourceConfigData: response.data.data ? response.data.data : []
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch(e => {
                    this.LoaderFunction(false)
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                })

        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    postOutSourceConfigData = () => {
        try {
            var states = this.state
            var data = {
                "outs_laboratory": states.outsourceLabName ? states.outsourceLabName.replace(/\s+/g, ' ').trim() : '',
                "reg_number": states.regNo ? +states.regNo : '',
                "gstn_no": states.GSTNno ? states.GSTNno.replace(/\s+/g, ' ').trim() : '',
                "address1": states.address1 ? states.address1.replace(/\s+/g, ' ').trim() : '',
                "address2": states.address2 ? states.address2.replace(/\s+/g, ' ').trim() : '',
                "city": states.city ? states.city.replace(/\s+/g, ' ').trim() : '',
                "pincode": states.pincode ? +states.pincode : '',
                "state": states.selectState ? states.selectState.replace(/\s+/g, ' ').trim() : ''
            }
            if (this.state.selectedEditID) {
                data['outsource_id'] = this.state.selectedEditID
            }
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.LAB_OUTSOURCE_CONFIG)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getOutSourceConfigData()
                            this.setState({
                                outsourceLabName: '',
                                regNo: '',
                                GSTNno: '',
                                address1: '',
                                address2: '',
                                pincode: '',
                                city: '',
                                selectState: '',
                                selectedEditID: '',
                                disableBtn: false,

                            })
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message,
                        disableBtn: false,
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                disableBtn: false,
            })
        }
    }

    deleteOutsourceConfigData = (id) => {
        try {
            var data = {
                "outsource_id": id
            }
            RestAPIService.delete(Serviceurls.LAB_OUTSOURCE_CONFIG, data)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getOutSourceConfigData()
                        })
                    }
                }).catch((e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                }))

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    editOutSourceConfigData = (item, index) => {
        this.setState({
            selectedEditID: item.id,
            outsourceLabName: item.outsource_laboratory_name,
            regNo: item.reg_number,
            GSTNno: item.gstn_no,
            address1: item.address1,
            address2: item.address2,
            city: item.city,
            pincode: item.pincode,
            selectState: item.state,
            selectedIndex: index,
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    textInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(value.length <= 150){
        this.setState({
            [name]: value
        })};
    };

    numInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            this.setState({
                [name]: value,
            });
        }
    };

    renderTextBox = (label, width, value, name) => {
        return (
            <div>
                <TextField
                    size={'small'}
                    sx={{ width: width }}
                    label={label}
                    required = {label ==="Registration No" ? true : false}
                    rows={3}
                    inputProps={{ maxLength: name === "regNo" ? 7 : name === 'pincode' ? 6 : '', emed_tid: `${name}` }}
                    name={name}
                    value={value}
                    variant="outlined"
                    onChange={name === 'regNo' || name === "pincode" ? this.numInputcheck : this.textInputcheck}
                />
            </div>
        )
    }

    renderLeftTable = () => {
        var states = this.state
        const { t } = this.props
        //  filter table data based on search Text
        const filtereddata = this.state.outSourceConfigData.filter((data) => {
            return (
                (data.outsource_laboratory_name === null ? null : data.outsource_laboratory_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.reg_number === null ? null : JSON.stringify(data.reg_number).toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.address1 === null ? null : data.address1.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.address2 === null ? null : data.address2.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.city === null ? null : data.city.toLowerCase().includes(this.state.SearchText.toLowerCase()))||
                (data.pincode === null ? null : JSON.stringify(data.pincode).toLowerCase().includes(this.state.SearchText.toLowerCase()))
            );
        });
        return (
            <div>
                <Paper elevation={0}>
                <Box className="eMed_Search_Box_">
                        <TextField
                            variant='standard'
                            placeholder='Search'
                            emed_tid = "SearchText_Test_ID"
                            value={this.state.SearchText}
                            onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"  emed_tid = "SearchIcon_Test_ID">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '15vw' }} />
                    </Box>
                    <TableContainer className='eMed_ConfigTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ height: '7.3vh' }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("LaboratoryName")}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('REGNumber')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Address')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                                .map((data, index) => {
                                    var address = (data.address1 ? data.address1 + ", " : '') +
                                        (data.address2 ? data.address2 + ", " : '') +
                                        (data.city ? data.city + " - " : '') + (data.pincode ? data.pincode + ", " : '') + data.state
                                    var sno = (states.page * states.rowsPerPage) + (index + 1)
                                    return (
                                        <TableBody>
                                            <TableRow sx={{ bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white' }}>
                                                <TableCell align='left'><div>{sno}</div></TableCell>
                                                <TableCell align='left'>
                                                    {data.outsource_laboratory_name.length > 15 ?
                                                        <Tooltip placement="top" title={data.outsource_laboratory_name} arrow>
                                                            <div>{data.outsource_laboratory_name ? data.outsource_laboratory_name.slice(0, 15) + '...' : '-'}</div>
                                                        </Tooltip> : <div>{data.outsource_laboratory_name ? data.outsource_laboratory_name : '-'}</div>}</TableCell>
                                                <TableCell align='center'>{data.reg_number ? data.reg_number : '-'}</TableCell>
                                                <TableCell align='left'>
                                                    {address.length > 20 ?
                                                        <Tooltip placement="top" title={address} arrow>
                                                            <div>{address ? address.slice(0, 20) + '...' : '-'}</div>
                                                        </Tooltip> : <div>{address ? address : '-'}</div>}</TableCell>
                                                <TableCell align='center'>
                                                    <div className='eMed_TestConfig_Con'>
                                                        <IconButton  emed_tid = "editOutSourceConfigData_Test_ID" size='small'  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Outsource Configuration",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Outsource Configuration",  null, "Tab")?.editAccess ? ImagePaths.Edit.default : ImagePaths.DisabledEdit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                                onClick={() => { this.editOutSourceConfigData(data, index) }}
                                                            />
                                                        </IconButton>
                                                        <IconButton emed_tid = "DisabledDelete__Test_ID" size='small'  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Outsource Configuration",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Outsource Configuration",  null, "Tab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        deletedPopup: true,
                                                                        deletedID: data.id
                                                                    })
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                        </Table>
                        {filtereddata.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Paper>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata.length}
                        rowsPerPage={states.rowsPerPage}
                        page={states.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        )
    }

    clearValue = () => {
        this.setState({
            outsourceLabName: '',
            regNo: '',
            GSTNno: '',
            address1: '',
            address2: '',
            pincode: '',
            city: '',
            selectState: '',
            selectedEditID: '',
            selectedIndex: '',

        })
    }

    validCheck = () => {
        const {t} = this.props;
        var saveErrorMsg = ''
        var states = this.state
        if (!states.outsourceLabName ||
            !states.city ||
            !states.selectState) {
            saveErrorMsg = t('Fillmandatory')
        }
        return saveErrorMsg
    }

    onSave = () => {
        var inValid = this.validCheck()
        if (inValid) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: inValid
            })
        } else {
            this.postOutSourceConfigData()
        }
    }

    qualificationSelect = (event) => {
        this.setState({
            selectQualification: event.target.value
        })
    }
    clinicSelect = (event) => {
        this.setState({
            selectQualification: event.target.value
        })
    }
    PopupClose = () => {
        this.setState({
            deletedPopup: false
        })
    }
    removeDataList = () => {
        this.deleteOutsourceConfigData(this.state.deletedID)
        this.setState({
            deletedPopup: false
        })
    }
    render() {
        const { t } = this.props
        return (
            <div className='eMed_TestConfig_MainContainer'>
                <div className='eMed_TestConfig_Con'>
                    <div className='eMed_TestConfig_leftSide'>
                        {this.renderLeftTable()}
                    </div>
                    <div className='eMed_TestConfig_RightSide'>
                        <div className='eMed_TestConfig_RightHead'>
                            <p className='eMed_Testconfig_RightHead_text'>{t('OutsourceConfigration')}</p>
                        </div>
                        <div className='eMed_TestConfig_RightContentBox'>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("OutsourceLaboratoryName")+ "*", '29vw', this.state.outsourceLabName, 'outsourceLabName')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("RegistrationNo"), '29vw', this.state.regNo, 'regNo')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("GSTNNo"), '29vw', this.state.GSTNno, 'GSTNno')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Address1"), '29vw', this.state.address1, 'address1')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Address2"), '29vw', this.state.address2, 'address2')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Pincode*"), '14vw', this.state.pincode, 'pincode')}
                                {this.renderTextBox(t("City") + "*", '14vw', this.state.city, 'city')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("State") + "*", '14vw', this.state.selectState, 'selectState')}
                            </div>
                        </div>
                        <div className='eMed_TestConfig_RightBtnCon'>
                            <Stack spacing={2} direction="row">
                                <Button id='eMed_Btn_Text'  emed_tid='OutSurce_Config_clearValue_Btn01' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                                <Button id='eMed_Btn_Text' emed_tid='OutSurce_Config_Save_Btn02' disabled={this.state.disableBtn ? true : !CheckAccessFunc("Laboratory", "Configuration", "Outsource Configuration",  null, "Tab")?.editAccess}  variant='contained' onClick={() => { this.onSave() }}>{t("Save")}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("ConfirmRemovalOutsourceConfig")}
                        DeleteNotify={t("effectEntireConfig")}
                        DeletContent={t("linkedOutsource")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                 <Loader loaderOpen={this.state.isLoader} /> 
            </div>
        );
    }
}

export default withTranslation()(OutsourceConfig);