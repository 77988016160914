import React, { Component } from "react";
import { Box, Button, Chip, Modal, Typography, Tab, Tabs, FormControlLabel, Checkbox, Tooltip, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { Colors } from "../../../../Styles/Colors";
import ShowComponents from "./ShowComponent";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { UCVA_OPTIONS, PHVA_OPTION, CV_OPTIONS, NV_TYPE_OPTIONS } from "../../../../Utility/Constants";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class DocVisionAssesment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            value: "Two",
            LeftEye: {
                UCVA: "",
                PH: "",
                PG_CL: "",
                NV: "",
                CV: ""
            },
            RightEye: {
                UCVA: "",
                PH: "",
                PG_CL: "",
                NV: "",
                CV: ""
            },
            AssessmentData: [],
            leftEyeData: [],
            rightEyeData: [],
            leftEyeId: null,
            rightEyeId: null,
            Leftchecked: false,
            Rightchecked: false,
            postLoad: false,
            disableLeft: false,
            disableRight: false,
            isEdit: false,
            currentData: [],
            PreviousData: [],
            oldDataLeft: [],
            oldDataRight: [],
            selectedText: "",
            RightEyeComments: "",
            LeftEyeComments: "",
            deleteId: null,
            deletedPopup: false,
        }
        this.UCVA = React.createRef();
        this.PH = React.createRef();
        this.PG_CL = React.createRef();
        this.NV = React.createRef();
        this.CV = React.createRef();
    }

    apiCalls = () => {
        this.getVisionAssesment()
        this.getPreviousVision()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getVisionAssesment = () => {
        let states = this.state
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_OPT_VISIONASSESSMENT + `?appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        let data = []
                        data = response.data.data.vision_assessment ? response.data.data.vision_assessment : []

                        this.setState({
                            disableLeft: false,
                            disableRight: false,
                            currentData: data,
                            AssessmentData: data

                        })
                        this.processEyeData(data);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getPreviousVision = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_VISION + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = []
                        data = response.data.data ? response.data.data : [];
                        this.setState({
                            PreviousData: data
                        }, () => {
                            var oldDataLeft = []; var oldDataRight = [];
                            if (data.length > 0) {
                                data.forEach(item => {
                                    if (item.eye_type === "LE") {
                                        oldDataLeft.push(item);
                                    } else if (item.eye_type === "RE") {
                                        oldDataRight.push(item);
                                    }
                                });
                            }
                            this.setState({
                                oldDataLeft,
                                oldDataRight
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    processEyeData = (data) => {
        const leftEyeData = [];
        const rightEyeData = [];
        let leftEyeId = null;
        let rightEyeId = null;

        if (data.length === 1) {
            if (data[0].eye_type === 'LE') {
                leftEyeData.push(data[0]);
                leftEyeId = data[0].id;
            } else if (data[0].eye_type === 'RE') {
                rightEyeData.push(data[0]);
                rightEyeId = data[0].id;
            }
        } else if (data.length === 2) {
            data.forEach((item) => {
                if (item.eye_type === 'LE') {
                    leftEyeData.push(item);
                    leftEyeId = item.id;
                } else if (item.eye_type === 'RE') {
                    rightEyeData.push(item);
                    rightEyeId = item.id;
                }
            });
        }

        this.setState({
            leftEyeData,
            rightEyeData,
            leftEyeId,
            rightEyeId,
        });
    };

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        var { LeftEye, RightEye } = this.state
        if ((LeftEye.UCVA == "" && LeftEye.PH == "" && LeftEye.PG_CL == "" && LeftEye.NV == "" && LeftEye.CV == "") &&
            (RightEye.UCVA == "" && RightEye.PH == "" && RightEye.PG_CL == "" && RightEye.NV == "" && RightEye.CV == "")) {
            return "Select Any Eye Value"
        } else {
            return null
        }
    }

    addVisionAssesment = () => {
        var { LeftEye, RightEye, appointmentId, rightEyeId, leftEyeId, LeftEyeComments, RightEyeComments } = this.state
        try {
            let data = [];
            if (
                (LeftEye.UCVA != "" || LeftEye.PH != "" || LeftEye.PG_CL != "" || LeftEye.NV != "" || LeftEye.CV != "") &&
                (RightEye.UCVA != "" || RightEye.PH != "" || RightEye.PG_CL != "" || RightEye.NV != "" || RightEye.CV != "")
            ) {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "LE",
                        UCVA: LeftEye.UCVA ? LeftEye.UCVA : "",
                        PH: LeftEye.PH ? LeftEye.PH : "",
                        PGCL: LeftEye.PG_CL ? LeftEye.PG_CL : "",
                        NV: LeftEye.NV ? LeftEye.NV : "",
                        CV: LeftEye.CV ? LeftEye.CV : "",
                        DV: "",
                        comments : LeftEyeComments ? LeftEyeComments : "",
                    },
                    {
                        appointment_id: appointmentId,
                        eye_type: "RE",
                        UCVA: RightEye.UCVA ? RightEye.UCVA : "",
                        PH: RightEye.PH ? RightEye.PH : "",
                        PGCL: RightEye.PG_CL ? RightEye.PG_CL : "",
                        NV: RightEye.NV ? RightEye.NV : "",
                        CV: RightEye.CV ? RightEye.CV : "",
                        DV: "",
                        comments : RightEyeComments ? RightEyeComments : "",
                    }
                ]

                if (leftEyeId) { data[0]['id'] = leftEyeId }
                if (rightEyeId) { data[1]['id'] = rightEyeId }
            }
            else if (LeftEye.UCVA != "" || LeftEye.PH != "" || LeftEye.PG_CL != "" || LeftEye.NV != "" || LeftEye.CV != "") {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "LE",
                        UCVA: LeftEye.UCVA ? LeftEye.UCVA : "",
                        PH: LeftEye.PH ? LeftEye.PH : "",
                        PGCL: LeftEye.PG_CL ? LeftEye.PG_CL : "",
                        NV: LeftEye.NV ? LeftEye.NV : "",
                        CV: LeftEye.CV ? LeftEye.CV : "",
                        DV: "",
                        comments : LeftEyeComments ? LeftEyeComments : "",
                    }
                ]
                if (leftEyeId) { data[0]['id'] = leftEyeId }
            }
            else if (RightEye.UCVA != "" || RightEye.PH != "" || RightEye.PG_CL != "" || RightEye.NV != "" || RightEye.CV != "") {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "RE",
                        UCVA: RightEye.UCVA ? RightEye.UCVA : "",
                        PH: RightEye.PH ? RightEye.PH : "",
                        PGCL: RightEye.PG_CL ? RightEye.PG_CL : "",
                        NV: RightEye.NV ? RightEye.NV : "",
                        CV: RightEye.CV ? RightEye.CV : "",
                        DV: "",
                        comments : RightEyeComments ? RightEyeComments : "",
                    }
                ]
                if (rightEyeId) { data[0]['id'] = rightEyeId }
            }

            let validate = this.checkValidation()
            if (validate == null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_OPT_VISIONASSESSMENT)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.onClearHandler()
                            this.setState({ postLoad: false, openPopUp: false, isEdit: false }, () => {
                                this.getVisionAssesment()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else { this.errorMessage(validate) }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        if (this.state.isEdit) {
            this.setState({
                LeftEye: {
                    UCVA: "",
                    PH: "",
                    PG_CL: "",
                    NV: "",
                    CV: "",
                    id: this.state.LeftEye?.id
                },
                RightEye: {
                    UCVA: "",
                    PH: "",
                    PG_CL: "",
                    NV: "",
                    CV: "",
                    id: this.state.RightEye?.id
                },
                LeftEyeComments: "",
                RightEyeComments: "",
            })
        } else {
            this.setState({
                LeftEye: {
                    UCVA: "",
                    PH: "",
                    PG_CL: "",
                    NV: "",
                    CV: ""
                },
                RightEye: {
                    UCVA: "",
                    PH: "",
                    PG_CL: "",
                    NV: "",
                    CV: ""
                },
                leftEyeId: null,
                rightEyeId: null,
                Leftchecked: false,
                Rightchecked: false,
                postLoad: false,
                disableLeft: false,
                disableRight: false,
                value: "Two",
                LeftEyeComments: "",
                RightEyeComments: "",
            })
        }
    }

    renderNewText = (Heading, values, Tab) => {
        let states = this.state
        return (
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
                <TextField
                    label={(`Add ${Heading}`)}
                    size="small"
                    style={{ marginTop: "0.5vw", width: "20vw" }}
                    variant="outlined"
                    value={states.NewText}
                    onChange={(event) => {
                        let value = event.target.value
                        this.setState({ NewText: value })
                    }}
                />
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        if (this.state.NewText) {
                            let data = { label: CommonValidation.removeSpace(this.state.NewText), value: CommonValidation.removeSpace(this.state.NewText) }
                            values.push(data)
                            states[Tab][Heading] = this.state.NewText
                            this.setState({ states }, () => { this.setState({ selectedText: "", NewText: "" }) })
                        }
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                </div>
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        this.setState({ selectedText: "", NewText: "" })
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                </div>
            </div>
        )
    }

    renderGlassPower = (Heading, values, key, Tab, ref, scrollTo, AddButton) => {
        let states = this.state
        return (
            <div ref={ref}>
                <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{Heading}</div>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        values && values.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: Heading === "CV" ? "7vw" : "4vw" }}
                                label={item.label?.length > 6 && Heading !== "CV" ? <Tooltip placement='top' title={item?.label}><div style={{ fontSize: '0.7vw' }}>{item?.label?.slice(0, 6) + '...'}</div></Tooltip> : item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (Tab == "LeftEye" && this.state.Leftchecked) {
                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                    } else if (Tab == "RightEye" && this.state.Rightchecked) {
                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                    }
                                    if (item.label === key) {
                                        states[Tab][Heading == "PG/CL" ? "PG_CL" : Heading] = ""
                                        this.setState({ states })
                                    } else {
                                        states[Tab][Heading == "PG/CL" ? "PG_CL" : Heading] = item?.value
                                        this.setState({ states })
                                    }
                                    this.scrollToRef(scrollTo)
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
                <div>
                    {AddButton && this.state.selectedText != Heading ?
                        <Button
                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                            variant='contained'
                            onClick={() => { this.setState({ selectedText: Heading, NewText: "" }) }}
                        >Add</Button>
                        : null}
                </div>
            </div>
        )
    }

    CopyLefttoRight = (value) => {
        let { LeftEye } = this.state
        let states = this.state
        if (value) {
            states.RightEye["UCVA"] = LeftEye.UCVA
            states.RightEye["PH"] = LeftEye.PH
            states.RightEye["PG_CL"] = LeftEye.PG_CL
            states.RightEye["NV"] = LeftEye.NV
            states.RightEye["CV"] = LeftEye.CV
            states.RightEyeComments = states.LeftEyeComments ? states.LeftEyeComments : ""
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                value: this.state.isEdit ? "One" : "Two",
                states
            })
        } else {
            states.RightEye["UCVA"] = ""
            states.RightEye["PH"] = ""
            states.RightEye["PG_CL"] = ""
            states.RightEye["NV"] = ""
            states.RightEye["CV"] = ""
            states.RightEyeComments = ""
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                states
            })
        }
    }

    CopyRighttoLeft = (value) => {
        let { RightEye } = this.state
        let states = this.state
        if (value) {
            states.LeftEye["UCVA"] = RightEye.UCVA
            states.LeftEye["PH"] = RightEye.PH
            states.LeftEye["PG_CL"] = RightEye.PG_CL
            states.LeftEye["NV"] = RightEye.NV
            states.LeftEye["CV"] = RightEye.CV
            states.LeftEyeComments = states.RightEyeComments ? states.RightEyeComments : ""
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                value: this.state.isEdit ? "Two" : "One",
                states
            })
        } else {
            states.LeftEye["UCVA"] = ""
            states.LeftEye["PH"] = ""
            states.LeftEye["PG_CL"] = ""
            states.LeftEye["NV"] = ""
            states.LeftEye["CV"] = ""
            states.LeftEyeComments = ""
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                states
            })
        }
    }

    renderVissionAssesmentTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>UCVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>PH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>PG/CL</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>NV</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CV</TableCell>
                                {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rightEyeData?.length > 0 ? this.state.rightEyeData?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                    <TableCell>
                                        {item?.UCVA ? item?.UCVA?.length > 15 ?
                                            <Tooltip placement='top' title={item?.UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.PH ? item?.PH : '-'}</TableCell>
                                    <TableCell>
                                        {item?.PGCL ? item?.PGCL?.length > 15 ?
                                            <Tooltip placement='top' title={item?.PGCL}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.PGCL?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.PGCL}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.NV ? item?.NV : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CV ? item?.CV : '-'}</TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ deletedPopup: true, deleteId: item?.id }) }}
                                                emed_tid='visionAssesment_DeleteBtn_testId'
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                            {this.state.leftEyeData?.length > 0 ? this.state.leftEyeData?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                    <TableCell>
                                        {item?.UCVA ? item?.UCVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.PH ? item?.PH : '-'}</TableCell>
                                    <TableCell>
                                        {item?.PGCL ? item?.PGCL?.length > 10 ?
                                            <Tooltip placement='top' title={item?.PGCL}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.PGCL?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.PGCL}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.NV ? item?.NV : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CV ? item?.CV : '-'}</TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ deletedPopup: true, deleteId: item?.id }) }}
                                                emed_tid='visionAssesment_secondDeleteBtn_testId'
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderOldGlassPowerTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>UCVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>PH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>PG/CL</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>NV</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CV</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.oldDataRight?.length > 0 ? this.state.oldDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                    <TableCell>
                                        {item?.UCVA ? item?.UCVA?.length > 15 ?
                                            <Tooltip placement='top' title={item?.UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.PH ? item?.PH : '-'}</TableCell>
                                    <TableCell>
                                        {item?.PGCL ? item?.PGCL?.length > 15 ?
                                            <Tooltip placement='top' title={item?.PGCL}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.PGCL?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.PGCL}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.NV ? item?.NV : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CV ? item?.CV : '-'}</TableCell>
                                </TableRow>
                            )) : null}
                            {this.state.oldDataLeft?.length > 0 ? this.state.oldDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                    <TableCell>
                                        {item?.UCVA ? item?.UCVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.PH ? item?.PH : '-'}</TableCell>
                                    <TableCell>
                                        {item?.PGCL ? item?.PGCL?.length > 10 ?
                                            <Tooltip placement='top' title={item?.PGCL}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.PGCL?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.PGCL}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.NV ? item?.NV : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CV ? item?.CV : '-'}</TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRightSideValues = (Heading, value) => {
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <div>{Heading}</div>
                <div className="eMed_BoxShadow">
                    {value?.length > 13 ? <Tooltip title={value} placement='top' arrow><div>{value?.slice(0, 10) + "..."}</div></Tooltip>: value}
                </div>
            </div>
        )
    }

    scrollToRef = (ref) => {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    CopyPreviousData = () => {
        var left = []; var Right = []; var data = this.state.PreviousData
        if (data[0] && data[1]) {
            if (data[0]?.eye_type == "LE") {
                left.push(data[0])
            } else {
                Right.push(data[0])
            }
            if (data[1]?.eye_type == "RE") {
                Right.push(data[1])
            } else {
                left.push(data[1])
            }
        } else if (data[0]) {
            if (data[0]?.eye_type == "LE") {
                left.push(data[0])
            } else {
                Right.push(data[0])
            }
        }

        this.setState({
            LeftEye: {
                UCVA: left[0]?.UCVA ? left[0]?.UCVA : "",
                PH: left[0]?.PH ? left[0]?.PH : "",
                PG_CL: left[0]?.PGCL ? left[0]?.PGCL : "",
                NV: left[0]?.NV ? left[0]?.NV : "",
                CV: left[0]?.CV ? left[0]?.CV : "",
                id: this.state?.leftEyeId
            },
            RightEye: {
                UCVA: Right[0]?.UCVA ? Right[0]?.UCVA : "",
                PH: Right[0]?.PH ? Right[0]?.PH : "",
                PG_CL: Right[0]?.PGCL ? Right[0]?.PGCL : "",
                NV: Right[0]?.NV ? Right[0]?.NV : "",
                CV: Right[0]?.CV ? Right[0]?.CV : "",
                id: this.state?.rightEyeId
            }
        }, () => { this.addVisionAssesment() })
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "55vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Vision Assesment</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                            <Box component={'div'}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5vw", marginTop: "0.65vw" }}>
                                    <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Vision Assesment</Typography>
                                    <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw", marginTop: "-0.2vw" }} onClick={() => { this.CopyPreviousData() }}>
                                        <Tooltip placement='top' title='Copy'>
                                            <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>{this.renderOldGlassPowerTable()}</div>
                            </Box>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ marginTop: "0.65vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Vision Assesment</Typography>
                                        {this.renderVissionAssesmentTable()}
                                    </div>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    renderPopUpScreen = () => {
        let { LeftEye, RightEye, selectedText } = this.state
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "78vw", height: "90vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Vision Assessment</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openPopUp: false, isEdit: false }, () => { this.onClearHandler() })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{ width: "60%" }}>
                                    <Tabs
                                        value={this.state.value}
                                        onChange={(e, newValue) => this.setState({
                                            value: newValue
                                        }, () => this.scrollToRef(this.UCVA))}
                                    >
                                        <Tab value="Two" disabled={this.state.disableRight} label={"Right Eye"} className='eMed_tab_header' />
                                        <Tab value="One" disabled={this.state.disableLeft} label={"Left Eye"} className='eMed_tab_header' />
                                    </Tabs>
                                    {
                                        this.state.value === "One" ?
                                            <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "70vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                    <div>
                                                        {this.renderGlassPower("UCVA", UCVA_OPTIONS, LeftEye.UCVA, "LeftEye", this.UCVA, this.PH)}
                                                        {this.renderGlassPower("PH", PHVA_OPTION, LeftEye.PH, "LeftEye", this.PH, this.PG_CL)}
                                                        {this.renderGlassPower("PG/CL", PHVA_OPTION, LeftEye.PG_CL, "LeftEye", this.PG_CL, this.NV)}
                                                        {this.renderGlassPower("NV", NV_TYPE_OPTIONS, LeftEye.NV, "LeftEye", this.NV, this.CV)}
                                                        {this.renderGlassPower("CV", CV_OPTIONS, LeftEye.CV, "LeftEye", this.CV, this.CV, true)}
                                                        {selectedText === "CV" ? this.renderNewText("CV", CV_OPTIONS, "LeftEye") : null}
                                                    </div>
                                                </Box>
                                            </Box>
                                            : this.state.value === "Two" ?
                                                <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                    <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "70vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                        <div>
                                                            {this.renderGlassPower("UCVA", UCVA_OPTIONS, RightEye.UCVA, "RightEye", this.UCVA, this.PH)}
                                                            {this.renderGlassPower("PH", PHVA_OPTION, RightEye.PH, "RightEye", this.PH, this.PG_CL)}
                                                            {this.renderGlassPower("PG/CL", PHVA_OPTION, RightEye.PG_CL, "RightEye", this.PG_CL, this.NV)}
                                                            {this.renderGlassPower("NV", NV_TYPE_OPTIONS, RightEye.NV, "RightEye", this.NV, this.CV)}
                                                            {this.renderGlassPower("CV", CV_OPTIONS, RightEye.CV, "RightEye", this.CV, this.CV, true)}
                                                            {selectedText === "CV" ? this.renderNewText("CV", CV_OPTIONS, "RightEye") : null}
                                                        </div>
                                                    </Box>
                                                </Box>
                                                : null
                                    }
                                </div>
                                <div style={{ width: "40%" }}>
                                    <Box component={'div'} style={{ marginTop: "4.4vw", marginLeft: "1vw", height: "67vh" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                            <div style={{ fontWeight: "600", marginBottom: "0.75vw" }}>{this.state.value === "One" ? "Left Eye" : "Right Eye"}</div>
                                        </div>
                                        {this.state.value === "One" ?
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-1vw" }}>
                                                    {this.renderRightSideValues("UCVA", LeftEye.UCVA)}
                                                    {this.renderRightSideValues("PH", LeftEye.PH)}
                                                    {this.renderRightSideValues("PG/CL", LeftEye.PG_CL)}
                                                    {this.renderRightSideValues("NV", LeftEye.NV)}
                                                    {this.renderRightSideValues("CV", LeftEye.CV)}
                                                    <Box sx={{ paddingX: '0.5vw', marginTop:"2vw" }}>
                                                        <TextField
                                                            label={"Comments ( Left Eye )"}
                                                            sx={{ width: '29vw' }}
                                                            multiline={true}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    LeftEyeComments: e.target.value
                                                                })
                                                            }}
                                                            inputProps={{ maxLength: 250 }}
                                                            value={this.state.LeftEyeComments}
                                                            rows={2} />
                                                    </Box>
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Leftchecked} control={<Checkbox checked={this.state.Leftchecked} onClick={(e) => { this.CopyLefttoRight(!this.state.Leftchecked) }} />} label="Copy to Right Eye (RE)" />
                                                </Box>
                                            </div> :
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-1vw" }}>
                                                    {this.renderRightSideValues("UCVA", RightEye.UCVA)}
                                                    {this.renderRightSideValues("PH", RightEye.PH)}
                                                    {this.renderRightSideValues("PG/CL", RightEye.PG_CL)}
                                                    {this.renderRightSideValues("NV", RightEye.NV)}
                                                    {this.renderRightSideValues("CV", RightEye.CV)}
                                                    <Box sx={{ paddingX: '0.5vw', marginTop:"2vw" }}>
                                                        <TextField
                                                            label={"Comments ( Right Eye )"}
                                                            sx={{ width: '29vw' }}
                                                            multiline={true}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    RightEyeComments: e.target.value
                                                                })
                                                            }}
                                                            inputProps={{ maxLength: 250 }}
                                                            value={this.state.RightEyeComments}
                                                            rows={2} />
                                                    </Box>
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Rightchecked} control={<Checkbox checked={this.state.Rightchecked} onClick={(e) => { this.CopyRighttoLeft(!this.state.Rightchecked) }} />} label="Copy to Left Eye (LE)" />
                                                </Box>
                                            </div>}
                                    </Box>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginTop: "-0.75vw", marginRight: "0.75vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.onClearHandler() }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.addVisionAssesment() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    onEditHandler = (data) => {
        let states = this.state
        if (data?.eye_type === "RE") {
            states.RightEye["UCVA"] = data?.UCVA
            states.RightEye["PH"] = data?.PH
            states.RightEye["PG_CL"] = data?.PGCL
            states.RightEye["NV"] = data?.NV
            states.RightEye["CV"] = data?.CV
            states.rightEyeId = data?.id
            states.RightEyeComments = data?.comments ? data?.comments : ""
            this.setState({
                states,
                value: "Two",
                openPopUp: true,
                disableLeft: true,
                isEdit: true
            })
        } else {
            states.LeftEye["UCVA"] = data?.UCVA
            states.LeftEye["PH"] = data?.PH
            states.LeftEye["PG_CL"] = data?.PGCL
            states.LeftEye["NV"] = data?.NV
            states.LeftEye["CV"] = data?.CV
            states.leftEyeId = data?.id
            states.LeftEyeComments = data?.comments ? data?.comments : ""
            this.setState({
                states,
                value: "One",
                openPopUp: true,
                disableRight: true,
                isEdit: true
            })
        }
    }

    onDeleteHandler = () => {
        try{
            let id = this.state.deleteId ? this.state.deleteId : null
            RestAPIService.delete(Serviceurls.DOC_OPT_VISIONASSESSMENT + `?id=${id}`)
            .then((response)=>{
                if(response.data.status === "success"){
                    this.successMessage(response?.data?.message)
                    this.getVisionAssesment()
                    this.setState({ deletedPopup: false, deleteId: null })
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
        }catch(e){
            this.errorMessage(e?.message)
        }
    }

    closeTableDelete = () => {
        this.setState({ 
            deletedPopup: false,
            deleteId: null
        })
    }

    render() {
        let states = this.state
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: Colors.Background, height: "3.5vw" }}
                        >
                            <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} height={'3.5vw'}>
                                <Typography fontWeight={600}>Vision Assessment</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mb: "0.65vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && !this.props.isLocked) { this.CopyPreviousData() } }}>
                                            {this.state.PreviousData?.length > 0 ?
                                                <Tooltip placement='top' title='Copy Previous Subjective Refraction'>
                                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                                </Tooltip> :
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            }
                                        </div>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0) && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                                            {this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 ?
                                                <Tooltip placement='top' title='Compare'>
                                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                                </Tooltip> :
                                                <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                            }
                                        </div>
                                        <Button
                                            variant='contained'
                                            sx={{ height: '2vw', width: '5vw', textTransform: "capitalize", mt: '0.2vw', ml: '0.5vw' }}
                                            onClick={() => { this.setState({ openPopUp: true }) }}
                                            disabled={this.state.isLocked || this.props.isLocked || this.state.currentData.length > 1}
                                        >Add</Button>
                                    </div>
                                </Box>
                                <Box component={'div'}>
                                    {this.renderVissionAssesmentTable()}
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {this.renderPopUpScreen()}
                    {this.PreviousPowerPopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletedPopup ?
                        <DeletePopup DeletTitle={`Are you sure you want to delete ?`}
                            deleteAlertPopupClose={this.closeTableDelete.bind(this)}
                            removeData={this.onDeleteHandler.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents >
        )
    }
}