import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./Billings.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography } from '@mui/material';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import './Billings.css';
import { CurrencySymbol } from '../../../Utility/Constants';
const CreditTypeList = [
    { value: "Patient credit", label: "Patient Credit" },
    { value: "Insurance credit", label: "Insurance Credit" },
    { value: "Corprate credit", label: "Corporate Credit" },
]
class ChangePaymentMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Open: true,
            BillData: this.props.BillData ? this.props.BillData : {},
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            PaymentNotes: "",
            disabled:false
        }
    }
    handleClose = () => {
        this.setState({
            Open: false,
        })
        this.props.ClosePopUp()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    ClearData() {
        this.setState({
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            PaymentNotes: "",
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    PaymentModePost() {
        try {
            let { t, BillData } = this.props
            let states = this.state
            let payment = []
                if (this.state.isCash) {
                    payment.push(1)
                }
                if (this.state.isCard) {
                    payment.push(2)
                }
                if (this.state.isUPI) {
                    payment.push(3)
                }
                if (this.state.isCheque) {
                    payment.push(4)
                }
                if (this.state.isBankTranfer) {
                    payment.push(5)
                }
            let data = {
                "receipt_id": BillData?.id,
                "amount_cash": +states?.CashAmount,
                "amount_card": +states?.CardAmount,
                "card_ref_no": states?.CardTransactionNumber,
                "upi_amount": +states?.UPIAmount,
                "upi_ref_no": states?.UPINumber,
                "bank_transfer_amount": +states?.BankTfrAmount,
                "bank_ref_no": states?.BankTfrNumber,
                "cheque_amount": +states?.ChequeAmount,
                "cheque_ref_no": this.state.ChequeNumber,
                // "description": this.state.PaymentNotes,
                "payment_mode": payment,
            }
            this.setState({disabled:true})
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.PAYMENT_MODE_UPDATE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({disabled:false})
                        this.props.PaymentModeChanged(response.data.message)
                    }
                    else {
                        this.setState({disabled:false})
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({disabled:false})
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.setState({disabled:false})
            this.errorMessage(e.message)
        }
    }

    handleCheckBox = (e, key) => {
        var states = this.state
        states[key] = e.target.checked
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ CashAmount: "" }) }
            if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "" }) }
            if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
            if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "" }) }
            if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
            if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
                this.setState({ CashAmount: this.props?.BillData?.amount_received ? this.props?.BillData?.amount_received : "", })  // Fetch Full amount in Cash Amount, when only the cash is selected
            }
        })
    }

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                disabled={this.state.forCancel}
                label={t(label)}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                inputProps={{ emed_tid: `${label}_TestId` }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "15vw" }}
                inputProps={{ maxLength: 20 }}
                disabled={this.state.forCancel}
                size='small'
                label={t(label)}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    if (number || e.target.value === "") {
                        states[key] = e.target.value.toUpperCase()
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    EnteredDataIsValid() {
        try {
            let states = this.state;
            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
            let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
            let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
            if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt) == +states.BillData?.amount_received) {
                if (
                    (!states.isCash || CashAmt > 0) &&
                    (!states.isCard || CardAmt > 0) &&
                    (!states.isUPI || UPIAmt > 0) &&
                    (!states.isBankTranfer || bankTransferAmt > 0) &&
                    (!states.isCheque || ChequeAmt > 0)
                ) {
                    return true
                }
            }
            else {
                return false
            }
        }
        catch (e) {
            return false
        }
    }

    render() {
        let { t, BillData } = this.props
        let ReceiptAmount = BillData?.amount_received;
        let ReceiptNo = BillData?.receipt_number;
        let PatientName = BillData?.patient_name
        let PatientAge = BillData?.patient_age
        let PatientUHID = BillData?.patient_uhid
        let PatientMobile = BillData?.mobile_number
        let PrevPaymentMode = BillData?.payment_mode_types
        return (
            <div>
                <Dialog open={this.state.Open} >
                    <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
                        <Typography fontWeight={600} marginLeft={"1vw"}>{t("Change Payment Mode")}</Typography>
                        <Button emed_tid='LabCloseButton_TestID' onClick={() => { this.handleClose() }} >
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </Button>
                    </DialogTitle>
                    <DialogContent sx={{ paddingTop: 0, paddingBottom: 0, maxHeight: "38vw" }}>
                        <Box className="eMed_Crdtype_Header" height={"5vw"}>
                            {this.props.isFromCanteen ? null : <Box>
                                <Typography color={Colors?.grayShade}>Patient Details</Typography>
                                {
                                    PatientName?.length > 18 ? <Tooltip placement='top' title={PatientName} arrow>
                                        <Typography fontWeight={600} fontSize={"0.9vw"}>{`${PatientName.slice(0, 15) + "..."}  ${PatientAge ? `(${PatientAge})` : ""}`}</Typography>
                                    </Tooltip> : <Typography fontWeight={600} fontSize={"0.9vw"}>{`${PatientName}  ${PatientAge ? `(${PatientAge})` : ""}`}</Typography>
                                }
                                <Typography fontSize={"0.8vw"}>{`${PatientUHID} ${PatientMobile ? ` | ${PatientMobile}` : ""}`}</Typography>
                            </Box>}
                            <Box>
                                <Typography color={Colors?.grayShade}>Receipt Amount</Typography>
                                <Typography fontWeight={600}>{`${CurrencySymbol} ${ReceiptAmount}`}</Typography>
                            </Box>
                            <Box>
                                <Typography color={Colors?.grayShade}>Receipt No</Typography>
                                <Typography fontWeight={600}>{`${ReceiptNo}`}</Typography>
                            </Box>
                        </Box>
                        <Typography paddingLeft={"1vw"} paddingBottom={"0.4vw"} fontSize={"0.9vw"}>{`Payment Mode : ${PrevPaymentMode}`}</Typography>
                        <Divider />
                        <Box component={'div'} marginY={"0.5vw"}>
                            <Box ml={"0.5vw"}>
                                <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
                                {(BillData?.receipt_for === "Refund") ? null :
                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'  control={<Checkbox emed_tid='eMed_CrdPop_CheckBoxDiv_test_ID_01' checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />}
                                <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'  control={<Checkbox  emed_tid='eMed_CrdPop_CheckBoxDiv_test_ID_02' checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
                                <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'   sx={{ width: "10vw" }} control={<Checkbox emed_tid='eMed_CrdPop_CheckBoxDiv_test_ID_03' checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
                                {(BillData?.receipt_for === "Refund") || this.props.isFromCanteen ? null :
                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'  control={<Checkbox emed_tid='eMed_CrdPop_CheckBoxDiv_test_ID_04'checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} size='small' />} label={t("Cheque")} />}
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} ml={'0.5vw'} overflow={'scroll'} mt={'-0.3vw'}>
                                {
                                    this.state.isCash ?
                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                            {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                        </Box> : null
                                }
                                {
                                    this.state.isCard ?
                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                            {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                            {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                        </Box>
                                        : null
                                }
                                {
                                    this.state.isUPI ?
                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                            {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                            {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                        </Box>
                                        : null
                                }
                                {
                                    this.state.isBankTranfer ?
                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                            {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                            {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                        </Box>
                                        : null
                                }
                                {
                                    this.state.isCheque ?
                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                            {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                            {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                        </Box>
                                        : null
                                }
                            </Box>
                        </Box>
                        {/* <Box>
                            <TextField
                                sx={{ width: '100%' }}
                                multiline={true}
                                label={"Notes"}
                                rows={2}
                                inputProps={{ maxLength: 150 }}
                                variant="outlined"
                                value={this.state.PaymentNotes}
                                onChange={(e) => {
                                    this.setState({ PaymentNotes: e.target.value })
                                }}
                                helperText={`${this.state.PaymentNotes?.length} / 150`}
                            />
                        </Box> */}
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' size='small' className='eMed_Filter_Btns' emed_tid='eMed_Filter_Btns_001' onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                        <Button
                            sx={{ width: "5vw" }}
                            variant='contained' size='small'
                            className='eMed_Filter_Btns'
                            emed_tid='eMed_Filter_Btns_002'
                            disabled={!this.EnteredDataIsValid() || this.state.disabled}
                            onClick={() => {
                                this.PaymentModePost()
                            }}>{t("Change")}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}

export default withTranslation()(ChangePaymentMode)
