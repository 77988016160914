import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Divider, Autocomplete, Tooltip, Modal, Chip } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { DateTime } from "luxon";
import { FullDurationsList } from '../../../Utility/Constants';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

class NextAppointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Open: true,
            Duration: "",
            DurationType: "days",
            PatientTag: "",
            Specilazation: null,
            Doctor: null,
            AppointmentDate: this.props.AppointmentDate ? new Date(this.props.AppointmentDate) : new Date(),
            AppointmentType: "",
            PurposeOfVisit: "",
            SpecialityList: [],
            DoctorsList: [],
            filteredDocList: [],
            AppointmentTypeList: [],
            TagsList: [],
            POVList: [],
            disableBtn: false,
            isDoctorMandatory : JSON.parse(localGetItem("loggedInUserInfo"))?.token_gen_type === "Specialization" ? false : true,
            selectedSlot: "",
        }
    }
    componentDidMount() {
        this.GetDatas()
        this._getAppointTypeList()
        let POVList = localGetItem("PurposeOfVisit") === null ? '[]' : localGetItem("PurposeOfVisit")
        let array = []
        let userData = JSON.parse(localGetItem("loggedInUserInfo"))
        this.setState({ isQmsConfig: userData?.is_qms_enabled ? userData?.is_qms_enabled : false })
        JSON.parse(POVList).forEach(element => array.push(element.reason));
        if (array.length > 0) {
            this.setState({
                POVList: array
            })
        }
    }

    GetDatas = () => {
        try {
            var date = new Date(this.state.AppointmentDate)
            var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
            let urlQuery = this.props.FromDocTrans ? `?clinic_id=${this.props.clinic_id}` : `?date=${appointment_date}`
            if (appointment_date !== "Invalid DateTime") {
                RestAPIService.getAll(`${Serviceurls.FO_CONFIG_OPDOCTORNAME}${urlQuery}`).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.setState({
                                DoctorsList: response.data.data.filter((item) => (item.specialization_active === true))
                            })
                        }
                        else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch((error) => {
                        this.errorMessage(error.message)
                    })
            }

            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            SpecialityList: response.data.data.filter((item) => (item.is_active === true))
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
            let module_name = localGetItem('loggedInUserInfo')
            let userInfo = JSON.parse(module_name)
            let clinic_id = localGetItem('SelectedDoctorClinicId')
            let params = ''
            if (userInfo?.module_name === 'Doctor') {
                params = Serviceurls.FO_CONFIG_OPAPPOINTMENT + '?clinic_id=' + (clinic_id === "all" ? userInfo?.clininc_id : clinic_id)
            } else {
                params = Serviceurls.FO_CONFIG_OPAPPOINTMENT
            }
            RestAPIService.getAll(params).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentTypeList: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(this.props.FromDoctor ? `${Serviceurls.FO_CONFIG_OPPATIENTTAG}?clinic_id=${this.props.clinic_id}` : Serviceurls.FO_CONFIG_OPPATIENTTAG).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            TagsList: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    handleClose = () => {
        this.setState({
            Open: false,
        })
        this.props.NxtAppiontmentClose()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    renderSuggestionDropDown = (label, stateKey, Data) => {
        let states = this.state;
        return (
            <Autocomplete
                size='small'
                clearIcon={false}
                disabled={this.props?.is_Nurse && label === "Specialization" ? true : false}
                sx={{ width: '14vw' }}
                componentsProps={{ popper: { style: { width: label === 'Doctor' ? "23vw" : "14vw" } } }}
                options={Data}
                getOptionLabel={(item) => (label === "Doctor" ? item.doctor_name : label === "Specialization" ? item.name : label === "Patient Tag" ? item.patient_tag_name : label === "Appointment Type" ? item.appoinment_type : label === "Nurse" ? item.nurse : "")}
                value={states[stateKey]}
                renderOption={label === 'Doctor' ? (props, item) => (
                    <Box>
                        {props["data-option-index"] === 0 ?
                            <Box className="eMed_App_Doctor_table_head">
                                <Typography width={"50%"} fontWeight={'600'} fontSize={"0.8vw"}>Doctor Name</Typography>
                                <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>Total App</Typography>
                                <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>New App</Typography>
                            </Box> : null}
                        <Box component="li" className='eMed_App_Doctor_table_data' {...props}>
                            <div style={{ width: "50%" }}>{item?.doctor_name.length > 15 ? <Tooltip placement="top" title={item?.doctor_name} arrow><div>{item?.doctor_name.slice(0, 16) + "..."}</div></Tooltip> : item?.doctor_name ? item?.doctor_name : "-"}</div>
                            <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.total_appointment}</Typography>
                            <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.new_appointment}</Typography>
                        </Box>
                    </Box>) : null}
                onChange={(event, newValue) => {
                    states[stateKey] = newValue
                    if (newValue !== null && "doctor_id" in newValue) {
                        let docDetail = { ...newValue }
                        docDetail["name"] = docDetail["specialization_name"];
                        delete docDetail.specialization_name;
                        docDetail["id"] = docDetail["specialization_id"];
                        delete docDetail.specialization_id;
                        states["Specilazation"] = docDetail;
                    }
                    if (newValue !== null && "name" in newValue) {
                        let filteredLst = states["DoctorsList"].length > 0 && states["DoctorsList"].filter(item => item["specialization_id"] === newValue["id"])
                        states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
                        states["Doctor"] = null;
                    }
                    if (newValue === null && label === "Specialization") {
                        states["filteredDocList"] = states["DoctorsList"];
                    }
                    this.setState({ states })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        emed_tid = {label}
                        label={label + `${((label === "Doctor" && this.state.isDoctorMandatory) || label === "Specialization" || label === "Nurse") ? " *" : ""}`}
                        placeholder={label}
                    />
                )}
            />
        )
    }

    renderDropdown = (label, Key, List, LabelKey, ValueKey, width) => {
        let { t } = this.props
        var states = this.state
        let { selectedSplId } = this.state;
        return (
            <FormControl size='small' sx={{ width: width }}>
                <InputLabel>{t(label)}</InputLabel>
                <Select
                    MenuProps={{
                        style: { maxHeight: 350 }
                    }}
                    value={states[Key]}
                    label={t(label)}
                    onChange={(e, newValue) => {
                        states[Key] = e.target.value
                        this.setState({
                            states
                        }, () => {
                            if (Key === "DurationType") {
                                this.CalculateDate()
                                this.setState({
                                    Duration: ''
                                })
                            }
                        })
                    }}
                >
                    {
                        List?.length > 0 ?
                            List.map((list) => (
                                <MenuItem value={list[ValueKey]} onClick={() => {
                                    if ("doctor_id" in list) this.setState({ Specilazation: list["specialization_id"] });
                                    if ("name" in list) {
                                        let filteredLst = states["DoctorsList"].length > 0 && states["DoctorsList"].filter(item => item["specialization_id"] === list["id"])
                                        states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
                                        this.setState({
                                            states
                                        })
                                    }
                                }}>{list[LabelKey]}</MenuItem>
                            )) : null}
                </Select>
            </FormControl>
        )
    }
    ClearData() {
        this.setState({
            Duration: "",
            DurationType: "days",
            PatientTag: "",
            Specilazation: null,
            Doctor: null,
            AppointmentDate: new Date(),
            AppointmentType: "",
            PurposeOfVisit: "",
            filteredDocList: [],
        })
    }

    PostReschedule = () => {
        try {
            var states = this.state
            var date = new Date(states.AppointmentDate)
            var currentDate = new Date()
            if ((currentDate.getFullYear() + 1) < date.getFullYear()) {
                this.errorMessage('Appointment date should be minimum one year')
            } else {
                if (states.Specilazation && (this.state.isDoctorMandatory ? states.Doctor : true) && states.PurposeOfVisit) {
                    this.setState({ disableBtn: true })
                    var data = {
                        "appointment_id": this.props.PatientDetails?.appointment_id,
                        "appointment_status": "Reschedule",
                        "appointment_date": DateTime.fromJSDate(this.state.AppointmentDate).toFormat("yyyy-MM-d"),
                        "appointment_time": DateTime.fromJSDate(this.state.AppointmentDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
                        "appointment_type": states.AppointmentType,
                        "specialization_id": states.Specilazation?.id ? states.Specilazation?.id : null,
                        "purpose_of_visit": states.PurposeOfVisit,
                        "doctor_id": states.Doctor?.doctor_id ? states.Doctor?.doctor_id : null
                    }
                    RestAPIService.create(data, Serviceurls.FO_HOME_OP_STATUS)
                        .then(response => {
                            if (response.data.status == "Success") {
                                this.props.PostSuccess(response.data.message)
                                this.setState({ disableBtn: false })
                            }
                        }).catch(e => {
                            if (states.AppointmentDate == 'Invalid Date') {
                                this.errorMessage("Invalid Date and Time")
                            } else if (e.response.data.status === "fail") {
                                this.errorMessage(e.response.data.message)
                            } else {
                                this.errorMessage(e.message)
                            }
                            this.setState({ disableBtn: false })
                        })
                }
                else {
                    if (!states.Specilazation) {
                        this.errorMessage("Select Specilazation")
                    }
                    else if (!states.Doctor) {
                        this.errorMessage("Select Doctor")
                    }
                    else if (!states.PurposeOfVisit) {
                        this.errorMessage("Select Purpose Of Visit")
                    }
                }
            }

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    _getAppointTypeList = () => {
        let module_name = localGetItem('loggedInUserInfo')
        let userInfo = JSON.parse(module_name)
        let clinic_id = localGetItem('SelectedDoctorClinicId')
        let params = ''
        if (userInfo?.module_name === 'Doctor') {
            params = Serviceurls.FO_CONFIG_OPAPPOINTMENT + '?clinic_id=' + clinic_id
        } else {
            params = Serviceurls.FO_CONFIG_OPAPPOINTMENT
        }
        RestAPIService.getAll(params)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({
                        selectedAppointTypeId: response.data.data.find((item) => (item.appoinment_type === this.props.PatientDetails.patient_appointment_types))
                    });
                }
            })
            .catch(e => {
                this.errorMessage(e.message)
            });
    }

    postPatientStatus = () => {
        try {
            var states = this.state
            var transfer = this.props.PatientDetails
            var data = {
                "appointment_id": transfer.appointment_id,
                "appointment_status": "Completed",
                "waiting_timer": null,
                "is_transfered": true,
                "transfer_doctor_id": transfer.doctor_id
            }
            RestAPIService.create(data, Serviceurls.FO_HOME_OP_STATUS)
                .then(response => {
                    if (response.data.status == "success") {
                        this.props.PostSuccess(response.data.message)
                        this.props.getPatientDetails()
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === "fail") {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    TransferAppointment = () => {
        try {
            var states = this.state
            var transfer = this.props.PatientDetails
            var date = new Date(states.AppointmentDate)
            var currentDate = new Date()
            if ((currentDate.getFullYear() + 1) < date.getFullYear()) {
                this.errorMessage('Appointment date should be minimum one year')
            }
            else {
                if (states.Specilazation !== "" && states.Doctor !== "" && states.PurposeOfVisit !== "") {
                    this.setState({ disableBtn: true })
                    var data = {
                        patient_id: transfer?.patient_id,
                        doctor_id: states.Doctor.doctor_id ? states.Doctor.doctor_id : null,
                        speciality_id: states.Specilazation?.id ? states.Specilazation?.id : null,
                        purpose_of_visit: states.PurposeOfVisit,
                        // patient_tag_id: transfer.patient_tag_name === null ? null : transfer.patient_tag_name,
                        appointment_date: DateTime.fromJSDate(this.state.AppointmentDate).toFormat("yyyy-MM-d"),
                        appointment_start_time: DateTime.fromJSDate(this.state.AppointmentDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
                        patient_appointment_type: states.selectedAppointTypeId ? states.selectedAppointTypeId.id : null,
                        nurse_id: transfer?.nurse_id ? transfer?.nurse_id : null
                    }
                    RestAPIService.create(data, Serviceurls.FO_PATIENT_APPOINTMENT)
                        .then(response => {
                            if (response.data.status == "success") {
                                this.postPatientStatus()
                                this.setState({ disableBtn: false })
                            }
                        }).catch(e => {
                            this.setState({ disableBtn: false })
                            if (states.AppointmentDate == 'Invalid Date') {
                                this.errorMessage("Invalid Date and Time")
                            } else if (e.response.data.status === "fail") {
                                this.errorMessage(e.response.data.message)
                            } else {
                                this.errorMessage(e.message)
                            }
                        })
                } else {
                    if (states.Specilazation === "") {
                        this.errorMessage("Select Specilazation")
                    }
                    else if (states.Doctor === "") {
                        this.errorMessage("Select Doctor")
                    }
                    else if (states.PurposeOfVisit === "") {
                        this.errorMessage("Select Purpose Of Visit")
                    }
                }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    PostNxtAppointment = () => {
        try {
            var states = this.state
            var date = new Date(states.AppointmentDate)
            var currentDate = new Date()
            if ((currentDate.getFullYear() + 1) < date.getFullYear()) {
                this.errorMessage('Appointment date should be minimum one year')
            } else {
                if (states.Specilazation && (this.state.isDoctorMandatory ? states.Doctor : true) && states.PurposeOfVisit) {
                    this.setState({ disableBtn: true })
                    var data = {
                        "patient_id": this.props.PatientDetails?.patient_id,
                        "patient_account_number": this.props.PatientDetails?.patient_account_number,
                        "doctor_id": states.Doctor?.doctor_id ? states.Doctor?.doctor_id : null,
                        "speciality_id": states.Specilazation?.id ? states.Specilazation?.id : null,
                        "appointment_date": DateTime.fromJSDate(this.state.AppointmentDate).toFormat("yyyy-MM-d"),
                        "appointment_start_time": DateTime.fromJSDate(this.state.AppointmentDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
                        "purpose_of_visit": states.PurposeOfVisit,
                        "patient_tag_id": states.PatientTag,
                        "patient_appointment_type": states.AppointmentType
                    }
                    RestAPIService.create(data, Serviceurls.FO_CREATE_APPOINTMENT)
                        .then(response => {
                            if (response.data.status == "success") {
                                this.props.PostSuccess(response.data.message)
                                this.setState({ disableBtn: false })
                            }
                        }).catch(e => {
                            if (states.AppointmentDate == 'Invalid Date') {
                                this.errorMessage("Invalid Date and Time")
                            } else if (e.response.data.status === "fail") {
                                this.errorMessage(e.response.data.message)
                            } else {
                                this.errorMessage(e.message)
                            }
                            this.setState({ disableBtn: false })
                        })
                }
                else {
                    if (!states.Specilazation) {
                        this.errorMessage("Select Specilazation")
                    }
                    else if (!states.Doctor) {
                        this.errorMessage("Select Doctor")
                    }
                    else if (!states.PurposeOfVisit) {
                        this.errorMessage("Select Purpose Of Visit")
                    }
                }
            }

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    PostDocNxtAppoinment = () => {
        try {
            var states = this.state
            var date = new Date(states.AppointmentDate)
            var currentDate = new Date()
            if ((currentDate.getFullYear() + 1) < date.getFullYear()) {
                this.errorMessage('Appointment date should be minimum one year')
            } else {
                let validate = this.props.FromDocTrans ? (states.PurposeOfVisit !== "" && states.Doctor !== "") : (states.PurposeOfVisit !== "")
                if (validate) {
                    let data = {};
                    this.setState({ disableBtn: true })
                    if (this.props.FromDocTrans) {
                        // transfer doctor appoinment
                        data = {
                            "clinic_id": +this.props.clinic_id,
                            "patient_id": this.props.PatientDetails?.patient_id,
                            "doctor_id": states.Doctor.doctor_id ? states.Doctor.doctor_id : null,
                            "appointment_date": DateTime.fromJSDate(this.state.AppointmentDate).toFormat("yyyy-MM-d"),
                            "appointment_start_time": DateTime.fromJSDate(this.state.AppointmentDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
                            "purpose_of_visit": states.PurposeOfVisit,
                            "appointment_patient_type": states.AppointmentType ? states.AppointmentType : null,
                            "new_appointment": true
                        }

                    } else {
                        data = {
                            "clinic_id": +this.props.clinic_id,
                            "patient_id": this.props.PatientDetails?.patient_id,
                            "appointment_date": DateTime.fromJSDate(this.state.AppointmentDate).toFormat("yyyy-MM-d"),
                            "appointment_start_time": DateTime.fromJSDate(this.state.AppointmentDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
                            "purpose_of_visit": states.PurposeOfVisit,
                            "patient_tag_id": states.PatientTag,
                            "appointment_patient_type": states.AppointmentType,
                            "create_without_schedule": true
                        }
                    }
                    RestAPIService.create(data, Serviceurls.DOC_PATIENT_APPOINTMENT)
                        .then(response => {
                            if (response.data.status == "success") {
                                if (this.props.FromDocTrans) {
                                    let appointment_id = response?.data?.data ? response?.data?.data?.appointment_id : null
                                    this.postDocPatientStatus(appointment_id)
                                }
                                this.props.PostSuccess(response?.data?.message)
                                this.setState({ disableBtn: false })
                            }
                        }).catch(e => {
                            if (states.AppointmentDate == 'Invalid Date') {
                                this.errorMessage("Invalid Date and Time")
                            } else if (e.response.data.status === "fail") {
                                this.errorMessage(e.response?.data?.message)
                            } else {
                                this.errorMessage(e.message)
                            }
                            this.setState({ disableBtn: false })
                        })
                }
                else {
                    if (states.PurposeOfVisit === "") {
                        this.errorMessage("Select Purpose Of Visit")
                    } else if (this.props.FromDocTrans && states.Doctor === "") {
                        this.errorMessage("Select Doctor")
                    }
                    this.setState({ disableBtn: false })
                }
            }

        } catch (e) {
            this.setState({ disableBtn: false })
            this.errorMessage(e.message)
        }
    }

    postDocPatientStatus = (appoinment_id) => {
        try {
            var states = this.state
            var transfer = this.props.PatientDetails
            let clinic_id = localGetItem('SelectedDoctorClinicId')
            var data = {
                "appointment_id": transfer.appointment_id,
                "appointment_status": "Completed",
                "waiting_timer": transfer.timer ? transfer.timer : null,
                "is_transfered": true,
                "transfer_doctor_id": transfer.transfer_doctor_id ? transfer.transfer_doctor_id : "",
                "clinic_id": clinic_id,
            }
            RestAPIService.create(data, Serviceurls.DOC_APPOINTMENT_STATUS_UPDATE)
                .then(response => {
                    if (response.data.status === "success") {
                        this.props.PostSuccess(response.data.message)
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === "fail") {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    CalculateDate() {
        var currentDate = new Date();
        var reviewDay = null;
        var getReviewDate = null;
        var selectedDuration = parseInt(this.state.Duration) || 0;

        if (selectedDuration && selectedDuration > 0 && this.state.DurationType !== "") {

            let isDateCalculate = false;
            if (this.state.DurationType == "days") {
                reviewDay = selectedDuration;
                getReviewDate = new Date(currentDate.getTime() + (reviewDay * 24 * 60 * 60 * 1000));
                isDateCalculate = true;
            } else if (this.state.DurationType == "week") {
                reviewDay = selectedDuration * 7;
                getReviewDate = new Date(currentDate.getTime() + (reviewDay * 24 * 60 * 60 * 1000));
                isDateCalculate = true;
            } else if (this.state.DurationType == "month") {
                reviewDay = selectedDuration * 30;
                getReviewDate = new Date(currentDate.getTime() + (reviewDay * 24 * 60 * 60 * 1000));
                isDateCalculate = true;
            } else if (this.state.DurationType == "year") {
                reviewDay = selectedDuration * 365;
                getReviewDate = new Date(currentDate.getTime() + (reviewDay * 24 * 60 * 60 * 1000));
                isDateCalculate = true;
            }
            if (isDateCalculate) {
                this.setState({ AppointmentDate: getReviewDate , selectedSlot: "" }, () => this.GetDatas())
            }
        } else {
            this.setState({ AppointmentDate: new Date(), selectedSlot: "" }, () => this.GetDatas())
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    convertTo12Hour(time) {
        const [hour, minute] = time.split(':').map(Number);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12; // Converts "0" hour to "12"
        return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
    }

    getSlotIntervel = () => {
        var date = new Date(this.state.AppointmentDate)
        var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
        try {
            RestAPIService.getAll(Serviceurls.SCHEDULE_TIME_GET + "?date=" + appointment_date + ((this.props.FromDoctor || this.props.FromDocTrans) ? ("&clinic_id=" + localGetItem('SelectedDoctorClinicId')) : ("&doctor_id=" + this.state.Doctor?.doctor_id)))
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            slotList: response.data.data,
                            slotPopup: true
                        }, () => { })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    onClickChipHandler = (item) => {
        this.setState({ selectedSlot: item })
      }

    OpenAppointmentSlotPopUP = () => {
        return (
          <Box>
            <Modal open={true}>
              <Box className="eMed_WebCamPop_Main" sx={{ height: "40vh" }}>
                <Box component={'div'} className="eMed_WebCam_Header">
                  <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                    Select Slot
                  </Typography>
                  <Button onClick={() => { this.setState({ slotPopup: false }) }} >
                    <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                  </Button>
                </Box>
                <Box component={'div'} style={{ display: "flex", flexWrap: "wrap", width: "90%", alignItems: "center", margin: "1vw", marginLeft: "2.5vw", height: "25vh", overflow: "scroll" }}>
                  {this.state.slotList?.length > 0 ? this.state.slotList.map((item, index) => (
                    <Chip className='eMed_chip'
                      size="medium"
                      label={this.convertTo12Hour(item)}
                      variant={item === this.state.selectedSlot ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => { this.onClickChipHandler(item) }}
                      color={'primary'}
                      sx={{width:"5vw", borderRadius:"0.6vw"}}
                      clickable />)) : <Typography sx={{ height: "5vw", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "red" }}>{"Doctor Schedule not available"}</Typography>}
                </Box>
                <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingLeft: "1vw", paddingRight: "3vw" }}>
                  <Button variant='outlined' size='small' id="eMed_Config_btn" style={{ marginRight: "0.5vw" }} onClick={() => { this.setState({ selectedSlot: "" }) }}>Clear</Button>
                  <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => {
                    let AppointTime = new Date(this.state.AppointmentDate)
                    if (this.state.selectedSlot) {
                      AppointTime.setHours(this.state.selectedSlot?.split(":")[0]);
                      AppointTime.setMinutes(this.state.selectedSlot?.split(":")[1]);
                    }
                    this.setState({
                      AppointmentDate: (AppointTime && this.state.selectedSlot) ? AppointTime : this.state.AppointmentDate,
                      slotPopup: false
                    })
                  }}>Select</Button>
                </Box>
              </Box>
            </Modal>
          </Box>
        )
      }

    render() {
        let { t } = this.props
        let Address = `${this.props.PatientDetails?.patient_address_1 ? this.props.PatientDetails?.patient_address_1 + ',' : "-"}  ${this.props.PatientDetails?.patient_city ? this.props.PatientDetails?.patient_city + ',' : "-"}  ${this.props.PatientDetails?.patient_state ? this.props.PatientDetails?.patient_state + ',' : "-"} ${this.props.PatientDetails?.pincode ? this.props.PatientDetails?.pincode + '.' : "-"}`
        var currentDate = new Date()
        var oneYearFromNow = new Date();
        var states = this.state
        return (
            <div>
                <Dialog open={this.state.Open} >
                    <DialogTitle className='eMed_NxtAppPop_Title_Div'>
                        <Typography marginLeft={"1vw"}>{t(this.props.ScreenName)}</Typography>
                        <Button emed_tid = {"LabCloseButton_TestID01"} onClick={() => { this.handleClose() }} >
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_nxtAppoint_img' />
                        </Button>
                    </DialogTitle>
                    <DialogContent sx={{ paddingTop: 0, paddingBottom: 0, width: "35vw" }} >
                        <Box component={"div"} marginY={"0.5vw"}>
                            <CommonPatientDetails data={this.props.PatientDetails} />
                        </Box>
                        <Box component={"div"} className='eMed_NxtAppPop_TextDiv' >
                            <Box component={"div"} className="eMed_details_label">
                                <img src={ImagePaths.LabDateImage.default} alt="close" className='eMed_nxtAppoint_img_small' />
                                <Typography fontSize={"0.8vw"} marginLeft={"1vw"}>{formatDate(this.props.PatientDetails?.dob)}</Typography>
                            </Box>
                            <Box component={"div"} className="eMed_details_label">
                                <img src={ImagePaths.DisabledHome.default} alt="close" className='eMed_nxtAppoint_img_small' />
                                <Typography fontSize={"0.8vw"} marginLeft={"0.5vw"}>{(this.props.PatientDetails?.patient_address_1 || this.props.PatientDetails?.patient_city || this.props.PatientDetails?.patient_state) ? Address : "-"}</Typography>
                            </Box>
                        </Box>

                        {this.props.ScreenName === 'NextAppointment' ?
                            <Box component={"div"} className='eMed_NxtAppPop_Duration_div'>
                                <Grid container className='eMed_NxtAppPop_Duration_Grid'>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            value={this.state.Duration}
                                            sx={{ width: "30%" }}
                                            emed_tid = {"DurationType_TestID"} 
                                            onChange={(e) => {
                                                let number = CommonValidation.numberOnly(e.target.value);
                                                if ((number && e.target.value <= 366) && states.DurationType === 'days' || e.target.value === "") {
                                                    this.setState({ Duration: e.target.value }, () => { this.CalculateDate() })
                                                } else if ((number && e.target.value <= 52) && states.DurationType === 'week' || e.target.value === "") {
                                                    this.setState({ Duration: e.target.value }, () => { this.CalculateDate() })
                                                } else if ((number && e.target.value <= 12) && states.DurationType === 'month' || e.target.value === "") {
                                                    this.setState({ Duration: e.target.value }, () => { this.CalculateDate() })
                                                } else {
                                                    switch (states.DurationType) {
                                                        case "days": this.errorMessage(t('DSTY'))
                                                            break;
                                                        case "week": this.errorMessage(t("WSTY"))
                                                            break;
                                                        case "month": this.errorMessage(t("MSTY"))
                                                            break;
                                                        default: break
                                                    }
                                                }
                                            }} />
                                        {this.renderDropdown("DurationType", "DurationType", FullDurationsList, "label", "value", "60%")}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {this.props.ScreenName === 'NextAppointment' ?
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DateTimePicker
                                                    inputFormat='DD-MM-YYYY hh:mm A'
                                                    label={t("AppointmentDate*")}
                                                    value={this.state.AppointmentDate}
                                                    name="AppointmentDate"
                                                    maxDate={oneYearFromNow.setFullYear(currentDate.getFullYear() + 1)}
                                                    minDate={new Date()}
                                                    onChange={(newDate) => {
                                                        if (newDate === null) {
                                                            this.setState({ AppointmentDate: new Date(), DurationType: "", Duration: "", selectedSlot: "" }, () => this.GetDatas())
                                                        }
                                                        else if (newDate < new Date()) {
                                                            this.setState({ AppointmentDate: new Date(), DurationType: "", Duration: "", selectedSlot: "" }, () => this.GetDatas())
                                                        }
                                                        else {
                                                            this.setState({ AppointmentDate: newDate?.$d, DurationType: "", Duration: "", selectedSlot: "" }, () => this.GetDatas())
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField  emed_tid = {"patient_tag_name_TestID"}  style={{ width: "100%" }} size='small' {...params} />}
                                                />
                                            </LocalizationProvider> :
                                            this.renderDropdown("PatientTag", "PatientTag", this.state.TagsList, "patient_tag_name", "id", "100%")}
                                    </Grid>
                                </Grid>
                            </Box> : null
                        }
                        <Box>
                            <Grid container className='eMed_NxtAppPop_Duration_Grid'>
                                {this.props.FromDoctor || this.props.FromDocTrans ? null : <Grid item xs={6}>
                                    {this.renderSuggestionDropDown("Specialization", "Specilazation", this.state.SpecialityList)}
                                    {/* {this.renderDropdown("SelectSpecilazation*", "Specilazation", this.state.SpecialityList, "name", "id", "90%")} */}
                                </Grid>}
                                {this.props.FromDoctor ? null : <Grid item xs={6}>
                                    {this.renderSuggestionDropDown("Doctor", "Doctor", this.state.filteredDocList.length > 0 ? this.state.filteredDocList : this.state.DoctorsList)}
                                    {/* {this.renderDropdown("SelectDoctor*", "Doctor", this.state.filteredDocList.length > 0 ? this.state.filteredDocList : this.state.DoctorsList, "doctor_name", "doctor_id", "100%")} */}
                                </Grid>}
                                <Grid item xs={6} className={this.props.FromDocTrans ? "" : 'eMed_NxtAppPop_Duration_GridItem'}>
                                    {this.props.ScreenName === 'NextAppointment' ?
                                        this.renderDropdown("PatientTag", "PatientTag", this.state.TagsList, "patient_tag_name", "id", "90%") :
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DateTimePicker
                                                disabled={this.props.disableDatePicker ? this.props.disableDatePicker : false}
                                                inputFormat='DD-MM-YYYY hh:mm A'
                                                label={t("AppointmentDate*")}
                                                value={this.state.AppointmentDate}
                                                name="AppointmentDate"
                                                maxDate={this.props.AppointmentType ? new Date(this.state.AppointmentDate) : oneYearFromNow.setFullYear(currentDate.getFullYear() + 1)}
                                                minDate={new Date()}
                                                onChange={(newDate) => {
                                                    if (newDate === null) {
                                                        this.setState({ AppointmentDate: new Date(), DurationType: "", Duration: "" }, () => { this.GetDatas() })
                                                    }
                                                    else if (newDate < new Date()) {
                                                        this.setState({ AppointmentDate: new Date(), DurationType: "", Duration: "" }, () => { this.GetDatas() })
                                                    }
                                                    else {
                                                        this.setState({ AppointmentDate: newDate?.$d, DurationType: "", Duration: "" }, () => this.GetDatas())
                                                    }
                                                }}
                                                renderInput={(params) => <TextField   emed_tid = {"AppointmentDate_TestID"} style={{ width: "90%" }} size='small' {...params} />}
                                            />
                                        </LocalizationProvider>}
                                </Grid>
                                {this.props.AppointmentType ? null :
                                    <Grid item xs={6} className='eMed_NxtAppPop_Duration_GridItem'>
                                        {this.renderDropdown("AppointmentType", "AppointmentType", this.state.AppointmentTypeList, 'appoinment_type', this.props.FromDoctor ? "appoinment_type" : "id", "100%")}
                                    </Grid>
                                }
                            </Grid>
                            <Box>
                                <Button variant='outlined' disabled={this.state.AppointmentDate == null || ((this.props.FromDoctor || this.props.FromDocTrans) ? false : !this.state.Doctor?.doctor_id) || !this.state.isQmsConfig} style={{ width: "32vw", height: "2.5vw", marginBottom: '1vw' }} size="small" id="eMed_Config_btn" onClick={() => { this.getSlotIntervel() }}>{this.state.selectedSlot ? `Slot : ${this.convertTo12Hour(this.state.selectedSlot)}` : "Select Appointment Slots"}</Button>
                            </Box>
                            <Box>
                                <Autocomplete
                                    inputProps={{ maxLength: 90 }}
                                    options={states.POVList}
                                    getOptionLabel={(options) => options}
                                    onChange={(e, value) => {
                                        this.setState({
                                            PurposeOfVisit: value
                                        })
                                    }}
                                    clearIcon={false}
                                    size='small'
                                    sx={{ width: "100%" }}
                                    value={states.PurposeOfVisit}
                                    renderInput={(params) => <TextField onChange={(event) => {
                                        this.setState({
                                            PurposeOfVisit: event.target.value
                                        })
                                    }}
                                        {...params}
                                        emed_tid = {"Purpose_Of_Visit_TestID"} 
                                        placeholder="Purpose Of Visit *"
                                    />}
                                />
                            </Box>


                        </Box>
                    </DialogContent>
                    <DialogActions className='eMed_NxtAmt_POp_BtnDiv'>
                        <Button   emed_tid = {"Clear_TestID"}  variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                        <Button  disabled={this.state.disableBtn} variant='contained' size='small' className='eMed_Filter_Btns' emed_tid="Reschedule_NextAppointment_Create_Btn"
                            onClick={() => {
                                (this.props.AppointmentType && !this.props.FromDocTrans) ? this.TransferAppointment() :
                                    this.props.FromDocTrans ? this.PostDocNxtAppoinment() :
                                        (this.props.ScreenName === 'NextAppointment' && !this.props.FromDoctor) ? this.PostNxtAppointment() :
                                            this.props.FromDoctor ? this.PostDocNxtAppoinment() : this.PostReschedule()
                            }}>
                            {this.props.AppointmentType ? t('Transfer') : this.props.ScreenName === 'NextAppointment' ? t("Create") : t("Reschedule")}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.slotPopup ? this.OpenAppointmentSlotPopUP() : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}
export default withTranslation()(NextAppointments)
